<template>
    <v-dialog v-model="showNotice" width="600">
        <v-card id="holiday-notice">
            <v-container fluid>
                <v-row column justify-center align-center>
                    <v-col cols="12" class="text-center font-white">
                        <div
                            v-if="
                                messageModalContent &&
                                messageModalContent[$i18n.locale]
                            "
                        >
                            <BlockContent
                                :blocks="
                                    messageModalContent &&
                                    messageModalContent[$i18n.locale]
                                "
                                :serializers="serializers"
                            />
                        </div>
                    </v-col>
                    <v-col cols="12" class="text-center pt-0">
                        <v-btn
                            small
                            color="white"
                            class="mr-4"
                            @click="setCookie()"
                            ><TranslatableStringComponent :tstring="{string: 'close'}" /></v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>
import Cookies from 'js-cookie'
import { mapState } from 'vuex'
import serializers from '~/util/sanity/serializers'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
export default {
    name: 'HolidayNotice',
    components: {
        TranslatableStringComponent,
    },
    data() {
        return {
            isCookieSet: true,
            serializers,
        }
    },
    computed: {
        ...mapState('common', ['noticeSettings']),
        messageModalActive() {
            return process.env.V2_BASE_URL !== 'https://www.c3controls.com'
                ? this.noticeSettings?.websiteNotice?.messageModalActiveDev
                : this.noticeSettings?.websiteNotice?.messageModalActive
        },
        messageModalContent() {
            return this.noticeSettings?.websiteNotice?.messageModalContent
        },
        messageModalName() {
            return this.noticeSettings?.websiteNotice?.messageModalName
        },
        showNotice() {
            return this.messageModalActive && !this.isCookieSet
        },
    },
    watch: {
        noticeSettings: {
            handler(newVal) {
                if (newVal) {
                    this.getCookie()
                }
            },
            deep: true,
        },
    },
    methods: {
        getCookie() {
            const cookieValue = Cookies.get('Notice')
            if (cookieValue) {
                if (cookieValue === this.messageModalName)
                    this.isCookieSet = true
                else {
                    Cookies.remove('Notice')
                    this.isCookieSet = false
                }
            } else this.isCookieSet = false
        },
        setCookie() {
            Cookies.set('Notice', this.messageModalName)
            this.isCookieSet = true
        },
    },
}
</script>
