import _ from 'lodash'
export const state = () => ({
    cartProductsAvailability: [],
    loading: false,
})

export const actions = {
    async checkProductAvailabilityInRealTimeInventory(
        { commit, state, dispatch },
        payload
    ) {
        const { products, source } = payload
        const cartProductsRequestedPartNumbers = products.map((item) => {
            return {
                pn: item.pn,
                qty:
                    source === 'cart'
                        ? item.prod_quantity
                        : item.quantityInCart,
            }
        })
        commit('setLoading', true)
        const response = await this.$axios.post(
            'real-time-inventory',
            cartProductsRequestedPartNumbers
        )
        let tempCartProductsAvailability = []
        if (response.data.state === 'Failure') {
            cartProductsRequestedPartNumbers.forEach((item) => {
                tempCartProductsAvailability.push({
                    ...item,
                    alertType: 'error',
                    availabilityMessage: 'requestFailedTryAgainLater',
                    isFresh: true,
                })
            })
        } else {
            const inventorySummary = response.data
            inventorySummary.forEach((item) => {
                const leadDate = new Date(item.leadDate)
                const currentDate = new Date()
                const diffTime = Math.abs(currentDate - leadDate)
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                const productCategorySlug = products.find(
                    (i) => i.pn === item.partNumberErp
                )?.parent.en.current
                if (!item.isAvailableInERP || diffDays > 30) {
                    const availabilityMessage =
                        productCategorySlug === 'miniature-circuit-breakers'
                            ? 'mcbAvailabilityMessage'
                            : 'contactForAvailability'
                    tempCartProductsAvailability.push({
                        ...item,
                        alertType: 'error',
                        availabilityMessage,
                        isFresh: true,
                    })
                } else {
                    if (item.qtySellable >= item.qtyRequested) {
                        tempCartProductsAvailability.push({
                            ...item,
                            alertType: 'success',
                            availabilityMessage: 'availableNow',
                            isFresh: true,
                        })
                    } else {
                        tempCartProductsAvailability.push({
                            ...item,
                            alertType: 'warning',
                            availabilityMessage: 'availableOnOrBefore1',
                            isFresh: true,
                        })
                    }
                }
            })
        }
        //If call is from cart with multiple items
        if (cartProductsRequestedPartNumbers.length > 1)
            commit('setCartProductsAvailability', tempCartProductsAvailability)
        else {
            //If availability in store is 0 & call is from configurator
            if (state.cartProductsAvailability.length === 0)
                commit(
                    'setCartProductsAvailability',
                    tempCartProductsAvailability
                )
            else {
                //If quantity is changed for a product and availability is refreshed
                if (
                    state.cartProductsAvailability.find(
                        (item) =>
                            item.partNumberErp ===
                            tempCartProductsAvailability[0].partNumberErp
                    )
                ) {
                    dispatch(
                        'replaceItemInCartProductsAvailability',
                        tempCartProductsAvailability
                    )
                }
                //If new Item is added from configurator/ shop page
                else {
                    dispatch(
                        'addItemInCartProductsAvailability',
                        tempCartProductsAvailability
                    )
                }
            }
        }
        commit('setLoading', false)
    },
    debounceCartItemQtyChange: _.debounce(function ({ dispatch }, payload) {
        dispatch('checkProductAvailabilityInRealTimeInventory', payload)
    }, 1000),
    updateDataStateForAvailability({ commit, state }, payload) {
        const tempCartProductsAvailability = state.cartProductsAvailability.map(
            (item) => {
                return item.partNumberErp === payload.pn
                    ? { ...item, isFresh: false }
                    : item
            }
        )
        commit('setCartProductsAvailability', tempCartProductsAvailability)
    },
    replaceItemInCartProductsAvailability({ commit, state }, payload) {
        const tempCartProductsAvailability = state.cartProductsAvailability.map(
            (item) =>
                payload.find((i) => i.partNumberErp === item.partNumberErp) ||
                item
        )
        commit('setCartProductsAvailability', tempCartProductsAvailability)
    },
    addItemInCartProductsAvailability({ commit, state }, payload) {
        const tempCartProductsAvailability = [].concat(
            state.cartProductsAvailability,
            payload
        )
        commit('setCartProductsAvailability', tempCartProductsAvailability)
    },
    removeItemFromCartProductsAvailability({ commit, state }, payload) {
        const tempCartProductsAvailability = state.cartProductsAvailability.filter(
            (el) => {
                return !payload.find((element) => {
                    return element.pn === el.partNumberErp
                })
            }
        )
        commit('setCartProductsAvailability', tempCartProductsAvailability)
    },
}

export const mutations = {
    setCartProductsAvailability(state, payload) {
        state.cartProductsAvailability = payload
    },
    setLoading(state, payload) {
        state.loading = payload
    },
}

export const getters = {
    fetchCartProductsAvailability(state) {
        return state.cartProductsAvailability
    },
    fetchAvailabilityLoadingState(state) {
        return state.loading
    },
}
