import _ from 'lodash'
import { getPrefix } from '../util/prefixing'
import ConfigFilters from '../util/ConfigFilters'
import ProductCreator from '../util/ProductCreator'
import { childDOLSlugs } from '../util/dol-staters-helper'
import { SELECTED_CONFIG_KEY } from '~/util/localStorageHelper'

export const state = () => ({
    currentConfigurations: [],
    selectedProduct: null,
    selectedConfig: {},
    CompatibleTables: [],
})

export const actions = {
    removeConfiguration({ commit }, payload) {
        commit('removeConfigurationFromLocalStorage', payload)
    },
    fetchSelectedConfigFromLocalStorage({ commit, state, dispatch }) {
        const selectedConfig = JSON.parse(
            localStorage.getItem(SELECTED_CONFIG_KEY(state.selectedProduct._id))
        )
        commit('setSelectedConfigValue', selectedConfig || state.selectedConfig)
        dispatch('setSelectedConfig', {})
    },
    setSelectedConfig({ commit, state }, { tableId, optionId }) {
        const selectedConfig = JSON.parse(JSON.stringify(state.selectedConfig))
        if (tableId) {
            selectedConfig[tableId] = optionId
        }
        if (state.selectedProduct) {
            const { selectedProduct } = state
            const _ConfigFilters = new ConfigFilters(
                selectedProduct.productTables,
                selectedConfig
            )
            const {
                filteredTables,
                newselectedConfig,
            } = _ConfigFilters.getFilteredValues()
            commit('setSelectedConfigValue', newselectedConfig)
            commit('setCompatibleTables', filteredTables)
        }
    },
    clearConfiguration({ commit, state, dispatch }) {
        commit('setSelectedConfigValue', {})
        // dispatch('setSelectedConfig', {})
    },
    resetConfigurator({ commit, state, dispatch }) {
        commit('setSelectedConfigValue', {})
        dispatch('setSelectedConfig', {})
    },
    async saveConfig({ state }, product) {
        const { selectedProduct } = state
        try {
            const res = await this.$axios.$post('saveProductConfig', {
                ...product,
                configurator_id: selectedProduct._id,
            })
            if (res) {
                // console.log()
            }
        } catch (error) {}
    },
    async fetchProductByPartNO(_, { pn, productSlug }) {
        const conditions = [
            {
                term: {
                    pn,
                },
            },
        ]
        if (productSlug === 'direct-on-line-starters') {
            conditions.push({
                terms: {
                    'parent.en.current.keyword': childDOLSlugs,
                },
            })
        } else if (productSlug) {
            conditions.push({
                term: {
                    'parent.en.current.keyword': productSlug,
                },
            })
        }
        try {
            const res = await this.$axios.post('elastic-search', {
                elasticQuery: {
                    query: {
                        bool: {
                            should: conditions,
                        },
                    },
                },
                elasticIndex: 'products-sanity',
            })
            return res.data.hits.hits[0]
        } catch (error) {
            return undefined
        }
    },
}

export const mutations = {
    clearConfigurations(state) {
        state.currentConfigurations = []
        localStorage.clear()
    },
    removeConfigurationFromLocalStorage(state, itemId) {
        state.currentConfigurations = [...state.currentConfigurations].filter(
            (item) => item._id !== itemId
        )
    },
    initLocalConfigurations(state) {
        // payload here is localStorage
        if (localStorage.getItem('c3pn')) {
            // console.log('localStorage with cart', localStorage)
            state.cartProducts = JSON.parse(
                localStorage.getItem('c3pn')
            ).cartProducts
        }
        // else {
        //     console.log('localStorage without c3pn', localStorage)
        // }
    },
    setSelectedConfigValue(state, payload) {
        state.selectedConfig = Object.fromEntries(
            Object.entries(payload).filter(([k, v]) => typeof v === 'string')
        )
        sessionStorage.setItem(
            SELECTED_CONFIG_KEY(state.selectedProduct._id),
            JSON.stringify(state.selectedConfig)
        )
    },
    setSelectedProduct(state, payload) {
        state.selectedProduct = payload
    },
    setCompatibleTables(state, payload) {
        state.CompatibleTables = payload
    },
}

export const getters = {
    fetchConfiguration(state, itemId) {
        return [...state.currentConfigurations].filter(
            (item) => item._id === itemId
        )
    },
    isConfigured: (state) => {
        const { CompatibleTables, selectedConfig } = state
        return (
            CompatibleTables.length > 0 &&
            CompatibleTables.length === Object.keys(selectedConfig).length
        )
    },
    getProductObj({ selectedProduct, selectedConfig }) {
        if (selectedProduct) {
            const productCreator = new ProductCreator(
                selectedProduct,
                selectedConfig
            )
            return productCreator.getObj()
        }
    },
    getSKUCode: (state) => () => {
        if (!state.selectedProduct) return ''
        const { selectedProduct, selectedConfig } = state
        return selectedProduct.productTables
            .map((t) => {
                if (selectedConfig[t._id]) {
                    return getPrefix(
                        t.codePrefix,
                        t.productOptions.find(
                            (o) => o._id === selectedConfig[t._id]
                        ).code
                    )
                }
            })
            .join('')
    },
    getSKUName: (state) => () => {
        if (!state.selectedProduct) return ''
        const { selectedProduct, selectedConfig } = state
        const names = selectedProduct.productTables.map((t) => {
            if (selectedConfig[t._id]) {
                return t.productOptions.find(
                    (o) => o._id === selectedConfig[t._id]
                ).optionLabel.en
            }
        })
        return names
            .filter((n) => {
                return n !== undefined
            })
            .join(', ')
    },
    getPrice: (state) => () => {
        if (!state.selectedProduct) return ''
        const { selectedProduct, selectedConfig } = state
        const allSelectedOptions = []
        for (const key in selectedConfig) {
            if (selectedConfig.hasOwnProperty(key)) {
                allSelectedOptions.push(
                    _.chain(selectedProduct.productTables)
                        .find({ _id: key })
                        .get('productOptions')
                        .find({ _id: selectedConfig[key] })
                        .value()
                )
            }
        }
        return _.chain(allSelectedOptions).map('listPrice').sum().value()
    },
    getDetails: (state) => () => {
        if (!state.selectedConfig || _.isEmpty(state.selectedConfig))
            return null
        const { selectedProduct, selectedConfig } = state
        const allSelectedOptions = []
        for (const key in selectedConfig) {
            if (selectedConfig.hasOwnProperty(key)) {
                allSelectedOptions.push(
                    _.chain(selectedProduct.productTables)
                        .find({ _id: key })
                        .get('productOptions')
                        .find({ _id: selectedConfig[key] })
                        .value()
                )
            }
        }
        // TITLE: get the last optionData TITLE if one exists
        const title = allSelectedOptions
            .map((item) => {
                return item && item.optionData && item.optionData.title
                    ? item.optionData.title
                    : {}
            })
            .filter((item) => item._type === 'localeString')
            .pop()
        // SUBTITLE: get the last optionData SUBTITLE if one exists
        const subtitle = allSelectedOptions
            .map((item) => {
                return item && item.optionData && item.optionData.subtitle
                    ? item.optionData.subtitle
                    : {}
            })
            .filter((item) => item._type === 'localeString')
            .pop()
        // THUMBNAIL: get the last optionData THUMBNAIL if one exists
        const thumbnail = allSelectedOptions
            .map((item) => {
                return item && item.optionData && item.optionData.thumbnail
                    ? item.optionData.thumbnail
                    : {}
            })
            .filter((item) => item.imageDir && item.imageId)
            .pop()
        // GALLERY: get the last optionData GALLERY if one exists
        const gallery = allSelectedOptions
            .map((item) => {
                return item && item.optionData && item.optionData.gallery
                    ? item.optionData.gallery
                    : {}
            })
            .filter((item) => item.imageDir && item.images)
            .pop()
        // RESOURCES: get the last optionData RESOURCES (resourceTag) if one exists
        const resources = allSelectedOptions
            .map((item) => {
                return item && item.optionData && item.optionData.resourceTag
                    ? item.optionData.resourceTag
                    : []
            })
            .filter((item) => item.length > 0)
            .pop()
        // INTRO: get the last optionData INTRO (product features) if one exists
        const intro = allSelectedOptions
            .map((item) => {
                return item && item.optionData && item.optionData.intro
                    ? item.optionData.intro
                    : {}
            })
            .filter((item) => item._type === 'localeBlockContent')
            .pop()
        // INTRO EXTRAS: get the last optionData INTRO EXTRAS (secondary product features) if one exists
        const introExtra = allSelectedOptions
            .map((item) => {
                return item && item.optionData && item.optionData.introExtra
                    ? item.optionData.introExtra
                    : {}
            })
            .filter((item) => item._type === 'localeBlockContent')
            .pop()
        // CUSTOM TEXT INPUTS: get the last optionData CUSTOM TEXT INPUTS if one exists
        const customTextInputs = allSelectedOptions
            .map((item) => {
                return item &&
                    item.optionData &&
                    item.optionData.customTextInputs
                    ? item.optionData.customTextInputs
                    : []
            })
            .filter((item) => item.length > 0)
            .pop()
        return {
            title,
            subtitle,
            thumbnail,
            gallery,
            resources,
            intro,
            introExtra,
            customTextInputs,
        }
    },
}
