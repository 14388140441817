const _ = require('lodash')
var hasher = require('node-object-hash')()

module.exports = class {
    constructor(
        selectedProduct,
        selectionObj,
        isPartial = false,
        isManual = false,
        partialId = ''
    ) {
        this.selectedProduct = selectedProduct
        this.selectionObj = selectionObj
        this.isManual = isManual
        this.isPartial = isPartial
        this.partialId = partialId
    }

    getObj() {
        const selectionMap = this.getMapFromSelectionObjOptions(
            this.selectionObj
        )
        return {
            product_id: this.generateHash(this.selectionObj),
            categories: this.getCategories(selectionMap),
            categoriesObj: this.getcategoriesObj(selectionMap),
            discount_schedule: this.getDiscountSchedule(selectionMap),
            list_price: this.getPrice(selectionMap),
            img: this.getImg(selectionMap),
            name_en: this.getNames(selectionMap).en,
            name_es: this.getNames(selectionMap).es,
            parent: this.selectedProduct.slug,
            pkgqty: this.getPkgqty(selectionMap) || '',
            pn: this.getPartNo(selectionMap),
            selectedConfig: this.selectionObj,
            series: this.getSeries(selectionMap),
            tariff: this.getTariff(selectionMap),
            og: this.getOGImg(selectionMap),
            alt: this.getAlt(selectionMap),
            isPartial: this.isPartial,
            partialSlug: this.isPartial
                ? this.getPartialSlug(selectionMap)
                : null,
            gallery: this.getgallery(selectionMap),
            isManual: this.isManual,
            attributes: this.getAttributes(selectionMap),
            partialId: this.partialId,
            createdDate: new Date(),
        }
    }

    getMapFromSelectionObjOptions(selectionObj) {
        const selectionMap = new Map()
        _.map(this.selectedProduct.productTables, '_id').forEach((tabledID) => {
            if (selectionObj[tabledID]) {
                const { table, option } = this.getSelectionItemObj(
                    tabledID,
                    selectionObj[tabledID]
                )
                selectionMap.set(table, option)
            }
        })
        return selectionMap
    }
    getSelectionItemObj(tableId, optionId) {
        const table = _.chain(this.selectedProduct.productTables)
            .find({ _id: tableId })
            .value()
        return {
            table: table,
            option: _.find(table.productOptions, { _id: optionId }),
        }
    }

    getPartNo(selectionObjMap) {
        return Array.from(selectionObjMap)
            .map(([table, option]) =>
                this.getPrefix(table.codePrefix, option.code)
            )
            .join('')
    }

    getPrefix(codePrefix, code) {
        if (codePrefix === 'dash' && code.substring(0, 1) !== '-') {
            return '-' + code
        } else if (codePrefix === 'slash' && code.substring(0, 1) !== '/') {
            return '/' + code
        } else if (code === '(Blank)') {
            return ''
        } else {
            return code
        }
    }

    getPrice(selectionObjMap) {
        const options = Array.from(selectionObjMap.values())
        return _.chain(options)
            .map('listPrice')
            .filter((price) => !_.isNil(price))
            .sum()
            .value()
    }

    getCategories(selectionObjMap) {
        const allCategories = [
            ...this.selectedProduct.categories,
            ..._.map(selectionObjMap.keys(), 'categories'),
            ..._.map(selectionObjMap.values(), 'categories'),
        ].map((c) => c.title.en)

        return _.uniq(allCategories)
    }

    getcategoriesObj(selectionObjMap) {
        const allCategories = [
            ...this.selectedProduct.categories,
            ..._.map(selectionObjMap.keys(), 'categories'),
            ..._.map(selectionObjMap.values(), 'categories'),
        ]
        return _.uniqBy(allCategories, 'title.en').map((c) => ({
            _id: c._id,
            title: c.title,
        }))
    }

    getDiscountSchedule(selectionObjMap) {
        let discountSchedule = this.selectedProduct.discountSchedule
        for (let [table, option] of selectionObjMap) {
            if (table.discountSchedule) {
                discountSchedule = table.discountSchedule
            }

            if (option.discountSchedule) {
                discountSchedule = option.discountSchedule
            }
        }
        return discountSchedule.length > 0 ? discountSchedule[0].title : ''
    }

    getNames(selectionObjMap) {
        const en = [
            this.selectedProduct.description
                ? this.selectedProduct.description.en
                : '',
            ..._.map(Array.from(selectionObjMap.values()), 'description'),
        ]
            .filter((name) => name)
            .join(', ')
        const es = [
            this.selectedProduct.description
                ? this.selectedProduct.description.es
                : '',
            ..._.map(Array.from(selectionObjMap.values()), 'description'),
        ]
            .filter((name) => name)
            .join(', ')
        return { en, es }
    }

    getPkgqty(selectionObjMap) {
        let pkgqty = this.selectedProduct.pkgQty
        for (let [table, option] of selectionObjMap) {
            if (table.pkgQty) {
                pkgqty = table.pkgQty
            }

            if (option.pkgQty) {
                pkgqty = option.pkgQty
            }
        }
        return pkgqty
    }
    getTariff(selectionObjMap) {
        let tariff = this.selectedProduct.tariff
        for (let [table, option] of selectionObjMap) {
            if (table.tariff) {
                tariff = table.tariff
            }

            if (option.tariff) {
                tariff = option.tariff
            }
        }
        return tariff
    }

    getSeries(selectionObjMap) {
        let series = this.selectedProduct.series
        for (let [table, option] of selectionObjMap) {
            if (table.series) {
                series = table.series
            }

            if (option.series) {
                series = option.series
            }
        }
        return series.length > 0 ? series[0].title : ''
    }

    getImg(selectionObjMap) {
        let img = this.selectedProduct.sanityThumbnail || null
        for (let [table, option] of selectionObjMap) {
            if (table.sanityPlainthumbnail) {
                img = table.sanityPlainthumbnail
            }

            if (option.sanityPlainthumbnail) {
                img = option.sanityPlainthumbnail
            }
        }
        return img
    }
    getOGImg(selectionObjMap) {
        let ogImg = this.selectedProduct.seometa?.sanityImageOG || null
        for (let [table, option] of selectionObjMap) {
            if (table.sanityImageOG) {
                ogImg = table.sanityImageOG
            }

            if (option.sanityImageOG) {
                ogImg = option.sanityImageOG
            }
        }
        return ogImg
    }

    getAlt(selectionObjMap) {
        let altText = this.selectedProduct.sanityThumbnail.alt
            ? {
                en: this.selectedProduct.sanityThumbnail.alt.en,
                es: this.selectedProduct.sanityThumbnail.alt.es,
            }
            : { en: '', es: '' }
        for (let [table, option] of selectionObjMap) {
            if (table.sanityThumbnail) {
                altText = {
                    en: this.selectedProduct.sanityThumbnail.alt.en,
                    es: this.selectedProduct.sanityThumbnail.alt.es,
                }
            }

            if (option.sanityThumbnail) {
                altText = {
                    en: this.selectedProduct.sanityThumbnail.alt.en,
                    es: this.selectedProduct.sanityThumbnail.alt.es,
                }
            }
        }
        return altText
    }

    getPartialSlug(selectionObjMap) {
        let slug = ''
        // eslint-disable-next-line no-unused-vars
        for (let [table, option] of selectionObjMap) {
            slug += option.optionSlug
        }
        return slug
    }

    getgallery(selectionObjMap) {
        let gallery = this.selectedProduct.gallery || []
        for (let [table, option] of selectionObjMap) {
            if (table.gallery) {
                gallery = table.gallery
            }

            if (option.gallery) {
                gallery = option.gallery
            }
        }
        return gallery
    }

    getAttributes(selectionObjMap) {
        var attributesObj = {}
        let attributes = this.selectedProduct.atts || []
        for (let [table, option] of selectionObjMap) {
            if (table.atts) {
                attributes = [...attributes, ...table.atts]
            }
            if (option.atts) {
                attributes = [...attributes, ...option.atts]
            }
        }

        attributes.forEach(({ key, value, exclusive }) => {
            if (!exclusive && attributesObj[key]) {
                if (attributesObj[key].includes(value) === false) {
                    attributesObj[key].push(value)
                }
            } else {
                attributesObj[key] = [value]
            }
        })
        return attributesObj
    }

    generateHash(selectedConfig) {
        return hasher.hash({ selectedConfig, isPartial: this.isPartial })
    }
}
