import EmbedButton from '~/components/embeds/EmbedButton.vue'
import EmbedCloudinary2 from '~/components/embeds/EmbedCloudinary2.vue'
import EmbedSanityImage from '~/components/embeds/EmbedSanityImage.vue'
import EmbedEmailLink from '~/components/embeds/EmbedEmailLink.vue'
import EmbedInternalLink from '~/components/embeds/EmbedInternalLink.vue'
import EmbedPhoneLink from '~/components/embeds/EmbedPhoneLink.vue'
import EmbedSubscript from '~/components/embeds/EmbedSubscript.vue'
import EmbedSuperscript from '~/components/embeds/EmbedSuperscript.vue'
import EmbedTextJustify from '~/components/embeds/EmbedTextJustify.vue'
import EmbedTextLeft from '~/components/embeds/EmbedTextLeft.vue'
import EmbedTextRight from '~/components/embeds/EmbedTextRight.vue'
import EmbedTextCenter from '~/components/embeds/EmbedTextCenter.vue'
import EmbedFontSize from '~/components/embeds/EmbedFontSize.vue'
import EmbedBullet from '~/components/embeds/EmbedBullet.vue'
import EmbedYouTube from '~/components/embeds/EmbedYouTube.vue'
import EmbedEbook from '~/components/embeds/EmbedEbook.vue'
const serializers = {
    types: {
        youtube: EmbedYouTube,
        //cloudinary: EmbedCloudinary2,
        cloudinary: EmbedSanityImage,
        button: EmbedButton,
        eBook: EmbedEbook,
        bulletObject: EmbedBullet
    },
    marks: {
        emailLink: EmbedEmailLink,
        internalLink: EmbedInternalLink,
        phoneLink: EmbedPhoneLink,
        sub: EmbedSubscript,
        sup: EmbedSuperscript,
        justify: EmbedTextJustify,
        left: EmbedTextLeft,
        right: EmbedTextRight,
        center: EmbedTextCenter,
        fontSize: EmbedFontSize
    },
}
export { serializers as default }
