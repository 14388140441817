/*
 ** Global components
 ** SOURCE: https://github.com/nuxt/nuxt.js/issues/421
 */
import Vue from 'vue'

// import VueTour from 'vue-tour'

import VueScrollTo from 'vue-scrollto'

// block-content for parsing Sanity portable text | https://github.com/rdunk/sanity-blocks-vue-component
import BlockContent from 'sanity-blocks-vue-component'
// Sanity components
import sanityClient from '@sanity/client'
// import imageUrlBuilder from '@sanity/image-url'
// cloudinary components | https://cloudinary.com/documentation/vue_integration
// import Cloudinary from 'cloudinary-vue'

// mask
// import VueMask from 'v-mask'

// JSON LD
import NuxtJsonld from 'nuxt-jsonld'

// dev components
import cloudinary from 'cloudinary-core'
import DevOutput from '../components/DevOutput.vue'

// mixins
import getLocaleSlugs from '../mixins/getLocaleSlugs'
import getLocaleStrings from '../mixins/getLocaleStrings'
import getDollarString from '../mixins/getDollarString'
import kformatter from '../mixins/kformatter'
import handlingRoute from '../mixins/handlingRoute'

// globally importable constants, example: import { client, builder } from '~/plugins/global.js'
// SOURCE: https://forum.vuejs.org/t/global-variable-for-vue-project/32510/4
export const client = sanityClient({
    projectId: process.env.SANITY_PROJECT_ID,
    dataset: process.env.SANITY_DATASET, // production or development
    useCdn: true // process.env.DEBUG_ON !== 'true'
})

export const clientForPreview = sanityClient({
    projectId: process.env.SANITY_PROJECT_ID,
    dataset: process.env.SANITY_DATASET,
    useCdn: false,
    withCredentials: true
})

export const gtmEvent = ({
    gtm = null,
    event = '',
    eventType = 'form',
    atts = null,
    ecommerce = null
}) => {
    if (gtm && event) {
        // console.log('ATTS ARE', atts)
        const timestamp = Date.now()
        if (eventType === 'form') {
            const subject = atts && atts.subject ? atts.subject : ''
            const company = atts && atts.company ? atts.company : ''
            gtm.push({ event, subject, company, timestamp })
        } else if (event === 'addToCart') {
            const ecommerce = atts && atts.ecommerce ? atts.ecommerce : {}
            const c3source = atts && atts.c3source ? atts.c3source : ''
            gtm.push({ event, ecommerce, timestamp, c3source })
        } else if (event === 'paymentForm') {
            const webOrder = atts && atts.webOrder ? atts.webOrder : ''
            // const ecommerce = atts && atts.ecommerce ? atts.ecommerce : {}
            gtm.push({
                event,
                webOrder,
                timestamp,
                ecommerce: { ...atts.ecommerce }
            })
        }
    } else {
        console.log('gtmEvent was called but failed')
    }
}

// export const devlog = (first = null, second = null, third = null) => {
//     if (process.env.DEBUG_ON !== 'false' || process.env.DEBUG_ON !== false) {
//         if (first && second && third) {
//             console.log(first, second, third)
//         } else if (first && second) {
//             console.log(first, second)
//         } else if (first) {
//             console.log(first)
//         }
//     }
// }

Vue.component('BlockContent', BlockContent)

Vue.component('DevOutput', DevOutput)

Vue.use(NuxtJsonld)

// Vue.use(VueTour)

Vue.use(VueScrollTo)

Vue.mixin(getLocaleSlugs)

Vue.mixin(getLocaleStrings)

Vue.mixin(getDollarString)

Vue.mixin(kformatter)

Vue.mixin(handlingRoute)

export const cl = new cloudinary.Cloudinary({
    cloud_name: 'c3controls',
    cname: 'img.c3controls.com',
    private_cdn: true,
    secure_distribution: 'img.c3controls.com',
    secure: true
})
