import Cookies from 'js-cookie'
export const state = () => ({
    showSubscriptionDialog: false,
    subscriptionTab: 0,
    subscribedId: '',
    subscribedEmail: '',
    subscribedCreatedAt: null,
    showBadge: true,
})

export const actions = {}

export const mutations = {
    handleSubsciptionDialog(state, payload) {
        state.showSubscriptionDialog = !state.showSubscriptionDialog
        state.subscriptionTab = 0
    },
    handleSubscriptionTab(state, payload) {
        state.subscriptionTab = ++state.subscriptionTab
    },
    saveFormAResponse(state, payload) {
        // state.subscribedId = payload.id
        state.subscribedEmail = payload
        // state.subscribedCreatedAt = payload.createdAt
    },
    handleShowBadge(state, payload) {
        state.showBadge = payload
    }
}

export const getters = {}
