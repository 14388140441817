export default {
    computed: {
        text() {
            function findText({text, children}) {
                return text ? text : findText(children[0])
            }
            return findText(this.$slots.default[0])
        },
        tags() {
            function findTags({ tag, children, data }) {
                if(data?.style?.textDecoration === 'underline')
                    return ['underline', ...(children ? findTags(children[0]) : [])]
                else return [tag, ...(children ? findTags(children[0]) : [])]
            }
            return findTags(this.$slots.default[0])
        },
        customClass() {
            let tempClass = ''
            if (this.tags.includes('strong'))
                tempClass = tempClass + 'strong '
            if (this.tags.includes('em'))
                tempClass = tempClass + 'italic '
            if (this.tags.includes('underline'))
                tempClass = tempClass + 'underline '
            if (this.tags.includes('del'))
                tempClass = tempClass + 'strike-through '
            return tempClass
        },
    },
}
