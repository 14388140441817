const skuprefixes = [
    {
        _id: '109901_13',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '13',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '13mm-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '13mm-luces-piloto'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '13mm Pilot Lights (Indicating)',
                    es: 'Luces Piloto de 13mm (Indicadora)'
                }
            }
        }
    },
    {
        _id: '109903_22cb',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22CB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Accessories',
                    es: 'Accesorios NEMA de 22 mm'
                }
            }
        }
    },
    {
        _id: '109904_22cr',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22CR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Accessories',
                    es: 'Accesorios NEMA de 22 mm'
                }
            }
        }
    },
    {
        _id: '109905_22cs',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '22CS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109906_22dc',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '22DC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109907_22ecc',
        discountSchedule: 'H-2',
        isPublic: true,
        title: '22ECC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109908_22ee',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22EE',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Accessories',
                    es: 'Accesorios NEMA de 22 mm'
                }
            }
        }
    },
    {
        _id: '109910_22i',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22I',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Push Buttons',
                    es: '22mm NEMA Push Buttons'
                }
            }
        }
    },
    {
        _id: '109911_22k',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22K',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-selector-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-selector-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Selector Switches',
                    es: '22mm NEMA Selector Switches'
                }
            }
        }
    },
    {
        _id: '109912_22loafc',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22LOAFC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Accessories',
                    es: 'Accesorios IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '109913_22lp',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '22LP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'legend-plates' },
                    es: { _type: 'slug', current: 'legend-plates' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Legend Plates',
                    es: 'Legend Plates'
                }
            }
        }
    },
    {
        _id: '109915_22pb',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22PB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Push Buttons',
                    es: '22mm NEMA Push Buttons'
                }
            }
        }
    },
    {
        _id: '109916_22pc',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '22PC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109917_22po',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22PO',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'potentiometer-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: 'potentiometer-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Industrial Potentiometer Operators : 30mm \u0026 22mm',
                    es: 'Industrial Potentiometer Operators : 30mm \u0026 22mm'
                }
            }
        }
    },
    {
        _id: '109918_22pp',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22PP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Push Buttons',
                    es: '22mm NEMA Push Buttons'
                }
            }
        }
    },
    {
        _id: '109920_22s',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22S',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-selector-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-selector-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Selector Switches',
                    es: '22mm NEMA Selector Switches'
                }
            }
        }
    },
    {
        _id: '109922_22x',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22X',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Accessories',
                    es: 'Accesorios NEMA de 22 mm'
                }
            }
        }
    },
    {
        _id: '109924_30',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '30',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Pilot Lights',
                    es: '30mm Industrial Pilot Lights'
                }
            }
        }
    },
    {
        _id: '109928_620',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '620',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'direct-on-line-starters'
                    },
                    es: {
                        _type: 'slug',
                        current: 'direct-on-line-starters'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Direct-On-Line (DOL) Starters',
                    es: 'Direct-On-Line (DOL) Starters'
                }
            }
        }
    },
    {
        _id: '109929_630',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '630',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'direct-on-line-starters'
                    },
                    es: {
                        _type: 'slug',
                        current: 'direct-on-line-starters'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Direct-On-Line (DOL) Starters',
                    es: 'Direct-On-Line (DOL) Starters'
                }
            }
        }
    },
    {
        _id: '109931_a1',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'A1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109932_a5',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'A5',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109933_a7',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'A7',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109934_aa',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'AA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Special Operators',
                    es: '30mm Special Operators'
                }
            }
        }
    },
    {
        _id: '109935_acm',
        discountSchedule: 'F-1',
        isPublic: true,
        title: 'ACM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '109936_acp',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'ACP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Special Operators',
                    es: '30mm Special Operators'
                }
            }
        }
    },
    {
        _id: '109937_acs',
        discountSchedule: 'F-1',
        isPublic: true,
        title: 'ACS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '109938_aecr',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'AECR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '109939_ah',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'AH',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Special Operators',
                    es: '30mm Special Operators'
                }
            }
        }
    },
    {
        _id: '109940_ak',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'AK',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-selector-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-selector-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Selector Switches',
                    es: '30mm Industrial Selector Switches'
                }
            }
        }
    },
    {
        _id: '109941_am1',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'AM1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'enclosures' },
                    es: { _type: 'slug', current: 'enclosures' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosures',
                    es: 'Enclosures'
                }
            }
        }
    },
    {
        _id: '109942_am2',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'AM2',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'enclosures' },
                    es: { _type: 'slug', current: 'enclosures' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosures',
                    es: 'Enclosures'
                }
            }
        }
    },
    {
        _id: '109943_am3',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'AM3',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'enclosures' },
                    es: { _type: 'slug', current: 'enclosures' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosures',
                    es: 'Enclosures'
                }
            }
        }
    },
    {
        _id: '109944_am6',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'AM6',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'enclosures' },
                    es: { _type: 'slug', current: 'enclosures' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosures',
                    es: 'Enclosures'
                }
            }
        }
    },
    {
        _id: '109945_am7',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'AM7',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109946_am8',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'AM8',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'enclosures' },
                    es: { _type: 'slug', current: 'enclosures' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosures',
                    es: 'Enclosures'
                }
            }
        }
    },
    {
        _id: '109947_am9',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'AM9',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109948_amp',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'AMP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'enclosures' },
                    es: { _type: 'slug', current: 'enclosures' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosures',
                    es: 'Enclosures'
                }
            }
        }
    },
    {
        _id: '109949_amu',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'AMU',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'enclosures' },
                    es: { _type: 'slug', current: 'enclosures' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosures',
                    es: 'Enclosures'
                }
            }
        }
    },
    {
        _id: '109950_appac',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'APPAC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '109951_aws',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'AWS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Special Operators',
                    es: '30mm Special Operators'
                }
            }
        }
    },
    {
        _id: '109953_cpo',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'CPO',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Special Operators',
                    es: '30mm Special Operators'
                }
            }
        }
    },
    {
        _id: '109954_crw',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'CRW',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '109955_cs1',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'CS1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109956_cs11',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'CS11',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'cam-switches' },
                    es: { _type: 'slug', current: 'cam-switches' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Cam Switches',
                    es: 'Cam Switches'
                }
            }
        }
    },
    {
        _id: '109957_cs2',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'CS2',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109958_cs21',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'CS21',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'cam-switches' },
                    es: { _type: 'slug', current: 'cam-switches' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Cam Switches',
                    es: 'Cam Switches'
                }
            }
        }
    },
    {
        _id: '109959_cs3',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'CS3',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109960_cs41',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'CS41',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'cam-switches' },
                    es: { _type: 'slug', current: 'cam-switches' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Cam Switches',
                    es: 'Cam Switches'
                }
            }
        }
    },
    {
        _id: '109961_cs42',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'CS42',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'cam-switches' },
                    es: { _type: 'slug', current: 'cam-switches' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Cam Switches',
                    es: 'Cam Switches'
                }
            }
        }
    },
    {
        _id: '109962_cs51',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'CS51',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'cam-switches' },
                    es: { _type: 'slug', current: 'cam-switches' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Cam Switches',
                    es: 'Cam Switches'
                }
            }
        }
    },
    {
        _id: '109963_csl',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'CSL',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'legend-plates' },
                    es: { _type: 'slug', current: 'legend-plates' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Legend Plates',
                    es: 'Legend Plates'
                }
            }
        }
    },
    {
        _id: '109964_d1',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'D1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches : Accessories',
                    es: 'Disconnect Switches : Accessories'
                }
            }
        }
    },
    {
        _id: '109965_da',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'DA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches : Accessories',
                    es: 'Disconnect Switches : Accessories'
                }
            }
        }
    },
    {
        _id: '109966_dcm',
        discountSchedule: 'F-1',
        isPublic: true,
        title: 'DCM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '109967_dcs',
        discountSchedule: 'F-1',
        isPublic: true,
        title: 'DCS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '109968_dc1',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'DC1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109969_dc2',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'DC2',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109970_dc3',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'DC3',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109971_dcd',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'DCD',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109972_dd',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'DD',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches',
                    es: 'Disconnect Switches'
                }
            }
        }
    },
    {
        _id: '109973_dn',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'DN',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches : Accessories',
                    es: 'Disconnect Switches : Accessories'
                }
            }
        }
    },
    {
        _id: '109974_dp',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'DP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches : Accessories',
                    es: 'Disconnect Switches : Accessories'
                }
            }
        }
    },
    {
        _id: '109976_ds',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'DS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches : Accessories',
                    es: 'Disconnect Switches : Accessories'
                }
            }
        }
    },
    {
        _id: '109977_e330',
        discountSchedule: 'F-2',
        isPublic: true,
        title: 'E330',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers',
                    es: 'Motor Protection Circuit Breakers'
                }
            }
        }
    },
    {
        _id: '109979_ecp',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'ECP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'enclosures' },
                    es: { _type: 'slug', current: 'enclosures' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosures',
                    es: 'Enclosures'
                }
            }
        }
    },
    {
        _id: '109980_ecr',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'ECR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '109981_ecw',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'ECW',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109982_ed',
        discountSchedule: 'H-7',
        isPublic: true,
        title: 'ED',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches',
                    es: 'Disconnect Switches'
                }
            }
        }
    },
    {
        _id: '109983_eecr',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'EECR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '109985_fpu',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'FPU',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosure Accessories',
                    es: 'Enclosure Accessories'
                }
            }
        }
    },
    {
        _id: '109986_ftb',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'FTB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'NEMA Terminal Blocks : Accessories',
                    es: 'NEMA Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '109988_g1',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'G1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109989_g5',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'G5',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109990_g7',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'G7',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '109992_hdc',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HDC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Special Operators',
                    es: '30mm Special Operators'
                }
            }
        }
    },
    {
        _id: '109993_hdt',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'HDT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'high-density-terminal-blocks'
                    },
                    es: {
                        _type: 'slug',
                        current: 'high-density-terminal-blocks'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'High Density Terminal Blocks',
                    es: 'High Density Terminal Blocks'
                }
            }
        }
    },
    {
        _id: '109996_hp',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '109997_hp2',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'HP2',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Accessories',
                    es: 'Accesorios IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '109999_hs',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-selector-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-selector-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Hazardous Location Selector Switches',
                    es: '30mm Hazardous Location Selector Switches'
                }
            }
        }
    },
    {
        _id: '110001_js2',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'JS2',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'NEMA Terminal Blocks : Accessories',
                    es: 'NEMA Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '110002_ko',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'KO',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-selector-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-selector-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Selector Switches',
                    es: '30mm Industrial Selector Switches'
                }
            }
        }
    },
    {
        _id: '110003_lecr',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'LECR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '110004_lo',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'LO',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '110005_lp',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'LP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'legend-plates' },
                    es: { _type: 'slug', current: 'legend-plates' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Legend Plates',
                    es: 'Legend Plates'
                }
            }
        }
    },
    {
        _id: '110006_lrt',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'LRT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '110007_ma',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'MA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Accessories',
                    es: 'Accesorios IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '110008_mf',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'MF',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '16mm-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '16mm-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '16mm Pilot Lights',
                    es: '16mm Pilot Lights'
                }
            }
        }
    },
    {
        _id: '110009_mi',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'MI',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '110011_mp',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'MP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '110012_mr',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'MR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '16mm-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '16mm-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '16mm Pilot Lights',
                    es: '16mm Pilot Lights'
                }
            }
        }
    },
    {
        _id: '110013_mtb',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'MTB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'NEMA Terminal Blocks : Accessories',
                    es: 'NEMA Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '110014_mtf',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'MTF',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '16mm-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '16mm-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '16mm Pilot Lights',
                    es: '16mm Pilot Lights'
                }
            }
        }
    },
    {
        _id: '110015_p1',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'P1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '110016_p5',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'P5',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '110017_p7',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'P7',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '110018_pa12',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'PA12',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches : Accessories',
                    es: 'Disconnect Switches : Accessories'
                }
            }
        }
    },
    {
        _id: '110019_pa16d',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'PA16D',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches : Accessories',
                    es: 'Disconnect Switches : Accessories'
                }
            }
        }
    },
    {
        _id: '110020_pa18',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'PA18',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosure Accessories',
                    es: 'Enclosure Accessories'
                }
            }
        }
    },
    {
        _id: '110021_pa164',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'PA164',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosure Accessories',
                    es: 'Enclosure Accessories'
                }
            }
        }
    },
    {
        _id: '110022_pa2',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'PA2',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosure Accessories',
                    es: 'Enclosure Accessories'
                }
            }
        }
    },
    {
        _id: '110023_pa3',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'PA3',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosure Accessories',
                    es: 'Enclosure Accessories'
                }
            }
        }
    },
    {
        _id: '110024_pa7',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'PA7',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosure Accessories',
                    es: 'Enclosure Accessories'
                }
            }
        }
    },
    {
        _id: '110025_pa9',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'PA9',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosure Accessories',
                    es: 'Enclosure Accessories'
                }
            }
        }
    },
    {
        _id: '110026_pad',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'PAD',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches : Accessories',
                    es: 'Disconnect Switches : Accessories'
                }
            }
        }
    },
    {
        _id: '110027_pbo',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'PBO',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '110028_pc',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'PC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '110029_pds',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'PDS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches',
                    es: 'Disconnect Switches'
                }
            }
        }
    },
    {
        _id: '110030_pdb',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'PDB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks'
                    },
                    es: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'NEMA Terminal Blocks',
                    es: 'NEMA Terminal Blocks'
                }
            }
        }
    },
    {
        _id: '110031_pg',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'PG',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '110032_pla',
        discountSchedule: 'G-6',
        isPublic: true,
        title: 'PLA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'enclosure-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Enclosure Accessories',
                    es: 'Enclosure Accessories'
                }
            }
        }
    },
    {
        _id: '110034_po',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'PO',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'potentiometer-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: 'potentiometer-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Industrial Potentiometer Operators : 30mm \u0026 22mm',
                    es: 'Industrial Potentiometer Operators : 30mm \u0026 22mm'
                }
            }
        }
    },
    {
        _id: '110036_pr',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'PR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '110037_ptb',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'PTB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks'
                    },
                    es: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'NEMA Terminal Blocks',
                    es: 'NEMA Terminal Blocks'
                }
            }
        }
    },
    {
        _id: '110038_ptr',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'PTR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '110040_rm',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'RM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '110041_rpb',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'RPB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Special Operators',
                    es: '30mm Special Operators'
                }
            }
        }
    },
    {
        _id: '110042_rtlu',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'RTLU',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Pilot Lights',
                    es: '30mm Industrial Pilot Lights'
                }
            }
        }
    },
    {
        _id: '110043_sr',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'SR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-selector-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-selector-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Selector Switches',
                    es: '30mm Industrial Selector Switches'
                }
            }
        }
    },
    {
        _id: '110044_ss',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'SS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-selector-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-selector-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Selector Switches',
                    es: '30mm Industrial Selector Switches'
                }
            }
        }
    },
    {
        _id: '110045_tbk',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'TBK',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Special Operators',
                    es: '30mm Special Operators'
                }
            }
        }
    },
    {
        _id: '110047_tt',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'TT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '110049_wdb',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'WDB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Accessories',
                    es: 'Accesorios IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '110051_we',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'WE',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Accessories',
                    es: 'Accesorios IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '110052_wn',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'WN',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Accessories',
                    es: 'Accesorios IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '110053_ws',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'WS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Special Operators',
                    es: '30mm Special Operators'
                }
            }
        }
    },
    {
        _id: '110054_wtb2',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks'
                    },
                    es: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'IEC Terminal Blocks',
                    es: 'IEC Terminal Blocks'
                }
            }
        }
    },
    {
        _id: '110056_ypp',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'YPP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112558_pdn',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'PDN',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches : Accessories',
                    es: 'Disconnect Switches : Accessories'
                }
            }
        }
    },
    {
        _id: '112560_pdp',
        discountSchedule: 'G-1',
        isPublic: true,
        title: 'PDP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disconnect-switches-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Disconnect Switches : Accessories',
                    es: 'Disconnect Switches : Accessories'
                }
            }
        }
    },
    {
        _id: '112566_1100-cb',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '1100-CB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'miniature-circuit-breakers'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disyuntores-en-miniatura'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Miniature Circuit Breakers',
                    es: 'Disyuntores en miniatura'
                }
            }
        }
    },
    {
        _id: '112568_1100-sp',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '1100-SP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'miniature-circuit-breakers'
                    },
                    es: {
                        _type: 'slug',
                        current: 'disyuntores-en-miniatura'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Miniature Circuit Breakers',
                    es: 'Disyuntores en miniatura'
                }
            }
        }
    },
    {
        _id: '112572_300-s09',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-S09',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112573_300-s12',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-S12',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112576_300-s18',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-S18',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112578_300-s25',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-S25',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112579_300-s32',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-S32',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112581_300-s40',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-S40',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112583_300-s50',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-S50',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112585_300-s65',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-S65',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112587_300-s80',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-S80',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112590_300-s95',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-S95',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112592_300-s105',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-S105',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112595_300-sfa',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-SFA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112597_300-ssa',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-SSA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112599_300-sm',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-SM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112601_300-rws',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-RWS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112603_300-sds',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-SDS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112605_300-src',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-SRC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112607_310-s',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '310-S',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112609_310-m',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '310-M',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112611_300-m07',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-M07',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112613_300-m09',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-M09',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112615_300-m12',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-M12',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112617_300-m16',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-M16',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112619_300-c16n',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-C16N',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '112622_300-mmi',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-MMI',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112624_300-rwm',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-RWM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112626_300-mpc',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-MPC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112627_300-mfa',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-MFA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112630_300-cfa',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-CFA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112632_300-mt',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-MT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112634_300-mr',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-MR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112636_300-mv',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-MV',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112637_300-md',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300-MD',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Contactors : Accessories \u0026 Replacement Components',
                    es: 'Contactors : Accessories \u0026 Replacement Components'
                }
            }
        }
    },
    {
        _id: '112640_320-b1',
        discountSchedule: 'F-3',
        isPublic: true,
        title: '320-B1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'overload-relays' },
                    es: { _type: 'slug', current: 'overload-relays' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Overload Relays',
                    es: 'Overload Relays'
                }
            }
        }
    },
    {
        _id: '112642_320-b2',
        discountSchedule: 'F-3',
        isPublic: true,
        title: '320-B2',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'overload-relays' },
                    es: { _type: 'slug', current: 'overload-relays' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Overload Relays',
                    es: 'Overload Relays'
                }
            }
        }
    },
    {
        _id: '112644_320-b3',
        discountSchedule: 'F-3',
        isPublic: true,
        title: '320-B3',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'overload-relays' },
                    es: { _type: 'slug', current: 'overload-relays' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Overload Relays',
                    es: 'Overload Relays'
                }
            }
        }
    },
    {
        _id: '112646_320-b4',
        discountSchedule: 'F-3',
        isPublic: true,
        title: '320-B4',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'overload-relays' },
                    es: { _type: 'slug', current: 'overload-relays' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Overload Relays',
                    es: 'Overload Relays'
                }
            }
        }
    },
    {
        _id: '112647_320-b5',
        discountSchedule: 'F-3',
        isPublic: true,
        title: '320-B5',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'overload-relays' },
                    es: { _type: 'slug', current: 'overload-relays' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Overload Relays',
                    es: 'Overload Relays'
                }
            }
        }
    },
    {
        _id: '112650_320-bs',
        discountSchedule: 'F-3',
        isPublic: true,
        title: '320-BS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'overload-relays-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'overload-relays-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'Overload Relays : Accessories \u0026 Special Operators',
                    es: 'Overload Relays : Accessories \u0026 Special Operators'
                }
            }
        }
    },
    {
        _id: '112652_330-t',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-T',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers',
                    es: 'Motor Protection Circuit Breakers'
                }
            }
        }
    },
    {
        _id: '112654_330-m',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-M',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers',
                    es: 'Motor Protection Circuit Breakers'
                }
            }
        }
    },
    {
        _id: '112656_330-fa',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-FA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers : Accessories',
                    es: 'Motor Protection Circuit Breakers : Accessories'
                }
            }
        }
    },
    {
        _id: '112658_330-sa',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-SA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers : Accessories',
                    es: 'Motor Protection Circuit Breakers : Accessories'
                }
            }
        }
    },
    {
        _id: '112660_330-mf1',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-MF1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers : Accessories',
                    es: 'Motor Protection Circuit Breakers : Accessories'
                }
            }
        }
    },
    {
        _id: '112661_330-ba',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-BA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers : Accessories',
                    es: 'Motor Protection Circuit Breakers : Accessories'
                }
            }
        }
    },
    {
        _id: '112664_330-sr',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-SR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers : Accessories',
                    es: 'Motor Protection Circuit Breakers : Accessories'
                }
            }
        }
    },
    {
        _id: '112666_330-ur',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-UR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers : Accessories',
                    es: 'Motor Protection Circuit Breakers : Accessories'
                }
            }
        }
    },
    {
        _id: '112668_330-cl',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-CL',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers : Accessories',
                    es: 'Motor Protection Circuit Breakers : Accessories'
                }
            }
        }
    },
    {
        _id: '112670_330-cm',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-CM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers : Accessories',
                    es: 'Motor Protection Circuit Breakers : Accessories'
                }
            }
        }
    },
    {
        _id: '112672_330-st',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-ST',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers : Accessories',
                    es: 'Motor Protection Circuit Breakers : Accessories'
                }
            }
        }
    },
    {
        _id: '112674_330-ls',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-LS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers : Accessories',
                    es: 'Motor Protection Circuit Breakers : Accessories'
                }
            }
        }
    },
    {
        _id: '112676_330-ft',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-FT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers : Accessories',
                    es: 'Motor Protection Circuit Breakers : Accessories'
                }
            }
        }
    },
    {
        _id: '112678_330-sc',
        discountSchedule: 'F-2',
        isPublic: true,
        title: '330-SC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'motor-protection-circuit-breakers-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Motor Protection Circuit Breakers : Accessories',
                    es: 'Motor Protection Circuit Breakers : Accessories'
                }
            }
        }
    },
    {
        _id: '112681_e620',
        discountSchedule: 'H-4',
        isPublic: true,
        title: 'E620',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'direct-on-line-starters'
                    },
                    es: {
                        _type: 'slug',
                        current: 'direct-on-line-starters'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Direct-On-Line (DOL) Starters',
                    es: 'Direct-On-Line (DOL) Starters'
                }
            }
        }
    },
    {
        _id: '112684_e630',
        discountSchedule: 'H-5',
        isPublic: true,
        title: 'E630',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'direct-on-line-starters'
                    },
                    es: {
                        _type: 'slug',
                        current: 'direct-on-line-starters'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Direct-On-Line (DOL) Starters',
                    es: 'Direct-On-Line (DOL) Starters'
                }
            }
        }
    },
    {
        _id: '112689_ppac',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'PPAC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112691_ppmi',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'PPMI',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112693_fvppm',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'FVPPM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112695_fvmi',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'FVMI',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112697_fvac',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'FVAC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112699_tfppm',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'TFPPM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112701_tfac',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'TFAC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112703_tfmi',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'TFMI',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112705_rlac',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'RLAC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112706_rlmi',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'RLMI',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112709_rlppm',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'RLPPM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112720_appmi',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'APPMI',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Push Buttons',
                    es: '30mm Industrial Push Buttons'
                }
            }
        }
    },
    {
        _id: '112722_appcpo',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'APPCPO',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Special Operators',
                    es: '30mm Special Operators'
                }
            }
        }
    },
    {
        _id: '112724_ppcpo',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'PPCPO',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-special-operators'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Special Operators',
                    es: '30mm Special Operators'
                }
            }
        }
    },
    {
        _id: '112727_fvptt',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'FVPTT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Pilot Lights',
                    es: '30mm Industrial Pilot Lights'
                }
            }
        }
    },
    {
        _id: '112730_tfptt',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'TFPTT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Pilot Lights',
                    es: '30mm Industrial Pilot Lights'
                }
            }
        }
    },
    {
        _id: '112732_rlptt',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'RLPTT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Pilot Lights',
                    es: '30mm Industrial Pilot Lights'
                }
            }
        }
    },
    {
        _id: '112742_ttw',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'TTW',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '112745_ppgd',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'PPGD',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '112754_pmfc',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'PMFC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '112756_pmxc',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'PMXC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '112758_apmfc',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'APMFC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '112759_apmxc',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'APMXC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '112762_cbip20',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'CBIP20',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '112764_cbn',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'CBN',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '112766_cbe',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'CBE',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '112768_cbd',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'CBD',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '112770_fvip20',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'FVIP20',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '112772_mlip20',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'MLIP20',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '112779_w22pb',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22PB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-botones-pulsadores'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Push Buttons',
                    es: 'Botones pulsadores IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '112781_w22dpb',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22DPB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-botones-pulsadores'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Push Buttons',
                    es: 'Botones pulsadores IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '112783_w22pp-',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22PP-',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-botones-pulsadores'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Push Buttons',
                    es: 'Botones pulsadores IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '112785_w22pp3',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22PP3',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-botones-pulsadores'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Push Buttons',
                    es: 'Botones pulsadores IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '112787_w22ptr',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22PTR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-botones-pulsadores'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Push Buttons',
                    es: 'Botones pulsadores IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '112789_w22ipb',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22IPB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-botones-pulsadores'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Push Buttons',
                    es: 'Botones pulsadores IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '112791_w22idpb',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22IDPB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-botones-pulsadores'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Push Buttons',
                    es: 'Botones pulsadores IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '112793_w22ipp',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22IPP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-botones-pulsadores'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Push Buttons',
                    es: 'Botones pulsadores IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '112795_w22iptr',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22IPTR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-botones-pulsadores'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Push Buttons',
                    es: 'Botones pulsadores IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '112798_w22rpb',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22RPB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-reset-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-reset-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Reset Push Buttons',
                    es: '22mm IEC Reset Push Buttons'
                }
            }
        }
    },
    {
        _id: '112800_w22u-',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22U-',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Pilot Lights',
                    es: '22mm IEC Pilot Lights'
                }
            }
        }
    },
    {
        _id: '112802_w22unr',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22UNR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Pilot Lights',
                    es: '22mm IEC Pilot Lights'
                }
            }
        }
    },
    {
        _id: '112804_w22mv',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22MV',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Pilot Lights',
                    es: '22mm IEC Pilot Lights'
                }
            }
        }
    },
    {
        _id: '112806_w22rmv',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22RMV',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Pilot Lights',
                    es: '22mm IEC Pilot Lights'
                }
            }
        }
    },
    {
        _id: '112808_w22pl',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22PL',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Pilot Lights',
                    es: '22mm IEC Pilot Lights'
                }
            }
        }
    },
    {
        _id: '112810_w22ptt',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22PTT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Pilot Lights',
                    es: '22mm IEC Pilot Lights'
                }
            }
        }
    },
    {
        _id: '112812_w22is',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22IS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-selector-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-selector-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Selector Switches',
                    es: '22mm IEC Selector Switches'
                }
            }
        }
    },
    {
        _id: '112814_w22s',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22S',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-selector-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-selector-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Selector Switches',
                    es: '22mm IEC Selector Switches'
                }
            }
        }
    },
    {
        _id: '112816_w22k',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22K',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-selector-switches'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-selector-switches'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Selector Switches',
                    es: '22mm IEC Selector Switches'
                }
            }
        }
    },
    {
        _id: '112841_w22-ld',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22-LD',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Accessories',
                    es: 'Accesorios IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '112846_w22crw',
        discountSchedule: 'E-1',
        isPublic: true,
        title: 'W22CRW',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-iec-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-iec-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm IEC Accessories',
                    es: 'Accesorios IEC de 22 mm'
                }
            }
        }
    },
    {
        _id: '112848_wtl-50p',
        discountSchedule: 'E-3',
        isPublic: true,
        title: 'WTL-50P',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'world-tower-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: 'luces-de-la-torre'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'World Tower Lights',
                    es: 'Luces de la torre'
                }
            }
        }
    },
    {
        _id: '112850_wtl-50lm',
        discountSchedule: 'E-3',
        isPublic: true,
        title: 'WTL-50LM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'world-tower-lights-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'world-tower-lights-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'World Tower Lights : Components \u0026 Accessories',
                    es: 'World Tower Lights : Components \u0026 Accessories'
                }
            }
        }
    },
    {
        _id: '112852_wtl-50sm3',
        discountSchedule: 'E-3',
        isPublic: true,
        title: 'WTL-50SM3',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'world-tower-lights-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'world-tower-lights-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'World Tower Lights : Components \u0026 Accessories',
                    es: 'World Tower Lights : Components \u0026 Accessories'
                }
            }
        }
    },
    {
        _id: '112854_wtl-50bp1',
        discountSchedule: 'E-3',
        isPublic: true,
        title: 'WTL-50BP1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'world-tower-lights-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'world-tower-lights-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'World Tower Lights : Components \u0026 Accessories',
                    es: 'World Tower Lights : Components \u0026 Accessories'
                }
            }
        }
    },
    {
        _id: '112856_wtl-50bp2',
        discountSchedule: 'E-3',
        isPublic: true,
        title: 'WTL-50BP2',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'world-tower-lights-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'world-tower-lights-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'World Tower Lights : Components \u0026 Accessories',
                    es: 'World Tower Lights : Components \u0026 Accessories'
                }
            }
        }
    },
    {
        _id: '112858_wtl-50bp3',
        discountSchedule: 'E-3',
        isPublic: true,
        title: 'WTL-50BP3',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'world-tower-lights-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'world-tower-lights-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'World Tower Lights : Components \u0026 Accessories',
                    es: 'World Tower Lights : Components \u0026 Accessories'
                }
            }
        }
    },
    {
        _id: '112860_wtl-50bpv',
        discountSchedule: 'E-3',
        isPublic: true,
        title: 'WTL-50BPV',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'world-tower-lights-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'world-tower-lights-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'World Tower Lights : Components \u0026 Accessories',
                    es: 'World Tower Lights : Components \u0026 Accessories'
                }
            }
        }
    },
    {
        _id: '112868_wd-b2',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-B2',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'wire-duct' },
                    es: { _type: 'slug', current: 'wire-duct' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct \u0026 Replacement Covers',
                    es: 'Wire Duct \u0026 Replacement Covers'
                }
            }
        }
    },
    {
        _id: '112870_wd-b4',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-B4',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'wire-duct' },
                    es: { _type: 'slug', current: 'wire-duct' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct \u0026 Replacement Covers',
                    es: 'Wire Duct \u0026 Replacement Covers'
                }
            }
        }
    },
    {
        _id: '112872_wd-b6',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-B6',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'wire-duct' },
                    es: { _type: 'slug', current: 'wire-duct' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct \u0026 Replacement Covers',
                    es: 'Wire Duct \u0026 Replacement Covers'
                }
            }
        }
    },
    {
        _id: '112874_wd-b8',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-B8',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'wire-duct' },
                    es: { _type: 'slug', current: 'wire-duct' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct \u0026 Replacement Covers',
                    es: 'Wire Duct \u0026 Replacement Covers'
                }
            }
        }
    },
    {
        _id: '112876_wd-bc2',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-BC2',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'wire-duct' },
                    es: { _type: 'slug', current: 'wire-duct' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct \u0026 Replacement Covers',
                    es: 'Wire Duct \u0026 Replacement Covers'
                }
            }
        }
    },
    {
        _id: '112878_wd-bc4',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-BC4',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'wire-duct' },
                    es: { _type: 'slug', current: 'wire-duct' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct \u0026 Replacement Covers',
                    es: 'Wire Duct \u0026 Replacement Covers'
                }
            }
        }
    },
    {
        _id: '112880_wd-bc6',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-BC6',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'wire-duct' },
                    es: { _type: 'slug', current: 'wire-duct' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct \u0026 Replacement Covers',
                    es: 'Wire Duct \u0026 Replacement Covers'
                }
            }
        }
    },
    {
        _id: '112882_wd-bc8',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-BC8',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'wire-duct' },
                    es: { _type: 'slug', current: 'wire-duct' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct \u0026 Replacement Covers',
                    es: 'Wire Duct \u0026 Replacement Covers'
                }
            }
        }
    },
    {
        _id: '112884_wd-bmt',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-BMT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'wire-duct-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'wire-duct-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct Accessories',
                    es: 'Wire Duct Accessories'
                }
            }
        }
    },
    {
        _id: '112886_wd-bct',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-BCT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'wire-duct-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'wire-duct-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct Accessories',
                    es: 'Wire Duct Accessories'
                }
            }
        }
    },
    {
        _id: '112888_wd-bnr',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-BNR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'wire-duct-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'wire-duct-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct Accessories',
                    es: 'Wire Duct Accessories'
                }
            }
        }
    },
    {
        _id: '112890_wd-bwr',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-BWR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'wire-duct-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'wire-duct-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct Accessories',
                    es: 'Wire Duct Accessories'
                }
            }
        }
    },
    {
        _id: '112892_wd-bnp',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-BNP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'wire-duct-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'wire-duct-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct Accessories',
                    es: 'Wire Duct Accessories'
                }
            }
        }
    },
    {
        _id: '112894_wd-bmc',
        discountSchedule: 'G-2',
        isPublic: true,
        title: 'WD-BMC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'wire-duct-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'wire-duct-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Wire Duct Accessories',
                    es: 'Wire Duct Accessories'
                }
            }
        }
    },
    {
        _id: '112896_gpra-sp',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRA-SP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays-sockets-retainer-clips'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays-sockets-retainer-clips'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'General Purpose Relays : Sockets \u0026 Retainer Clips',
                    es: 'General Purpose Relays : Sockets \u0026 Retainer Clips'
                }
            }
        }
    },
    {
        _id: '112898_gpra-sb',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRA-SB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays-sockets-retainer-clips'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays-sockets-retainer-clips'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'General Purpose Relays : Sockets \u0026 Retainer Clips',
                    es: 'General Purpose Relays : Sockets \u0026 Retainer Clips'
                }
            }
        }
    },
    {
        _id: '112900_gpra-rc',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRA-RC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays-sockets-retainer-clips'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays-sockets-retainer-clips'
                    }
                },
                title: {
                    _type: 'localeString',
                    en:
                        'General Purpose Relays : Sockets \u0026 Retainer Clips',
                    es: 'General Purpose Relays : Sockets \u0026 Retainer Clips'
                }
            }
        }
    },
    {
        _id: '112902_hgprm',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'HGPRM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112904_hgprs',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'HGPRS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112906_gprm-s1c12',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRM-S1C12',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112908_gprm-b4c10',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRM-B4C10',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112910_gprm-b1c15',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRM-B1C15',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112912_gprm-b2c10',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRM-B2C10',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112914_gprm-b2c05',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRM-B2C05',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112916_gprm-b4c05',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRM-B4C05',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112918_gprf-t',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRF-T',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112919_gprs-b2c25',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRS-B2C25',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112922_gprs-b3c20',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRS-B3C20',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112924_gprs-b2c13',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRS-B2C13',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112926_gprs-b3c13',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRS-B3C13',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112928_gprs-p2c10',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRS-P2C10',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112930_gprs-p3c10',
        discountSchedule: 'G-3',
        isPublic: true,
        title: 'GPRS-P3C10',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'general-purpose-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'General Purpose Relays',
                    es: 'General Purpose Relays'
                }
            }
        }
    },
    {
        _id: '112933_etr-d17dei',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'ETR-D17DEI',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Electronic Timing Relays',
                    es: 'Electronic Timing Relays'
                }
            }
        }
    },
    {
        _id: '112935_etr-d17mf1',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'ETR-D17MF1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Electronic Timing Relays',
                    es: 'Electronic Timing Relays'
                }
            }
        }
    },
    {
        _id: '112937_etr-d22dei',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'ETR-D22DEI',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Electronic Timing Relays',
                    es: 'Electronic Timing Relays'
                }
            }
        }
    },
    {
        _id: '112941_etr-d22sd',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'ETR-D22SD',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Electronic Timing Relays',
                    es: 'Electronic Timing Relays'
                }
            }
        }
    },
    {
        _id: '112943_etr-d22sc',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'ETR-D22SC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Electronic Timing Relays',
                    es: 'Electronic Timing Relays'
                }
            }
        }
    },
    {
        _id: '112945_etr-d22ss',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'ETR-D22SS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Electronic Timing Relays',
                    es: 'Electronic Timing Relays'
                }
            }
        }
    },
    {
        _id: '112947_etr-s45',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'ETR-S45',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Electronic Timing Relays',
                    es: 'Electronic Timing Relays'
                }
            }
        }
    },
    {
        _id: '112949_etrd-p45',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'ETRD-P45',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'electronic-timing-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Electronic Timing Relays',
                    es: 'Electronic Timing Relays'
                }
            }
        }
    },
    {
        _id: '112953_900-sba',
        discountSchedule: 'G-5',
        isPublic: true,
        title: '900-SBA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112955_900-sea',
        discountSchedule: 'G-5',
        isPublic: true,
        title: '900-SEA',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112957_900-scc',
        discountSchedule: 'G-5',
        isPublic: true,
        title: '900-SCC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112959_900-sme',
        discountSchedule: 'G-5',
        isPublic: true,
        title: '900-SME',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112960_900-scm',
        discountSchedule: 'G-5',
        isPublic: true,
        title: '900-SCM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112963_drtb50',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'DRTB50',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks'
                    },
                    es: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'NEMA Terminal Blocks',
                    es: 'NEMA Terminal Blocks'
                }
            }
        }
    },
    {
        _id: '112965_drtb25',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'DRTB25',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks'
                    },
                    es: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'NEMA Terminal Blocks',
                    es: 'NEMA Terminal Blocks'
                }
            }
        }
    },
    {
        _id: '112967_drftb6',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'DRFTB6',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks'
                    },
                    es: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'NEMA Terminal Blocks',
                    es: 'NEMA Terminal Blocks'
                }
            }
        }
    },
    {
        _id: '112969_drdtb',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'DRDTB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks'
                    },
                    es: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'NEMA Terminal Blocks',
                    es: 'NEMA Terminal Blocks'
                }
            }
        }
    },
    {
        _id: '112971_dreb',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'DREB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'nema-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'NEMA Terminal Blocks : Accessories',
                    es: 'NEMA Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '112980_wtb2-vpm',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-VPM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112982_wtb2-pp',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-PP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112984_wtb2-clf',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-CLF',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112986_wtb2-ink',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-INK',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112988_wtb2-pm',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-PM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112990_wtb2-vss',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-VSS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112992_wtb2-cc1',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-CC1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112994_wtb2-vp6',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-VP6',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'page',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'shop' },
                    es: { _type: 'slug', current: 'tienda' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Shop',
                    es: 'Tienda'
                }
            }
        }
    },
    {
        _id: '112996_wtb2-gm',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-GM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'IEC Terminal Blocks : Accessories',
                    es: 'IEC Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '112998_wtb2-mg',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-MG',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'IEC Terminal Blocks : Accessories',
                    es: 'IEC Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '113000_wtb2-m2',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-M2',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'IEC Terminal Blocks : Accessories',
                    es: 'IEC Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '113002_wtb2-m5',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-M5',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'IEC Terminal Blocks : Accessories',
                    es: 'IEC Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '113004_wtb2-m6',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-M6',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'IEC Terminal Blocks : Accessories',
                    es: 'IEC Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '113006_wtb2-m8',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-M8',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'IEC Terminal Blocks : Accessories',
                    es: 'IEC Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '113008_wtb2-m9',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-M9',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'IEC Terminal Blocks : Accessories',
                    es: 'IEC Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '113010_wtb2-m1',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2-M1',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'IEC Terminal Blocks : Accessories',
                    es: 'IEC Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '113083_wtb2s',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'WTB2S',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks'
                    },
                    es: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'IEC Terminal Blocks',
                    es: 'IEC Terminal Blocks'
                }
            }
        }
    },
    {
        _id: '113139_13n',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '13N',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '13mm-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '13mm-luces-piloto'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '13mm Pilot Lights (Indicating)',
                    es: 'Luces Piloto de 13mm (Indicadora)'
                }
            }
        }
    },
    {
        _id: '113140_13c',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '13C',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '13mm-accessories'
                    },
                    es: { _type: 'slug', current: '13mm-accesorios' }
                },
                title: {
                    _type: 'localeString',
                    en: '13mm Accessories',
                    es: '13mm Accesorios'
                }
            }
        }
    },
    {
        _id: '113141_13p',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '13P',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '13mm-accessories'
                    },
                    es: { _type: 'slug', current: '13mm-accesorios' }
                },
                title: {
                    _type: 'localeString',
                    en: '13mm Accessories',
                    es: '13mm Accesorios'
                }
            }
        }
    },
    {
        _id: '113142_13sb',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '13SB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '13mm-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '13mm-luces-piloto'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '13mm Pilot Lights (Indicating)',
                    es: 'Luces Piloto de 13mm (Indicadora)'
                }
            }
        }
    },
    {
        _id: '113147_13m',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '13M',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'legend-plates' },
                    es: { _type: 'slug', current: 'legend-plates' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Legend Plates',
                    es: 'Legend Plates'
                }
            }
        }
    },
    {
        _id: '113153_16m',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '16M',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '16mm-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '16mm-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '16mm Pilot Lights',
                    es: '16mm Pilot Lights'
                }
            }
        }
    },
    {
        _id: '113154_16r',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '16R',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '16mm-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '16mm-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '16mm Pilot Lights',
                    es: '16mm Pilot Lights'
                }
            }
        }
    },
    {
        _id: '113157_mll',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'MLL',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'legend-plates' },
                    es: { _type: 'slug', current: 'legend-plates' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Legend Plates',
                    es: 'Legend Plates'
                }
            }
        }
    },
    {
        _id: '113159_16c',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '16C',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '16mm-accessories'
                    },
                    es: { _type: 'slug', current: '16mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '16mm Accessories',
                    es: '16mm Accessories'
                }
            }
        }
    },
    {
        _id: '113164_22th',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22TH',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Push Buttons',
                    es: '22mm NEMA Push Buttons'
                }
            }
        }
    },
    {
        _id: '113165_22tv',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22TV',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Push Buttons',
                    es: '22mm NEMA Push Buttons'
                }
            }
        }
    },
    {
        _id: '113171_22tfl',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22TFL',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Pilot Lights',
                    es: '22mm NEMA Pilot Lights'
                }
            }
        }
    },
    {
        _id: '113174_22fvl',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22FVL',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Pilot Lights',
                    es: '22mm NEMA Pilot Lights'
                }
            }
        }
    },
    {
        _id: '113176_22fvptt',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22FVPTT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Pilot Lights',
                    es: '22mm NEMA Pilot Lights'
                }
            }
        }
    },
    {
        _id: '113178_22tptt',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22TPTT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Pilot Lights',
                    es: '22mm NEMA Pilot Lights'
                }
            }
        }
    },
    {
        _id: '113180_22rptt',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22RPTT',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Pilot Lights',
                    es: '22mm NEMA Pilot Lights'
                }
            }
        }
    },
    {
        _id: '113182_22rl',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22RL',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Pilot Lights',
                    es: '22mm NEMA Pilot Lights'
                }
            }
        }
    },
    {
        _id: '113183_22rtl',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22RTL',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Pilot Lights',
                    es: '22mm NEMA Pilot Lights'
                }
            }
        }
    },
    {
        _id: '113189_22mi',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22MI',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Accessories',
                    es: 'Accesorios NEMA de 22 mm'
                }
            }
        }
    },
    {
        _id: '113191_22tw',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22TW',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Accessories',
                    es: 'Accesorios NEMA de 22 mm'
                }
            }
        }
    },
    {
        _id: '113194_22me',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22ME',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Accessories',
                    es: 'Accesorios NEMA de 22 mm'
                }
            }
        }
    },
    {
        _id: '113198_22pg',
        discountSchedule: 'E-1',
        isPublic: true,
        title: '22PG',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '22mm-nema-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: '22mm-nema-accesorios'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '22mm NEMA Accessories',
                    es: 'Accesorios NEMA de 22 mm'
                }
            }
        }
    },
    {
        _id: '113202_mlr',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'MLR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '113204_lrn',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'LRN',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '113206_cbf',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'CBF',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '113209_hpbo',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HPBO',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Hazardous Location Push Buttons',
                    es: '30mm Hazardous Location Push Buttons'
                }
            }
        }
    },
    {
        _id: '113211_hptr',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HPTR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Hazardous Location Push Buttons',
                    es: '30mm Hazardous Location Push Buttons'
                }
            }
        }
    },
    {
        _id: '113213_hfvlu',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HFVLU',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Hazardous Location Push Buttons',
                    es: '30mm Hazardous Location Push Buttons'
                }
            }
        }
    },
    {
        _id: '113215_htflu',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HTFLU',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Hazardous Location Push Buttons',
                    es: '30mm Hazardous Location Push Buttons'
                }
            }
        }
    },
    {
        _id: '113218_hrlu',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HRLU',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Hazardous Location Push Buttons',
                    es: '30mm Hazardous Location Push Buttons'
                }
            }
        }
    },
    {
        _id: '113220_hrtlu',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HRTLU',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Hazardous Location Push Buttons',
                    es: '30mm Hazardous Location Push Buttons'
                }
            }
        }
    },
    {
        _id: '113222_hfvppm',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HFVPPM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Hazardous Location Push Buttons',
                    es: '30mm Hazardous Location Push Buttons'
                }
            }
        }
    },
    {
        _id: '113224_htfppm',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HTFPPM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Hazardous Location Push Buttons',
                    es: '30mm Hazardous Location Push Buttons'
                }
            }
        }
    },
    {
        _id: '113226_hrlppm',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HRLPPM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-push-buttons'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Hazardous Location Push Buttons',
                    es: '30mm Hazardous Location Push Buttons'
                }
            }
        }
    },
    {
        _id: '113228_hfs',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HFS',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Hazardous Location Pilot Lights',
                    es: '30mm Hazardous Location Pilot Lights'
                }
            }
        }
    },
    {
        _id: '113230_hpr',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'HPR',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-hazardous-location-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Hazardous Location Pilot Lights',
                    es: '30mm Hazardous Location Pilot Lights'
                }
            }
        }
    },
    {
        _id: '113276_drm',
        discountSchedule: 'G-4',
        isPublic: true,
        title: 'DRM',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    },
                    es: {
                        _type: 'slug',
                        current: 'iec-terminal-blocks-accessories'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'IEC Terminal Blocks : Accessories',
                    es: 'IEC Terminal Blocks : Accessories'
                }
            }
        }
    },
    {
        _id: '113278_fvb',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'FVB',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '113281_led',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'LED',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-accessories'
                    },
                    es: { _type: 'slug', current: '30mm-accessories' }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Accessories',
                    es: '30mm Accessories'
                }
            }
        }
    },
    {
        _id: '113288_ecc',
        discountSchedule: 'H-3',
        isPublic: true,
        title: 'ECC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '113290_w22ec',
        discountSchedule: 'H-2',
        isPublic: true,
        title: 'W22EC',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '113293_22ecd',
        discountSchedule: 'H-2',
        isPublic: true,
        title: '22ECD',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '113295_ecd',
        discountSchedule: 'H-3',
        isPublic: true,
        title: 'ECD',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'control-stations'
                    },
                    es: { _type: 'slug', current: 'control-stations' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Control Stations',
                    es: 'Control Stations'
                }
            }
        }
    },
    {
        _id: '77f9e93e-105f-4c00-bf97-db44295b11f8',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '280',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'definite-purpose-contactors'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactores-de-propósito-definido'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Definite Purpose Contactors',
                    es: 'Contactores de propósito definido'
                }
            }
        }
    },
    {
        _id: '113642_300',
        discountSchedule: 'F-1',
        isPublic: true,
        title: '300',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    },
                    es: {
                        _type: 'slug',
                        current: 'contactors-control-relays'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: 'Contactors and Control Relays',
                    es: 'Contactors and Control Relays'
                }
            }
        }
    },
    {
        _id: '120254_22eclp',
        discountSchedule: 'E-2',
        isPublic: true,
        title: '22ECLP',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: { _type: 'slug', current: 'legend-plates' },
                    es: { _type: 'slug', current: 'legend-plates' }
                },
                title: {
                    _type: 'localeString',
                    en: 'Legend Plates',
                    es: 'Legend Plates'
                }
            }
        }
    },
    {
        _id: '120272_fvlu',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'FVLU',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Pilot Lights',
                    es: '30mm Industrial Pilot Lights'
                }
            }
        }
    },
    {
        _id: '120274_tflu',
        discountSchedule: 'E-2',
        isPublic: true,
        title: 'TFLU',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Pilot Lights',
                    es: '30mm Industrial Pilot Lights'
                }
            }
        }
    },
    {
        _id: '120276_rlu',
        discountSchedule: 'E-2',
        isPublic: '',
        title: 'RLU',
        url: {
            _type: 'internalLink',
            linkTo: {
                _type: 'product',
                slug: {
                    _type: 'localeSlug',
                    en: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    },
                    es: {
                        _type: 'slug',
                        current: '30mm-industrial-pilot-lights'
                    }
                },
                title: {
                    _type: 'localeString',
                    en: '30mm Industrial Pilot Lights',
                    es: '30mm Industrial Pilot Lights'
                }
            }
        }
    }
]

export { skuprefixes as default }
