export default function ({ taxRates, contactDetails }) {
    const validTaxRates = taxRates.filter((item) => {
        return contactDetails.addressState === item.state
    })
    if (validTaxRates.length > 1) {
        // This occurs when a state has multiple county
        let commonTaxRate, countyTaxRate
        for (let index = 0; index < validTaxRates.length; index++) {
            const item = validTaxRates[index]
            if (item.zipCodes !== '*') {
                countyTaxRate = taxableZipcode({
                    zipcodeList: item.zipCodes,
                    contactZipcode: contactDetails.addressPostalCode,
                })
                if (countyTaxRate) {
                    countyTaxRate = item
                    break
                }
            } else {
                commonTaxRate = item
            }
        }
        return countyTaxRate || commonTaxRate
    } else {
        return validTaxRates[0]
    }
}

function taxableZipcode({ zipcodeList, contactZipcode }) {
    return zipcodeList?.split(',').find((item) => {
        if (item.includes('*')) {
            const regEx = new RegExp(`^${item.replace('*', '')}`)
            return regEx.test(contactZipcode)
        } else {
            return item === contactZipcode
        }
    })
}
