import { getField, updateField } from 'vuex-map-fields'
import fetchSchedulePrice from '~/util/fetchSchedulePriceNew'
import fetchSalePrice from '~/util/fetchSalePrice'
import { ExportToCsv } from 'export-to-csv'
export const state = () => ({
    filter: [],
    loadingData: false,
    productList: [],
    size: 20,
    from: 0,
    totalItemsServer: 0,
    options: null,
    page: 1,
    itemsPerPage: 20,
    pageCount: 0,
    rowsPerPageItems: [5, 10, 20, 50, 100],
    sortBy: [],
    sortDesc: [],
    exportLoading: false,
})

export const actions = {
    fetch_products_from_elastic_search({ state, getters }, payload) {
        const { maxCount } = payload
        return new Promise(async (resolve, reject) => {
            const res = await this.$axios.post('elastic-search', {
                elasticQuery: {
                    from: state.from,
                    size: getters.selectedCategory
                        ? maxCount
                            ? maxCount
                            : state.size
                        : 5,
                    query: getters.getQueryByFilter,
                    sort: getters.getSortArray,
                },
                elasticIndex: 'products-sanity',
            })
            resolve(res)
        })
    },
    async set_elastic_data(
        { state, getters, rootState, commit, dispatch },
        payload
    ) {
        return new Promise(async (resolve, reject) => {
            commit('SET_LOADING_DATA', true)
            const res = await dispatch('fetch_products_from_elastic_search', {
                maxCount: null,
            })
            const productList = [
                ..._.map(res.data.hits.hits, (p) => ({
                    ...p._source,
                    _id: p._source.product_id,
                    pkgqty: p._source.pkgqty || 1,
                })),
            ]
            commit('SET_PRODUCTS_FROM_ELASTIC', productList)
            commit('SET_LOADING_DATA', false)
            commit('SET_TOTAL_ITEMS_ON_SERVER', res.data.hits.total.value)
            resolve()
        })
    },
    async handleExport({ getters, dispatch, commit }) {
        commit('SET_EXPORT_LOADING', true)
        const res = await dispatch('fetch_products_from_elastic_search', {
            maxCount: 1000,
        })
        const productList = [
            ..._.map(res.data.hits.hits, (p) => ({
                ...p._source,
                _id: p._source.product_id,
                pkgqty: p._source.pkgqty || 1,
            })),
        ]

        const productListWithAppliedPricelist = await dispatch(
            'applyPricelist',
            productList
        )
        const csvData = productListWithAppliedPricelist.map((i) => {
            const attributeObj = { ...i.attributes }
            Object.entries(attributeObj).forEach(([key, val], index) => {
                attributeObj[key] = val.join()
            })
            return {
                'Part Number': i.pn,
                Description: i.name_en,
                'List Prce': i.list_price,
                'Web Price': i.sale_price,
                'Std. Pkg. Qty.': i.pkgqty,
                ...attributeObj,
            }
        })
        const options = {
            filename: getters.selectedCategory,
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: getters.selectedCategory,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        }
        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(csvData)
        commit('SET_EXPORT_LOADING', false)
    },
    applyPricelist({ state, rootState, rootGetters }, payload) {
        return new Promise((resolve) => {
            const p = payload.map((item, i) => {
                let salePrice
                if (rootState.priceList.priceList.length > 0) {
                    const selectedSchedulePrice = fetchSchedulePrice(
                        rootState.priceList.priceList,
                        item
                    )
                    salePrice =
                        selectedSchedulePrice &&
                        fetchSalePrice(selectedSchedulePrice, item.list_price)
                }
                const isFoundInCart = rootGetters[
                    'cart/fetchCartProducts'
                ].find((cartItem) => cartItem.product_id === item.product_id)
                // console.log('------ isFoundInCart -----', isFoundInCart)
                let quantityInCart = 0
                let subTotalSalePrice = salePrice
                let subTotalListPrice = item.list_price
                if (isFoundInCart) {
                    quantityInCart = isFoundInCart.prod_quantity
                    subTotalSalePrice = salePrice * quantityInCart
                    subTotalListPrice = item.list_price * quantityInCart
                }
                return {
                    ...item,
                    sale_price: salePrice,
                    quantityInCart,
                    subTotalSalePrice,
                    subTotalListPrice,
                }
            })
            resolve(p)
        })
    },
}

export const mutations = {
    updateField,
    SET_PRODUCTS_FROM_ELASTIC(state, payload) {
        state.productList = payload
    },
    SET_LOADING_DATA(state, payload) {
        state.loadingData = payload
    },
    SET_TOTAL_ITEMS_ON_SERVER(state, payload) {
        state.totalItemsServer = payload
    },
    SET_EXPORT_LOADING(state, payload) {
        state.exportLoading = payload
    },
    SET_ITEMS_PER_PAGE(state, payload) {
        state.itemsPerPage = payload
    },
    SET_SIZE(state, payload) {
        state.size = payload
    },
}

export const getters = {
    getField,
    selectedCategory(state, _, rootState) {
        return rootState.route.query.Categories || 'Control Relays'
    },
    getQueryByFilter(state, getters) {
        const attributeTerms =
            getters.decodedFilterValues.length > 0
                ? getters.decodedFilterValues
                      .filter((f) => f.value.length > 0)
                      .map((f) => {
                          return {
                              terms: {
                                  [`attributes.${f.property}.keyword`]: f.value,
                              },
                          }
                      })
                : []
        const categoryTerms = {
            terms: {
                categories: [getters.selectedCategory],
            },
        }
        const accessoriesTerms = {
            terms: {
                categories: [`${getters.selectedCategory} Accessories`],
            },
        }
        const mustTerms = [categoryTerms, ...attributeTerms]

        const mustNotTerms = getters.selectedCategory.includes('Accessories')
            ? []
            : [accessoriesTerms]

        return {
            bool: {
                must: mustTerms,
                must_not: mustNotTerms,
                filter: [
                    {
                        term: {
                            isPartial: false,
                        },
                    },
                ],
            },
        }
    },
    getSortArray(state) {
        return state.sortBy.map((s, i) => {
            const sortAttrName = s.includes('attributes')
                ? `${s}.keyword`
                : s.includes('sale_price')
                ? 'list_price'
                : s
            const sortAttrValue = state.options.sortDesc[i]
            return {
                [sortAttrName]: {
                    order: !sortAttrValue ? 'asc' : 'desc',
                },
            }
        })
    },
    decodedFilterValues(state, _, rootState) {
        const decodedFilterObj =
            rootState.route.query.filter &&
            isBase64(rootState.route.query.filter)
                ? JSON.parse(window.atob(rootState.route.query.filter))
                : null

        return decodedFilterObj
            ? Object.entries(decodedFilterObj).map(([k, v]) => ({
                  property: k,
                  value: v,
              }))
            : []
    },
    productListWithAppliedPricelist(state, getters, rootState, rootGetters) {
        return state.productList.map((item, i) => {
            let salePrice
            if (rootState.priceList.priceList?.length > 0) {
                const selectedSchedulePrice = fetchSchedulePrice(
                    rootState.priceList.priceList,
                    item
                )
                salePrice =
                    selectedSchedulePrice &&
                    fetchSalePrice(selectedSchedulePrice, item.list_price)
            }
            const isFoundInCart = rootGetters['cart/fetchCartProducts'].find(
                (cartItem) => cartItem.product_id === item.product_id
            )
            // console.log('------ isFoundInCart -----', isFoundInCart)
            let quantityInCart = 0
            let subTotalSalePrice = salePrice
            let subTotalListPrice = item.list_price
            if (isFoundInCart) {
                quantityInCart = isFoundInCart.prod_quantity
                subTotalSalePrice = salePrice * quantityInCart
                subTotalListPrice = item.list_price * quantityInCart
            }
            return {
                ...item,
                sale_price: salePrice,
                quantityInCart,
                subTotalSalePrice,
                subTotalListPrice,
            }
        })
    },
}

const isBase64 = function (str) {
    if (typeof str !== 'string' && !process.client) {
        return false
    }
    try {
        return window.btoa(window.atob(str)) == str
    } catch (err) {
        return false
    }
}
