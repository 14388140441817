var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700","persistent":""},model:{value:(_vm.showPopup),callback:function ($$v) {_vm.showPopup=$$v},expression:"showPopup"}},[_c('v-card',{attrs:{"color":"primary"}},[_c('v-card-title',[_c('v-btn',{staticClass:"cancel-btn",attrs:{"fab":"","x-small":"","elevation":"2","absolute":""},on:{"click":_vm.setCookie}},[_c('fa',{staticClass:"fa-lg",attrs:{"icon":['fal', 'times'],"color":"primary"}})],1)],1),_vm._v(" "),_c('v-card-text',[(_vm.eBookImageLink)?_c('div',{on:{"click":_vm.setCookie}},[_c('nuxt-link',{attrs:{"to":_vm.renderUrl({
                            item:
                                _vm.noticeSettings && _vm.noticeSettings.eBookModal,
                            lang: _vm.$i18n.locale,
                            nuxt: true,
                        })}},[_c('EmbedSanityImage',{attrs:{"classes":"mt-0","originalFilename":_vm.sanityEBookImage.selectedImage &&
                            _vm.sanityEBookImage.selectedImage.asset
                                .originalFilename,"alt":_vm.sanityEBookImage.alt &&
                            _vm.sanityEBookImage.alt[_vm.$i18n.locale]
                                ? _vm.sanityEBookImage.alt
                                : {
                                      en: 'c3controls image',
                                      es: 'c3controls image',
                                  },"width":"700","height":"600"}})],1)],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }