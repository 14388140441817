import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
    alpsProductInquiryForm: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
        message: '',
        subject: ['Enclosed Power Supplies'],
    }
})

export const actions = {}

export const mutations = {
    updateInquiryField(state, field) {
        updateField(state.alpsProductInquiryForm, field)
    }
}

export const getters = {
    getInquiryField(state) {
        return getField(state.alpsProductInquiryForm)
    }
}
