export default function cleanQuerySelector(selector) {
    if (typeof selector === 'string') {
        let id = selector
        const isHash = selector.charAt(0) === '#'
        if (isHash) {
            id = selector.substring(1)
        }
        const c = id.charAt(0)
        const beginsWithNumber = !isNaN(parseInt(c, 10))
        if (beginsWithNumber) {
            id = `a-${id}`
        }
        return isHash ? `#${id}` : id
    }
    return selector
}
