/* eslint-disable camelcase */
import GoTrue from 'gotrue-js'
import { NetlifyDownError } from '~/util/Exceptions/index'

const auth = new GoTrue({
    APIUrl: process.env.NETLIFY_IDENTITY_URL,
    audience: '',
    setCookie: true,
})

/* ******** STATE *******   */

export const state = () => ({
    currentUser: null,
    currentUserDetails: {
        full_name: '',
    },
    loading: null,
    loggedIn: false,
    alert: false,
    token: '',
    recoveryToken: '',
    inviteToken: '',
    notifications: [],
    recentAction: {
        action: '',
        message: '',
    },
    webUser: null,
})

/* ******** ACTIONS ******* */

export const actions = {
    attemptSignUp(_, credentials) {
        return auth.signup(
            credentials.email.toLowerCase(),
            credentials.password,
            {
                full_name: credentials.name,
            }
        )
    },
    attemptLogin({ commit, dispatch, rootGetters, getters }, credentials) {
        return new Promise((resolve, reject) => {
            dispatch('attemptConfirmation', credentials).then(() => {
                auth.login(
                    credentials.email.toLowerCase(),
                    credentials.password,
                    true
                )
                    .then(async (response) => {
                        // console.log('response', response)
                       
                        // commit('SET_CURRENT_USER', response)
                        commit('SET_CURRENT_USER_DETAILS', response)
                        await dispatch('fetchWebUser')
                        commit('SET_LOGGED_IN', true)
                        await dispatch('abandonedCartNew/setUserCart', null, {
                            root: true,
                        })
                        resolve(response)
                        dispatch('savedCarts/clearSavedCartData', null, {
                            root: true,
                        })
                        dispatch('savedCarts/fetchSavedCarts', null, {
                            root: true,
                        })
                        dispatch('priceList/fetchPriceListEntries', null, {
                            root: true,
                        })
                    })
                    .catch((error) => {
                        reject(error.json)
                    })
            })
        })
    },
    attemptLogout({ commit, state, dispatch }) {
        return new Promise((resolve, reject) => {
            const user = auth.currentUser()
            user.logout()
                .then((response) => {
                    // console.log('User has been logged out!')
                    commit('SET_CURRENT_USER', null)
                    commit('SET_CURRENT_USER_DETAILS', '')
                    commit('SET_WEBUSER', null)
                    commit('SET_LOGGED_IN', false)
                    dispatch('abandonedCartNew/clearAbandonedCart', null, {
                        root: true,
                    })
                    dispatch('savedCarts/clearSavedCartData', null, {
                        root: true,
                    })
                    commit('priceList/setSelectedPriceListId', '', {
                        root: true,
                    })
                    commit('priceList/setAppliedPriceList', [], {
                        root: true,
                    })
                    commit('priceList/setActivePriceListIds', [], {
                        root: true,
                    })
                    dispatch('priceList/fetchPriceListEntries', null, {
                        root: true,
                    })
                    commit('favorites/setFavList', null, {
                        root:true,
                    })

                    resolve(response)
                })
                .catch((error) => {
                    // console.log('Could not log out', error)
                    reject(error)
                })
        })
    },
    attemptConfirmation({ commit, dispatch }, credentials) {
        return new Promise((resolve, reject) => {
            if (!credentials.token) {
                resolve()
                return
            }
            auth.confirm(credentials.token)
                .then((response) => {
                    credentials.token = null
                    dispatch('attemptLogin', credentials)
                    commit('REMOVE_TOKEN')
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                    // console.log(error)
                })
        })
    },
    attemptAcceptInvitaion({ commit, dispatch }, credentials) {
        return new Promise((resolve, reject) => {
            auth.acceptInvite(credentials.token, credentials.password)
                .then((response) => {
                    // console.log('response', response)
                    resolve(response)
                    // commit('SET_CURRENT_USER', response)
                    commit('SET_CURRENT_USER_DETAILS', response)
                    commit('REMOVE_TOKEN')
                    dispatch('fetchWebUser')
                    commit('SET_LOGGED_IN', true)
                    dispatch('savedCarts/clearSavedCartData', null, {
                        root: true,
                    })
                    dispatch('priceList/fetchPriceListEntries', null, {
                        root: true,
                    })
                })
                .catch((error) => {
                    reject(error.json)
                })
        })
    },
    attemptRequestPasswordRecovery({ commit, state }, credentials) {
        return new Promise((resolve, reject) => {
            auth.requestPasswordRecovery(credentials.email.toLowerCase())
                .then((response) => {
                    console.log('Recovery email sent', {
                        response,
                    })
                    resolve(response)
                })
                .catch((error) => {
                    // console.log('Error sending recovery mail: %o', error)
                    reject(error)
                })
        })
    },
    attemptAccountRecoveryConfirmation({ commit, state }, token) {
        return new Promise((resolve, reject) => {
            auth.recover(token)
                .then((response) => {
                    // console.log(
                    //     'Logged in as %s',
                    //     JSON.stringify({
                    //         response
                    //     })
                    // )
                    resolve(response)
                })
                .catch((error) => {
                    // console.log('Failed to verify recover token: %o', error)
                    reject(error)
                })
        })
    },
    attemptResetPassword({ commit, dispatch }, credentials) {
        return new Promise((resolve, reject) => {
            dispatch('attemptAccountRecoveryConfirmation', credentials.token)
                .then(() => {
                    const user = auth.currentUser()

                    user.update({
                        email: user.email.toLowerCase(),
                        password: credentials.password,
                    })
                        .then((user) => {
                            // console.log('Updated user %s', user)
                            resolve(user)
                        })
                        .catch((error) => {
                            // console.log('Failed to update user: %o', error)
                            reject(error)
                            // throw error
                        })
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getExistingUser({ commit, dispatch }, emailId) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.$axios.post('get-existing-users', {
                    emailId,
                })
                resolve(response.data)
            } catch (error) {
                reject(error)
            }
        })
    },
    async fetchWebUser({ commit, getters, dispatch }) {
        const user = auth.currentUser()
        if (user) {
            const res = await this.$axios.post('erp-webuser?method=read', {
                webUseremail: user.email,
            })
            if (res.data.success === false) {
                throw new Error(res.data.message)
            } else {
                commit('SET_WEBUSER', res.data.webUser)
                commit ('favorites/setFavList', getters.favorites, { root: true})
                if(getters.liveCart){
                    dispatch('abandonedCartNew/setLiveCart', null, { root: true })
                }
            }
        }
    },
    cookieTheToken({ commit }, payload) {
        commit('COOKIE_THE_TOKEN', payload)
    },
    cookieTheRecoveryToken({ commit }, payload) {
        commit('COOKIE_THE_RECOVERY_TOKEN', payload)
    },
    cookieTheInviteToken({ commit }, payload) {
        commit('COOKIE_THE_INVITE_TOKEN', payload)
    },
    async checkNetlifyStatus() {
        const url = 'https://www.netlifystatus.com/api/v2/status.json'
        const data = await this.$axios.$get(url)
        if (data.status.indicator === 'none') {
            return true
        } else {
            throw new NetlifyDownError(
                `Netlify Server down ${JSON.stringify(data.status)}`
            )
        }
    },
}

/* ******** MUTATIONS ******* */

export const mutations = {
    COOKIE_THE_TOKEN(state, payload) {
        state.token = payload
    },
    COOKIE_THE_RECOVERY_TOKEN(state, payload) {
        state.recoveryToken = payload
    },
    COOKIE_THE_INVITE_TOKEN(state, payload) {
        state.inviteToken = payload
    },
    REMOVE_TOKEN(state) {
        state.token = ''
        state.inviteToken = ''
    },
    SET_CURRENT_USER(state, value) {
        if (value) {
            state.currentUser = { ...value }
        } else {
            state.currentUser = null
        }
    },
    SET_CURRENT_USER_DETAILS(state, value) {
        state.currentUserDetails.full_name =
            value.user_metadata && value.user_metadata.full_name
                ? value.user_metadata.full_name
                : ''
    },
    TOGGLE_LOAD(state) {
        state.loading = !state.loading
    },
    SET_LOGGED_IN(state, value) {
        state.loggedIn = value
    },
    SET_WEBUSER(state, val) {
        state.webUser = val
    },
}

/* ******** GETTERS ******* */

export const getters = {
    isLoggedIn(state) {
        return state.loggedIn
    },
    currentUser(state) {
        return state.currentUser
    },
    webUser(state) {
        return state.webUser
    },
    signupToken(state) {
        return state.token || ''
    },
    passwordRecoveryToken(state) {
        return state.recoveryToken || ''
    },
    isApprovedForTerms(state, getters) {
        if (getters.ARTerm) {
            if (getters.ARTerm?.name === 'CREDIT CARD') {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    },
    primaryCustomer(state) {
        if (state.webUser?.Customers.length > 0) {
            if (state.webUser.WebUserPrimaryCustomerId) {
                return state.webUser.Customers.find(
                    (item) => item.id === state.webUser.WebUserPrimaryCustomerId
                )
            } else {
                return state.webUser.Customers[0]
            }
        } else {
            return null
        }
    },
    isCustomerCreated(state) {
        return !!(state.webUser && state.webUser.Customers.length > 0)
    },
    ARTerm(state, getters) {
        return getters.primaryCustomer?.ARTerm
    },
    priceList(state, getters) {
        let priceList = getters.primaryCustomer?.PriceList?.name
        priceList = ['Select','Base Price List'].includes(priceList) ? 'OEM' : priceList
        return priceList
    },
    custType(state, getters) {
        return getters.primaryCustomer?.CustType
    },
    isCustomerTaxable(state, getters) {
        if (getters.primaryCustomer) {
            return getters.primaryCustomer.taxable
        } else {
            return true
        }
    },
    userName(state) {
        const { currentUserDetails, currentUser } = state
        return (
            currentUserDetails?.full_name ||
            currentUser?.user_metadata.full_name ||
            ''
        )
    },
    isAuthor(state, getters) {
        if (state.webUser?.email.includes('@c3controls.com')) {
            return true
        } else {
            return false
        }
    },
    isProduction() {
        return process.env.V2_BASE_URL === 'https://www.c3controls.com'
    },
    isDistributor(state, getters) {
        if (
            [
                'D1-Distributor/Stocking',
                'D2-Distributor/Reseller/Non-Stocking',
            ].includes(getters.custType?.name)
        ) {
            return true
        } else {
            return false
        }
    },
    freeGroundShippingThreshold(state, getters) {
        return getters.primaryCustomer?.isFreeGroundAt || 49
    },
    finalARTerm(state, getters, rootState, rootGetters) {
        if (
            !(rootGetters['checkout/getShippingDetails'] &&
                ['United States', 'Canada'].includes(
                    rootGetters['checkout/getShippingDetails'].addressCountry
                ) &&
                !['HI', 'AK', 'Hawaii', 'Alaska'].includes(
                    rootGetters['checkout/getShippingDetails'].addressState
                ))
             &&
            !getters.isApprovedForTerms
        )
            return 'WIRE TRANSFER'
        else return getters.ARTerm?.name || 'CREDIT CARD'
    },
    isCustomerConnected(state, getters) {
        return !(getters.isLoggedIn && !getters.primaryCustomer)
    },
    favorites(state, getters){
        let favorites = state.webUser?.favorites
        favorites = favorites && favorites.length === 0 ? null : favorites
        return favorites
    },
    liveCart( state ) {
        return state.webUser?.Carts?.find(cart => cart.isLive)
    }
}
