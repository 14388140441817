<template>
    <div class="mr-2">
        <v-badge
            :top="true"
            :color="lineByLineValues.length > 0 ? 'primary' : 'transparent'"
            :right="true"
            :overlap="false"
            offset-x="9px"
            offset-y="9px"
            class="align-self-center mr-4 d-none d-lg-flex cart-badge--default"
        >
            <template v-slot:badge>
                <span
                    :title="`${lineByLineValues.length} items present in bulk order pad.`"
                    >{{
                        lineByLineValues.length > 0
                            ? `${lineByLineValues.length}`
                            : ''
                    }}</span
                >
            </template>
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                offset-y
                :nudge-width="100"
                transition="scale-transition"
                :open-on-hover="isHoverOn"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        outlined
                        small
                        dark
                        v-bind="attrs"
                        v-on="on"
                        ><TranslatableStringComponent
                            :tstring="{ string: 'quickOrderButton' }"
                        /><fa
                            :icon="['fal', 'clipboard-list']"
                            class="fa-md ml-2"
                        />
                    </v-btn>
                </template>

                <div style="background: #ffffff">
                    <ValidationObserver
                        ref="observer"
                        v-slot="{ handleSubmit }"
                    >
                        <div class="pa-4 d-flex justify-space-between">
                            <div class="align-self-center">
                                <TranslatableStringComponent
                                    :tstring="{ string: 'lineByLine' }"
                                />
                            </div>
                            <div>
                                <v-btn icon @click="menu = false">
                                    <fa
                                        :icon="['fal', 'times']"
                                        class="fa-lg"
                                    />
                                </v-btn>
                            </div>
                        </div>

                        <v-divider></v-divider>

                        <v-container
                            style="
                                width: 320px;
                                height: 280px;
                                overflow-y: auto;
                            "
                            class="py-0"
                        >
                            <v-row
                                v-for="(item, index) in textFieldValues"
                                :key="index"
                            >
                                <v-col cols="12" sm="8" class="pb-0 pr-0">
                                    <ValidationProvider
                                        :rules="`${
                                            item.qty && formType === '1'
                                                ? 'required'
                                                : ''
                                        }`"
                                    >
                                        <div
                                            slot-scope="{ errors }"
                                            style="position: relative"
                                        >
                                            <v-text-field
                                                v-model="item.pn"
                                                label="Part No #"
                                                outlined
                                                dense
                                                :error="errors.length > 0"
                                                :hide-details="true"
                                                @click="handleContentClick()"
                                            ></v-text-field></div
                                    ></ValidationProvider>
                                </v-col>
                                <!-- <div><InputFieldStepper /></div> -->
                                <v-col cols="12" sm="4" class="pb-0">
                                    <ValidationProvider
                                        :rules="`${
                                            item.pn && formType === '1'
                                                ? 'required'
                                                : ''
                                        }`"
                                    >
                                        <div
                                            slot-scope="{ errors }"
                                            style="position: relative"
                                        >
                                            <v-text-field
                                                v-model="item.qty"
                                                type="number"
                                                small
                                                :label="localize('qty')"
                                                outlined
                                                :error="errors.length > 0"
                                                dense
                                                :hide-details="true"
                                                @click="handleContentClick()"
                                            ></v-text-field>
                                        </div>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                        </v-container>

                        <div class="pa-4">
                            <div class="d-flex justify-space-between mb-4">
                                <v-btn
                                    small
                                    color="primary"
                                    :loading="isLoading"
                                    :disabled="disabledCheckout && !isAuthor"
                                    width="47%"
                                    @click="handleSubmit(handleClick)"
                                >
                                    <TranslatableStringComponent
                                        :tstring="{ string: 'addToCart' }"
                                    />
                                </v-btn>
                                <v-btn
                                    outlined
                                    small
                                    width="47%"
                                    color="primary"
                                    class=""
                                    @click="clearAllFields()"
                                >
                                    <TranslatableStringComponent
                                        :tstring="{ string: 'clearAll' }"
                                    />
                                </v-btn>
                            </div>

                            <v-btn
                                block
                                text
                                small
                                color="primary"
                                :disabled="disabledCheckout && !isAuthor"
                                :to="
                                    getStaticLocalePath({
                                        type: 'page',
                                        slugName: 'quick-order-pad',
                                    })
                                "
                                ><TranslatableStringComponent
                                    :tstring="{
                                        string: 'showMoreFieldsForCopyPaste',
                                    }"
                                /><fa
                                    :icon="['fal', 'arrow-right']"
                                    class="fa-md ml-2"
                                />
                            </v-btn>
                        </div>
                    </ValidationObserver>
                </div>
            </v-menu>
        </v-badge>
        <BulkOrderPadDialog />
        <v-dialog v-model="showDialog" width="500" persistent>
            <v-card>
                <v-card-title class="headline grey lighten-2 mb-4" primary-title
                    ><TranslatableStringComponent
                        :tstring="{ string: 'sharedCartPricingNotice' }"
                /></v-card-title>
                <v-card-text>
                    <p class="mb-0">
                        <span
                            ><TranslatableStringComponent
                                :tstring="{ string: 'sharedCartPricingNote' }"
                        /></span>
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="removePricelist()">
                        <span
                            ><TranslatableStringComponent
                                :tstring="{ string: 'yes' }"
                        /></span>
                    </v-btn>
                    <v-btn color="primary" text @click="showDialog = false">
                        <span
                            ><TranslatableStringComponent
                                :tstring="{ string: 'no' }"
                        /></span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import handleSnackbar from '~/mixins/handleSnackbar'
import BulkOrderPadDialog from '~/components/BulkOrderPadDialog.vue'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'

const { mapFields, mapMultiRowFields } = createHelpers({
    getterType: 'getBulkOrderPadField',
    mutationType: 'updateBulkOrderPadField',
})
export default {
    name: 'BulkOrderPadMini',
    components: {
        ValidationProvider,
        ValidationObserver,
        BulkOrderPadDialog,
        TranslatableStringComponent,
    },
    mixins: [handleSnackbar],
    data() {
        return {
            isLoading: false,
            e1: 1,
            menu: false,
            isHoverOn: true,
            showDialog: false,
            isPricelistRemoved: false,
            disabledCheckout: false,
        }
    },
    computed: {
        ...mapFields('bulkOrderPad', ['formType']),
        ...mapMultiRowFields('bulkOrderPad', ['textFieldValues']),
        ...mapState('bulkOrderPad', [
            'dialog',
            'availableBulkOrderItems',
            'unAvailableItems',
        ]),
        ...mapState('priceList', ['appliedPriceList']),
        ...mapState('cart', ['cartProducts']),
        ...mapGetters({
            selectedPns: 'bulkOrderPad/selectedPns',
            lineByLineValues: 'bulkOrderPad/lineByLineValues',
            isPriceListMismatched: 'priceList/isPriceListMismatched',
            isAuthor: 'auth/isAuthor',
        }),
        cartUpdatedMessage() {
            return `itemsAddedToCart`
        },
    },
    watch: {
        menu(newValue, oldValue) {
            if (newValue === false) {
                this.isHoverOn = true
            }
        },
    },
    methods: {
        ...mapMutations('bulkOrderPad', ['addTextFieldRow']),
        setFormType(type) {
            if (type === 1) {
                this.formType = '1'
            } else {
                this.formType = '2'
            }
        },
        addEntryFields() {
            const item = {}
            item[`pn${this.textFieldValues.length}`] = ''
            item[`qty${this.textFieldValues.length}`] = ''
            this.textFieldValues = [...this.textFieldValues, item]
        },
        handleContentClick() {
            this.isHoverOn = false
        },
        removePricelist() {
            this.$store.commit('priceList/setSelectedPriceListId', '')
            this.$store.commit('priceList/setAppliedPriceList', [])
            this.isPricelistRemoved = true
            this.handleClick()
        },
        async handleClick() {
            this.$store.commit('bulkOrderPad/setUnavailableItems', [])
            this.$store.commit('bulkOrderPad/setStepperValue', 1)
            if (this.selectedPns.length === 0) {
                const message = 'pleaseEnterPartNumbersAndQuantity'
                this.hitSnackbar({ string: message }, null)
                return
            }
            if (
                this.appliedPriceList.length > 0 &&
                this.isPriceListMismatched &&
                !this.isPricelistRemoved
            ) {
                this.showDialog = true
                return
            }
            this.showDialog = false
            this.isPricelistRemoved = false
            this.isLoading = true
            await this.$store.dispatch(
                'bulkOrderPad/fetchBulkOrderItemsFromElastic'
            )
            if (
                this.availableBulkOrderItems.length !== this.selectedPns.length
            ) {
                this.$store.dispatch('bulkOrderPad/setUnavailableItems')
            }
            this.isLoading = false
            if (
                this.unAvailableItems.length > 0 ||
                this.cartProducts.length > 0
            ) {
                this.$store.commit('bulkOrderPad/setDialog', true)
                if (this.unAvailableItems.length === 0) {
                    this.$store.commit('bulkOrderPad/setStepperValue', 2)
                }
            } else {
                this.$store.dispatch(
                    'bulkOrderPad/replaceCartItemsWithBulkOrderItems'
                )
                this.hitSnackbar(
                    {
                        string: this.cartUpdatedMessage,
                        variables: [
                            {
                                var: this.availableBulkOrderItems.length,
                            },
                        ],
                    },
                    null
                )
            }
        },
        checkProductAvailibilityInElastic() {
            const cartProducts = localStorage.getItem('c3cart')
                ? JSON.parse(localStorage.getItem('c3cart')).cartProducts
                : []
            this.$store.dispatch(
                'cart/checkProductAvailibilityInElastic',
                {products: cartProducts}
            )
        },
        clearAllFields() {
            this.$store.commit('bulkOrderPad/clearFieldValues')
        },
    },
}
</script>

<style></style>
