import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
    creditCardForm: {
        cardHolder: '',
        cardNumber: '',
        expiryDate: '',
        cvc: '',
    },
    achForm: {
        accountName: '',
        routingNumber: '',
        accountType: '',
        accountNumber: '',
    },
})

export const actions = {
    clearPaymentFields({ commit }) {
        commit('clearACH')
        commit('clearCreditCard')
    },
}

export const mutations = {
    updateCreditCardFormField(state, field) {
        updateField(state.creditCardForm, field)
    },
    updateAchFormField(state, field) {
        updateField(state.achForm, field)
    },
    clearCreditCard(state) {
        state.creditCardForm.cardHolder = ''
        state.creditCardForm.cardNumber = ''
        state.creditCardForm.expiryDate = ''
        state.creditCardForm.cvc = ''
    },
    clearACH(state) {
        state.achForm.accountName = ''
        state.achForm.routingNumber = ''
        state.achForm.accountType = ''
        state.achForm.accountNumber = ''
    },
}

export const getters = {
    getCreditCardFormField(state) {
        return getField(state.creditCardForm)
    },
    getAchFormField(state) {
        return getField(state.achForm)
    },
}
