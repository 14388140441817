<template>
    <v-app id="sales">
        <v-navigation-drawer
            v-model="drawer"
            :clipped="$vuetify.breakpoint.lgAndUp"
            app
        >
            <v-list dense>
                <template v-for="(item, num) in items">
                    <v-row
                        v-if="item.heading"
                        :key="item.heading"
                        align="center"
                    >
                        <v-col cols="6">
                            <v-subheader v-if="item.heading">
                                {{ item.heading }}
                            </v-subheader>
                        </v-col>
                        <v-col cols="6" class="text-center">
                            <a href="#!" class="body-2 black--text">EDIT</a>
                        </v-col>
                    </v-row>
                    <!-- <v-list-group
                        v-else-if="item.children"
                        :key="item.text"
                        v-model="item.model"
                        :prepend-icon="
                            item.model ? item.icon : item['icon-alt']
                        "
                        append-icon=""
                    > -->
                    <v-list-group v-else-if="item.children" :key="item.text">
                        <template v-slot:activator>
                            <v-list-item>
                                <v-list-item-action v-if="item.icon">
                                    <fa
                                        :icon="['fal', `${item.icon}`]"
                                        class="fa-lg"
                                    />
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <nuxt-link
                            v-for="(child, i) in item.children"
                            :key="i"
                            :to="`/sales/${child.route}/`"
                            class="no-underline"
                            @click.stop=""
                        >
                            <v-list-item>
                                <v-list-item-action v-if="child.icon">
                                    <fa
                                        :icon="['fal', `${child.icon}`]"
                                        class="fa-lg"
                                    />
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ child.text }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </nuxt-link>
                    </v-list-group>

                    <nuxt-link
                        v-else
                        :key="item.text"
                        :to="`/sales/${item.route}/`"
                        class="no-underline"
                        @click.stop=""
                    >
                        <v-list-item>
                            <v-list-item-action>
                                <fa
                                    :icon="['fal', `${item.icon}`]"
                                    class="fa-lg"
                                />
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </nuxt-link>
                    <hr v-if="item.hr" :key="num" />
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
            :clipped-left="$vuetify.breakpoint.lgAndUp"
            app
            color="secondary"
            dark
        >
            <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
                <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                ></v-app-bar-nav-icon>
                <span class="hidden-sm-and-down">Customer First</span>
            </v-toolbar-title>
            <v-text-field
                flat
                solo-inverted
                hide-details
                label="Search"
                class="hidden-sm-and-down"
            ></v-text-field>
            <div class="flex-grow-1"></div>
            <v-btn icon>
                <fa
                    :icon="['fal', 'calendar-alt']"
                    class="fa-lg"
                    style="color:white"
                />
            </v-btn>
            <v-btn icon>
                <fa :icon="['fal', 'bell']" class="fa-lg" style="color:white" />
            </v-btn>
            <v-btn icon>
                <fa
                    :icon="['fal', 'external-link']"
                    class="fa-lg"
                    style="color:white"
                />
            </v-btn>
        </v-app-bar>
        <v-main>
            <v-container class="" fluid>
                <nuxt />
            </v-container>
        </v-main>
        <v-btn
            bottom
            color="primary"
            dark
            fab
            fixed
            right
            @click="dialog = !dialog"
        >
            <fa :icon="['fal', 'plus']" class="fa-lg" style="color:white" />
        </v-btn>
        <v-dialog v-model="dialog" width="800px">
            <v-card>
                <v-card-title class="grey darken-2">
                    Email Message To...
                </v-card-title>
                <v-container>
                    <v-row>
                        <v-col
                            class="align-center justify-space-between"
                            cols="12"
                        >
                            <v-row align="center">
                                <v-avatar size="40px" class="mr-4">
                                    <img
                                        src="//ssl.gstatic.com/s2/oz/images/sge/grey_silhouette.png"
                                        alt=""
                                    />
                                </v-avatar>
                                <v-text-field placeholder="Name"></v-text-field>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field placeholder="Company"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                placeholder="Job title"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field placeholder="Email"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                type="tel"
                                placeholder="(000) 000 - 0000"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field placeholder="Notes"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-btn text color="primary">More</v-btn>
                    <div class="flex-grow-1"></div>
                    <v-btn text color="primary" @click="dialog = false">{{
                        localize('cancel')
                    }}</v-btn>
                    <v-btn text @click="dialog = false">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
export default {
    props: {
        source: {
            type: String,
            default: ''
        },
        on: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        dialog: false,
        drawer: null,
        items: [
            {
                icon: 'building',
                text: 'Companies',
                route: 'companies',
                hr: false
            },
            {
                icon: 'money-check-edit-alt',
                text: 'Customers',
                route: 'customers',
                hr: false
            },
            {
                icon: 'address-book',
                text: 'Contacts',
                route: 'contacts',
                hr: true
            },
            {
                icon: 'star-exclamation',
                text: 'Web Users',
                route: 'webusers',
                hr: false
            },
            {
                icon: 'star-exclamation',
                text: 'New Web Orders',
                route: 'pending',
                hr: true
            },
            {
                icon: 'file-invoice-dollar',
                text: 'Sales Invoices',
                route: 'invoices'
            },
            {
                icon: 'file-invoice',
                text: 'Sales Orders',
                route: 'orders',
                hr: false
            },
            {
                icon: 'file-signature',
                text: 'Sales Quotes',
                route: 'quotes',
                hr: false
            },
            {
                icon: 'receipt',
                text: 'Sales Receipts',
                route: 'receipts',
                hr: false
            },
            {
                icon: 'undo-alt',
                text: 'Sales Returns',
                route: 'returns',
                hr: true
            },
            {
                icon: 'inventory',
                text: 'Fulfillments',
                route: 'fulfillments',
                hr: false
            },
            {
                icon: 'shipping-fast',
                text: 'Shippers',
                route: 'shippers',
                hr: false
            },
            {
                icon: 'file-exclamation',
                text: 'Shipping Docs',
                route: 'documentation',
                hr: true
            },
            {
                icon: 'scanner',
                text: 'Items',
                route: 'items',
                hr: false
            },
            {
                icon: 'scanner',
                text: 'Pricing',
                route: 'pricing',
                hr: true
            },
            {
                icon: 'sliders-v-square',
                text: 'Settings',
                model: true,
                children: [
                    {
                        icon: 'question',
                        text: 'Help',
                        route: 'help',
                        hr: false
                    },
                    {
                        icon: 'keyboard',
                        text: 'Cheatsheet',
                        route: 'cheatsheet',
                        hr: false
                    },
                    {
                        icon: 'bacon',
                        text: 'Contact Webmaster',
                        route: 'webmaster',
                        hr: true
                    }
                ]
            }
        ]
    })
}
</script>
<style lang="scss" scoped>
.svg-inline--fa {
    color: $primary;
    .nuxt-link-active & {
        color: $accent;
    }
}
.v-list-group__header {
    padding-left: 0;
}
.v-list-group__items .v-list-item {
    padding-left: 2rem;
}
</style>
