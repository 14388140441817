export const state = () => ({
    favList: null,
    availableFavList: null,
})

export const actions =  {
    updateFavList({commit,state},payload){
        let tempFavList = []
        const isFound = state.favList?.find(
            (item) => item.name === payload.name
        )
        //add a new favorite
        if (!isFound) {
            tempFavList = state.favList
                ? [...state.favList, payload]
                : [payload]
        }
        //remove from favorites
        else
            tempFavList = state.favList.filter(
                (item) => item.name !== payload.name
            )
        commit('setFavList',tempFavList)
    },
}

export const mutations = {
    setFavList(state,payload){
        state.favList = payload
    },
    setAvailableFavList(state,payload){
        state.availableFavList = payload
    }
}

export const getters = {
    fetchFavList(state){
        return state.favList
    },
    fetchAvailableFavList(state){
        return state.availableFavList
    }
}
