<template>
    <div class="cta-links-wrapper">
        <div v-if="layout === 'Default'">
            <div v-for="link in links" :key="link._key">
                <component
                    :is="isButton(link) ? 'v-btn' : 'div'"
                    :class="calcButtonStyle(link)"
                >
                    <!-- If this is an internal link... -->
                    <nuxt-link
                        v-if="isInternalLink(link)"
                        :to="calcTo(link)"
                        :class="calcLinkClass(link)"
                        >{{ calcText(link) }}</nuxt-link
                    >
                    <!-- If this is an external link... -->
                    <a
                        v-else-if="isExternalLink(link)"
                        :href="calcHref(link)"
                        :target="calcTarget(link)"
                        :class="calcLinkClass(link)"
                        >{{ calcText(link) }}</a
                    >
                    <a
                        v-else-if="isPhoneLink(link) || isEmailLink(link)"
                        :href="calcHref(link)"
                        :class="calcLinkClass(link)"
                        >{{ calcText(link) }}</a
                    >
                </component>
            </div>
        </div>
        <div v-else-if="layout === 'AnchorTabs'">
            <v-btn-toggle
                v-model="AnchorTab"
                tile
                color="white"
                group
                dark
                dense
            >
                <v-btn
                    v-for="link in links"
                    :key="link._key"
                    :value="calcText(link)"
                    :target="isExternalLink(link) ? '_blank' : '_self'"
                    @click="
                        calcAnchor(link)
                            ? $vuetify.goTo(calcAnchor(link), scrollOptions)
                            : goTo(link)
                    "
                >
                    {{ calcText(link) }}
                </v-btn>
            </v-btn-toggle>
        </div>
        <div v-else-if="layout === 'NoLink'">
            <div class="cta-links-wrapper">
                <h2 :class="`shadow ${font}`">{{ calcText(links[0]) }}</h2>
            </div>
        </div>
        <div v-else-if="layout === 'Image'">
            <div class="cta-links-wrapper">
                <nuxt-link
                    v-if="isInternalLink(links[0])"
                    :to="calcTo(links[0])"
                    :class="calcLinkClass(links[0])"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <EmbedSanityImage
                                    :selectedImage="
                                        content.sanityImage.selectedImage
                                    "
                                    classes="mt-0"
                                    :alt="content.sanityImage.alt"
                                    :width="imgWidth"
                                    height="150"
                                />
                            </div>
                        </template>
                        <span>{{ calcText(links[0]) }}</span>
                    </v-tooltip>
                    <!-- <EmbedCloudinary2
                        :image-id="content.image.imageId"
                        :image-dir="content.image.imageDir"
                        :image-align="img.align"
                        classes="mt-0"
                        :alt="
                            content.image.alt && content.image.alt[$i18n.locale]
                                ? content.image.alt
                                : {
                                      en: 'c3controls image',
                                      es: 'c3controls image',
                                  }
                        "
                        :width="imgWidth"
                        height="300"
                /> -->
                </nuxt-link>
                <a
                    v-else-if="isExternalLink(links[0])"
                    :href="calcHref(links[0])"
                    :target="calcTarget(links[0])"
                    :class="calcLinkClass(links[0])"
                    ><EmbedCloudinary2
                        :image-id="content.image.imageId"
                        :image-dir="content.image.imageDir"
                        :image-align="img.align"
                        classes="mt-0"
                        :alt="
                            content.image.alt && content.image.alt[$i18n.locale]
                                ? content.image.alt
                                : {
                                      en: 'c3controls image',
                                      es: 'c3controls image',
                                  }
                        "
                        :width="imgWidth"
                        height="300"
                /></a>
            </div>
        </div>
    </div>
</template>
<script>
import EmbedCloudinary2 from '~/components/embeds/EmbedCloudinary2.vue'
import EmbedSanityImage from '~/components/embeds/EmbedSanityImage.vue'
import prependRoute from '~/util/prependRoute'
export default {
    name: 'CtaLinks',
    components: {
        EmbedCloudinary2,
        EmbedSanityImage,
    },
    props: {
        links: {
            type: Array,
            default: null,
        },
        layout: {
            type: String,
            default: 'Default',
        },
        font: {
            type: String,
            default: 'font-h2',
        },
        content: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            AnchorTab: '',
        }
    },
    computed: {
        calcClass() {
            return 'no-underline'
        },
        scrollOptions() {
            return {
                duration: 1000,
                offset: 0,
                easing: 'easeInOutCubic',
            }
        },
        imgWidth() {
            return '250px'
        },
        img() {
            // console.log(this.image)
            return this.image ? setCloudinaryData(this.image, 'column') : {}
        },
    },
    methods: {
        isButton(link) {
            return link.ctaLinkStyle && link.ctaLinkStyle.indexOf('btn') === 0
        },
        isInternalLink(link) {
            // debugger
            return (
                !!link.ctaLinkURL &&
                !link.ctaLinkURLo &&
                !link.ctaLinkTelo &&
                !link.ctaLinkEmailo
            )
        },
        isExternalLink(link) {
            return (
                !!(
                    link.sanityFile?.asset?.originalFilename || link.ctaLinkURLo
                ) &&
                !link.ctaLinkTelo &&
                !link.ctaLinkEmailo
            )
        },
        isPhoneLink(link) {
            return !!link.ctaLinkTelo && !link.ctaLinkEmailo
        },
        isEmailLink(link) {
            return !!link.ctaLinkEmailo
        },
        calcButtonStyle(link) {
            if (this.isButton(link)) {
                if (link.ctaLinkStyle === 'btn--dark') {
                    return 'secondary hover-light'
                } else if (link.ctaLinkStyle === 'btn--light') {
                    return 'hover-dark'
                } else {
                    return ''
                }
            }
        },
        calcFont(link) {
            // console.log(link)
            return link.ctaFont ? link.ctaFont : 'font-h2'
        },
        calcTarget(link) {
            return link.ctaLinkTarget ? link.ctaLinkTarget : '_self'
        },
        calcLinkClass(link) {
            return link.ctaLinkStyle ? link.ctaLinkStyle : 'text--dark'
        },
        calcText(link) {
            const locale = this.$i18n.locale
            return link.ctaLinkLabel && link.ctaLinkLabel[locale]
                ? link.ctaLinkLabel[locale]
                : 'Click Here'
        },
        calcHref(link) {
            const locale = this.$i18n.locale
            if (this.isPhoneLink(link)) {
                return link.ctaLinkTelo ? `tel:+${link.ctaLinkTelo}` : '#'
            }
            if (this.isEmailLink(link)) {
                return link.ctaLinkEmailo ? `mailto:${link.ctaLinkEmailo}` : '#'
            }
            if (link.ctaLinkAnchor && link.ctaLinkAnchor[locale]) {
                return `${link.ctaLinkURLo}#${link.ctaLinkAnchor[locale]}`
            }
            if (link.ctaLinkURLo) {
                return link.ctaLinkURLo
            }
            if (link.sanityFile?.asset?.originalFilename) {
                return `${process.env.ASSET_HOSTNAME}${link.sanityFile?.asset?.originalFilename}`
            }
            return ''
        },
        calcTo(link) {
            const locale = this.$i18n.locale
            const type = link.ctaLinkURL ? link.ctaLinkURL._type : 'page'
            const slug = link.ctaLinkURL ? link.ctaLinkURL.slug : '/'
            const partial =
                link.ctaLinkPartial &&
                link.ctaLinkPartial[locale] &&
                link.ctaLinkPartial &&
                link.ctaLinkPartial[locale] !== ''
                    ? `${link.ctaLinkPartial[locale]}/`
                    : ''
            const anchor =
                link.ctaLinkAnchor &&
                link.ctaLinkAnchor[locale] &&
                link.ctaLinkAnchor[locale] !== ''
                    ? `#${link.ctaLinkAnchor[locale]}`
                    : ''
            const calc = prependRoute({ type, slug })
            // console.log('type: ', link.ctaLinkURL._type)
            // console.log('slug: ', link.ctaLinkURL.slug)
            // console.log('anchor: ', anchor)
            // console.log('calc: ', calc)
            // console.log('link: ', link)
            return calc && calc[locale]
                ? `${calc[locale]}${partial}${anchor}`
                : `#`
            // return '/about/'
        },
        calcAnchor(link) {
            if (this.isInternalLink(link)) {
                const locale = this.$i18n.locale
                const slug =
                    link.ctaLinkURL &&
                    link.ctaLinkURL.slug &&
                    link.ctaLinkURL.slug[locale]
                        ? `/${link.ctaLinkURL.slug[locale].current}/`
                        : '/'
                const anchor =
                    link.ctaLinkAnchor && link.ctaLinkAnchor[locale]
                        ? `#${link.ctaLinkAnchor[locale]}`
                        : ''
                // console.log('slug: ', slug)
                // console.log('anchor: ', anchor)
                // console.log('params: ', this.$route.params)
                if (slug.includes(this.$route.params.slug)) {
                    return `${anchor}`
                }
                return false
            }
            return false
            // return `${anchor}`
            // return '/about/'
        },
        goTo(link) {
            if (this.isInternalLink(link)) {
                const goto = this.calcTo(link)
                // console.log('goto: ', goto)
                if (process.browser) {
                    window.open(goto, '_self')
                }
            } else if (this.isExternalLink(link)) {
                const goto = this.calcHref(link)
                // console.log('goto: ', goto)
                if (process.browser) {
                    window.open(goto, '_blank')
                }
            }
            // console.log('link: ', link)
        },
    },
}
</script>
