import _ from 'lodash'
export const state = () => ({
    currentUserAbandonedCart: [],
    currentUserCartId: null,
    availableAbandonedCartItems: [],
    isAbandonedCartPresent: null,
})

export const actions = {
    async setUserCart({ commit, dispatch, rootGetters, rootState, state }) {
        if (rootGetters['auth/isLoggedIn']) {
            // No products in local cart
            if (
                rootGetters['auth/liveCart'] &&
                rootGetters['cart/fetchCartProducts'].length === 0
            )
                dispatch('syncLocalCartToErpCart')
            // Products present in local cart
            else if (
                rootGetters['auth/liveCart'] &&
                rootGetters['cart/fetchCartProducts'].length > 0
            ) {
                // if live cart different from local cart
                const liveCart = rootGetters['auth/liveCart']?.itemData.map(
                    (item) => {
                        return {
                            pn: item.pn,
                            prod_quantity: item.qty,
                            salePrice: item.yourPrice,
                        }
                    }
                )
                const localCart = rootGetters['cart/fetchCartProducts']?.map(
                    (item) => {
                        return {
                            pn: item.pn,
                            prod_quantity: item.prod_quantity,
                            salePrice: item.salePrice,
                        }
                    }
                )
                const getDifference = (array1, array2) => {
                    return array1.filter((object1) => {
                        return !array2.some((object2) => {
                            return (
                                object1.pn === object2.pn &&
                                object1.prod_quantity ===
                                    object2.prod_quantity &&
                                object1.salePrice === object2.salePrice
                            )
                        })
                    })
                }
                if (
                    [
                        ...getDifference(liveCart, localCart),
                        ...getDifference(localCart, liveCart),
                    ].length > 0
                ) {
                    //Save erp cart
                    if (
                        rootGetters['auth/webUser']?.Carts?.length - 1 <
                        rootGetters['savedCarts/maxSavedCartLimit']
                    ) {
                        await dispatch(
                            'savedCarts/createSavedCart',
                            {
                                ...rootGetters['auth/liveCart'],
                                label: `Previous_Cart_${new Date().toDateString()}`,
                                cartItems:
                                    rootGetters['auth/liveCart'].itemData,
                                subTotal: rootGetters['auth/liveCart'].subtotal,
                            },
                            {
                                root: true,
                            }
                        )
                        dispatch('savedCarts/clearSavedCartData', null, {
                            root: true,
                        })
                    } else {
                        commit('savedCarts/showMaxLimitMessage', true, {
                            root: true,
                        })
                    }
                    // delete previous live erp cart
                    dispatch('deleteErpCart', rootGetters['auth/liveCart'].id)
                    // create new erp live cart
                    dispatch('createErpCart')
                }
                // local cart same as live cart
                else {
                    dispatch('setLiveCart')
                }
            } else {
                commit('setIsAbandonedCartPresent', false)
                if (rootGetters['cart/fetchCartProducts'].length > 0)
                    dispatch('createErpCart')
            }
        }
    },

    setLiveCart({ commit, dispatch, rootGetters, state }) {
        commit('setIsAbandonedCartPresent', true)
        // Locally store cart id and items
        commit('updateCurrentUserCartId', rootGetters['auth/liveCart'].id)
        commit(
            'updateCurrentUserAbandonedCart',
            rootGetters['auth/liveCart'].itemData
        )
    },

    syncLocalCartToErpCart({ commit, dispatch, rootGetters, state }) {
        dispatch('setLiveCart')
        let itemPn = state.currentUserAbandonedCart.map((item) => item.pn)
        dispatch('fetchAbandonedCartItemsFromElastic', itemPn)
    },

    async createErpCart({ commit, getters, rootGetters, dispatch }) {
        try {
            const resp = await this.$axios.post('erp-cart?method=create', {
                ...getters.getProperties,
            })
            // console.log(resp)
            commit('updateCurrentUserCartId', resp.data.createCart.id)
            commit('setIsAbandonedCartPresent', true)
            commit(
                'updateCurrentUserAbandonedCart',
                resp.data.createCart.itemData
            )
        } catch (err) {
            // console.log(err)
        }
    },

    debounceQtyUpdate: _.debounce(function ({ dispatch }) {
        dispatch('updateErpCart')
    }, 1000),

    async updateErpCart(
        { dispatch, commit, state, rootState, getters, rootGetters },
        payload
    ) {
        if (rootGetters['auth/isLoggedIn']) {
            const id = state.currentUserCartId
            try {
                const resp = await this.$axios.post('erp-cart?method=update', {
                    ...getters.getProperties,
                    id,
                })
                if (resp) {
                    // console.log(resp)
                }
            } catch (err) {
                // console.log(err)
            }
        }
    },

    async deleteErpCart(
        { dispatch, commit, state, rootState, getters, rootGetters },
        payload
    ) {
        if (rootGetters['auth/isLoggedIn']) {
            try {
                const resp = await this.$axios.post('erp-cart?method=delete', {
                    id: payload ? payload : state.currentUserCartId,
                })
                // console.log(resp)
                dispatch('clearAbandonedCart')
            } catch (err) {
                // console.log(err)
            }
        }
    },

    clearAbandonedCart({ commit }) {
        commit('updateCurrentUserCartId', null)
        commit('updateCurrentUserAbandonedCart', [])
        commit('setAvailableAbandonedCartItems', [])
        commit('setIsAbandonedCartPresent', false)
    },

    async fetchAbandonedCartItemsFromElastic(
        { dispatch, commit, getters, rootGetters, state },
        payload
    ) {
        const res = await this.$axios.post('elastic-search', {
            elasticQuery: {
                size: 999,
                query: {
                    terms: {
                        pn: payload,
                    },
                },
            },
            elasticIndex: 'products-sanity',
        })
        // console.log(res)
        const items = res.data.hits.hits
            .map((item) => item._source)
            .filter((obj, pos, arr) => {
                // removing duplicate products with same 'pn' number
                return (
                    arr.map((mapObj) => obj.pn && mapObj.pn).indexOf(obj.pn) ===
                    pos
                )
            })

        // Update available items quantity with erp cart quantities
        const availableItems = items.map((item) => {
            item.prod_quantity = state.currentUserAbandonedCart.find(
                (i) => i.pn === item.pn
            ).qty
            return item
        })

        // Add isAvailable flag and quantity in cart to display in cart
        const filteredItemsWithAvailableFlag = availableItems.map((item) => {
            const locale = this.$i18n.locale
            return {
                ...item,
                isAvailable: true,
                name: item[`name_${locale}`],
                quantityInCart: item.prod_quantity,
            }
        })
        commit('setAvailableAbandonedCartItems', filteredItemsWithAvailableFlag)
        dispatch('cart/appendCart', state.availableAbandonedCartItems, {
            root: true,
        })
    },
}

export const mutations = {
    setIsAbandonedCartPresent(state, payload) {
        state.isAbandonedCartPresent = payload
    },
    updateCurrentUserCartId(state, payload) {
        state.currentUserCartId = payload
    },
    updateCurrentUserAbandonedCart(state, payload) {
        state.currentUserAbandonedCart = payload
    },
    setAvailableAbandonedCartItems(state, payload) {
        state.availableAbandonedCartItems = payload
    },
}

export const getters = {
    isAbandonedCartPresent(state) {
        return state.isAbandonedCartPresent
    },
    currentUserCartId(state) {
        return state.currentUserCartId
    },
    abandonedCartItemsPn(state, getters, rootState, rootGetters) {
        return state.currentUserAbandonedCart.map((item) => {
            return item.pn
        })
    },
    getProperties(state, getters, rootState, rootGetters) {
        let payloadItem = {}
        payloadItem.cartItems = rootGetters['cart/fetchCartProducts'].map(
            (item) => {
                return {
                    pn: item.pn,
                    qty: item.prod_quantity,
                    listPrice: item.list_price,
                    yourPrice: item.salePrice,
                    img: item.img,
                }
            }
        )
        payloadItem.priceList = rootGetters['priceList/cartPriceListId']
        // Initially it was from cart but it updates only when on cart page, so calculating here
        payloadItem.subTotal = rootGetters['cart/fetchCartProducts'].reduce(
            (acc, item) => {
                return acc + item.subTotalSalePrice
            },
            0
        )
        ;(payloadItem.isLive = true),
            (payloadItem.email = rootGetters['auth/webUser']?.email)

        return payloadItem
    },
}
