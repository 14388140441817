export class NetlifyDownError extends Error {
    constructor(message) {
        super(message)
        this.name = 'NETLIFY_DOWN_ERROR'
    }
}

export class RecaptchaVerificationFailedError extends Error {
    constructor(message) {
        super(message)
        this.name = 'RECAPTCHA_VERIFICATION_FAILED_ERROR'
    }
}
