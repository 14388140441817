<template>
    <nuxt-link :class="customClass" :to="link">{{ text }}</nuxt-link>
</template>
<script>
import embedLinks from '~/mixins/embedLinks'
import prependRoute from '~/util/prependRoute'
export default {
    name: 'EmbedInternalLink',
    props: {
        internalLink: {
            type: Object,
            default: null,
        },
    },
    mixins: [embedLinks],
    computed: {
        link() {
            const locale = this.$i18n.locale
            const type =
                this.internalLink && this.internalLink.linkTo
                    ? this.internalLink.linkTo._type
                    : 'page'
            const partial =
                this.internalLink &&
                this.internalLink.linkPartial &&
                this.internalLink.linkPartial[locale]
                    ? `${this.internalLink.linkPartial[locale]}/`
                    : ''
            const anchor =
                this.internalLink &&
                this.internalLink.linkAnchor &&
                this.internalLink.linkAnchor[locale]
                    ? `#${this.internalLink.linkAnchor[locale]}`
                    : ''
            const slug =
                this.internalLink && this.internalLink.linkTo
                    ? this.internalLink.linkTo.slug
                    : '#'
            const calc = prependRoute({ type, slug })
            if (!calc) return { en: '/', es: '/' }
            return calc && calc[locale]
                ? `${calc[locale]}${partial}${anchor}`
                : `#`
        },
    },
}
</script>
<style scoped>
.strong {
    font-weight: bold;
}
.italic {
    font-style: italic;
}
.underline {
    text-decoration: underline;
}
.strike-through {
    text-decoration: line-through;
}
</style>
