import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
    surveyForm: {
        experienceRating: 0,
        recommedationRating: 0,
        buyAgainRating: 0,
        message: '',
        followUpRequest: ''
    }
})

export const actions = {}

export const mutations = {
    updateSurveyField(state, field) {
        updateField(state.surveyForm, field)
    }
}

export const getters = {
    getSurveyField(state) {
        return getField(state.surveyForm)
    }
}
