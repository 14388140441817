import Vue from 'vue'
import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import { afterFrame } from '@elastic/apm-rum-core'

export default ({ app }) => {
    /*
     ** Only run on client-side and only in production mode
     */
    if (process.env.NODE_ENV !== 'production') return
    console.log('hash =====>', process.env.HASH)
    Vue.use(ApmVuePlugin, {
        config: {
            serviceName: 'v2-website',
            serverUrl: process.env.APM_ENDPOINT,
            serviceVersion: process.env.HASH,
            environment: process.env.APM_ENV,
            disableInstrumentations: ['eventtarget']
        }
    })

    let transaction
    const router = app.router
    router.beforeEach((to, from, next) => {
        const path = to.path
        transaction = Vue.prototype.$apm.startTransaction(
            path,
            'route-change',
            {
                managed: true,
                canReuse: true
            }
        )
        next()
    })
    router.afterEach(() => {
        afterFrame(() => (transaction ? transaction.detectFinish() : null)) // replaced ? operator with ternary until bugfix
    })
    router.onError(() => (transaction ? transaction.end() : null)) // replaced ? operator with ternary until bugfix
}
