export default {
    data() {
        return {
            token: null,
            captchaError: false,
            justRegistered: false
        }
    },
    methods: {
        resetForm(formId) {
            this.$refs[formId].reset()
            requestAnimationFrame(() => {
                this.$refs.observer.reset()
            })
            this.justRegistered = true
        },
        onSuccess(token) {
            // console.log('Succeeded:', token)
            this.token = token
            this.captchaError = false
        },
        onError(token) {
            this.captchaError = true
            this.token = null
        },
        onExpired(token) {
            this.captchaError = true
            this.token = null
        }
    }
}
