<template>
    <v-dialog v-model="dialog" width="500" persistent>
        <v-stepper v-model="stepperValue">
            <v-stepper-items>
                <v-stepper-content step="1" class="pa-0">
                    <v-card>
                        <v-card-title
                            class="headline grey lighten-2"
                            primary-title
                        >
                            <TranslatableStringComponent :tstring="{string: 'unavailableItems'}" />
                        </v-card-title>
                        <v-card-text class="pt-5">
                            <div>
                                <p>
                                    <TranslatableStringComponent :tstring="{string: 'incorrectUnavailablePartNo'}" />
                                </p>
                                <ul>
                                    <li
                                        v-for="(item, i) in unAvailableItems"
                                        :key="i"
                                    >
                                        {{ item }}
                                    </li>
                                </ul>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="availableBulkOrderItems.length > 0"
                                color="primary"
                                text
                                @click="proceed()"
                            >
                                <TranslatableStringComponent :tstring="{string: 'proceedWithAvailableItems'}" />
                            </v-btn>
                            <v-btn color="error" text @click="cancel()">
                                <TranslatableStringComponent :tstring="{string: 'cancel'}" />
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
                <v-stepper-content step="2" class="pa-0">
                    <v-card>
                        <v-card-title
                            class="headline grey lighten-2"
                            primary-title
                        >
                            <TranslatableStringComponent :tstring="{string: 'updateCart'}" />
                            <i class="subtitle-1 ml-2"
                                >(<TranslatableStringComponent :tstring="{string: 'itemsAlreadyExists', variables: [
                                        {
                                            var: cartProducts.length,
                                        },
                                    ]}" />.)</i
                            >
                        </v-card-title>
                        <v-card-text class="pa-0">
                            <v-list-item-group
                                v-model="settings"
                                active-class="primary--text"
                            >
                                <v-list-item>
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox
                                                :input-value="active"
                                            ></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <TranslatableBlockComponent :tstring="{string: 'clearAndReplaceItems'}" />
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>

                                <v-list-item>
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox
                                                :input-value="active"
                                            ></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <TranslatableBlockComponent :tstring="{string: 'addItemsToExistingCart'}" />
                                            </v-list-item-title>
                                            <v-list-item-subtitle
                                                ><i
                                                    >(<TranslatableStringComponent :tstring="{string: 'handlingSimilarProductsNote'}" />)</i
                                                ></v-list-item-subtitle
                                            >
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="handleDone()">
                                <TranslatableStringComponent :tstring="{string: 'done'}" />
                            </v-btn>
                            <v-btn color="error" text @click="cancel()">
                                <TranslatableStringComponent :tstring="{string: 'cancel'}" />
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import handleSnackbar from '~/mixins/handleSnackbar'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
import TranslatableBlockComponent from '~/components/TranslatableBlockComponent.vue'
export default {
    name: 'BulkOrderPadDialog',
    components: {
        TranslatableStringComponent,
        TranslatableBlockComponent,
    },
    mixins: [handleSnackbar],
    data() {
        return {
            isLoading: false,
            settings: 1,
        }
    },
    computed: {
        ...mapState('bulkOrderPad', [
            'dialog',
            'stepperValue',
            'availableBulkOrderItems',
            'unAvailableItems',
        ]),
        ...mapState('cart', ['cartProducts']),
        ...mapGetters({
            selectedPns: 'bulkOrderPad/selectedPns',
        }),
        cartUpdatedMessage() {
            return 'cartUpdatedWithQuickOrderPadItems'
        },
    },

    methods: {
        proceed() {
            if (this.cartProducts.length > 0) {
                this.$store.commit('bulkOrderPad/setStepperValue', 2)
            } else {
                this.replace()
            }
        },
        cancel() {
            this.$store.commit('bulkOrderPad/setStepperValue', 1)
            this.$store.commit('bulkOrderPad/setDialog', false)
            this.settings = 0
        },
        handleDone() {
            if (this.settings === 0) {
                this.replace()
            } else if (this.settings === 1) {
                this.append()
            }
        },
        append() {
            this.$store.commit('bulkOrderPad/setDialog', false)
            this.$store.dispatch('bulkOrderPad/appendBulkOrderItemsToCart')
            this.hitSnackbar({string: this.cartUpdatedMessage} , null)
            if (
                this.$route.params.slug === this.getSlug({ slugName: 'cart' })
            ) {
                this.checkProductAvailibilityInElastic()
            } else {
                this.routerPush({ type: 'page', slugName: 'cart' })
            }
            this.settings = 0
        },
        replace() {
            this.$store.commit('bulkOrderPad/setDialog', false)
            this.$store.dispatch(
                'bulkOrderPad/replaceCartItemsWithBulkOrderItems'
            )
            this.hitSnackbar({ string: this.cartUpdatedMessage}, null)
            if (
                this.$route.params.slug === this.getSlug({ slugName: 'cart' })
            ) {
                this.checkProductAvailibilityInElastic()
            } else {
                this.routerPush({ type: 'page', slugName: 'cart' })
            }
            this.settings = 0
        },
        checkProductAvailibilityInElastic() {
            const cartProducts = localStorage.getItem('c3cart')
                ? JSON.parse(localStorage.getItem('c3cart')).cartProducts
                : []
            this.$store.dispatch(
                'cart/checkProductAvailibilityInElastic',
                {products: cartProducts}
            )
        },
    },
}
</script>

<style></style>
