<template>
    <v-container class="pa-0">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <v-form
                ref="subscriptionFormA"
                @submit.prevent="handleSubmit(handleSubmission)"
            >
                <v-container class="pa-0">
                    <v-row>
                        <v-col cols="12">
                            <ValidationProvider
                                v-slot="{ errors }"
                                name="email"
                                rules="required|email|emoji"
                                class="validationWrapper"
                            >
                                <div>
                                    <span class="validationMessage">
                                        {{ errors[0] }}
                                    </span>
                                    <v-text-field
                                        id="email"
                                        v-model="email"
                                        name="email"
                                        :placeholder="localize('email')"
                                        outlined
                                        flat
                                        dense
                                        :hide-details="true"
                                    ></v-text-field>
                                </div> </ValidationProvider
                        ></v-col>
                        <v-col
                            cols="12"
                            :class="{
                                'pr-0': $vuetify.breakpoint.mdAndUp,
                                'pb-0': true, // $vuetify.breakpoint.smAndDown
                                'pl-0': $vuetify.breakpoint.mdAndUp,
                                'd-flex': $vuetify.breakpoint.mdAndUp,
                                'justify-center': $vuetify.breakpoint.mdAndUp,
                            }"
                        >
                            <recaptcha
                                @success="onSuccess"
                                @error="onError"
                                @expired="onExpired"
                                style="transform: scale(0.8)"
                            />
                            <div v-if="captchaError" class="captcha-error">
                                <TranslatableStringComponent :tstring="{string: 'captchaRequired'}" />
                            </div>
                        </v-col>
                        <v-col cols="12" class="text-center">
                            <v-btn
                                type="submit"
                                color="primary"
                                :loading="loader"
                                ><TranslatableStringComponent :tstring="{string: 'subscribe'}" /></v-btn
                            >
                            <p v-if="isError" class="red--text">
                                * <TranslatableStringComponent :tstring="{string: 'somethingWentWrongPleaseTryAgainLater'}" />.
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </ValidationObserver>
    </v-container>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapState } from 'vuex'
import Cookies from 'js-cookie'
import { required, email } from 'vee-validate/dist/rules'
import handleRecaptcha from '~/mixins/handleRecaptcha'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
import handleSnackbar from '~/mixins/handleSnackbar'

export default {
    name: 'FormSubscriptionPartA',
    components: {
        ValidationProvider,
        ValidationObserver,
        TranslatableStringComponent,
    },
    mixins: [handleRecaptcha, handleSnackbar],
    data() {
        return {
            email: '',
            loader: false,
            isError: false,
            captchaError: false,
        }
    },
    computed: {
        ...mapState('formSelectOptions', ['queryOptions']),
    },
    methods: {
        async handleSubmission() {
            if (!this.token) {
                this.captchaError = true
                return
            }
            try {
                const res = await this.$axios.post(
                    'reCaptcha-server-side-validation',
                    {
                        token: this.token,
                    }
                )

                if (res.data && res.data.success === false) throw res.data

                try {
                    this.isError = false
                    this.loader = true
                    this.$emit('setDisabled', true)
                    this.$store.commit(
                        'subscription/saveFormAResponse',
                        this.email
                    )
                    this.loader = false
                    Cookies.set('c3_never_show_subscription_again', 'true', {
                        expires: 2147483647,
                    })
                    this.$emit('hideSubscriptionTag')
                    this.resetForm('subscriptionFormA')
                    this.$store.commit('subscription/handleSubscriptionTab')
                    this.$emit('setDisabled', false)
                } catch (error) {
                    this.loader = false
                    this.isError = true
                    this.$emit('setDisabled', false)
                    console.log(error)
                }
            } catch (err) {
                const errorMessage = this.localize('recaptchaFailedMessage')
                console.log(errorMessage, err)
                this.handleErrors(errorMessage)
            }
        },
        handleErrors(errorMessage) {
            this.$recaptcha.reset()
            this.loader = false
            this.sending = false
            this.waiting = false
            this.failure = true
            this.hitSnackbar({ string: errorMessage }, {})
        },
    },
}
</script>
