<template>
    <v-dialog v-model="dialog" width="480" persistent>
        <v-card>
            <v-progress-linear
                v-if="dialogLoader"
                indeterminate
                color="#0063a6"
            ></v-progress-linear>
            <v-card-title class="headline" color="primary" primary-title>
                <TranslatableStringComponent :tstring="{string: 'logout'}" />
            </v-card-title>
            <v-card-text>
                <p><TranslatableStringComponent :tstring="{string: 'pleaseConfirmLogout'}" /></p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="primary" text @click="logout()">
                    ok
                </v-btn>
                <v-btn color="primary" text @click="handleDialog()">
                    <TranslatableStringComponent :tstring="{string: 'cancel'}" />
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import handleSnackbar from '~/mixins/handleSnackbar'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue' 
export default {
    name: 'DialogLogout',
    components: {
        TranslatableStringComponent,
    },
    mixins: [handleSnackbar],
    props: {
        dialog: {
            type: Boolean
        }
    },
    data() {
        return {
            dialogLoader: false
        }
    },
    computed: {
        coupon: {
            get() {
                return this.$store.state.coupon.couponCode
            },
            set(v) {
                this.$store.commit('coupon/setCouponCode', v)
            }
        }
    },
    methods: {
        ...mapActions('auth', ['attemptLogout']),
        logout() {
            // call API logout//
            this.dialogLoader = true
            this.attemptLogout()
                .then(() => {
                    // this.dialog = false

                    this.dialogLoader = false
                    this.hitSnackbar( { string: 'youHaveSuccessfullyLoggedOut' }, '')
                    this.routerPush({ type: 'page', slugName: 'login' })
                    
                    // console.log('You have successfully logged out')
                })
                .catch((err) => {
                    console.log(
                        err,
                        'Womp womp. Something went wrong when trying to log you out.'
                    )
                })
                .finally(() => {
                    this.$emit('handleDialog')
                })
        },
        handleDialog() {
            this.$emit('handleDialog')
        }
    }
}
</script>

<style></style>
