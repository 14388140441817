/* eslint-disable prettier/prettier */
// import axios from 'axios'
import prependRoute from '~/util/prependRoute'
import { client } from '~/plugins/global.js'
// const api = 'https://tkk9p83q.api.sanity.io/v1/data/query/production?query='

const groqIconGuts = `_type,camel,isFA,isPublic,snake,title`
const groq = `*[_type in ["menu","shippingCarrier","shippingRates","tstring","siteConfig","social","icon","category"]]{
    ...,
     defaultPriceList->{...},
    "menuSlug": slug.current,
    menuItems[]{
        ...,
        menuItemIcon->{${groqIconGuts}},
        menuItemURL->{_type,slug},
        menuItemURLo->{...},
        submenuItems[]{
            ...,
            menuItemIcon->{${groqIconGuts}},
            submenuItemURL->{_type,slug},
            submenuItemURLo->{...}
        }
    },
    contactPoints[]{
        ...,
        content{
            ...,
            en[]{
                ...,
                markDefs[]{
                    ...,
                    internalLink{
                        ...,
                        linkTo->{_type,slug,title}
                    }
                },
                settings{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
            },
            es[]{
                ...,
                markDefs[]{
                    ...,
                    internalLink{
                        ...,
                        linkTo->{_type,slug,title}
                    }
                },
                settings{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
            }
		},
        icon[]->{camel,isFA,isPublic,snake,title}
    },
    primaryContactInfo{
        ...,
        content{
            ...,
            en[]{
                ...,
                markDefs[]{
                    ...,
                    internalLink{
                        ...,
                        linkTo->{_type,slug,title}
                    }
                },
                settings{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
            },
            es[]{
                ...,
                markDefs[]{
                    ...,
                    internalLink{
                        ...,
                        linkTo->{_type,slug,title}
                    }
                },
                settings{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
            }
		}
	},
    ctaDefaultConfigurator{
        ...,
        en[]{
            ...,
            markDefs[]{
                ...,
                internalLink{
                    ...,
                    linkTo->{_type,slug,title}
                },
                settings{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
            },
            settings{
                ...,
                ctaLinkURL->{_type,slug,title}
            }
        },
        es[]{
            ...,
            markDefs[]{
                ...,
                internalLink{
                    ...,
                    linkTo->{_type,slug,title}
                },
                settings{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
            },
            settings{
                ...,
                ctaLinkURL->{_type,slug,title}
            }
        }
    },
    shopPageSettings{
        ...,
        shopMenuGroups[]{
            ...,
            shopMenuItems[]{
                ...,
                category->{...}
            }
        }
    },
    tblock{
        ...,
        en[]{
          ...,
          markDefs[]{
            ...,
            internalLink{
                ...,
                linkTo->{_type,slug,title}
            }
          }
        }
    }
}
`
const groqNoticeSettings = `*[_type in ["siteConfig"]]{
    _id,
    websiteNotice,
    maintenanceNotice,
    maintenanceNoticeDev,
   "eBookModal":eBookModal{...,eBookCtaLink->,sanityEBookImage{...,selectedImage{"asset":asset->{originalFilename}}}},
}`

export const state = () => ({
    list: null, // all documents
    rates: null, // shipping rates
    carriers: null, // shipping carriers
    strings: null, // translatable strings
    topBarRight: null, // menus from here down
    topBarLeft: null,
    mainBarRight: null,
    mainBarLeft: null,
    mobile: null,
    footerRight: null,
    footerLeft: null,
    footerProducts: null,
    footerServices: null,
    myAccount: null,
    offCanvas: null,
    siteSettings: null,
    socialIcons: null,
    noticeSettings: null,
    categories: null,
    languageSwitchGlobal: false,
})

export const mutations = {
    SET_COMMON(state, documents) {
        state.list = documents
        const stringObj = {}
        const carriersObj = []
        const ratesObj = []
        let siteSettings = {}
        const socialIcons = []
        const categories = []
        documents.forEach((document, i) => {
            if (document._type === 'tstring') {
                stringObj[document.slug.current] = {
                    en: document.tstring?.en || document.tblock?.en,
                    es: document.tstring?.es || document.tblock?.es,
                    changeEditor: document.changeEditor,
                    id: document._id,
                }
            } else if (
                document._type === 'shippingCarrier' ||
                document._type === 'shippingRates'
            ) {
                if (document._type === 'shippingCarrier') {
                    carriersObj.push(document)
                } else if (document._type === 'shippingRates') {
                    ratesObj.push(document)
                }
            } else if (document._type === 'menu') {
                document.menuItems.forEach((menuItem) => {
                    menuItem.slug = menuItem.slugo
                        ? menuItem.slugo
                        : prependRoute({
                              type: menuItem.type,
                              slug: menuItem.slug,
                          })
                    if (menuItem.submenuItems) {
                        menuItem.submenuItems.forEach((submenuItem) => {
                            submenuItem.slug = submenuItem.slugo
                                ? submenuItem.slugo
                                : prependRoute({
                                      type: submenuItem.type,
                                      slug: submenuItem.slug,
                                  })
                        })
                    }
                })
                state[document.menuSlug] = document
            } else if (
                document._type === 'siteConfig' &&
                document._id === process.env.SANITY_SETTINGS_ID
            ) {
                siteSettings = document
            } else if (document._type === 'social') {
                socialIcons.push(document)
            } else if (document._type === 'category') {
                categories.push(document)
            }
        })
        state.strings = stringObj
        state.rates = ratesObj
        state.carriers = carriersObj
        state.siteSettings = siteSettings
        state.socialIcons = socialIcons
        state.categories = categories
    },
    setNoticeSettings(state, payload) {
        state.noticeSettings = payload
    },
    handleLanguageSwitch(state, payload) {
        state.languageSwitchGlobal = payload
    },
}

export const actions = {
    async init({ commit }) {
        const result = await client.fetch(groq)
        commit('SET_COMMON', result)
    },

    async fetchSanityNoticeSettings({ commit }) {
        const result = await client.fetch(groqNoticeSettings)
        result.forEach((element) => {
            if (element._id === process.env.SANITY_SETTINGS_ID)
                commit('setNoticeSettings', element)
        })
    },
}

export const getters = {
    categoryTree(state) {
        const sortedCategories = _.sortBy(state.categories, (c) =>
            parseFloat(c.sequence)
        )
        const topLevelCategories = sortedCategories.filter(
            (c) => c.isFeatured === true
        )
        const subCategories = sortedCategories.filter(
            (c) => c.isFeatured === false && c.sequence
        )
        const groupSubCategoriesBySequence = _.groupBy(subCategories, (c) =>
            Math.floor(parseInt(c.sequence) / 100)
        )
        return topLevelCategories.map((category) => {
            return {
                ...category,
                subCategories:
                    groupSubCategoriesBySequence[
                        `${parseInt(category.sequence) / 100}`
                    ] || [],
            }
        })
    },
}
