export default function (selectedSchedulePrice, listPrice) {
    const value = parseFloat(selectedSchedulePrice.value)
    if (selectedSchedulePrice.valueType === '% Discount') {
        const discountPrice = listPrice * (value / 100)
        return listPrice - discountPrice
    } else if (selectedSchedulePrice.valueType === 'Actual') {
        return value
    } else {
        return 0
    }
}
