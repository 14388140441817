import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
    catalogForm: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        jobtitle: '',
        hs_persona: '',
        company: '',
        country: '',
        address: '',
        street_address_2: '',
        city: '',
        state: '',
        zip: ''
    }
})

export const actions = {}

export const mutations = {
    updateCatalogField(state, field) {
        updateField(state.catalogForm, field)
    },
    clearFields(state, payload) {
        state.catalogForm.state = ''
        state.catalogForm.city = ''
        state.catalogForm.zip = ''
    }
}

export const getters = {
    getCatalogField(state) {
        return getField(state.catalogForm)
    }
}
