<template>
    <div style="display: inline">
        <v-tooltip
            color="#cccccc"
            bottom
            close-delay="2000"
            v-if="webUser && translators.includes(webUser.email)"
        >
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on"
                    ><BlockContent
                        :className="includeClass ? 'class1' : ''"
                        :blocks="localize(tstring.string, tstring.variables)"
                        :serializers="serializers"
                /></span>
            </template>
            <a
                v-if="
                    $store.state.common.strings[tstring.string] &&
                    $store.state.common.strings[tstring.string].id
                "
                :href="`https://sanity.c3controls.tech/desk/translatableStrings;${
                    $store.state.common.strings[tstring.string].id
                }`"
                target="blank"
                >Go to sanity tstring</a
            >
        </v-tooltip>
        <span v-else
            ><BlockContent
                :className="includeClass ? 'class1' : ''"
                :blocks="localize(tstring.string, tstring.variables)"
                :serializers="serializers"
            /></span
        >
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import serializers from '~/util/sanity/serializers'
export default {
    name: 'TranslatableBlockComponent',
    props: {
        tstring: {
            type: Object,
            default: null,
        },
        includeClass: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        ...mapGetters({
            webUser: ['auth/webUser'],
        }),
        translators() {
            return this.$store.state.common.siteSettings.emailTranslators.split(',')
        }
    },
    data() {
        return {
            serializers,
        }
    }
}
</script>
<style lang="scss" scoped>
.class1{
    p { 
        margin-bottom: 0;
    }
}
</style>
