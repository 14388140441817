<template>
    <v-container class="pa-0">
        <v-stepper v-if="isCheckoutTemplate" :value="activeStep">
            <v-stepper-header>
                <v-stepper-step step="1" editable @click="navigateTo('cart')">
                    <TranslatableStringComponent :tstring="{string: 'cart'}" />
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                    step="2"
                    :editable="!isCartEmpty"
                    @click="handleCheckoutNavigation()"
                >
                    <TranslatableStringComponent :tstring="{string: 'billingShipping'}" />
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                    v-show="!isCustomerCreated"
                    step="3"
                    :editable="!isCartEmpty"
                    @click="navigateTo('customer-details')"
                    ><TranslatableStringComponent :tstring="{string: 'customerDetails'}" /></v-stepper-step
                >
                <v-divider v-show="!isCustomerCreated"></v-divider>
                <v-stepper-step
                    :step="isCustomerCreated ? 3 : 4"
                    :editable="
                        !isCartEmpty &&
                            isAddressSelected &&
                            !isCarrierVendorDetailsEmpty &&
                            isCustomerCreated
                    "
                    @click="navigateTo('payment')"
                >
                    <TranslatableStringComponent :tstring="{string: 'payment'}" />
                </v-stepper-step>
            </v-stepper-header>
        </v-stepper>
        <ConnectCustomerDialog 
            :showModal="showModal"
            :stepperValue="stepperValue"
            @handleProceedCheckout="() => {handleDialog();navigateTo('checkout')}"
            @handleDialog="handleDialog()"
            @handlePostCustomerConnect="handlePostCustomerConnect()"
            @changeStepperValue="stepperValue='2'"
        />
        <UnavailableProductWarningModal
            :showModal="showUnavailableProductsModal"
            @handleProceedCheckout="
                () => {
                    handleDialog()
                    navigateTo('checkout')
                }
            "
            @handleDialog="handleDialog()"
        />
    </v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import handleSnackbar from '~/mixins/handleSnackbar'
import ConnectCustomerDialog from '~/components/ConnectCustomerDialog.vue'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue' 
import UnavailableProductWarningModal from '~/components/UnavailableProductWarningModal.vue'
export default {
    name: 'StepperCheckout',
    components: {
        ConnectCustomerDialog,
        TranslatableStringComponent,
        UnavailableProductWarningModal,
    },
    mixins: [handleSnackbar],
    props: {},
    data() {
        return {
            showModal:false,
            stepperValue: '1',
            showUnavailableProductsModal: false,
        }
    },
    computed: {
        ...mapGetters({
            isCartEmpty: 'cart/isCartEmpty',
            isCarrierVendorDetailsEmpty: 'cart/isCarrierVendorDetailsEmpty',
            isShipperNumberValidationTrue: 'cart/isShipperNumberValidationTrue',
            isCustomerCreated: 'auth/isCustomerCreated',
            isCustomerConnected: 'auth/isCustomerConnected',
            getUnavailableProductListInElastic:
                'cart/getUnavailableProductListInElastic',
        }),
        ...mapState('checkout', [
            'billingFormArray',
            'selectedBillingAddressID',
            'selectedShippingAddressID',
            'checkoutPages'
        ]),
        ...mapState('cart', [
            'selectedCarrierMethod',
            'shipperNumber',
            'carrierVendorName'
        ]),
        isCheckoutTemplate() {
            const isFound = this.checkoutPages.find((item) => {
                return item.slug[this.$i18n.locale] === this.$route.params.slug
            })
            return !!isFound
        },
        activeStep() {
            return this.checkoutPages.find((item) => {
                return item.slug[this.$i18n.locale] === this.$route.params.slug
            }).step
        },
        isAddressSelected() {
            if (
                this.selectedBillingAddressID &&
                this.selectedShippingAddressID
            ) {
                return true
            } else {
                return false
            }
        }
    },
    methods: {
        navigateTo(page) {
            if (this.isCartEmpty) {
                return undefined
            } else if (page === 'payment' || page === 'customer-details') {
                if (!this.isCustomerCreated && page === 'payment') {
                    this.hitSnackbar({string :'pleaseFillTheCustomerDetails'}, '')
                } else if (this.isCarrierVendorDetailsEmpty) {
                    this.hitSnackbar(
                        {string :'pleaseFillTheCarrierVendorDetails'},
                        ''
                    )
                } else if (!this.isShipperNumberValidationTrue) {
                    this.hitSnackbar({string :'shipperNumberEnteredIsInvalid'}, '')
                } else if (this.billingFormArray.length === 0) {
                    this.hitSnackbar(
                        {string :'pleaseAddAddressToProceedToPayment'},
                        ''
                    )
                } else if (
                    !this.selectedBillingAddressID &&
                    !this.selectedShippingAddressID
                ) {
                    this.hitSnackbar(
                        {string :'pleaseSelectBillingAddressAndShippingAddress'},
                        ''
                    )
                } else if (!this.selectedBillingAddressID) {
                    this.hitSnackbar({string :'pleaseSelectBillingAddress'}, '')
                } else if (!this.selectedShippingAddressID) {
                    this.hitSnackbar({string :'pleaseSelectShippingAddress'}, '')
                } else {
                    this.routerPush({ type: 'page', slugName: page })
                }
            } else {
                this.routerPush({ type: 'page', slugName: page })
            }
        },
        handleCheckoutNavigation(){
            if (
                this.getUnavailableProductListInElastic &&
                this.getUnavailableProductListInElastic.length > 0
            )
                this.showUnavailableProductsModal = true
            else if (!this.isCustomerConnected) this.showModal = true
            else this.navigateTo('checkout')
        },
        handleDialog() {
            this.showModal = false
            this.stepperValue = '1'
        },
        handlePostCustomerConnect(){
            this.handleDialog()
            this.navigateTo('checkout')      
        }
    }
}
</script>
