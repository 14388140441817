<template>
    <v-app
        v-if="$vuetify.breakpoint.width > 0"
        light
        :class="{
            breakpoints: true,
            'app--sm-and-down': $vuetify.breakpoint.smAndDown,
            'app--md-and-up': $vuetify.breakpoint.mdAndUp,
            'app--lg-and-up': $vuetify.breakpoint.lgAndUp,
        }"
        style="overflow-x: hidden"
    >
        <v-app-bar
            id="v-app-bar"
            app
            elevate-on-scroll
            :hide-on-scroll="true"
            scroll-threshold="1"
            dense
            container
            height="48px"
            extension-height="64px"
        >
            <div style="max-width: 50%">
                <nuxt-link :to="localePath('index')">
                    <v-toolbar-title style="font-size: 1; line-height: 0">
                        <EmbedSanityImage
                            originalFilename="c3controls-logo-t.png"
                            :height="
                                $vuetify.breakpoint.smAndDown ? '30' : '25'
                            "
                            image-align="none"
                            :width="
                                $vuetify.breakpoint.smAndDown ? '251' : '209'
                            "
                            context="specific"
                        />
                    </v-toolbar-title>
                </nuxt-link>
            </div>
            <v-spacer />
            <MenuNavigation
                id="menuTopBarRight"
                :menu="menuTopBarRight"
                :steez="`buttons`"
                class="d-none d-lg-flex nav-menu--dark"
            />
            <MenuBadges v-if="$vuetify.breakpoint.smAndDown && $vuetify.breakpoint.width !== 0" />
            <v-btn icon class="d-lg-none btn--light">
                <div class="menu-icon-wrapper" router exact>
                    <fa
                        :icon="['fal', 'bars']"
                        class="fa-lg"
                        @click.stop="leftDrawer = !leftDrawer"
                    />
                </div>
                <span class="menu-icon-subtext">{{ `menu` }}</span>
            </v-btn>
            <template v-slot:extension>
                <Search
                    :class="{
                        'nav-input--md-and-down': $vuetify.breakpoint.mdAndDown,
                        'nav-input--lg-and-up': $vuetify.breakpoint.lgAndUp,
                    }"
                    :label="localize('enterPnOrKw')"
                />
                <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
                <BulkOrderPadMini v-if="$vuetify.breakpoint.mdAndUp" />
                <MenuBadges v-if="$vuetify.breakpoint.mdAndUp" />
            </template>
        </v-app-bar>

        <v-main
            id="main-container"
            :class="{
                'scrolling-down': !showC3Icon,
                'scrolling-up': showC3Icon,
            }"
            style="padding-top: 112px"
        >
            <stepperCheckout />
            <DynamicSnackbar />
            <!-- <pre style="position:absolute">{{ menuOffCanvas }}</pre> -->
            <nuxt />
        </v-main>
        <v-navigation-drawer
            v-model="leftDrawer"
            :mini-variant="miniVariant"
            :left="right"
            fixed
            app
            width="320"
            :disable-resize-watcher="true"
        >
            <MenuOffCanvas
                id="menuOffCanvas"
                :menu="menuOffCanvas"
                :steez="`offcanvas`"
                :mini-variant="miniVariant"
                :open-items="openItems"
            />
        </v-navigation-drawer>
        <v-footer
            v-if="$vuetify.breakpoint.mdAndUp"
            :fixed="fixed"
            app
            class="footer-bar-wrapper pa-0"
        >
            <v-container>
                <v-row column align-center no-gutters>
                    <v-col
                        cols="12" md="2"
                        class="footer-bar footer-copyright hidden-sm-and-down"
                    >
                        <span>&copy; 1976-{{ new Date().getFullYear() }} </span>
                        <span>c3controls</span>
                    </v-col>
                    <v-col cols="12" md="3" class="footer-bar footer-phone">
                        <a href="tel:+17247757926" class="no-underline">{{
                            localize('c3Phone')
                        }}</a>
                    </v-col>
                    <v-col cols="12" md="3" class="footer-bar footer-social">
                        <SocialIcons />
                    </v-col>
                    <v-col cols="12" md="2" class="footer-bar justify-center">
                        <LanguageSwitch />
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
        <MaintenanceNotice />
        <HolidayNotice />
        <PopupCookieNotice />
        <!-- <dialogLogout :dialog="dialog" @handleDialog="handleDialog()" /> -->
        <v-btn
            v-show="fab"
            v-scroll="onScrollButton"
            fab
            dark
            fixed
            bottom
            left
            color="primary"
            @click="toTop"
        >
            <fa
                :icon="['fal', 'angle-up']"
                :style="{ color: 'white' }"
                size="2x"
            />
        </v-btn>
        <!-- <div
            v-else
            v-show="fab"
            style=" position: fixed;top: 90%;width: 100%;text-align: center;z-index:10;"
        >
            <v-btn
                v-scroll="onScrollButton"
                x-small
                rounded
                style="margin:0 auto;opacity: 0.9;"
                @click="toTop"
            ><span>scroll to top</span><fa :icon="['fal', 'angle-up']" class="fa-md ml-1" />
            </v-btn>
        </div> -->
        <EbookModal />
    </v-app>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
// import Cookies from 'js-cookie'
import cleanQuerySelector from '~/util/cleanQuerySelector'
// import Cookies from 'js-cookie' // <-- TEMPORARY FOR BETA SITE
import HolidayNotice from '~/components/popups/HolidayNotice.vue'
import MaintenanceNotice from '~/components/popups/MaintenanceNotice.vue'
import PopupCookieNotice from '~/components/popups/PopupCookieNotice.vue'
import EmbedCloudinary2 from '~/components/embeds/EmbedCloudinary2.vue'
// import FormDev from '~/components/forms/FormDev.vue'
import MenuNavigation from '~/components/menus/MenuNavigation.vue'
import MenuOffCanvas from '~/components/menus/MenuOffCanvas.vue'
import Search from '~/components/Search.vue'
import SocialIcons from '~/components/SocialIcons.vue'
import stepperCheckout from '~/components/stepperCheckout.vue'
import DynamicSnackbar from '~/components/DynamicSnackbar.vue'
import menuUtilities from '~/mixins/menuUtilities'
import handleAuth from '~/mixins/handleAuth'
import handleSnackbar from '~/mixins/handleSnackbar'
import BulkOrderPadMini from '~/components/BulkOrderPadMini.vue'
import MenuBadges from '~/components/MenuBadges.vue'
import EbookModal from '~/components/popups/EbookModal.vue'
// import SubscriptionTag from '~/components/SubscriptionTag.vue'
import LanguageSwitch from '~/components/LanguageSwitch.vue'
import EmbedSanityImage from '~/components/embeds/EmbedSanityImage.vue'
export default {
    name: 'DefaultLayout',
    components: {
        // EmbedCloudinary2,
        EmbedSanityImage,
        // FormDev,
        HolidayNotice,
        MaintenanceNotice,
        MenuNavigation,
        MenuOffCanvas,
        PopupCookieNotice,
        Search,
        SocialIcons,
        stepperCheckout,
        DynamicSnackbar,
        BulkOrderPadMini,
        MenuBadges,
        EbookModal,
        // SubscriptionTag,
        LanguageSwitch,
    },
    mixins: [menuUtilities, handleAuth, handleSnackbar],
    // head() {
    //     return this.$nuxtI18nSeo()
    // },
    data() {
        return {
            showC3Icon: false, // https://medium.com/@Taha_Shashtari/hide-navbar-on-scroll-down-in-vue-fb85acbdddfe
            lastScrollPosition: 0,
            drawer: false,
            fixed: false,
            timeout: 3000,
            snackbar: false,
            e1: 1,
            miniVariant: false,
            right: true,
            leftDrawer: false,
            hasProductsOpen: false,
            openItems: {},
            title: 'c3controls',
            collapse: '',
            menuTopBarRight: this.$store.state.common.topBarRight,
            menuTopBarLeft: this.$store.state.common.topBarLeft,
            menuMainBarRight: this.$store.state.common.mainBarRight,
            menuMobile: this.$store.state.common.mobile,
            menuFooterRight: this.$store.state.common.footerRight,
            menuFooterLeft: this.$store.state.common.footerLeft,
            menuFooterProducts: this.$store.state.common.footerProducts,
            menuOffCanvas: this.$store.state.common.offCanvas,
            password: '',
            fab: false,
        }
    },
    computed: {
        ...mapState('priceList', ['appliedPriceList', 'selectedPriceListId']),
        ...mapState('cart', ['cartProducts']),
        ...mapGetters({
            fetchCartProducts: 'cart/fetchCartProducts',
            fetchCartQty: 'cart/fetchCartQty',
            isLoggedIn: 'auth/isLoggedIn',
            cartPriceListId: 'priceList/cartPriceListId',
            fetchCartSubtotal: 'cart/fetchCartSubtotal',
            favorites: 'auth/favorites',
        }),
        isLocalhost() {
            return process.env.SECURE_COOKIES === 'false'
        },
        navRoutes() {
            return {
                cart: {
                    route:
                        this.$i18n.locale === 'es'
                            ? '/es/carrito-de-compras/'
                            : '/cart/',
                    label: this.$i18n.locale === 'es' ? 'Cesta' : 'Cart',
                },
                login: {
                    route:
                        this.$i18n.locale === 'es'
                            ? '/es/iniciar-sesión/'
                            : '/login/',
                    label:
                        this.$i18n.locale === 'es'
                            ? 'Iniciar sesión'
                            : 'Account',
                },
            }
        }
    },
    mounted() {
        // this.getCookie() // <-- TEMPORARY FOR BETA SITE
        // console.log(this.$vuetify.breakpoint.smAndDown)
        this.$store.commit('cart/initLocalCart') // <--- persist user's cart
        this.$store.commit('customProducts/initCustomProductList')
        this.$store.commit('configurations/initLocalConfigurations') // <--- persist user's configurator configurations
        this.$store.commit('priceList/initPriceList')
        this.$store.commit('savedCarts/initSavedCarts')
        this.$store.subscribe((mutation, state) => {
            // Store the state object as a JSON string
            if (mutation.type.indexOf('cart/') === 0) {
                localStorage.setItem('c3cart', JSON.stringify(state.cart))
            } else if (mutation.type.indexOf('configurations/') === 0) {
                // console.log('a configurations mutation:', mutation)
                localStorage.setItem(
                    'c3pn',
                    JSON.stringify(state.configurations)
                )
            } else if (mutation.type.indexOf('priceList/') === 0) {
                localStorage.setItem(
                    'c3PriceList',
                    JSON.stringify(state.priceList)
                )
            } else if (mutation.type.indexOf('savedCarts/') === 0) {
                localStorage.setItem(
                    'c3SavedCarts',
                    JSON.stringify(state.savedCarts)
                )
            } else if (mutation.type.indexOf('customProducts/') === 0) {
                localStorage.setItem(
                    'customProducts',
                    JSON.stringify(state.customProducts)
                )
            }
            // console.log(JSON.parse(localStorage.c3cart))
        })
        if (process.browser) {
            window.addEventListener('scroll', this.onScroll)
            window.onNuxtReady((app) => {
                if (window.location.hash) {
                    if (
                        window.location.hash.indexOf('#confirmation_token=') ===
                        0
                    ) {
                        const token = window.location.hash.replace(
                            '#confirmation_token=',
                            ''
                        )
                        history.replaceState(
                            '',
                            document.title,
                            window.location.pathname + window.location.search
                        )
                        this.$store.dispatch('auth/cookieTheToken', token)
                        if (this.isLoggedIn) {
                            this.silentLogout()
                        } else {
                            this.hitSnackbar(
                                {
                                    string:
                                        'pleaseLogInToConfirmYourRegistration',
                                },
                                null
                            )
                        }
                    } else if (
                        window.location.hash.indexOf('#recovery_token=') === 0
                    ) {
                        const token = window.location.hash.replace(
                            '#recovery_token=',
                            ''
                        )
                        history.replaceState(
                            '',
                            document.title,
                            window.location.pathname + window.location.search
                        )
                        this.$store.dispatch(
                            'auth/cookieTheRecoveryToken',
                            token
                        )
                        this.hitSnackbar(
                            {
                                string:
                                    'thankYouForConfirmingYourEmailPleaseSetANewPassword',
                            },
                            null
                        )
                        // this.logout()
                    } else if (
                        window.location.hash.indexOf('#invite_token=') === 0
                    ) {
                        const token = window.location.hash.replace(
                            '#invite_token=',
                            ''
                        )
                        history.replaceState(
                            '',
                            document.title,
                            window.location.pathname + window.location.search
                        )
                        this.$store.dispatch('auth/cookieTheInviteToken', token)
                        this.hitSnackbar(
                            { string: 'pleaseSetAPasswordToContinueLogin' },
                            null
                        )
                    }
                }
            })
        }
        // TODO: Prevent this from firing on every page

        this.handleSharedCartNavigation()
    },
    beforeDestroy() {
        if (process.browser) {
            window.removeEventListener('scroll', this.onScroll)
        }
    },
    async created() {
        await this.$store.dispatch('auth/fetchWebUser')
        this.$store.dispatch('priceList/fetchPriceListEntries')
        this.$store.dispatch('common/fetchSanityNoticeSettings')
        this.$nuxt.$on('toggleMiniVariant', () => {
            this.miniVariant = !this.miniVariant
        })
        this.$nuxt.$on('toggleRightDrawer', () => {
            this.leftDrawer = !this.leftDrawer
        })
        this.$nuxt.$on('toggleRightDrawerNoSubs', () => {
            if (this.leftDrawer && this.miniVariant) {
                this.miniVariant = false
                this.hasProductsOpen = false
            } else if (this.leftDrawer && this.hasProductsOpen) {
                this.hasProductsOpen = false
            } else {
                this.leftDrawer = !this.leftDrawer
                this.hasProductsOpen = false
            }
        })
        this.$nuxt.$on('toggleItemsOpen', (e) => {
            this.openItems[e] = !this.openItems[e]
        })
        this.$nuxt.$on('toggleProductsStart', () => {
            if (this.leftDrawer && !this.hasProductsOpen) {
                this.hasProductsOpen = true
            } else if (this.leftDrawer && this.hasProductsOpen) {
                this.hasProductsOpen = false
                this.leftDrawer = false
            } else {
                this.leftDrawer = !this.leftDrawer
                this.hasProductsOpen = !this.hasProductsOpen
            }
        })
    },
    methods: {
        ...mapActions('auth', ['attemptConfirmation', 'attemptLogout']),
        async silentLogout() {
            try {
                await this.attemptLogout()
                this.hitSnackbar(
                    { string: 'pleaseLogInToConfirmYourRegistration' },
                    null
                )
            } catch (error) {
                console.log(
                    error,
                    'Womp womp. Something went wrong when trying to confirm.'
                )
                this.hitSnackbar(
                    { string: 'wompWompSomethingWentWrongWhenTryingToConfirm' },
                    null
                )
            }
        },
        onScroll() {
            if (process.browser) {
                // Get the current scroll position
                const currentScrollPosition =
                    window.pageYOffset || document.documentElement.scrollTop
                // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
                if (currentScrollPosition < 0) {
                    return
                }
                // Here we determine whether we need to show or hide the navbar
                this.showC3Icon =
                    currentScrollPosition < this.lastScrollPosition
                // Set the current scroll position as the last scroll position
                this.lastScrollPosition = currentScrollPosition
            }
        },
        onScrollButton(e) {
            if (typeof window === 'undefined') return
            const top = window.pageYOffset || e.target.scrollTop || 0
            const minTriggerHeight = this.$vuetify.breakpoint.mdAndUp
                ? 20
                : document.documentElement.clientHeight
            this.fab = top > minTriggerHeight
        },
        toTop() {
            this.$vuetify.goTo(0)
        },
        handleSharedCartNavigation() {
            const exceptionPaths = [
                'checkout',
                'payment',
                'customer-details',
                'cart',
                'login',
                'register',
            ]
            const isExceptionPathFound = exceptionPaths.includes(
                this.$route.params.slug
            )
            if (
                this.appliedPriceList.length > 0 &&
                this.selectedPriceListId &&
                !isExceptionPathFound
            ) {
                this.$store.commit('priceList/setSelectedPriceListId', '')
                this.$store.commit('priceList/setAppliedPriceList', [])
            }
        },
        // setCookie() {
        //     if (this.password === process.env.MOUNTED_UNLOCK) {
        //         Cookies.set('isUnlocked', process.env.MOUNTED_UNLOCK)
        //         location.reload()
        //     }
        // },
        // getCookie() {
        //     const cookieValue = Cookies.get('isUnlocked')
        //     if (cookieValue === process.env.MOUNTED_UNLOCK) {
        //         this.isUnlocked = true
        //     } else {
        //         this.isUnlocked = false
        //     }
        // }
    },
}
</script>
<style>
.row {
    margin-top: 0;
    margin-bottom: 0;
}

.row + .row {
    margin-top: 0;
}

.cart-action-dialog {
    background: #fff;
    padding: 32px;
}
</style>
