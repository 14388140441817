const routes = [
    {
        type: 'page',
        name: 'cart',
        slug: {
            en: 'cart',
            es: 'carrito-de-compras'
        }
    },
    {
        type: 'page',
        name: 'checkout',
        slug: {
            en: 'checkout',
            es: 'realizar-pedido'
        }
    },
    {
        type: 'page',
        name: 'customer-details',
        slug: {
            en: 'customer-details',
            es: 'detalles-del-cliente'
        }
    },
    {
        type: 'page',
        name: 'payment',
        slug: {
            en: 'payment',
            es: 'forma-de-pago'
        }
    },
    {
        type: 'page',
        name: 'login',
        slug: {
            en: 'login',
            es: 'iniciar-sesión'
        }
    },
    {
        type: 'page',
        name: 'register',
        slug: {
            en: 'register',
            es: 'regístrate'
        }
    },
    {
        type: 'page',
        name: 'verify-email',
        slug: {
            en: 'verify-email',
            es: 'verificar-correo-electrónico'
        }
    },
    {
        type: 'page',
        name: 'contact',
        slug: {
            en: 'contact',
            es: 'contáctenos'
        }
    },
    {
        type: 'page',
        name: 'terms',
        slug: {
            en: 'terms',
            es: 'condiciones'
        }
    },
    {
        type: 'page',
        name: 'order-details',
        slug: {
            en: 'order-details',
            es: 'detalles-de-pedido'
        }
    },
    {
        type: 'page',
        name: 'quick-order-pad',
        slug: {
            en: 'quick-order-pad',
            es: 'almohadilla-de-pedido-rápido'
        }
    },
    {
        type: 'page',
        name: 'shop',
        slug: {
            en: 'shop',
            es: 'tienda'
        }
    },
    {
        type: 'account',
        name: 'dashboard',
        slug: {
            en: 'dashboard',
            es: 'tablero'
        }
    },
    {
        type: 'account',
        name: 'addresses',
        slug: {
            en: 'addresses',
            es: 'direcciones'
        }
    },
    {
        type: 'account',
        name: 'favorites',
        slug: {
            en: 'favorites',
            es: 'favoritas'
        }
    },
    {
        type: 'account',
        name: 'support',
        slug: {
            en: 'support',
            es: 'apoyo'
        }
    }
]

export default routes
