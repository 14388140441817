export const state = () => ({
    inProgressOrders: [],
    completedOrders: [],
    recentOrders: [],
    completedOrderOptions: { page: 1, count: 0 },
    recentOrderOptions: { page: 1, count: 0 },
    itemsPerPage: 10,
})

export const actions = {
    async fetchOrdersRecent({ state, dispatch, commit, getters, rootGetters }, payload) {
        const res = await this.$axios.post('erp-order?method=readall', {
            CustomerId:
                rootGetters['auth/primaryCustomer']?.id || 0,
            status: 'Recent',
            page: payload.page,
            itemsPerPage: state.itemsPerPage
        })
        commit('storeRecentOrders', res.data?.orders)
        commit('updateRecentOrderOptions', { page: payload.page, count: res.data?.aggregateOrder?._count?._all })
    },

    async fetchOrdersCompleted({ state, dispatch, commit, getters, rootGetters }, payload) {
        const res = await this.$axios.post('erp-order?method=readall', {
            CustomerId:
                rootGetters['auth/primaryCustomer']?.id || 0,
            status: 'Completed',
            page: payload.page,
            itemsPerPage: state.itemsPerPage
        })
        commit('storeCompletedOrders', res.data?.orders)
        commit('updateCompletedOrderOptions', { page: payload.page, count: res.data?.aggregateOrder?._count?._all })
    },

    async fetchOrdersInProgress({ dispatch, commit, getters, rootGetters }, payload) {
        const res = await this.$axios.post('erp-order?method=readall', {
            CustomerId:
                rootGetters['auth/primaryCustomer']?.id || 0,
            status: 'In Progress'
        })
        commit('storeInProgressOrders', res.data?.orders)
    },

    emptyOrderList({ commit }, payload) {
        commit('removeOrders')
    }
}

export const mutations = {
    removeOrders(state, payload) {
        state.orders = []
    },
    storeInProgressOrders(state, payload) {
        state.inProgressOrders = payload
    },
    storeCompletedOrders(state, payload) {
        state.completedOrders = payload
    },
    storeRecentOrders(state, payload) {
        state.recentOrders = payload
    },
    updateRecentOrderOptions(state, payload) {
        state.recentOrderOptions = payload
    },
    updateCompletedOrderOptions(state, payload) {
        state.completedOrderOptions = payload
    },
}

export const getters = {
    getBillingDetails(state) {
        const billingDetails = state.billingFormArray.find(
            (item) =>
                parseInt(item.recordNo) ===
                parseInt(state.selectedBillingAddressID)
        )
        return billingDetails
    },
    getShippingDetails(state) {
        const shippingDetails = state.billingFormArray.find(
            (item) =>
                parseInt(item.recordNo) ===
                parseInt(state.selectedShippingAddressID)
        )
        return shippingDetails
    }
}
