<template>
    <v-app dark>
        <v-container>
            <v-card class="pa-2" outlined tile>
                <v-card-title>
                    <h1 v-if="error && error.statusCode === 404">
                        {{ pageNotFoundMessage }}
                    </h1>
                    <h1 v-else-if="error && error.statusCode === 403">
                        {{ loggedInOnlyMessage }}
                    </h1>
                    <h1 v-else>
                        {{ otherError }}
                    </h1>
                </v-card-title>
                <v-card-text>
                    <TranslatableBlockComponent :tstring="{string: 'errorPage'}" />
                </v-card-text>
                <v-card-actions>
                    <NuxtLink :to="localePath('index')">
                        <TranslatableStringComponent :tstring="{string: 'returnToHomePage'}" />
                    </NuxtLink>
                </v-card-actions>
            </v-card>
        </v-container>
    </v-app>
</template>

<script>
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
import TranslatableBlockComponent from '~/components/TranslatableBlockComponent.vue'
export default {
    layout: 'empty',
    components: {
        TranslatableStringComponent,
        TranslatableBlockComponent,
    },
    props: {
        error: {
            type: Object,
            default: null
        }
    },
    head() {
        const title =
            this.error.statusCode === 404
                ? this.pageNotFoundTitle
                : this.otherError
        return {
            title
        }
    },
    data() {
        return {
            loggedInOnlyMessage: this.localize('youAreNotLoggedInNoSoupForYou'),
            pageNotFoundTitle: this.localize('404PageNotFound'),
            pageNotFoundMessage: this.localize('pageNotFoundMessage'),
            otherError: this.localize('anErrorOccurred'),
        }
    }
}
</script>
