<template>
    <v-form
        id="nav-search"
        :class="{
            'nav-search--full-width': $vuetify.breakpoint.smAndDown,
            'nav-search--half-width': $vuetify.breakpoint.mdAndUp,
        }"
    >
         
        <v-autocomplete
            ref="autocomplete"
            v-model="model"
            :items="items"
            :search-input.sync="search"
            :loading="isLoading"
            item-text="label"
            flat
            outlined
            dense
            light
            hide-details
            :class="{
                'nav-input--md-and-down': $vuetify.breakpoint.mdAndDown,
                'nav-input--lg-and-up': $vuetify.breakpoint.lgAndUp,
            }"
            :label="localize('enterPnOrKw')"
            return-object
            no-filter
            @keydown.enter="handleSearchSubmission($event)"
        >
            <!-- <v-icon slot="append" dense @click="handleSearchSubmission($event)">
            fa-search
        </v-icon> -->
            <fa slot="append" :icon="['fal', 'search']" class="fa-lg" />
            <template v-slot:no-data>
                <v-list>
                    <v-list-item>
                        <v-list-item-avatar>
                            <fa :icon="['fal', 'search']" class="fa-lg" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                <TranslatableStringComponent
                                    :tstring="{ string: 'searchSuggestions' }"
                                />:
                            </v-list-item-title>
                            <ul class="mb-0">
                                <li>
                                    <TranslatableStringComponent
                                        :tstring="{
                                            string: 'checkYourSlashesAndDashes',
                                        }"
                                    />
                                </li>
                                <li>
                                    <TranslatableBlockComponent
                                        :tstring="{
                                            string: 'tryShopPageToSearch',
                                        }"
                                    />
                                </li>
                            </ul>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
            <template v-slot:item="data">
                <template>
                    <v-list-item-content
                        v-if="data.item.contentType === 'flag'"
                    >
                        <h4 class="mb-0">{{ data.item.text }}</h4>
                        <v-divider></v-divider>
                    </v-list-item-content>
                    <template v-else-if="data.item.contentType === 'contents'">
                        <v-list-item-content>
                            <v-list-item-title
                                v-html="data.item.title"
                            ></v-list-item-title>
                            <v-list-item-subtitle
                                v-html="
                                    `${
                                        data.item.subtitle
                                            ? data.item.subtitle
                                            : ''
                                    }`
                                "
                            ></v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <template v-else-if="data.item.contentType === 'products'">
                        <v-list-item-avatar>
                            <img
                                src="https://img.c3controls.com/c3controls-icon.jpg?h=80&amp;fit=fill"
                            />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title v-html="data.item.title" />
                            <v-list-item-subtitle>{{
                                data.item.subtitle
                            }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </template>
            </template>
        </v-autocomplete>
    </v-form>
</template>
<script>
import _ from 'lodash'

import { mapActions } from 'vuex'
import { localeRoutePrefix, simpleRoutes } from '~/util/sitemap'
import prependRoute from '~/util/prependRoute'
import skuprefixes from '~/util/skuprefixes'
import { interpolateDOLStartersSlug } from '~/util/dol-staters-helper'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
import TranslatableBlockComponent from '~/components/TranslatableBlockComponent.vue'

export default {
    name: 'Search',
    components: {
        TranslatableStringComponent,
        TranslatableBlockComponent,
    },
    data: () => ({
        isLoading: false,
        search: null,
        model: null,
        elasticResult: [],
    }),
    computed: {
        items() {
            if (this.elasticResult.length === 0 || this.search === null)
                return []

            const products = _.filter(this.elasticResult, {
                _index: 'products-sanity',
            }).map((p) => ({
                contentType: 'products',
                label: p._source.name_en,
                // eslint-disable-next-line camelcase
                title: Array.isArray(p.highlight?.name_en)
                    ? p.highlight.name_en[0]
                    : p._source.name_en,
                subtitle: p._source.pn,
                url: this.getURLFromProduct(p._source),
            }))

            const contents = _.filter(this.elasticResult, {
                _index: 'contents',
            }).map((c) => ({
                contentType: 'contents',
                label: c._source.title,
                title: Array.isArray(c.highlight?.title)
                    ? c.highlight.title[0]
                    : c._source.title,
                subtitle:
                    Array.isArray(c.highlight?.content) &&
                    c.highlight.content[0],
                url: c._source.url,
            }))

            return [
                ...(products.length > 0
                    ? [{ contentType: 'flag', text: 'Products' }, ...products]
                    : []),
                ...(contents.length > 0
                    ? [{ contentType: 'flag', text: 'Pages' }, ...contents]
                    : []),
            ]
        },
    },

    watch: {
        async search(val) {
            // Items have already been requested
            if (this.isLoading || val?.length < 3) return

            const valUp = val ? val.toUpperCase() : ''
            val = val || ''
            this.isLoading = true
            // Lazily load input items
            const elasticResult = await this.$axios.$post('elastic-search', {
                elasticQuery: {
                    query: {
                        bool: {
                            should: [
                                {
                                    prefix: {
                                        pn: {
                                            value: valUp,
                                            boost: 100,
                                        },
                                    },
                                },
                                {
                                    match: {
                                        name_en: {
                                            query: val,
                                            fuzziness: 'AUTO',
                                        },
                                    },
                                },
                                {
                                    multi_match: {
                                        query: val,
                                        fields: ['title^3', 'content'],
                                    },
                                },
                            ],
                        },
                    },
                    highlight: {
                        pre_tags: ['<b>'],
                        post_tags: ['</b>'],
                        fields: {
                            title: {},
                            content: {},
                            name_en: {},
                        },
                    },
                },
                elasticIndex: 'contents,products-sanity',
            })
            this.elasticResult = elasticResult.hits.hits
            this.isLoading = false
        },
        model(item) {
            if (!item) return
            const { contentType, url } = item
            if (contentType === 'contents') {
                const _url = new URL(url)
                this.$router.push({ path: _url.pathname })
            } else if (contentType === 'products') {
                this.$router.push({ path: url })
            }
        },
        $route() {
            this.reset()
        },
    },
    methods: {
        ...mapActions('configurations', ['fetchProductByPartNO']),
        async handleSearchSubmission(event) {
            event.preventDefault()
            // console.log(this.$refs.autocomplete)
            const { search, elasticResult } = this
            const productFoundInResults = elasticResult.find(
                (item) => search === item._source.pn
            )
            if (productFoundInResults) {
                this.$router.push({
                    path: this.getURLFromProduct(productFoundInResults._source),
                })
                this.reset()
                return // early return if PartNo found in Search Results
            }
            // sort skuprefix array by title.length DESC to ensure more specific
            // skuprefixes are are matched prior to more generic ones, example:
            // "300-S80" should be checked before "30" is checked, obviously.
            skuprefixes.sort((a, b) => {
                return a.title.length < b.title.length ? 1 : -1
            })
            const prefixFound = skuprefixes.find((item) => {
                // const startLength = event.target.value.length
                // for (let i = startLength; i > 0; i--) {
                //     const substring = event.target.value.substring(0, i)
                //     if (substring.toLowerCase() === item.title.toLowerCase()) {
                //         return true
                //     }
                // }
                // return false
                return event.target.value.startsWith(item.title.toLowerCase())
            })
            const productInElastic = await this.fetchProductByPartNO({
                pn: search,
            })
            if (productInElastic) {
                this.$router.push({
                    path: this.getURLFromProduct(productInElastic._source),
                })
            } else if (prefixFound) {
                const type = prefixFound.url.linkTo._type
                const slug = prefixFound.url.linkTo.slug
                const route = prependRoute({ type, slug })
                const path = `${route[this.$i18n.locale]}`
                this.$router.push({ path })
            } else {
                this.$router.push({ path: `/search?q=${search}` })
                this.$refs.autocomplete.blur()
            }
            this.reset()
        },
        getURLFromProduct(product) {
            const locale = this.$i18n.locale
            const productRoute = simpleRoutes.product.slugPrefix[locale]
            const localePrefix = localeRoutePrefix[locale]
            const productSlug = interpolateDOLStartersSlug(product.parent[locale].current)
            const path =
                locale === 'en'
                    ? `/${productRoute}/${productSlug}/${product.pn}`
                    : `/${localePrefix}/${productRoute}/${productSlug}/${product.pn}`
            return path
        },
        reset() {
            this.$refs.autocomplete.blur()
            this.$refs.autocomplete.reset()
        },
    },
}
</script>
<style lang="scss">
.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: none;
}
</style>
