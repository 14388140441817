<template>
    <v-snackbar
        :value="isActive"
        :timeout="currentTimeout"
        :top="top"
        :center="center"
        :right="!center"
        :bottom="!top"
        :color="currentColor"
        :class="{
            'snackbar-container--dynamic': true,
            'snackbar-container--dynamic-right': !center,
            'snackbar-container--dynamic-center': center
        }"
    >
        <div v-if="isCurrentMessageArray">
            <span v-for="(i,index) in currentMessage.string" :key="i" class="mx-1">
                <TranslatableStringComponent :tstring="{string: i, variables:currentMessage.variables[index]}" /> 
            </span>
        </div>
        <TranslatableStringComponent v-else :tstring="{string: currentMessage.string, variables: currentMessage.variables}" /> 
        <template v-slot:action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="closeSnackbar()">
                <TranslatableStringComponent :tstring="{string: 'close'}" />
            </v-btn>
        </template>
    </v-snackbar>
</template>
<script>
import { mapGetters } from 'vuex'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
import handleSnackbar from '~/mixins/handleSnackbar'
import handleAuth from '~/mixins/handleAuth'
export default {
    name: 'DynamicSnackbar',
    components: {
        TranslatableStringComponent,
    },
    mixins: [handleAuth, handleSnackbar],
    props: {
        msg: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: 'primary'
        },
        show: {
            type: Boolean,
            default: false
        },
        timeout: {
            type: Number,
            default: 0
        },
        closable: {
            type: Boolean,
            default: true
        },
        center: {
            type: Boolean,
            default: true
        },
        top: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters({
            currentMessage: 'snackbar/fetchCurrentMessage',
            currentTimeout: 'snackbar/fetchCurrentTimeout',
            isActive: 'snackbar/snackbarIsActive',
            currentColor: 'snackbar/fetchCurrentColor'
        }),
        isCurrentMessageArray(){
            return Array.isArray(this.currentMessage.string)
        }

    }
}
</script>
