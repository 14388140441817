import _ from 'lodash'
export const state = () => ({
    currentUserAbandonedCart: [],
    currentUserCartId: null,
    availableAbandonedCartItems: [],
    isAbandonedCartPresent: null,
})

export const actions = {
    async getErpCart({ commit, dispatch, rootGetters, state }) {
        if (rootGetters['auth/isLoggedIn']) {
            try {
                const resp = await this.$axios.post('erp-cart?method=read', {
                    email: rootGetters['auth/webUser']?.email,
                    isLive: true,
                })
                // console.log(resp)
                if (resp.data.carts.length > 0) {
                    commit('setIsAbandonedCartPresent', true)
                    // Locally store cart id and items
                    commit('updateCurrentUserCartId', resp.data.carts[0].id)
                    commit(
                        'updateCurrentUserAbandonedCart',
                        resp.data.carts[0].itemData
                    )
                    // Check for intersection
                    await dispatch('checkForExclusiveAbandandonedCartItems')
                    dispatch('checkForExclusiveLocalCartItems')
                } else {
                    commit('setIsAbandonedCartPresent', false)
                    // If local storage has products before login and there is no cart in erp
                    if (rootGetters['cart/fetchCartProducts'].length > 0)
                        dispatch('createErpCart')
                }
            } catch (err) {
                // console.log(err)
            }
        }
    },

    async createErpCart({ commit, getters, rootGetters, dispatch }) {
        try {
            const resp = await this.$axios.post('erp-cart?method=create', {
                ...getters.getProperties,
            })
            // console.log(resp)
            commit('updateCurrentUserCartId', resp.data.createCart.id)
            commit('setIsAbandonedCartPresent', true)
            commit(
                'updateCurrentUserAbandonedCart',
                resp.data.createCart.itemData
            )
        } catch (err) {
            // console.log(err)
        }
    },

    checkForExclusiveLocalCartItems({
        state,
        rootState,
        commit,
        rootGetters,
        dispatch,
    }) {
        const exclusiveLocalCartItems = rootGetters[
            'cart/fetchCartProducts'
        ].filter((item) => {
            return !state.currentUserAbandonedCart.find((i) => i.pn === item.pn)
        })
        if (exclusiveLocalCartItems && exclusiveLocalCartItems.length > 0) {
            dispatch('updateErpCart')
        }
    },

    checkForExclusiveAbandandonedCartItems({
        state,
        rootState,
        commit,
        rootGetters,
        dispatch,
    }) {
        const exclusiveAbandandonedCartItems = state.currentUserAbandonedCart.filter(
            (item) => {
                return !rootGetters['cart/fetchCartProducts'].find(
                    (i) => i.pn === item.pn
                )
            }
        )
        if (
            exclusiveAbandandonedCartItems &&
            exclusiveAbandandonedCartItems.length > 0
        ) {
            let exclusiveItemsPn = exclusiveAbandandonedCartItems.map(
                (item) => item.pn
            )
            dispatch('fetchAbandonedCartItemsFromElastic', exclusiveItemsPn)
        }
    },

    async fetchAbandonedCartItemsFromElastic(
        { dispatch, commit, getters, rootGetters, state },
        payload
    ) {
        const res = await this.$axios.post('elastic-search', {
            elasticQuery: {
                size: 999,
                query: {
                    terms: {
                        pn: payload,
                    },
                },
            },
            elasticIndex: 'products-sanity',
        })
        // console.log(res)
        const items = res.data.hits.hits
            .map((item) => item._source)
            .filter((obj, pos, arr) => {
                // removing duplicate products with same 'pn' number
                return (
                    arr.map((mapObj) => obj.pn && mapObj.pn).indexOf(obj.pn) ===
                    pos
                )
            })

        // Update available items quantity with dynamo cart quantities
        const availableItems = items.map((item) => {
            item.prod_quantity = state.currentUserAbandonedCart.find(
                (i) => i.pn === item.pn
            ).qty
            return item
        })

        // Add isAvailable flag and quantity in cart to display in cart
        const filteredItemsWithAvailableFlag = availableItems.map((item) => {
            const locale = this.$i18n.locale
            return {
                ...item,
                isAvailable: true,
                name: item[`name_${locale}`],
                quantityInCart: item.prod_quantity,
            }
        })
        commit('setAvailableAbandonedCartItems', filteredItemsWithAvailableFlag)
        if (rootGetters['cart/fetchCartProducts'].length > 0) {
            dispatch('cart/appendCart', state.availableAbandonedCartItems, {
                root: true,
            })
            dispatch('updateErpCart')
        } else
            dispatch('cart/appendCart', state.availableAbandonedCartItems, {
                root: true,
            })
    },

    debounceQtyUpdate: _.debounce(function ({ dispatch }) {
        dispatch('updateErpCart')
    }, 1000),

    async updateErpCart(
        { dispatch, commit, state, rootState, getters, rootGetters },
        payload
    ) {
        if (rootGetters['auth/isLoggedIn']) {
            const id = state.currentUserCartId
            try {
                const resp = await this.$axios.post('erp-cart?method=update', {
                    ...getters.getProperties,
                    id,
                })
                if (resp) {
                    // console.log(resp)
                }
            } catch (err) {
                // console.log(err)
            }
        }
    },
    async deleteErpCart(
        { dispatch, commit, state, rootState, getters, rootGetters },
        payload
    ) {
        if (rootGetters['auth/isLoggedIn']) {
            try {
                const resp = await this.$axios.post('erp-cart?method=delete', {
                    id: state.currentUserCartId,
                })
                // console.log(resp)
                dispatch('clearAbandonedCart')
            } catch (err) {
                // console.log(err)
            }
        }
    },
    clearAbandonedCart({ commit }) {
        commit('updateCurrentUserCartId', null)
        commit('updateCurrentUserAbandonedCart', [])
        commit('setAvailableAbandonedCartItems', [])
        commit('setIsAbandonedCartPresent', false)
    },
}

export const mutations = {
    setIsAbandonedCartPresent(state, payload) {
        state.isAbandonedCartPresent = payload
    },
    updateCurrentUserCartId(state, payload) {
        state.currentUserCartId = payload
    },
    updateCurrentUserAbandonedCart(state, payload) {
        state.currentUserAbandonedCart = payload
    },
    setAvailableAbandonedCartItems(state, payload) {
        state.availableAbandonedCartItems = payload
    },
}

export const getters = {
    isAbandonedCartPresent(state) {
        return state.isAbandonedCartPresent
    },
    currentUserCartId(state) {
        return state.currentUserCartId
    },
    abandonedCartItemsPn(state, getters, rootState, rootGetters) {
        return state.currentUserAbandonedCart.map((item) => {
            return item.pn
        })
    },
    getProperties(state, getters, rootState, rootGetters) {
        let payloadItem = {}
        payloadItem.cartItems = rootGetters['cart/fetchCartProducts'].map(
            (item) => {
                return {
                    pn: item.pn,
                    qty: item.prod_quantity,
                    listPrice: item.list_price,
                    yourPrice: item.salePrice,
                    img: item.img,
                }
            }
        )
        payloadItem.priceList = rootGetters['priceList/cartPriceListId']
        // Initially it was from cart but it updates only when on cart page, so calculating here
        payloadItem.subTotal = rootGetters['cart/fetchCartProducts'].reduce(
            (acc, item) => {
                return acc + item.subTotalSalePrice
            },
            0
        )
        ;(payloadItem.isLive = true),
            (payloadItem.email = rootGetters['auth/webUser']?.email)

        return payloadItem
    },
}
