<template>
    <div v-if="debug">
        <div @click="show = !show"><a @click.prevent="">Dev Output</a></div>
        <div v-if="show">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'DevOutput',
    props: {
        display: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            show: false,
            debug: process.env.DEBUG_ON === 'true' || false
        }
    }
}
</script>
