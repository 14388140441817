function prependRoute({ type, slug }) {
    // type = string, slug = slug object
    if (!slug || !slug.en || !slug.es) {
        return slug
    }
    if (type === 'page' && slug.en && slug.en.current === 'home') {
        // localize homepage
        return {
            en: '/',
            es: '/es/'
        }
    }
    // determine prepend
    const prepend = { en: '/', es: '/es/' }
    if (type === 'article') {
        prepend.en = '/article/'
        prepend.es = '/es/articulo/'
    } else if (type === 'topic') {
        prepend.en = '/blog/'
        prepend.es = '/es/blog/'
    } else if (type === 'industry') {
        prepend.en = '/industry/'
        prepend.es = '/es/industria/'
    } else if (type === 'category') {
        prepend.en = '/category/'
        prepend.es = '/es/categoria/'
    } else if (type === 'account') {
        prepend.en = '/my-account/'
        prepend.es = '/es/mi-cuenta/'
    } else if (type === 'whitepaper') {
        prepend.en = '/white-paper/'
        prepend.es = '/es/documento-tecnico/'
    } else if (type === 'product' || type === 'productSingle') {
        prepend.en = '/products/'
        prepend.es = '/es/productos/'
    } else if (type === 'resource') {
        prepend.en = '/resource/'
        prepend.es = '/es/documento/'
    } else if (type === 'testimonial') {
        prepend.en = '/testimonial/'
        prepend.es = '/es/testimonial/'
    } else if (type === 'profile') {
        prepend.en = '/team/'
        prepend.es = '/es/personal/'
        // } else if (type !== 'page') {
        //     prepend.en = '/oops/'
        //     prepend.es = '/es/no-me-gusta/'
        // }
    } else {
        prepend.en = '/'
        prepend.es = '/es/'
    }

    // return concatenated route
    return {
        en: `${prepend.en}${slug.en.current}/`,
        es: `${prepend.es}${slug.es.current}/`
    }
}

export function extractTypeFromPrepend({ route }) {
    const parts = route.path.split('/')
    // if the route begins with es, grab 3rd part, else grab 2nd part
    const prepend = parts[1] === 'es' ? parts[2] : parts[1]
    const slug = parts[1] === 'es' ? parts[3] : parts[2]
    if (!slug) {
        return 'page'
    }
    // console.log('prepend is', prepend)
    // console.log('slug is', slug)
    if (['article', 'articulo'].includes(prepend)) {
        return 'article'
    } else if (['blog'].includes(prepend)) {
        return 'topic'
    } else if (['industry', 'industria'].includes(prepend)) {
        return 'industry'
    } else if (['category', 'categoria'].includes(prepend)) {
        return 'category'
    } else if (['my-account', 'mi-cuenta'].includes(prepend)) {
        return 'account'
    } else if (['white-paper', 'documento-tecnico'].includes(prepend)) {
        return 'whitepaper'
    } else if (['resource', 'documento'].includes(prepend)) {
        return 'resource'
    } else if (['testimonial'].includes(prepend)) {
        return 'testimonial'
    } else if (['products', 'productos'].includes(prepend)) {
        return 'product'
    } else if (['team', 'personal'].includes(prepend)) {
        return 'profile'
    }
    return 'page'
}

export { prependRoute as default }
