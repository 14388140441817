// import { client } from '~/plugins/global.js'
import { groqDocsTranslatable } from '~/util/sanity/groqStrings'
const stateObject = { test: 0, results: [] }
const contentTypes = JSON.parse(groqDocsTranslatable)
contentTypes.forEach((type) => {
    stateObject[type] = []
})
export const state = () => stateObject
export const actions = {
    populateContent({ dispatch, commit, getters, rootGetters }, payload) {
        const groqDocsTranslatableArray = JSON.parse(groqDocsTranslatable)
        const docsWithWordCount = payload.map((doc) => {
            return countAllTheWords(doc)
        })
        groqDocsTranslatableArray.forEach((type) => {
            const docs = docsWithWordCount.filter((document) => {
                return document._type === type
            })
            commit('setContents', { type, docs })
        })
        commit('setContent', docsWithWordCount)
        // commit('testContent', 9)
        function countAllTheWords(doc) {
            if (doc._type !== undefined) {
                doc.totalTranslatableES = 0
                doc.totalTranslatable = 0
                doc.totalTranslated = 0
                doc.totalUntranslated = 0
                const capitalize = (s) => {
                    if (typeof s !== 'string') return ''
                    return s.charAt(0).toUpperCase() + s.slice(1)
                }
                const fields = [
                    'title',
                    'subtitle',
                    'slug',
                    'seometa',
                    'tstring',
                    'contentSections'
                ]
                fields.forEach((fieldName) => {
                    const translatablePropES = `xE${capitalize(fieldName)}`
                    const translatableProp = `xT${capitalize(fieldName)}`
                    const translatedProp = `xD${capitalize(fieldName)}`
                    const untranslatedProp = `xU${capitalize(fieldName)}`
                    const strings = isTranslatable(doc[fieldName])
                    if (strings) {
                        const translatableWordCount =
                            countWordsInString(strings.stringEN) || 0
                        const translatableWordCountES =
                            countWordsInString(strings.stringES) || 0
                        doc[translatablePropES] = translatableWordCountES
                        doc[translatableProp] = translatableWordCount
                        doc[translatedProp] = countTranslatedWords(strings) || 0
                        doc[untranslatedProp] =
                            countUntranslatedWords(strings) || 0
                        doc.totalTranslatableES += translatableWordCountES
                        doc.totalTranslatable += translatableWordCount
                        doc.totalTranslated += doc[translatedProp]
                        doc.totalUntranslated += doc[untranslatedProp]
                    } else {
                        doc[translatablePropES] = 0
                        doc[translatableProp] = 0
                        doc[translatedProp] = 0
                        doc[untranslatedProp] = 0
                    }
                })
                return doc
            }
        }
        function isTranslatable(field) {
            let stringEN = ''
            let stringES = ''
            if (field?._type === 'localeSlug') {
                stringEN = field?.en?.current
                stringES = field?.es?.current
            } else if (
                field?._type === 'localeString' ||
                field?._type === 'localeText'
            ) {
                stringEN = field?.en
                stringES = field?.es
            } else if (field?._type === 'seometa') {
                stringEN = `${field?.metaTitle?.en} ${field?.metaDescription?.en}`
                stringES =
                    field?.metaTitle?.es && field?.metaDescription?.es
                        ? `${field?.metaTitle?.es} ${field?.metaDescription?.es}`
                        : ''
                // debug
                if (stringEN.includes('Favorite Products')) {
                    console.log(stringEN)
                    console.log(stringES)
                }
            } else if (field?._type === 'contentSections') {
                const contentSectionList = field.content
                contentSectionList.forEach((cs) => {
                    if (cs.hasOwnProperty('blurbage')) {
                        stringEN = `${stringEN} ${convertToPlainText(
                            cs.blurbage.en
                        )}`
                        stringES = `${stringES} ${convertToPlainText(
                            cs.blurbage.es
                        )}`
                    }
                    // if (cs.hasOwnProperty('blurbage')) {
                    //     stringEN += `${stringEN} ${convertToPlainText(
                    //         cs.blurbage.en
                    //     )}`
                    //     stringES += `${stringES} ${convertToPlainText(
                    //         cs.blurbage.es
                    //     )}`
                    // }
                })
            }
            if (
                stringEN &&
                stringEN.length > 0
                // &&
                // (stringEN === stringES || !stringES || stringES === '')
            ) {
                return { stringEN, stringES }
            }
            return null
        }
        function countTranslatedWords(strings = null) {
            if (!strings) return 0
            return strings.stringEN &&
                strings.stringES &&
                strings.stringEN !== strings.stringES &&
                strings.stringES !== '' // this is redundant check, right?
                ? countWordsInString(strings.stringEN)
                : 0
        }
        function countUntranslatedWords(strings = null) {
            if (!strings) return 0
            return strings.stringEN === strings.stringES || !strings.stringES
                ? countWordsInString(strings.stringEN)
                : 0
        }
        function countWordsInString(string) {
            const separators = [' ', '-']
            return typeof string === 'string'
                ? string.split(new RegExp(separators.join('|'), 'g')).length
                : 0
        }
        function convertToPlainText(blocks = []) {
            if (!blocks) return
            return (
                blocks
                    // loop through each block
                    .map((block) => {
                        // if it's not a text block with children,
                        // return nothing
                        if (block._type !== 'block' || !block.children) {
                            return ''
                        }
                        // loop through the children spans, and join the
                        // text strings
                        return block.children
                            .map((child) => child.text)
                            .join('')
                    })
                    // join the paragraphs leaving split by two linebreaks
                    .join('\n\n')
            )
        }
    }
}
export const mutations = {
    setContentTypes(state, payload) {
        state.results = payload
    },
    setContent(state, payload) {
        state.results = payload
    },
    testContent(state, payload) {
        state.test = payload
    },
    setContents(state, payload) {
        // console.log(payload)
        const type = payload.type || 'none'
        if (state[type] !== undefined) {
            state[type] = payload.docs
        }
    }
}
export const getters = {
    globalWordCount(state, getters, rootState, rootGetters) {
        return state.results.length > 0
            ? state.results.reduce(function(acc, curr) {
                  if (curr.hasOwnProperty('totalTranslatable')) {
                      return acc + curr.totalTranslatable
                  } else {
                      return acc + 0
                  }
              }, 0)
            : 0
    },
    globalWordCountToBeTranslated(state, getters, rootState, rootGetters) {
        return state.results.length > 0
            ? state.results.reduce(function(acc, curr) {
                  if (curr.hasOwnProperty('docWordCountToBeTranslated')) {
                      return acc + curr.docWordCountToBeTranslated
                  } else {
                      return acc + 0
                  }
              }, 0)
            : 0
    },
    globalWordCountTranslated(state, getters, rootState, rootGetters) {
        return getters.globalWordCount - getters.globalWordCountToBeTranslated
    }
}
