import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=3c8d54d2"
import script from "./Search.vue?vue&type=script&lang=js"
export * from "./Search.vue?vue&type=script&lang=js"
import style0 from "./Search.vue?vue&type=style&index=0&id=3c8d54d2&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VDivider,VForm,VList,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle})
