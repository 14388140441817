<template>
    <v-container class="pt-0" fluid>
        <v-row column justify-center align-center>
            <v-col cols="12" md="12">
                <TranslatableBlockComponent v-if="isAccountPage" :tstring="{string: 'formCustomerConnectDialogAccountPage'}" />
                <TranslatableBlockComponent v-else :tstring="{string: 'formCustomerConnectDialog'}" />
            </v-col>
            <v-col cols="12" class="pt-0">
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                    <v-form
                        id="customerConnectForm"
                        ref="customerConnectForm"
                        data-netlify="false"
                        name="customerConnectForm"
                        @submit.prevent="handleSubmit(handleSubmission)"
                    >
                        <input
                            id="form-name"
                            type="hidden"
                            name="form-name"
                            value="customerConnectForm"
                        />
                        <v-container fluid class="contact-form--wrapper pa-0">
                            <v-row column justify-center align-center>
                                <v-col
                                    cols="12"
                                    md="9"
                                    :class="{
                                        'pr-0 pt-0':
                                            $vuetify.breakpoint.mdAndUp,
                                        'pb-0': true,
                                    }"
                                >
                                    <ValidationProvider
                                        name="customerId"
                                        rules="required|number"
                                        class="validationWrapper"
                                    >
                                        <div slot-scope="{ errors }">
                                            <span class="validationMessage">
                                                {{ errors[0] }}
                                            </span>
                                            <v-text-field
                                                id="customerId"
                                                v-model.number="customerId"
                                                name="customerId"
                                                label="Customer ID"
                                                outlined
                                                dense
                                                :hide-details="true"
                                            ></v-text-field>
                                        </div>
                                    </ValidationProvider>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="3"
                                    :class="{
                                        'pr-0 pt-0':
                                            $vuetify.breakpoint.mdAndUp,
                                        'pb-0': true,
                                        'pl-2': true,
                                    }"
                                >
                                    <v-btn
                                        type="submit"
                                        color="primary"
                                        right
                                        :loading="loading"
                                        ><TranslatableStringComponent :tstring="{string: 'connect'}" /></v-btn
                                    >
                                </v-col>
                                <v-row v-if="loading" class="ml-4"
                                    >
                                    <TranslatableStringComponent :tstring="{string: 'pleaseWaitThisMayTakeSomeTime'}" />...</v-row
                                >
                                <span v-if="error && !loading" class="ml-4">
                                    <TranslatableStringComponent :tstring="{string: 'formCustomerConnectPart4'}" />
                                </span>
                            </v-row>
                        </v-container>
                    </v-form>
                </ValidationObserver>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapState } from 'vuex'
import handleSnackbar from '~/mixins/handleSnackbar'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
import TranslatableBlockComponent from '~/components/TranslatableBlockComponent.vue'

export default {
    name: 'FormConnectCustomer',
    components: {
        ValidationProvider,
        ValidationObserver,
        TranslatableStringComponent,
        TranslatableBlockComponent,
    },
    props: {
        parent: {
            type: String,
            default: '',
        },
        isAccountPage: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [handleSnackbar],
    computed: {
        ...mapState('auth', ['webUser']),
    },
    data() {
        return {
            customerId: null,
            check: null,
            loading: false,
            error: false,
        }
    },
    methods: {
        async handleSubmission() {
            this.loading = true
            this.error = false
            const payload = {
                email: this.webUser.email,
                WebUserPrimaryCustomerId: this.customerId,
                isHighRisk: true,
            }
            try {
                const res = await this.$axios.post(
                    'erp-webuser?method=update',
                    payload
                )
                if (res) {
                    if (res?.data?.updateWebUser) {
                        await this.$store.commit(
                            'auth/SET_WEBUSER',
                            res.data.updateWebUser
                        )
                        if (this.webUser.WebUserPrimaryCustomerId)
                            await this.$store.dispatch(
                                'priceList/fetchPriceListEntries'
                            )
                        this.loading = false
                        if (this.parent === 'FormCustomer') {
                            this.routerPush({
                                type: 'page',
                                slugName: 'checkout',
                            })
                            this.hitSnackbar(
                                {string: 'youAreNowConnectedToYourCustomerAccount'},
                                null
                            )
                        } else this.$emit('closeModal')
                    } else {
                        this.error = true
                        this.loading = false
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
    },
}
</script>
