var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"subscriptionFormA",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleSubmission)}}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{staticClass:"validationWrapper",attrs:{"name":"email","rules":"required|email|emoji"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('span',{staticClass:"validationMessage"},[_vm._v("\n                                    "+_vm._s(errors[0])+"\n                                ")]),_vm._v(" "),_c('v-text-field',{attrs:{"id":"email","name":"email","placeholder":_vm.localize('email'),"outlined":"","flat":"","dense":"","hide-details":true},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)})],1),_vm._v(" "),_c('v-col',{class:{
                            'pr-0': _vm.$vuetify.breakpoint.mdAndUp,
                            'pb-0': true, // $vuetify.breakpoint.smAndDown
                            'pl-0': _vm.$vuetify.breakpoint.mdAndUp,
                            'd-flex': _vm.$vuetify.breakpoint.mdAndUp,
                            'justify-center': _vm.$vuetify.breakpoint.mdAndUp,
                        },attrs:{"cols":"12"}},[_c('recaptcha',{staticStyle:{"transform":"scale(0.8)"},on:{"success":_vm.onSuccess,"error":_vm.onError,"expired":_vm.onExpired}}),_vm._v(" "),(_vm.captchaError)?_c('div',{staticClass:"captcha-error"},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'captchaRequired'}}})],1):_vm._e()],1),_vm._v(" "),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"type":"submit","color":"primary","loading":_vm.loader}},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'subscribe'}}})],1),_vm._v(" "),(_vm.isError)?_c('p',{staticClass:"red--text"},[_vm._v("\n                            * "),_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'somethingWentWrongPleaseTryAgainLater'}}}),_vm._v(".\n                        ")],1):_vm._e()],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }