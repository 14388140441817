var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('SubscriptionTag'),_vm._v(" "),_c('v-badge',{staticClass:"align-self-center mr-0 mr-md-2 d-lg-flex cart-badge--default",attrs:{"top":true,"color":_vm.fetchCartProducts.length > 0 ? 'primary' : 'transparent',"right":true,"overlap":false,"offset-x":"18px","offset-y":"18px"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',{attrs:{"title":_vm.localize('1DifferentProductsForATotalQuantityOf2', [
                                                {
                                                    var: ("" + (_vm.fetchCartProducts.length)),
                                                },
                                                {
                                                    var: ("" + _vm.fetchCartQty),
                                                } ])}},[_vm._v(_vm._s(_vm.fetchCartQty && _vm.fetchCartProducts.length > 0
                        ? ("" + (_vm.fetchCartProducts.length))
                        : ''))])]},proxy:true}])},[_vm._v(" "),_c('v-btn',{staticClass:"btn--light",attrs:{"icon":""}},[_c('nuxt-link',{attrs:{"to":_vm.getStaticLocalePath({
                        type: 'page',
                        slugName: 'cart'
                    })}},[_c('div',{staticClass:"menu-icon-wrapper "},[_c('fa',{staticClass:"fa-lg",attrs:{"icon":['fal', 'shopping-cart']}})],1),_vm._v(" "),_c('span',{staticClass:"menu-icon-subtext"},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'cart'}}})],1)])],1)],1),_vm._v(" "),_c('v-badge',{staticClass:"align-self-center mr-0 mr-md-2 d-lg-flex cart-badge--default",attrs:{"top":true,"color":false && _vm.fetchCartProducts.length === 5
                ? 'primary'
                : 'transparent',"right":true,"overlap":false,"offset-x":"18px","offset-y":"18px"},scopedSlots:_vm._u([{key:"badge",fn:function(){return undefined},proxy:true}])},[_vm._v(" "),_c('v-btn',{staticClass:"btn--light",attrs:{"icon":""}},[_c('nuxt-link',{attrs:{"to":_vm.getStaticLocalePath({
                        type: 'account',
                        slugName: 'dashboard'
                    })}},[_c('div',{staticClass:"menu-icon-wrapper",attrs:{"router":"","exact":""}},[_c('fa',{staticClass:"fa-lg",attrs:{"icon":['fal', 'user']}})],1),_vm._v(" "),_c('span',{staticClass:"menu-icon-subtext"},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'account'}}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }