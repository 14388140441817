import GoTrue from 'gotrue-js'
export default function ({ $axios }) {
    const auth = new GoTrue({
        APIUrl: process.env.NETLIFY_IDENTITY_URL,
        audience: '',
        setCookie: true,
    })
    $axios.onRequest(async (config) => {
        // console.log('CURRENT AXIOS CONFIG', config)
        const decoded = decodeURIComponent(config.data)
        const xssRegex = /(\b)(on\w+)=|javascript|(<\s*)(\/*)script/gi
        const xssAttack = xssRegex.test(decoded)
        // console.log('xss attack :', xssAttack)
        if (xssAttack) {
            return Promise.reject(new Error('xss attack identified'))
        } else if (config.url.startsWith('http')) {
            return config
        } else {
            const user = auth.currentUser()
            if (user) {
                const accessToken = await user.jwt()
                config.headers.Authorization = `Bearer ${accessToken}`
            }
            return config
        }
    })
}
