import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
    ebookForm: {
        firstname: '',
        lastname: '',
        company: '',
        email: '',
        phone: '',
        jobtitle: '',
        address: '',
        street_address_2: '',
        city: '',
        state: '',
        zip: '',
        company_zip: '',
        samples_requested: '',
        do_you_build_your_own_control_panels_: '',
        subscribe_to_our_enewsletter: ''
    }
})

export const actions = {}

export const mutations = {
    updateEbookField(state, field) {
        updateField(state.ebookForm, field)
    }
}

export const getters = {
    getEbookField(state) {
        return getField(state.ebookForm)
    }
}
