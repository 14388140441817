<template>
    <div style="display:inline">
        <v-tooltip
            color="#cccccc"
            bottom
            close-delay="2000"
            v-if="webUser && translators.includes(webUser.email)"
        >
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ localize(tstring.string, tstring.variables) }}</span>
            </template>
            <a
                v-if="$store.state.common.strings[tstring.string] && $store.state.common.strings[tstring.string].id"
                :href="`https://sanity.c3controls.tech/desk/translatableStrings;${$store.state.common.strings[tstring.string].id}`"
                target="blank"
                >Go to sanity tstring</a
            >
        </v-tooltip>
        <span v-else>{{ localize(tstring.string, tstring.variables) }}</span>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'TranslatableStringComponent',
    props: {
        tstring: {
            type: Object,
            default: null,
        },
    },
    computed: {
        ...mapGetters({
            webUser: ['auth/webUser'],
        }),
        translators() {
            return this.$store.state.common.siteSettings.emailTranslators.split(',')
        }
    },
}
</script>
