const shippingZones = [
    {
        zip: '004',
        zone: 4,
    },
    {
        zip: '005',
        zone: 4,
    },
    {
        zip: '010',
        zone: 4,
    },
    {
        zip: '011',
        zone: 4,
    },
    {
        zip: '012',
        zone: 4,
    },
    {
        zip: '013',
        zone: 4,
    },
    {
        zip: '014',
        zone: 4,
    },
    {
        zip: '015',
        zone: 4,
    },
    {
        zip: '016',
        zone: 4,
    },
    {
        zip: '017',
        zone: 4,
    },
    {
        zip: '018',
        zone: 4,
    },
    {
        zip: '019',
        zone: 4,
    },
    {
        zip: '020',
        zone: 4,
    },
    {
        zip: '021',
        zone: 4,
    },
    {
        zip: '022',
        zone: 4,
    },
    {
        zip: '023',
        zone: 4,
    },
    {
        zip: '024',
        zone: 4,
    },
    {
        zip: '025',
        zone: 4,
    },
    {
        zip: '026',
        zone: 4,
    },
    {
        zip: '027',
        zone: 4,
    },
    {
        zip: '028',
        zone: 4,
    },
    {
        zip: '029',
        zone: 4,
    },
    {
        zip: '030',
        zone: 4,
    },
    {
        zip: '031',
        zone: 4,
    },
    {
        zip: '032',
        zone: 4,
    },
    {
        zip: '033',
        zone: 4,
    },
    {
        zip: '034',
        zone: 4,
    },
    {
        zip: '035',
        zone: 4,
    },
    {
        zip: '036',
        zone: 4,
    },
    {
        zip: '037',
        zone: 4,
    },
    {
        zip: '038',
        zone: 4,
    },
    {
        zip: '039',
        zone: 4,
    },
    {
        zip: '040',
        zone: 4,
    },
    {
        zip: '041',
        zone: 4,
    },
    {
        zip: '042',
        zone: 4,
    },
    {
        zip: '043',
        zone: 4,
    },
    {
        zip: '044',
        zone: 5,
    },
    {
        zip: '045',
        zone: 4,
    },
    {
        zip: '046',
        zone: 5,
    },
    {
        zip: '047',
        zone: 5,
    },
    {
        zip: '048',
        zone: 5,
    },
    {
        zip: '049',
        zone: 4,
    },
    {
        zip: '050',
        zone: 4,
    },
    {
        zip: '051',
        zone: 4,
    },
    {
        zip: '052',
        zone: 4,
    },
    {
        zip: '053',
        zone: 4,
    },
    {
        zip: '054',
        zone: 4,
    },
    {
        zip: '055',
        zone: 4,
    },
    {
        zip: '056',
        zone: 4,
    },
    {
        zip: '057',
        zone: 4,
    },
    {
        zip: '058',
        zone: 4,
    },
    {
        zip: '059',
        zone: 4,
    },
    {
        zip: '060',
        zone: 4,
    },
    {
        zip: '061',
        zone: 4,
    },
    {
        zip: '062',
        zone: 4,
    },
    {
        zip: '063',
        zone: 4,
    },
    {
        zip: '064',
        zone: 4,
    },
    {
        zip: '065',
        zone: 4,
    },
    {
        zip: '066',
        zone: 4,
    },
    {
        zip: '067',
        zone: 4,
    },
    {
        zip: '068',
        zone: 4,
    },
    {
        zip: '069',
        zone: 4,
    },
    {
        zip: '070',
        zone: 4,
    },
    {
        zip: '071',
        zone: 4,
    },
    {
        zip: '072',
        zone: 4,
    },
    {
        zip: '073',
        zone: 4,
    },
    {
        zip: '074',
        zone: 4,
    },
    {
        zip: '075',
        zone: 4,
    },
    {
        zip: '076',
        zone: 4,
    },
    {
        zip: '077',
        zone: 4,
    },
    {
        zip: '078',
        zone: 3,
    },
    {
        zip: '079',
        zone: 4,
    },
    {
        zip: '080',
        zone: 3,
    },
    {
        zip: '081',
        zone: 3,
    },
    {
        zip: '082',
        zone: 3,
    },
    {
        zip: '083',
        zone: 3,
    },
    {
        zip: '084',
        zone: 4,
    },
    {
        zip: '085',
        zone: 3,
    },
    {
        zip: '086',
        zone: 3,
    },
    {
        zip: '087',
        zone: 4,
    },
    {
        zip: '088',
        zone: 4,
    },
    {
        zip: '089',
        zone: 4,
    },
    {
        zip: 100,
        zone: 4,
    },
    {
        zip: 101,
        zone: 4,
    },
    {
        zip: 102,
        zone: 4,
    },
    {
        zip: 103,
        zone: 4,
    },
    {
        zip: 104,
        zone: 4,
    },
    {
        zip: 105,
        zone: 4,
    },
    {
        zip: 106,
        zone: 4,
    },
    {
        zip: 107,
        zone: 4,
    },
    {
        zip: 108,
        zone: 4,
    },
    {
        zip: 109,
        zone: 4,
    },
    {
        zip: 110,
        zone: 4,
    },
    {
        zip: 111,
        zone: 4,
    },
    {
        zip: 112,
        zone: 4,
    },
    {
        zip: 113,
        zone: 4,
    },
    {
        zip: 114,
        zone: 4,
    },
    {
        zip: 115,
        zone: 4,
    },
    {
        zip: 116,
        zone: 4,
    },
    {
        zip: 117,
        zone: 4,
    },
    {
        zip: 118,
        zone: 4,
    },
    {
        zip: 119,
        zone: 4,
    },
    {
        zip: 120,
        zone: 4,
    },
    {
        zip: 121,
        zone: 4,
    },
    {
        zip: 122,
        zone: 4,
    },
    {
        zip: 123,
        zone: 4,
    },
    {
        zip: 124,
        zone: 4,
    },
    {
        zip: 125,
        zone: 4,
    },
    {
        zip: 126,
        zone: 4,
    },
    {
        zip: 127,
        zone: 3,
    },
    {
        zip: 128,
        zone: 4,
    },
    {
        zip: 129,
        zone: 4,
    },
    {
        zip: 130,
        zone: 3,
    },
    {
        zip: 131,
        zone: 3,
    },
    {
        zip: 132,
        zone: 3,
    },
    {
        zip: 133,
        zone: 4,
    },
    {
        zip: 134,
        zone: 4,
    },
    {
        zip: 135,
        zone: 4,
    },
    {
        zip: 136,
        zone: 4,
    },
    {
        zip: 137,
        zone: 3,
    },
    {
        zip: 138,
        zone: 3,
    },
    {
        zip: 139,
        zone: 3,
    },
    {
        zip: 140,
        zone: 3,
    },
    {
        zip: 141,
        zone: 3,
    },
    {
        zip: 142,
        zone: 3,
    },
    {
        zip: 143,
        zone: 3,
    },
    {
        zip: 144,
        zone: 3,
    },
    {
        zip: 145,
        zone: 3,
    },
    {
        zip: 146,
        zone: 3,
    },
    {
        zip: 147,
        zone: 2,
    },
    {
        zip: 148,
        zone: 3,
    },
    {
        zip: 149,
        zone: 3,
    },
    {
        zip: 150,
        zone: 2,
    },
    {
        zip: 151,
        zone: 2,
    },
    {
        zip: 152,
        zone: 2,
    },
    {
        zip: 153,
        zone: 2,
    },
    {
        zip: 154,
        zone: 2,
    },
    {
        zip: 155,
        zone: 2,
    },
    {
        zip: 156,
        zone: 2,
    },
    {
        zip: 157,
        zone: 2,
    },
    {
        zip: 158,
        zone: 2,
    },
    {
        zip: 159,
        zone: 2,
    },
    {
        zip: 160,
        zone: 2,
    },
    {
        zip: 161,
        zone: 2,
    },
    {
        zip: 162,
        zone: 2,
    },
    {
        zip: 163,
        zone: 2,
    },
    {
        zip: 164,
        zone: 2,
    },
    {
        zip: 165,
        zone: 2,
    },
    {
        zip: 166,
        zone: 2,
    },
    {
        zip: 167,
        zone: 2,
    },
    {
        zip: 168,
        zone: 2,
    },
    {
        zip: 169,
        zone: 3,
    },
    {
        zip: 170,
        zone: 3,
    },
    {
        zip: 171,
        zone: 3,
    },
    {
        zip: 172,
        zone: 3,
    },
    {
        zip: 173,
        zone: 3,
    },
    {
        zip: 174,
        zone: 3,
    },
    {
        zip: 175,
        zone: 3,
    },
    {
        zip: 176,
        zone: 3,
    },
    {
        zip: 177,
        zone: 3,
    },
    {
        zip: 178,
        zone: 3,
    },
    {
        zip: 179,
        zone: 3,
    },
    {
        zip: 180,
        zone: 3,
    },
    {
        zip: 181,
        zone: 3,
    },
    {
        zip: 182,
        zone: 3,
    },
    {
        zip: 183,
        zone: 3,
    },
    {
        zip: 184,
        zone: 3,
    },
    {
        zip: 185,
        zone: 3,
    },
    {
        zip: 186,
        zone: 3,
    },
    {
        zip: 187,
        zone: 3,
    },
    {
        zip: 188,
        zone: 3,
    },
    {
        zip: 189,
        zone: 3,
    },
    {
        zip: 190,
        zone: 3,
    },
    {
        zip: 191,
        zone: 3,
    },
    {
        zip: 192,
        zone: 3,
    },
    {
        zip: 193,
        zone: 3,
    },
    {
        zip: 194,
        zone: 3,
    },
    {
        zip: 195,
        zone: 3,
    },
    {
        zip: 196,
        zone: 3,
    },
    {
        zip: 197,
        zone: 3,
    },
    {
        zip: 198,
        zone: 3,
    },
    {
        zip: 199,
        zone: 3,
    },
    {
        zip: 200,
        zone: 3,
    },
    {
        zip: 201,
        zone: 3,
    },
    {
        zip: 202,
        zone: 3,
    },
    {
        zip: 203,
        zone: 3,
    },
    {
        zip: 204,
        zone: 3,
    },
    {
        zip: 205,
        zone: 3,
    },
    {
        zip: 206,
        zone: 3,
    },
    {
        zip: 207,
        zone: 3,
    },
    {
        zip: 208,
        zone: 3,
    },
    {
        zip: 209,
        zone: 3,
    },
    {
        zip: 210,
        zone: 3,
    },
    {
        zip: 211,
        zone: 3,
    },
    {
        zip: 212,
        zone: 3,
    },
    {
        zip: 213,
        zone: 3,
    },
    {
        zip: 214,
        zone: 3,
    },
    {
        zip: 215,
        zone: 2,
    },
    {
        zip: 216,
        zone: 3,
    },
    {
        zip: 217,
        zone: 3,
    },
    {
        zip: 218,
        zone: 3,
    },
    {
        zip: 219,
        zone: 3,
    },
    {
        zip: 220,
        zone: 3,
    },
    {
        zip: 221,
        zone: 3,
    },
    {
        zip: 222,
        zone: 3,
    },
    {
        zip: 223,
        zone: 3,
    },
    {
        zip: 224,
        zone: 3,
    },
    {
        zip: 225,
        zone: 3,
    },
    {
        zip: 226,
        zone: 2,
    },
    {
        zip: 227,
        zone: 3,
    },
    {
        zip: 228,
        zone: 2,
    },
    {
        zip: 229,
        zone: 2,
    },
    {
        zip: 230,
        zone: 3,
    },
    {
        zip: 231,
        zone: 3,
    },
    {
        zip: 232,
        zone: 3,
    },
    {
        zip: 233,
        zone: 4,
    },
    {
        zip: 234,
        zone: 4,
    },
    {
        zip: 235,
        zone: 4,
    },
    {
        zip: 236,
        zone: 4,
    },
    {
        zip: 237,
        zone: 4,
    },
    {
        zip: 238,
        zone: 3,
    },
    {
        zip: 239,
        zone: 3,
    },
    {
        zip: 240,
        zone: 3,
    },
    {
        zip: 241,
        zone: 3,
    },
    {
        zip: 242,
        zone: 3,
    },
    {
        zip: 243,
        zone: 3,
    },
    {
        zip: 244,
        zone: 2,
    },
    {
        zip: 245,
        zone: 3,
    },
    {
        zip: 246,
        zone: 3,
    },
    {
        zip: 247,
        zone: 3,
    },
    {
        zip: 248,
        zone: 3,
    },
    {
        zip: 249,
        zone: 3,
    },
    {
        zip: 250,
        zone: 2,
    },
    {
        zip: 251,
        zone: 2,
    },
    {
        zip: 252,
        zone: 2,
    },
    {
        zip: 253,
        zone: 2,
    },
    {
        zip: 254,
        zone: 2,
    },
    {
        zip: 255,
        zone: 3,
    },
    {
        zip: 256,
        zone: 3,
    },
    {
        zip: 257,
        zone: 3,
    },
    {
        zip: 258,
        zone: 3,
    },
    {
        zip: 259,
        zone: 3,
    },
    {
        zip: 260,
        zone: 2,
    },
    {
        zip: 261,
        zone: 2,
    },
    {
        zip: 262,
        zone: 2,
    },
    {
        zip: 263,
        zone: 2,
    },
    {
        zip: 264,
        zone: 2,
    },
    {
        zip: 265,
        zone: 2,
    },
    {
        zip: 266,
        zone: 2,
    },
    {
        zip: 267,
        zone: 2,
    },
    {
        zip: 268,
        zone: 2,
    },
    {
        zip: 270,
        zone: 3,
    },
    {
        zip: 271,
        zone: 3,
    },
    {
        zip: 272,
        zone: 3,
    },
    {
        zip: 273,
        zone: 3,
    },
    {
        zip: 274,
        zone: 3,
    },
    {
        zip: 275,
        zone: 4,
    },
    {
        zip: 276,
        zone: 4,
    },
    {
        zip: 277,
        zone: 4,
    },
    {
        zip: 278,
        zone: 4,
    },
    {
        zip: 279,
        zone: 4,
    },
    {
        zip: 280,
        zone: 4,
    },
    {
        zip: 281,
        zone: 4,
    },
    {
        zip: 282,
        zone: 4,
    },
    {
        zip: 283,
        zone: 4,
    },
    {
        zip: 284,
        zone: 4,
    },
    {
        zip: 285,
        zone: 4,
    },
    {
        zip: 286,
        zone: 3,
    },
    {
        zip: 287,
        zone: 4,
    },
    {
        zip: 288,
        zone: 4,
    },
    {
        zip: 289,
        zone: 4,
    },
    {
        zip: 290,
        zone: 4,
    },
    {
        zip: 291,
        zone: 4,
    },
    {
        zip: 292,
        zone: 4,
    },
    {
        zip: 293,
        zone: 4,
    },
    {
        zip: 294,
        zone: 4,
    },
    {
        zip: 295,
        zone: 4,
    },
    {
        zip: 296,
        zone: 4,
    },
    {
        zip: 297,
        zone: 4,
    },
    {
        zip: 298,
        zone: 4,
    },
    {
        zip: 299,
        zone: 4,
    },
    {
        zip: 300,
        zone: 4,
    },
    {
        zip: 301,
        zone: 4,
    },
    {
        zip: 302,
        zone: 4,
    },
    {
        zip: 303,
        zone: 4,
    },
    {
        zip: 304,
        zone: 4,
    },
    {
        zip: 305,
        zone: 4,
    },
    {
        zip: 306,
        zone: 4,
    },
    {
        zip: 307,
        zone: 4,
    },
    {
        zip: 308,
        zone: 4,
    },
    {
        zip: 309,
        zone: 4,
    },
    {
        zip: 310,
        zone: 4,
    },
    {
        zip: 311,
        zone: 4,
    },
    {
        zip: 312,
        zone: 4,
    },
    {
        zip: 313,
        zone: 4,
    },
    {
        zip: 314,
        zone: 4,
    },
    {
        zip: 315,
        zone: 5,
    },
    {
        zip: 316,
        zone: 5,
    },
    {
        zip: 317,
        zone: 5,
    },
    {
        zip: 318,
        zone: 4,
    },
    {
        zip: 319,
        zone: 4,
    },
    {
        zip: 320,
        zone: 5,
    },
    {
        zip: 321,
        zone: 5,
    },
    {
        zip: 322,
        zone: 5,
    },
    {
        zip: 323,
        zone: 5,
    },
    {
        zip: 324,
        zone: 5,
    },
    {
        zip: 325,
        zone: 5,
    },
    {
        zip: 326,
        zone: 5,
    },
    {
        zip: 327,
        zone: 5,
    },
    {
        zip: 328,
        zone: 5,
    },
    {
        zip: 329,
        zone: 5,
    },
    {
        zip: 330,
        zone: 5,
    },
    {
        zip: 331,
        zone: 5,
    },
    {
        zip: 332,
        zone: 5,
    },
    {
        zip: 333,
        zone: 5,
    },
    {
        zip: 334,
        zone: 5,
    },
    {
        zip: 335,
        zone: 5,
    },
    {
        zip: 336,
        zone: 5,
    },
    {
        zip: 337,
        zone: 5,
    },
    {
        zip: 338,
        zone: 5,
    },
    {
        zip: 339,
        zone: 5,
    },
    {
        zip: 341,
        zone: 5,
    },
    {
        zip: 342,
        zone: 5,
    },
    {
        zip: 343,
        zone: 5,
    },
    {
        zip: 344,
        zone: 5,
    },
    {
        zip: 345,
        zone: 5,
    },
    {
        zip: 346,
        zone: 5,
    },
    {
        zip: 347,
        zone: 5,
    },
    {
        zip: 348,
        zone: 5,
    },
    {
        zip: 349,
        zone: 5,
    },
    {
        zip: 350,
        zone: 4,
    },
    {
        zip: 351,
        zone: 4,
    },
    {
        zip: 352,
        zone: 4,
    },
    {
        zip: 353,
        zone: 4,
    },
    {
        zip: 354,
        zone: 5,
    },
    {
        zip: 355,
        zone: 4,
    },
    {
        zip: 356,
        zone: 4,
    },
    {
        zip: 357,
        zone: 4,
    },
    {
        zip: 358,
        zone: 4,
    },
    {
        zip: 359,
        zone: 4,
    },
    {
        zip: 360,
        zone: 5,
    },
    {
        zip: 361,
        zone: 5,
    },
    {
        zip: 362,
        zone: 4,
    },
    {
        zip: 363,
        zone: 5,
    },
    {
        zip: 364,
        zone: 5,
    },
    {
        zip: 365,
        zone: 5,
    },
    {
        zip: 366,
        zone: 5,
    },
    {
        zip: 367,
        zone: 5,
    },
    {
        zip: 368,
        zone: 4,
    },
    {
        zip: 369,
        zone: 5,
    },
    {
        zip: 370,
        zone: 4,
    },
    {
        zip: 371,
        zone: 4,
    },
    {
        zip: 372,
        zone: 4,
    },
    {
        zip: 373,
        zone: 4,
    },
    {
        zip: 374,
        zone: 4,
    },
    {
        zip: 375,
        zone: 5,
    },
    {
        zip: 376,
        zone: 3,
    },
    {
        zip: 377,
        zone: 4,
    },
    {
        zip: 378,
        zone: 4,
    },
    {
        zip: 379,
        zone: 4,
    },
    {
        zip: 380,
        zone: 5,
    },
    {
        zip: 381,
        zone: 5,
    },
    {
        zip: 382,
        zone: 4,
    },
    {
        zip: 383,
        zone: 4,
    },
    {
        zip: 384,
        zone: 4,
    },
    {
        zip: 385,
        zone: 4,
    },
    {
        zip: 386,
        zone: 5,
    },
    {
        zip: 387,
        zone: 5,
    },
    {
        zip: 388,
        zone: 5,
    },
    {
        zip: 389,
        zone: 5,
    },
    {
        zip: 390,
        zone: 5,
    },
    {
        zip: 391,
        zone: 5,
    },
    {
        zip: 392,
        zone: 5,
    },
    {
        zip: 393,
        zone: 5,
    },
    {
        zip: 394,
        zone: 5,
    },
    {
        zip: 395,
        zone: 5,
    },
    {
        zip: 396,
        zone: 5,
    },
    {
        zip: 397,
        zone: 5,
    },
    {
        zip: 398,
        zone: 5,
    },
    {
        zip: 399,
        zone: 4,
    },
    {
        zip: 400,
        zone: 4,
    },
    {
        zip: 401,
        zone: 4,
    },
    {
        zip: 402,
        zone: 4,
    },
    {
        zip: 403,
        zone: 3,
    },
    {
        zip: 404,
        zone: 3,
    },
    {
        zip: 405,
        zone: 3,
    },
    {
        zip: 406,
        zone: 3,
    },
    {
        zip: 407,
        zone: 4,
    },
    {
        zip: 408,
        zone: 4,
    },
    {
        zip: 409,
        zone: 4,
    },
    {
        zip: 410,
        zone: 3,
    },
    {
        zip: 411,
        zone: 3,
    },
    {
        zip: 412,
        zone: 3,
    },
    {
        zip: 413,
        zone: 3,
    },
    {
        zip: 414,
        zone: 3,
    },
    {
        zip: 415,
        zone: 3,
    },
    {
        zip: 416,
        zone: 3,
    },
    {
        zip: 417,
        zone: 3,
    },
    {
        zip: 418,
        zone: 3,
    },
    {
        zip: 420,
        zone: 4,
    },
    {
        zip: 421,
        zone: 4,
    },
    {
        zip: 422,
        zone: 4,
    },
    {
        zip: 423,
        zone: 4,
    },
    {
        zip: 424,
        zone: 4,
    },
    {
        zip: 425,
        zone: 3,
    },
    {
        zip: 426,
        zone: 3,
    },
    {
        zip: 427,
        zone: 4,
    },
    {
        zip: 430,
        zone: 2,
    },
    {
        zip: 431,
        zone: 2,
    },
    {
        zip: 432,
        zone: 2,
    },
    {
        zip: 433,
        zone: 2,
    },
    {
        zip: 434,
        zone: 3,
    },
    {
        zip: 435,
        zone: 3,
    },
    {
        zip: 436,
        zone: 3,
    },
    {
        zip: 437,
        zone: 2,
    },
    {
        zip: 438,
        zone: 2,
    },
    {
        zip: 439,
        zone: 2,
    },
    {
        zip: 440,
        zone: 2,
    },
    {
        zip: 441,
        zone: 2,
    },
    {
        zip: 442,
        zone: 2,
    },
    {
        zip: 443,
        zone: 2,
    },
    {
        zip: 444,
        zone: 2,
    },
    {
        zip: 445,
        zone: 2,
    },
    {
        zip: 446,
        zone: 2,
    },
    {
        zip: 447,
        zone: 2,
    },
    {
        zip: 448,
        zone: 2,
    },
    {
        zip: 449,
        zone: 2,
    },
    {
        zip: 450,
        zone: 3,
    },
    {
        zip: 451,
        zone: 3,
    },
    {
        zip: 452,
        zone: 3,
    },
    {
        zip: 453,
        zone: 3,
    },
    {
        zip: 454,
        zone: 3,
    },
    {
        zip: 455,
        zone: 3,
    },
    {
        zip: 456,
        zone: 2,
    },
    {
        zip: 457,
        zone: 2,
    },
    {
        zip: 458,
        zone: 3,
    },
    {
        zip: 459,
        zone: 3,
    },
    {
        zip: 460,
        zone: 4,
    },
    {
        zip: 461,
        zone: 4,
    },
    {
        zip: 462,
        zone: 4,
    },
    {
        zip: 463,
        zone: 4,
    },
    {
        zip: 464,
        zone: 4,
    },
    {
        zip: 465,
        zone: 4,
    },
    {
        zip: 466,
        zone: 4,
    },
    {
        zip: 467,
        zone: 3,
    },
    {
        zip: 468,
        zone: 3,
    },
    {
        zip: 469,
        zone: 4,
    },
    {
        zip: 470,
        zone: 3,
    },
    {
        zip: 471,
        zone: 4,
    },
    {
        zip: 472,
        zone: 3,
    },
    {
        zip: 473,
        zone: 3,
    },
    {
        zip: 474,
        zone: 4,
    },
    {
        zip: 475,
        zone: 4,
    },
    {
        zip: 476,
        zone: 4,
    },
    {
        zip: 477,
        zone: 4,
    },
    {
        zip: 478,
        zone: 4,
    },
    {
        zip: 479,
        zone: 4,
    },
    {
        zip: 480,
        zone: 3,
    },
    {
        zip: 481,
        zone: 3,
    },
    {
        zip: 482,
        zone: 3,
    },
    {
        zip: 483,
        zone: 3,
    },
    {
        zip: 484,
        zone: 3,
    },
    {
        zip: 485,
        zone: 3,
    },
    {
        zip: 486,
        zone: 3,
    },
    {
        zip: 487,
        zone: 3,
    },
    {
        zip: 488,
        zone: 3,
    },
    {
        zip: 489,
        zone: 3,
    },
    {
        zip: 490,
        zone: 3,
    },
    {
        zip: 491,
        zone: 3,
    },
    {
        zip: 492,
        zone: 3,
    },
    {
        zip: 493,
        zone: 4,
    },
    {
        zip: 494,
        zone: 4,
    },
    {
        zip: 495,
        zone: 4,
    },
    {
        zip: 496,
        zone: 4,
    },
    {
        zip: 497,
        zone: 4,
    },
    {
        zip: 498,
        zone: 4,
    },
    {
        zip: 499,
        zone: 4,
    },
    {
        zip: 500,
        zone: 5,
    },
    {
        zip: 501,
        zone: 5,
    },
    {
        zip: 502,
        zone: 5,
    },
    {
        zip: 503,
        zone: 5,
    },
    {
        zip: 504,
        zone: 5,
    },
    {
        zip: 505,
        zone: 5,
    },
    {
        zip: 506,
        zone: 5,
    },
    {
        zip: 507,
        zone: 5,
    },
    {
        zip: 508,
        zone: 5,
    },
    {
        zip: 509,
        zone: 5,
    },
    {
        zip: 510,
        zone: 5,
    },
    {
        zip: 511,
        zone: 5,
    },
    {
        zip: 512,
        zone: 5,
    },
    {
        zip: 513,
        zone: 5,
    },
    {
        zip: 514,
        zone: 5,
    },
    {
        zip: 515,
        zone: 5,
    },
    {
        zip: 516,
        zone: 5,
    },
    {
        zip: 520,
        zone: 4,
    },
    {
        zip: 521,
        zone: 5,
    },
    {
        zip: 522,
        zone: 4,
    },
    {
        zip: 523,
        zone: 4,
    },
    {
        zip: 524,
        zone: 4,
    },
    {
        zip: 525,
        zone: 5,
    },
    {
        zip: 526,
        zone: 4,
    },
    {
        zip: 527,
        zone: 4,
    },
    {
        zip: 528,
        zone: 4,
    },
    {
        zip: 529,
        zone: 4,
    },
    {
        zip: 530,
        zone: 4,
    },
    {
        zip: 531,
        zone: 4,
    },
    {
        zip: 532,
        zone: 4,
    },
    {
        zip: 533,
        zone: 4,
    },
    {
        zip: 534,
        zone: 4,
    },
    {
        zip: 535,
        zone: 4,
    },
    {
        zip: 536,
        zone: 4,
    },
    {
        zip: 537,
        zone: 4,
    },
    {
        zip: 538,
        zone: 4,
    },
    {
        zip: 539,
        zone: 4,
    },
    {
        zip: 540,
        zone: 5,
    },
    {
        zip: 541,
        zone: 4,
    },
    {
        zip: 542,
        zone: 4,
    },
    {
        zip: 543,
        zone: 4,
    },
    {
        zip: 544,
        zone: 4,
    },
    {
        zip: 545,
        zone: 4,
    },
    {
        zip: 546,
        zone: 4,
    },
    {
        zip: 547,
        zone: 5,
    },
    {
        zip: 548,
        zone: 5,
    },
    {
        zip: 549,
        zone: 4,
    },
    {
        zip: 550,
        zone: 5,
    },
    {
        zip: 551,
        zone: 5,
    },
    {
        zip: 552,
        zone: 5,
    },
    {
        zip: 553,
        zone: 5,
    },
    {
        zip: 554,
        zone: 5,
    },
    {
        zip: 555,
        zone: 5,
    },
    {
        zip: 556,
        zone: 5,
    },
    {
        zip: 557,
        zone: 5,
    },
    {
        zip: 558,
        zone: 5,
    },
    {
        zip: 559,
        zone: 5,
    },
    {
        zip: 560,
        zone: 5,
    },
    {
        zip: 561,
        zone: 5,
    },
    {
        zip: 562,
        zone: 5,
    },
    {
        zip: 563,
        zone: 5,
    },
    {
        zip: 564,
        zone: 5,
    },
    {
        zip: 565,
        zone: 5,
    },
    {
        zip: 566,
        zone: 5,
    },
    {
        zip: 567,
        zone: 5,
    },
    {
        zip: 568,
        zone: 5,
    },
    {
        zip: 569,
        zone: 5,
    },
    {
        zip: 570,
        zone: 5,
    },
    {
        zip: 571,
        zone: 5,
    },
    {
        zip: 572,
        zone: 5,
    },
    {
        zip: 573,
        zone: 5,
    },
    {
        zip: 574,
        zone: 5,
    },
    {
        zip: 575,
        zone: 6,
    },
    {
        zip: 576,
        zone: 6,
    },
    {
        zip: 577,
        zone: 6,
    },
    {
        zip: 580,
        zone: 5,
    },
    {
        zip: 581,
        zone: 5,
    },
    {
        zip: 582,
        zone: 5,
    },
    {
        zip: 583,
        zone: 6,
    },
    {
        zip: 584,
        zone: 6,
    },
    {
        zip: 585,
        zone: 6,
    },
    {
        zip: 586,
        zone: 6,
    },
    {
        zip: 587,
        zone: 6,
    },
    {
        zip: 588,
        zone: 6,
    },
    {
        zip: 590,
        zone: 7,
    },
    {
        zip: 591,
        zone: 7,
    },
    {
        zip: 592,
        zone: 6,
    },
    {
        zip: 593,
        zone: 6,
    },
    {
        zip: 594,
        zone: 7,
    },
    {
        zip: 595,
        zone: 7,
    },
    {
        zip: 596,
        zone: 7,
    },
    {
        zip: 597,
        zone: 7,
    },
    {
        zip: 598,
        zone: 7,
    },
    {
        zip: 599,
        zone: 7,
    },
    {
        zip: 600,
        zone: 4,
    },
    {
        zip: 601,
        zone: 4,
    },
    {
        zip: 602,
        zone: 4,
    },
    {
        zip: 603,
        zone: 4,
    },
    {
        zip: 604,
        zone: 4,
    },
    {
        zip: 605,
        zone: 4,
    },
    {
        zip: 606,
        zone: 4,
    },
    {
        zip: 607,
        zone: 4,
    },
    {
        zip: 608,
        zone: 4,
    },
    {
        zip: 609,
        zone: 4,
    },
    {
        zip: 610,
        zone: 4,
    },
    {
        zip: 611,
        zone: 4,
    },
    {
        zip: 612,
        zone: 4,
    },
    {
        zip: 613,
        zone: 4,
    },
    {
        zip: 614,
        zone: 4,
    },
    {
        zip: 615,
        zone: 4,
    },
    {
        zip: 616,
        zone: 4,
    },
    {
        zip: 617,
        zone: 4,
    },
    {
        zip: 618,
        zone: 4,
    },
    {
        zip: 619,
        zone: 4,
    },
    {
        zip: 620,
        zone: 4,
    },
    {
        zip: 621,
        zone: 4,
    },
    {
        zip: 622,
        zone: 4,
    },
    {
        zip: 623,
        zone: 4,
    },
    {
        zip: 624,
        zone: 4,
    },
    {
        zip: 625,
        zone: 4,
    },
    {
        zip: 626,
        zone: 4,
    },
    {
        zip: 627,
        zone: 4,
    },
    {
        zip: 628,
        zone: 4,
    },
    {
        zip: 629,
        zone: 4,
    },
    {
        zip: 630,
        zone: 4,
    },
    {
        zip: 631,
        zone: 4,
    },
    {
        zip: 632,
        zone: 4,
    },
    {
        zip: 633,
        zone: 4,
    },
    {
        zip: 634,
        zone: 4,
    },
    {
        zip: 635,
        zone: 5,
    },
    {
        zip: 636,
        zone: 4,
    },
    {
        zip: 637,
        zone: 4,
    },
    {
        zip: 638,
        zone: 4,
    },
    {
        zip: 639,
        zone: 4,
    },
    {
        zip: 640,
        zone: 5,
    },
    {
        zip: 641,
        zone: 5,
    },
    {
        zip: 642,
        zone: 5,
    },
    {
        zip: 643,
        zone: 5,
    },
    {
        zip: 644,
        zone: 5,
    },
    {
        zip: 645,
        zone: 5,
    },
    {
        zip: 646,
        zone: 5,
    },
    {
        zip: 647,
        zone: 5,
    },
    {
        zip: 648,
        zone: 5,
    },
    {
        zip: 649,
        zone: 5,
    },
    {
        zip: 650,
        zone: 5,
    },
    {
        zip: 651,
        zone: 5,
    },
    {
        zip: 652,
        zone: 5,
    },
    {
        zip: 653,
        zone: 5,
    },
    {
        zip: 654,
        zone: 5,
    },
    {
        zip: 655,
        zone: 5,
    },
    {
        zip: 656,
        zone: 5,
    },
    {
        zip: 657,
        zone: 5,
    },
    {
        zip: 658,
        zone: 5,
    },
    {
        zip: 659,
        zone: 5,
    },
    {
        zip: 660,
        zone: 5,
    },
    {
        zip: 661,
        zone: 5,
    },
    {
        zip: 662,
        zone: 5,
    },
    {
        zip: 663,
        zone: 5,
    },
    {
        zip: 664,
        zone: 5,
    },
    {
        zip: 665,
        zone: 5,
    },
    {
        zip: 666,
        zone: 5,
    },
    {
        zip: 667,
        zone: 5,
    },
    {
        zip: 668,
        zone: 5,
    },
    {
        zip: 669,
        zone: 5,
    },
    {
        zip: 670,
        zone: 5,
    },
    {
        zip: 671,
        zone: 5,
    },
    {
        zip: 672,
        zone: 5,
    },
    {
        zip: 673,
        zone: 5,
    },
    {
        zip: 674,
        zone: 5,
    },
    {
        zip: 675,
        zone: 5,
    },
    {
        zip: 676,
        zone: 6,
    },
    {
        zip: 677,
        zone: 6,
    },
    {
        zip: 678,
        zone: 6,
    },
    {
        zip: 679,
        zone: 6,
    },
    {
        zip: 680,
        zone: 5,
    },
    {
        zip: 681,
        zone: 5,
    },
    {
        zip: 682,
        zone: 5,
    },
    {
        zip: 683,
        zone: 5,
    },
    {
        zip: 684,
        zone: 5,
    },
    {
        zip: 685,
        zone: 5,
    },
    {
        zip: 686,
        zone: 5,
    },
    {
        zip: 687,
        zone: 5,
    },
    {
        zip: 688,
        zone: 5,
    },
    {
        zip: 689,
        zone: 5,
    },
    {
        zip: 690,
        zone: 6,
    },
    {
        zip: 691,
        zone: 6,
    },
    {
        zip: 692,
        zone: 6,
    },
    {
        zip: 693,
        zone: 6,
    },
    {
        zip: 700,
        zone: 5,
    },
    {
        zip: 701,
        zone: 5,
    },
    {
        zip: 702,
        zone: 5,
    },
    {
        zip: 703,
        zone: 5,
    },
    {
        zip: 704,
        zone: 5,
    },
    {
        zip: 705,
        zone: 5,
    },
    {
        zip: 706,
        zone: 5,
    },
    {
        zip: 707,
        zone: 5,
    },
    {
        zip: 708,
        zone: 5,
    },
    {
        zip: 709,
        zone: 5,
    },
    {
        zip: 710,
        zone: 5,
    },
    {
        zip: 711,
        zone: 5,
    },
    {
        zip: 712,
        zone: 5,
    },
    {
        zip: 713,
        zone: 5,
    },
    {
        zip: 714,
        zone: 5,
    },
    {
        zip: 715,
        zone: 5,
    },
    {
        zip: 716,
        zone: 5,
    },
    {
        zip: 717,
        zone: 5,
    },
    {
        zip: 718,
        zone: 5,
    },
    {
        zip: 719,
        zone: 5,
    },
    {
        zip: 720,
        zone: 5,
    },
    {
        zip: 721,
        zone: 5,
    },
    {
        zip: 722,
        zone: 5,
    },
    {
        zip: 723,
        zone: 5,
    },
    {
        zip: 724,
        zone: 5,
    },
    {
        zip: 725,
        zone: 5,
    },
    {
        zip: 726,
        zone: 5,
    },
    {
        zip: 727,
        zone: 5,
    },
    {
        zip: 728,
        zone: 5,
    },
    {
        zip: 729,
        zone: 5,
    },
    {
        zip: 730,
        zone: 5,
    },
    {
        zip: 731,
        zone: 5,
    },
    {
        zip: 732,
        zone: 5,
    },
    {
        zip: 733,
        zone: 6,
    },
    {
        zip: 734,
        zone: 6,
    },
    {
        zip: 735,
        zone: 6,
    },
    {
        zip: 736,
        zone: 6,
    },
    {
        zip: 737,
        zone: 5,
    },
    {
        zip: 738,
        zone: 6,
    },
    {
        zip: 739,
        zone: 6,
    },
    {
        zip: 740,
        zone: 5,
    },
    {
        zip: 741,
        zone: 5,
    },
    {
        zip: 742,
        zone: 5,
    },
    {
        zip: 743,
        zone: 5,
    },
    {
        zip: 744,
        zone: 5,
    },
    {
        zip: 745,
        zone: 5,
    },
    {
        zip: 746,
        zone: 5,
    },
    {
        zip: 747,
        zone: 5,
    },
    {
        zip: 748,
        zone: 5,
    },
    {
        zip: 749,
        zone: 5,
    },
    {
        zip: 750,
        zone: 6,
    },
    {
        zip: 751,
        zone: 6,
    },
    {
        zip: 752,
        zone: 6,
    },
    {
        zip: 753,
        zone: 6,
    },
    {
        zip: 754,
        zone: 6,
    },
    {
        zip: 755,
        zone: 5,
    },
    {
        zip: 756,
        zone: 5,
    },
    {
        zip: 757,
        zone: 5,
    },
    {
        zip: 758,
        zone: 6,
    },
    {
        zip: 759,
        zone: 5,
    },
    {
        zip: 760,
        zone: 6,
    },
    {
        zip: 761,
        zone: 6,
    },
    {
        zip: 762,
        zone: 6,
    },
    {
        zip: 763,
        zone: 6,
    },
    {
        zip: 764,
        zone: 6,
    },
    {
        zip: 765,
        zone: 6,
    },
    {
        zip: 766,
        zone: 6,
    },
    {
        zip: 767,
        zone: 6,
    },
    {
        zip: 768,
        zone: 6,
    },
    {
        zip: 769,
        zone: 6,
    },
    {
        zip: 770,
        zone: 6,
    },
    {
        zip: 771,
        zone: 6,
    },
    {
        zip: 772,
        zone: 6,
    },
    {
        zip: 773,
        zone: 6,
    },
    {
        zip: 774,
        zone: 6,
    },
    {
        zip: 775,
        zone: 6,
    },
    {
        zip: 776,
        zone: 6,
    },
    {
        zip: 777,
        zone: 6,
    },
    {
        zip: 778,
        zone: 6,
    },
    {
        zip: 779,
        zone: 6,
    },
    {
        zip: 780,
        zone: 6,
    },
    {
        zip: 781,
        zone: 6,
    },
    {
        zip: 782,
        zone: 6,
    },
    {
        zip: 783,
        zone: 6,
    },
    {
        zip: 784,
        zone: 6,
    },
    {
        zip: 785,
        zone: 6,
    },
    {
        zip: 786,
        zone: 6,
    },
    {
        zip: 787,
        zone: 6,
    },
    {
        zip: 788,
        zone: 6,
    },
    {
        zip: 789,
        zone: 6,
    },
    {
        zip: 790,
        zone: 6,
    },
    {
        zip: 791,
        zone: 6,
    },
    {
        zip: 792,
        zone: 6,
    },
    {
        zip: 793,
        zone: 6,
    },
    {
        zip: 794,
        zone: 6,
    },
    {
        zip: 795,
        zone: 6,
    },
    {
        zip: 796,
        zone: 6,
    },
    {
        zip: 797,
        zone: 6,
    },
    {
        zip: 798,
        zone: 7,
    },
    {
        zip: 799,
        zone: 7,
    },
    {
        zip: 800,
        zone: 6,
    },
    {
        zip: 801,
        zone: 6,
    },
    {
        zip: 802,
        zone: 6,
    },
    {
        zip: 803,
        zone: 6,
    },
    {
        zip: 804,
        zone: 6,
    },
    {
        zip: 805,
        zone: 6,
    },
    {
        zip: 806,
        zone: 6,
    },
    {
        zip: 807,
        zone: 6,
    },
    {
        zip: 808,
        zone: 6,
    },
    {
        zip: 809,
        zone: 6,
    },
    {
        zip: 810,
        zone: 6,
    },
    {
        zip: 811,
        zone: 6,
    },
    {
        zip: 812,
        zone: 6,
    },
    {
        zip: 813,
        zone: 7,
    },
    {
        zip: 814,
        zone: 7,
    },
    {
        zip: 815,
        zone: 7,
    },
    {
        zip: 816,
        zone: 7,
    },
    {
        zip: 820,
        zone: 6,
    },
    {
        zip: 821,
        zone: 7,
    },
    {
        zip: 822,
        zone: 6,
    },
    {
        zip: 823,
        zone: 6,
    },
    {
        zip: 824,
        zone: 7,
    },
    {
        zip: 825,
        zone: 7,
    },
    {
        zip: 826,
        zone: 6,
    },
    {
        zip: 827,
        zone: 6,
    },
    {
        zip: 828,
        zone: 6,
    },
    {
        zip: 829,
        zone: 7,
    },
    {
        zip: 830,
        zone: 7,
    },
    {
        zip: 831,
        zone: 7,
    },
    {
        zip: 832,
        zone: 7,
    },
    {
        zip: 833,
        zone: 7,
    },
    {
        zip: 834,
        zone: 7,
    },
    {
        zip: 835,
        zone: 8,
    },
    {
        zip: 836,
        zone: 8,
    },
    {
        zip: 837,
        zone: 8,
    },
    {
        zip: 838,
        zone: 8,
    },
    {
        zip: 840,
        zone: 7,
    },
    {
        zip: 841,
        zone: 7,
    },
    {
        zip: 842,
        zone: 7,
    },
    {
        zip: 843,
        zone: 7,
    },
    {
        zip: 844,
        zone: 7,
    },
    {
        zip: 845,
        zone: 7,
    },
    {
        zip: 846,
        zone: 7,
    },
    {
        zip: 847,
        zone: 7,
    },
    {
        zip: 850,
        zone: 8,
    },
    {
        zip: 851,
        zone: 8,
    },
    {
        zip: 852,
        zone: 7,
    },
    {
        zip: 853,
        zone: 7,
    },
    {
        zip: 854,
        zone: 8,
    },
    {
        zip: 855,
        zone: 7,
    },
    {
        zip: 856,
        zone: 7,
    },
    {
        zip: 857,
        zone: 7,
    },
    {
        zip: 858,
        zone: 7,
    },
    {
        zip: 859,
        zone: 7,
    },
    {
        zip: 860,
        zone: 7,
    },
    {
        zip: 861,
        zone: 7,
    },
    {
        zip: 862,
        zone: 7,
    },
    {
        zip: 863,
        zone: 7,
    },
    {
        zip: 864,
        zone: 8,
    },
    {
        zip: 865,
        zone: 7,
    },
    {
        zip: 866,
        zone: 7,
    },
    {
        zip: 867,
        zone: 7,
    },
    {
        zip: 868,
        zone: 7,
    },
    {
        zip: 869,
        zone: 7,
    },
    {
        zip: 870,
        zone: 7,
    },
    {
        zip: 871,
        zone: 7,
    },
    {
        zip: 872,
        zone: 7,
    },
    {
        zip: 873,
        zone: 7,
    },
    {
        zip: 874,
        zone: 7,
    },
    {
        zip: 875,
        zone: 7,
    },
    {
        zip: 877,
        zone: 6,
    },
    {
        zip: 878,
        zone: 7,
    },
    {
        zip: 879,
        zone: 7,
    },
    {
        zip: 880,
        zone: 7,
    },
    {
        zip: 881,
        zone: 6,
    },
    {
        zip: 882,
        zone: 7,
    },
    {
        zip: 883,
        zone: 7,
    },
    {
        zip: 884,
        zone: 6,
    },
    {
        zip: 885,
        zone: 7,
    },
    {
        zip: 889,
        zone: 8,
    },
    {
        zip: 890,
        zone: 8,
    },
    {
        zip: 891,
        zone: 8,
    },
    {
        zip: 892,
        zone: 8,
    },
    {
        zip: 893,
        zone: 8,
    },
    {
        zip: 894,
        zone: 8,
    },
    {
        zip: 895,
        zone: 8,
    },
    {
        zip: 896,
        zone: 8,
    },
    {
        zip: 897,
        zone: 8,
    },
    {
        zip: 898,
        zone: 8,
    },
    {
        zip: 899,
        zone: 8,
    },
    {
        zip: 900,
        zone: 8,
    },
    {
        zip: 901,
        zone: 8,
    },
    {
        zip: 902,
        zone: 8,
    },
    {
        zip: 903,
        zone: 8,
    },
    {
        zip: 904,
        zone: 8,
    },
    {
        zip: 905,
        zone: 8,
    },
    {
        zip: 906,
        zone: 8,
    },
    {
        zip: 907,
        zone: 8,
    },
    {
        zip: 908,
        zone: 8,
    },
    {
        zip: 909,
        zone: 8,
    },
    {
        zip: 910,
        zone: 8,
    },
    {
        zip: 911,
        zone: 8,
    },
    {
        zip: 912,
        zone: 8,
    },
    {
        zip: 913,
        zone: 8,
    },
    {
        zip: 914,
        zone: 8,
    },
    {
        zip: 915,
        zone: 8,
    },
    {
        zip: 916,
        zone: 8,
    },
    {
        zip: 917,
        zone: 8,
    },
    {
        zip: 918,
        zone: 8,
    },
    {
        zip: 919,
        zone: 8,
    },
    {
        zip: 920,
        zone: 8,
    },
    {
        zip: 921,
        zone: 8,
    },
    {
        zip: 922,
        zone: 8,
    },
    {
        zip: 923,
        zone: 8,
    },
    {
        zip: 924,
        zone: 8,
    },
    {
        zip: 925,
        zone: 8,
    },
    {
        zip: 926,
        zone: 8,
    },
    {
        zip: 927,
        zone: 8,
    },
    {
        zip: 928,
        zone: 8,
    },
    {
        zip: 929,
        zone: 8,
    },
    {
        zip: 930,
        zone: 8,
    },
    {
        zip: 931,
        zone: 8,
    },
    {
        zip: 932,
        zone: 8,
    },
    {
        zip: 933,
        zone: 8,
    },
    {
        zip: 934,
        zone: 8,
    },
    {
        zip: 935,
        zone: 8,
    },
    {
        zip: 936,
        zone: 8,
    },
    {
        zip: 937,
        zone: 8,
    },
    {
        zip: 938,
        zone: 8,
    },
    {
        zip: 939,
        zone: 8,
    },
    {
        zip: 940,
        zone: 8,
    },
    {
        zip: 941,
        zone: 8,
    },
    {
        zip: 942,
        zone: 8,
    },
    {
        zip: 943,
        zone: 8,
    },
    {
        zip: 944,
        zone: 8,
    },
    {
        zip: 945,
        zone: 8,
    },
    {
        zip: 946,
        zone: 8,
    },
    {
        zip: 947,
        zone: 8,
    },
    {
        zip: 948,
        zone: 8,
    },
    {
        zip: 949,
        zone: 8,
    },
    {
        zip: 950,
        zone: 8,
    },
    {
        zip: 951,
        zone: 8,
    },
    {
        zip: 952,
        zone: 8,
    },
    {
        zip: 953,
        zone: 8,
    },
    {
        zip: 954,
        zone: 8,
    },
    {
        zip: 955,
        zone: 8,
    },
    {
        zip: 956,
        zone: 8,
    },
    {
        zip: 957,
        zone: 8,
    },
    {
        zip: 958,
        zone: 8,
    },
    {
        zip: 959,
        zone: 8,
    },
    {
        zip: 960,
        zone: 8,
    },
    {
        zip: 961,
        zone: 8,
    },
    {
        zip: 970,
        zone: 8,
    },
    {
        zip: 971,
        zone: 8,
    },
    {
        zip: 972,
        zone: 8,
    },
    {
        zip: 973,
        zone: 8,
    },
    {
        zip: 974,
        zone: 8,
    },
    {
        zip: 975,
        zone: 8,
    },
    {
        zip: 976,
        zone: 8,
    },
    {
        zip: 977,
        zone: 8,
    },
    {
        zip: 978,
        zone: 8,
    },
    {
        zip: 979,
        zone: 8,
    },
    {
        zip: 980,
        zone: 8,
    },
    {
        zip: 981,
        zone: 8,
    },
    {
        zip: 982,
        zone: 8,
    },
    {
        zip: 983,
        zone: 8,
    },
    {
        zip: 984,
        zone: 8,
    },
    {
        zip: 985,
        zone: 8,
    },
    {
        zip: 986,
        zone: 8,
    },
    {
        zip: 988,
        zone: 8,
    },
    {
        zip: 989,
        zone: 8,
    },
    {
        zip: 990,
        zone: 8,
    },
    {
        zip: 991,
        zone: 8,
    },
    {
        zip: 992,
        zone: 8,
    },
    {
        zip: 993,
        zone: 8,
    },
    {
        zip: 994,
        zone: 8,
    },
]

export { shippingZones as default }
