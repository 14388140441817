import { getField, updateField } from 'vuex-map-fields'
import fetchTaxableNexusObj from '~/util/fetchTaxableNexusObj'

export const state = () => ({
    contactCard: {
        nameFirst: '',
        nameLast: '',
        nameCompany: '',
        addressCountry: 'United States',
        address1: '',
        address2: '',
        addressCity: '',
        addressState: '',
        addressPostalCode: '',
        phone1: '',
        email1: '',
        taxable: true,
        phone1CC: '1',
        phone1Ext: ''
    },
})

export const actions = {}

export const mutations = {
    updateContactCardField(state, field) {
        updateField(state.contactCard, field)
    },
    setContactCard(state, payload) {
        state.contactCard = {
            ...payload,
        }
    },
    clearFields(state, payload) {
        state.contactCard.addressState = ''
        state.contactCard.addressCity = ''
        state.contactCard.addressPostalCode = ''
        state.contactCard.phone1 = ''
    },
    resetTaxable(state, payload) {
        if(!payload) state.contactCard.taxable = false
        else state.contactCard.taxable = true
    },
    setCompanyName(state, payload) {
        state.contactCard.nameCompany = payload
    },
    resetPhoneCode(state, payload) {
        state.contactCard.phone1CC = payload
    },
    resetCountry(state, payload) {
        state.contactCard.addressCountry = payload
    }
}

export const getters = {
    getContactCardField(state) {
        return getField(state.contactCard)
    },
}
