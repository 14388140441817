function setCloudinaryData(cl, context) {
    // console.log(cl)
    const resp = {
        mobileWidth: '100',
        tabletWidth: '50',
        desktopWidth: '25',
        imageAlign: cl.imageAlign ? cl.imageAlign : 'center'
    }
    if (context === 'thumbnail' && (cl.style === 'default' || !cl.style)) {
        resp.imageAlign = 'right'
    }
    if (context === 'thumbnail' && cl.style === 'left') {
        resp.imageAlign = 'left'
    }
    if (context === 'thumbnail' && cl.style === 'fullWidth') {
        resp.tabletWidth = '100'
        resp.desktopWidth = '100'
        resp.imageAlign = 'center'
    }
    return {
        id: cl && cl.imageId ? cl.imageId : '',
        dir: cl && cl.imageDir ? cl.imageDir : '',
        mobile: resp.mobileWidth,
        tablet: resp.tabletWidth,
        desktop: resp.desktopWidth,
        align: resp.imageAlign,
        alt: cl.alt ? cl.alt : 'c3controls image'
    }
}
export { setCloudinaryData as default }
