import routes from '~/util/arrays/routes'
export default {
    methods: {
        getCustomLocalePath(item) {
            // adjust for custom landing page
            const hasCustomLandingPage =
                item.changeLandingPage && item.setLandingPage
            const post = hasCustomLandingPage ? item.setLandingPage : item
            const routeObj = this.getRouteObj(post)
            const path = this.localePath({
                name: routeObj.name,
                params: {
                    slug: routeObj.slug
                }
            })
            if (hasCustomLandingPage) {
                return this.maybeAddAnchorOrPartialToSlug(path, post)
            } else {
                return `${path}/`
            }
        },
        getRouteObj(item) {
            if (item.hasOwnProperty('menuItemURL')) {
                return {
                    name: this.getName(item.menuItemURL._type),
                    slug:
                        item?.menuItemURL?.slug?.[this.$i18n.locale]?.current ||
                        ''
                }
            } else if (item.hasOwnProperty('submenuItemURL')) {
                return {
                    name: this.getName(item.submenuItemURL._type),
                    slug:
                        item?.submenuItemURL?.slug?.[this.$i18n.locale]
                            ?.current || ''
                }
            } else if (item.hasOwnProperty('_type')) {
                return {
                    name: this.getName(item._type),
                    slug: item?.slug?.[this.$i18n.locale]?.current || ''
                }
            }
        },
        routerPush({ type, slugName, params, query }) {
            this.$router.push({
                path: this.localePath({
                    name: this.getName(type),
                    params: {
                        slug: this.getSlug({ type, slugName })
                    }
                }),
                query
            })
        },
        getStaticLocalePath({ type, slugName, params, query }) {
            return this.localePath({
                name: this.getName(type),
                params: {
                    slug: this.getSlug({ type, slugName })
                },
                query
            })
        },
        getName(type) {
            switch (type) {
                case 'page':
                    return 'slug'
                case 'account':
                    return 'my-account-slug'
                case 'product':
                    return 'products-slug'
                case 'whitepaper':
                    return 'white-paper-slug'
                case 'topic':
                    return 'blog-slug'
                case 'profile':
                    return 'team-slug'
                default:
                    return `${type}-slug`
            }
        },
        getSlug({ type, slugName }) {
            const isFound = routes.find((item) => {
                return item.name === slugName
            })
            return isFound.slug[this.$i18n.locale]
        },
        maybeAddAnchorOrPartialToSlug(path, post) {
            let anchor = ''
            let partial = ''
            if (post?.linkAnchor?.[this.$i18n.locale]) {
                anchor = `#${post.linkAnchor[this.$i18n.locale]}`
            }
            if (post?.linkPartial?.[this.$i18n.locale]) {
                partial = `${post.linkPartial[this.$i18n.locale]}/`
            }
            return `${path}/${partial}${anchor}`
        }
    }
}
