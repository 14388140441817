import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
    cadDownloadForm: {
        email: null,
        country: null,
        company: null,
    }
})

export const actions = {}

export const mutations = {
    updateCadDownloadFormField(state, field) {
        updateField(state.cadDownloadForm, field)
    }
}

export const getters = {
    getCadDownloadFormField(state) {
        return getField(state.cadDownloadForm)
    }
}
