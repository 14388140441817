<template>
    <div>
        <v-col
            v-if="settings"
            cols="12"
            :style="`text-align:${align}`"
            class="pr-0 pl-0"
            ><CtaLinks :links="[settings]"
        /></v-col>
    </div>
</template>
<script>
import CtaLinks from '~/components/CtaLinks'
export default {
    name: 'EmbedButton',
    components: {
        CtaLinks
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
        align: {
            type: String,
            default: 'center'
        }
    }
}
</script>
