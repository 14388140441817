import GoTrue from 'gotrue-js'
export default ({ store }) => {
    const auth = new GoTrue({
        APIUrl: process.env.NETLIFY_IDENTITY_URL,
        audience: '',
        setCookie: true
    })
    const user = auth.currentUser()
    if (user) {
        store.commit('auth/SET_CURRENT_USER', { ...user })
        store.commit('auth/SET_LOGGED_IN', true)
    }
}
