import skuprefixes from '~/util/skuprefixes'
function fallbackDiscountSchedule(pn) {
    const listOfProbablePrefixes = skuprefixes.filter((prefixItem) => {
        if (pn.startsWith(prefixItem.title)) {
            return prefixItem.title
        }
    })
    const longestPrefixMatch =
        listOfProbablePrefixes && listOfProbablePrefixes.length > 0
            ? listOfProbablePrefixes.reduce(function(a, b) {
                  return a.title.length > b.title.length ? a : b
              })
            : {}
    // console.log('fallback discount schedule : ', longestPrefixMatch)
    return longestPrefixMatch.discountSchedule
}

export { fallbackDiscountSchedule as default }
