<template>
    <v-card>
        <v-tabs-items v-model="subscriptionTab">
            <v-tab-item>
                <v-card flat>
                    <div class="head-section">
                        <v-btn
                            fab
                            x-small
                            elevation="2"
                            absolute
                            class="cancel-btn"
                            :disabled="isDisabled"
                            @click="handleSubsciptionDialog('formA')"
                        >
                            <fa
                                :icon="['fal', 'times']"
                                class="fa-lg"
                                color="primary"
                            />
                        </v-btn>
                        <div class="img-placeholder">
                            <img
                                src="~/assets/images/envelopes.png"
                                alt="c3Controls"
                            />
                        </div>
                    </div>
                    <div class="body-section text-center primary--text pa-4">
                        <div class="text-h4 text-sm-h3 mb-3"><TranslatableStringComponent :tstring="{string: 'subscribe'}" />!</div>
                        <p>
                            <TranslatableStringComponent :tstring="{string: 'subscriptionNote'}" />
                        </p>
                        <FormSubscriptionPartA
                            @setDisabled="setDisabled($event)"
                            @hideSubscriptionTag="$emit('hideSubscriptionTag')"
                        />
                    </div>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <div class="head-section2 text-center">
                        <div class="img-placeholder2">
                            <img
                                src="~/assets/images/envelopeSingle.png"
                                alt="c3Controls"
                            />
                        </div>

                        <div class="text-h4 text-sm-h2 mb-2"><TranslatableStringComponent :tstring="{string: 'thankYou'}" />!</div>
                        <div class="content">
                            <p class="ma-0">
                                <TranslatableStringComponent :tstring="{string: 'thankYouForSubscribingToC3Controls'}" />
                            </p>
                            <p class="ma-0">
                                <TranslatableStringComponent :tstring="{string: 'subscriptionReminder'}" />
                            </p>
                        </div>
                    </div>
                    <div class="body-section2 text-center primary--text pa-4">
                        <div class="text-h5 text-sm-h3 mb-2">
                            <TranslatableStringComponent :tstring="{string: 'beforeYouGo'}" />...
                        </div>
                        <p class="mb-0">
                            <TranslatableStringComponent :tstring="{string: 'completeYourProfileSoThatWeCanBetterServeYou'}" />
                        </p>
                        <FormSubscriptionPartB />
                    </div>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat class=" d-flex align-center justify-center pa-4 text-center">
                    <div style="width:inherit">
                        <div class="img-placeholder3">
                            <img
                                src="~/assets/images/arrow-done.png"
                                alt="c3Controls"
                            />
                        </div>
                        <div class="text-h5 text-sm-h3 mb-3 primary--text">
                            <TranslatableStringComponent :tstring="{string: 'youReAllSet'}" />
                        </div>
                        <v-btn
                            color="primary"
                            @click="handleSubsciptionDialog()"
                            ><TranslatableStringComponent :tstring="{string: 'done'}" /></v-btn
                        >
                    </div>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapState, mapGetters, mapMutations } from 'vuex'
import Cookies from 'js-cookie'
import FormSubscriptionPartA from '~/components/forms/FormSubscriptionPartA.vue'
import FormSubscriptionPartB from '~/components/forms/FormSubscriptionPartB.vue'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue' 
const { mapFields } = createHelpers({
    getterType: 'getBulkOrderPadField',
    mutationType: 'updateBulkOrderPadField'
})
export default {
    name: 'SubscriptionBox',
    components: {
        FormSubscriptionPartA,
        FormSubscriptionPartB,
        TranslatableStringComponent,
    },
    data() {
        return {
            isLoading: false,
            e1: 1,
            tab: 0,
            isDisabled: false
        }
    },
    computed: {
        ...mapState('subscription', ['subscriptionTab'])
    },
    methods: {
        handleSubsciptionDialog(formType) {
            this.$emit('closeSubscriptionBox')
            this.$store.commit('subscription/handleSubsciptionDialog')
        },
        setDisabled(val) {
            this.isDisabled = val
        }
    }
}
</script>

<style lang="scss">
.head-section {
    height: 170px;
    color: #fff;
    background-color: #0064a9;
    position: relative;
    .img-placeholder {
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-40%, -50%);
        img {
            width: 100%;
        }
    }
}
.head-section2 {
    color: #fff;
    background-color: #0064a9;
    position: relative;
    padding-top: 80px;
    padding-bottom: 20px;
    .img-placeholder2 {
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -10%);
    }
    .content {
        width: 70%;
        margin: 0 auto;
    }
}
.body-section {
    width: 60%;
    margin: 0 auto;
}
.cancel-btn {
    position: absolute;
    top: 8px;
    right: 10px;
}
@media only screen and (max-width: 600px) {
    .head-section {
        height: 130px;
    }
    .body-section {
        width: 100%;
    }
    .head-section2 {
        .content {
            width: 90%;
            margin: 0 auto;
        }
    }
    .img-placeholder3 {
        width: 55px;
        margin: 0 auto;
        img {
            width: 100%;
        }
    }
}
</style>
