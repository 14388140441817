<template>
    <v-dialog v-model="showPopup" width="700" class="" persistent>
        <v-card color="primary">
            <v-card-title>
                <v-btn
                    fab
                    x-small
                    elevation="2"
                    absolute
                    class="cancel-btn"
                    @click="setCookie"
                >
                    <fa
                        :icon="['fal', 'times']"
                        class="fa-lg"
                        color="primary"
                    />
                </v-btn>
            </v-card-title>
            <v-card-text>
                <div v-if="eBookImageLink" @click="setCookie">
                    <nuxt-link
                        :to="
                            renderUrl({
                                item:
                                    noticeSettings && noticeSettings.eBookModal,
                                lang: $i18n.locale,
                                nuxt: true,
                            })
                        "
                    >
                        <!-- <EmbedCloudinary2
                            :image-id="eBookImageLink.imageId"
                            :image-dir="eBookImageLink.imageDir"
                            :image-align="img.align"
                            classes="mt-0"
                            :alt="
                                eBookImageLink.alt &&
                                eBookImageLink.alt[$i18n.locale]
                                    ? eBookImageLink.alt
                                    : {
                                          en: 'c3controls image',
                                          es: 'c3controls image',
                                      }
                            "
                            width="700px"
                            height="600px"
                        /> -->
                        <EmbedSanityImage
                            classes="mt-0"
                            :originalFilename="
                                sanityEBookImage.selectedImage &&
                                sanityEBookImage.selectedImage.asset
                                    .originalFilename
                            "
                            :alt="
                                sanityEBookImage.alt &&
                                sanityEBookImage.alt[$i18n.locale]
                                    ? sanityEBookImage.alt
                                    : {
                                          en: 'c3controls image',
                                          es: 'c3controls image',
                                      }
                            "
                            width="700"
                            height="600"
                        />
                    </nuxt-link>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import CtaLinks from '~/components/CtaLinks.vue'
import EmbedCloudinary2 from '~/components/embeds/EmbedCloudinary2.vue'
import EmbedSanityImage from '~/components/embeds/EmbedSanityImage.vue'
import setCloudinaryData from '~/util/setCloudinaryData'
import renderUrl from '~/util/renderUrl'
import Cookies from 'js-cookie'
import { mapState } from 'vuex'
export default {
    name: 'EbookModal',
    components: {
        CtaLinks,
        EmbedCloudinary2,
        EmbedSanityImage,
    },
    props: {},
    data() {
        return {
            isCookieSet: true,
            isPaperTurnCookieSet: true,
            showDialog: true,
        }
    },
    watch: {
        noticeSettings: {
            handler(newVal) {
                if (newVal) {
                    this.getCookie()
                }
            },
            deep: true,
        },
    },
    computed: {
        ...mapState('common', ['noticeSettings']),
        eBookModalActive() {
            return process.env.V2_BASE_URL !== 'https://www.c3controls.com'
                ? this.noticeSettings?.eBookModal?.eBookModalActiveDev
                : this.noticeSettings?.eBookModal?.eBookModalActive
        },
        messageModalActive() {
            return process.env.V2_BASE_URL !== 'https://www.c3controls.com'
                ? this.noticeSettings?.websiteNotice?.messageModalActiveDev
                : this.noticeSettings?.websiteNotice?.messageModalActive
        },
        maintenanceModalActive() {
            return process.env.V2_BASE_URL !== 'https://www.c3controls.com'
                ? this.noticeSettings?.maintenanceNoticeDev
                : this.noticeSettings?.maintenanceNotice
        },
        eBookPaperTurnId() {
            return this.noticeSettings?.eBookModal?.eBookPaperturnId
        },
        eBookModalName() {
            return this.noticeSettings?.eBookModal?.eBookModalName
        },
        eBookImageLink() {
            return this.noticeSettings?.eBookModal?.eBookImage
        },
        sanityEBookImage() {
            return this.noticeSettings?.eBookModal?.sanityEBookImage
        },
        eBookCtaLink() {
            return this.noticeSettings?.eBookModal?.eBookCtaLink
        },
        showPopup() {
            return (
                this.eBookModalActive &&
                !this.isCookieSet &&
                !this.isPaperTurnCookieSet &&
                !this.messageModalActive &&
                !this.maintenanceModalActive
            )
        },
        isCentered() {
            return this.content.textAlign === 'center'
        },
        isRightAlign() {
            return this.content.textAlign === 'right'
        },
        imgWidth() {
            return '600px'
        },
        img() {
            // console.log(this.image)
            return this.image ? setCloudinaryData(this.image, 'column') : {}
        },
    },
    methods: {
        getCookie() {
            const cookieValue = Cookies.get('eBookModal')
            const eBookPaperTurnCookie = Cookies.get(
                `lead_form_sent_${this.eBookPaperTurnId}`
            )
            if (cookieValue || eBookPaperTurnCookie) {
                if (cookieValue === this.eBookModalName) this.isCookieSet = true
                else if (eBookPaperTurnCookie) this.isPaperTurnCookieSet = true
                else {
                    Cookies.remove('eBookModal')
                    this.isCookieSet = false
                    this.isPaperTurnCookieSet = false
                }
            } else {
                this.isCookieSet = false
                this.isPaperTurnCookieSet = false
            }
        },
        setCookie() {
            Cookies.set('eBookModal', this.eBookModalName, {
                expires: 15,
            })
            this.isCookieSet = true
        },
        renderUrl({ item, lang, nuxt }) {
            return renderUrl({ item, lang, nuxt })
        },
    },
}
</script>
