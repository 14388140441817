import { getField, updateField } from 'vuex-map-fields'
export const state = () => ({
    textAreaValues: '',
    formType: '1',
    textFieldValues: [
        {
            pn: '',
            qty: '',
        },
        {
            pn: '',
            qty: '',
        },
        {
            pn: '',
            qty: '',
        },
        {
            pn: '',
            qty: '',
        },
        {
            pn: '',
            qty: '',
        },
    ],
    availableBulkOrderItems: [],
    unAvailableItems: [],
    dialog: false,
    stepperValue: 1,
    addToCart: false,
    pnsWithIncorrectProdQty: [],
})
export const actions = {
    async fetchBulkOrderItemsFromElastic(
        { dispatch, commit, getters, rootGetters },
        payload
    ) {
        const res = await this.$axios.post('elastic-search', {
            elasticQuery: {
                size: 999,
                query: {
                    terms: {
                        pn: getters.selectedPns,
                    },
                },
            },
            elasticIndex: 'products-sanity',
        })
        const items = res.data.hits.hits.map((item) => item._source)
        const filteredItems = await dispatch('removeDuplicates', {
            items,
            pn: 'pn',
        })
        const filteredItemsWithAvailableFlag = filteredItems.map((item) => {
            return {
                ...item,
                isAvailable: true,
            }
        })
        commit('setAvailableBulkOrderItems', filteredItemsWithAvailableFlag)
    },
    replaceCartItemsWithBulkOrderItems(
        { state, commit, rootGetters, dispatch, getters },
        payload
    ) {
        const bulkOrderItems = state.availableBulkOrderItems.map((item) => {
            const prodQuantity = parseInt(
                getters.selectedItems
                    .find((i) => {
                        return i.split(',')[0].toUpperCase() === item.pn
                    })
                    .split(',')[1]
            )
            const prodQuantityCeiling = getCeilValue(prodQuantity, item.pkgqty)
            if (prodQuantity !== prodQuantityCeiling) {
                commit('addPnsWithIncorrectProdQty', item.pn)
            }
            return {
                ...item,
                prod_quantity: prodQuantityCeiling,
            }
        })
        dispatch('cart/replaceCart', bulkOrderItems, {
            root: true,
        })
        dispatch('cart/updateErpCart', null, { root: true })
        commit('clearFieldValues')
    },
    appendBulkOrderItemsToCart(
        { state, commit, rootGetters, dispatch, getters, rootState },
        payload
    ) {
        const intersectionItems = state.availableBulkOrderItems
            .filter((bulkItem) => {
                return rootState.cart.cartProducts.some(
                    (cartItem) => bulkItem.pn === cartItem.pn
                )
            })
            .map((bulkItem) => {
                const pn = bulkItem.pn
                const orderPadQuantity = parseInt(
                    getters.selectedItems
                        .find((i) => {
                            return i.split(',')[0].toUpperCase() === bulkItem.pn
                        })
                        .split(',')[1]
                )
                const prodQuantityCeiling = getCeilValue(
                    orderPadQuantity,
                    bulkItem.pkgqty
                )
                if (orderPadQuantity !== prodQuantityCeiling) {
                    commit('addPnsWithIncorrectProdQty', pn)
                }
                return {
                    pn,
                    orderPadQuantity: prodQuantityCeiling,
                }
            })
        let exclusiveOrderPadItems = []
        if (intersectionItems.length > 0) {
            commit('cart/addQuantitiesOfExistingCartItems', intersectionItems, {
                root: true,
            })
            exclusiveOrderPadItems =
                intersectionItems.length ===
                state.availableBulkOrderItems.length
                    ? []
                    : state.availableBulkOrderItems.filter((item) => {
                          const isFound = intersectionItems.find(
                              (i) => i.pn === item.pn
                          )
                          return !isFound
                      })
        } else {
            exclusiveOrderPadItems = state.availableBulkOrderItems
        }
        if (exclusiveOrderPadItems.length > 0) {
            const bulkOrderItems = exclusiveOrderPadItems.map((item) => {
                const orderPadQuantity = parseInt(
                    getters.selectedItems
                        .find((i) => {
                            return i.split(',')[0].toUpperCase() === item.pn
                        })
                        .split(',')[1]
                )

                const prodQuantityCeiling = getCeilValue(
                    orderPadQuantity,
                    item.pkgqty
                )
                if (orderPadQuantity !== prodQuantityCeiling) {
                    commit('addPnsWithIncorrectProdQty', item.pn)
                }
                return {
                    ...item,
                    prod_quantity: prodQuantityCeiling,
                }
            })
            dispatch('cart/appendCart', bulkOrderItems, { root: true })
            dispatch('cart/updateErpCart', null, { root: true })
        }
        commit('clearFieldValues')
    },
    setUnavailableItems(
        { state, commit, rootGetters, dispatch, getters, rootState },
        payload
    ) {
        const unAvailableItems = getters.selectedPns.filter(
            (item) =>
                !state.availableBulkOrderItems.map((i) => i.pn).includes(item)
        )
        commit('setUnavailableItems', unAvailableItems)
    },
    removeDuplicates(
        { state, commit, rootGetters, dispatch, getters, rootState },
        payload
    ) {
        return new Promise((resolve, reject) => {
            const filteredArray = payload.items.filter((obj, pos, arr) => {
                return (
                    arr
                        .map((mapObj) => obj.pn && mapObj[payload.pn])
                        .indexOf(obj[payload.pn]) === pos
                )
            })
            resolve(filteredArray)
        })
    },
}
export const mutations = {
    updateBulkOrderPadField(state, field) {
        updateField(state, field)
    },
    addTextFieldRow(state) {
        state.textFieldValues.push({
            pn: '',
            qty: '',
        })
    },
    setAvailableBulkOrderItems(state, payload) {
        state.availableBulkOrderItems = payload
    },
    setUnavailableItems(state, payload) {
        state.unAvailableItems = payload
    },
    setDialog(state, payload) {
        state.dialog = payload
    },
    clearFieldValues(state, payload) {
        state.textAreaValues = ''
        for (let i = 0; i < state.textFieldValues.length; i++) {
            state.textFieldValues[i].pn = ''
            state.textFieldValues[i].qty = ''
        }
    },
    setStepperValue(state, payload) {
        state.stepperValue = payload
    },
    addPnsWithIncorrectProdQty(state, payload) {
        if (!state.pnsWithIncorrectProdQty.includes(payload)) {
            state.pnsWithIncorrectProdQty = [
                ...state.pnsWithIncorrectProdQty,
                payload,
            ]
        }
    },
    EmptyPnsWithIncorrectProdQty(state, payload) {
        state.pnsWithIncorrectProdQty = payload
    },
}
export const getters = {
    getBulkOrderPadField(state) {
        return getField(state)
    },
    selectedItems(state, getters, rootState, rootGetters) {
        if (state.formType === '1') {
            return state.textFieldValues
                .map((item) => {
                    if (Object.values(item)[0] && Object.values(item)[1]) {
                        return `${Object.values(item)[0]
                            .trim()
                            .toUpperCase()},${Object.values(item)[1]}`
                    }
                })
                .filter((item) => item)
        } else {
            return state.textAreaValues ? state.textAreaValues.split('\n') : []
        }
    },
    selectedPns(state, getters, rootState, rootGetters) {
        return getters.selectedItems.length > 0
            ? getters.selectedItems.map((item) =>
                  item.split(',')[0].trim().toUpperCase()
              )
            : []
    },
    lineByLineValues(state, getters, rootState, rootGetters) {
        if (state.textFieldValues.length > 0) {
            return state.textFieldValues
                .map((item) => {
                    if (Object.values(item)[0] && Object.values(item)[1]) {
                        return `${Object.values(item)[0]
                            .trim()
                            .toUpperCase()},${Object.values(item)[1]}`
                    }
                })
                .filter((item) => item)
        } else {
            return []
        }
    },
}

function getCeilValue(prodQuantity, range) {
    const remainder = prodQuantity % range
    if (remainder !== 0) {
        return prodQuantity - remainder + range
    } else {
        return prodQuantity
    }
}
