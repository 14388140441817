<template>
    <div class="d-flex">
        <SubscriptionTag />
        <v-badge
            :top="true"
            :color="fetchCartProducts.length > 0 ? 'primary' : 'transparent'"
            :right="true"
            :overlap="false"
            offset-x="18px"
            offset-y="18px"
            class="align-self-center mr-0 mr-md-2 d-lg-flex cart-badge--default"
        >
            <template v-slot:badge>
                <span
                    :title="
                        localize('1DifferentProductsForATotalQuantityOf2', [
                                                    {
                                                        var: `${fetchCartProducts.length}`,
                                                    },
                                                    {
                                                        var: `${fetchCartQty}`,
                                                    },
                                                ])
                    "
                    >{{
                        fetchCartQty && fetchCartProducts.length > 0
                            ? `${fetchCartProducts.length}`
                            : ''
                    }}</span
                >
            </template>
            <v-btn icon class="btn--light">
                <nuxt-link
                    :to="
                        getStaticLocalePath({
                            type: 'page',
                            slugName: 'cart'
                        })
                    "
                >
                    <div class="menu-icon-wrapper ">
                        <fa :icon="['fal', 'shopping-cart']" class="fa-lg" />
                    </div>
                    <span class="menu-icon-subtext">
                        <TranslatableStringComponent :tstring="{string: 'cart'}" />
                    </span>
                </nuxt-link>
            </v-btn>
        </v-badge>
        <v-badge
            :top="true"
            :color="
                false && fetchCartProducts.length === 5
                    ? 'primary'
                    : 'transparent'
            "
            :right="true"
            :overlap="false"
            offset-x="18px"
            offset-y="18px"
            class="align-self-center mr-0 mr-md-2 d-lg-flex cart-badge--default"
        >
            <template v-slot:badge>
                <!-- <span
                    :title="
                        `${fetchCartProducts.length} different products for a total quantity of ${fetchCartQty}.`
                    "
                    >{{
                        fetchCartQty && fetchCartProducts.length > 0
                            ? `${fetchCartProducts.length}`
                            : ''
                    }}</span
                > -->
            </template>
            <v-btn icon class="btn--light">
                <nuxt-link
                    :to="
                        getStaticLocalePath({
                            type: 'account',
                            slugName: 'dashboard'
                        })
                    "
                >
                    <div class="menu-icon-wrapper" router exact>
                        <fa :icon="['fal', 'user']" class="fa-lg" />
                    </div>
                    <span class="menu-icon-subtext">
                        <TranslatableStringComponent :tstring="{string: 'account'}" />
                    </span>
                </nuxt-link>
            </v-btn>
        </v-badge>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
import SubscriptionTag from '~/components/SubscriptionTag.vue'
export default {
    name: 'MenuBadges',
    components: {
        TranslatableStringComponent,
        SubscriptionTag,
    },
    computed: {
        ...mapGetters({
            fetchCartProducts: 'cart/fetchCartProducts',
            fetchCartQty: 'cart/fetchCartQty'
        })
    }
}
</script>

<style></style>
