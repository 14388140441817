import { render, staticRenderFns } from "./EmbedInternalLink.vue?vue&type=template&id=48d8ef8b&scoped=true"
import script from "./EmbedInternalLink.vue?vue&type=script&lang=js"
export * from "./EmbedInternalLink.vue?vue&type=script&lang=js"
import style0 from "./EmbedInternalLink.vue?vue&type=style&index=0&id=48d8ef8b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d8ef8b",
  null
  
)

export default component.exports