export default {
    methods: {
        hitSnackbar(msg, auth, color, timeout) {
            // console.log('SNACKBAR WAS HIT:', msg)
            // const authMessages = ['loggedInSuccess', 'loggedInAlready']
            // let output = msg
            // if (authMessages.includes(msg)) {
            //     const name =
            //         auth && auth.currentUser && auth.currentUser.user_metadata
            //             ? auth.currentUser.user_metadata.full_name
            //             : ''
            //     if (msg === 'loggedInSuccess') {
            //         // Successfully Logged In
            //         const personalMessage = name
            //             ? 'Welcome, ' + name + '!'
            //             : 'Welcome!'
            //         output = 'You are logged in. ' + personalMessage
            //     } else if (msg === 'loggedInAlready') {
            //         // Already Logged In
            //         const personalMessage = name
            //             ? 'Hello, ' + name + '!'
            //             : 'Welcome!'
            //         output = personalMessage + ' You are already logged in.'
            //     }
            // } else {
            //     output = msg
            // }
            this.$store.commit('snackbar/SET_CURRENT_MESSAGE', msg)
            this.$store.commit('snackbar/SET_CURRENT_COLOR', color)
            this.$store.commit('snackbar/SET_CURRENT_TIMEOUT', timeout)
            this.$store.commit('snackbar/OPEN_SNACKBAR')
            this.$store.dispatch('snackbar/GET_READY_TO_CLEAR_SNACKBAR')
        },
        closeSnackbar() {
            this.$store.dispatch('snackbar/CLEAR_SNACKBAR')
            // console.log('SNACKBAR should have been closed just now.')
        }
    },
    computed: {}
}
