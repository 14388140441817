// Yer gonna need some documentation...
// Using nuxtServerInit to prepopulate data https://github.com/nuxt/nuxt.js/issues/1740
// Using vuex-persistedstate with js-cookie: https://codesandbox.io/s/q8vv6nym49?from-embed
// js-cookie https://github.com/js-cookie/js-cookie
// local storage vs cookies https://stackoverflow.com/questions/3220660/local-storage-vs-cookies

export const state = () => ({
    testCount: 0,
    authUser: null
})
export const getters = {}
export const mutations = {
    increment: (state) => state.testCount++,
    decrement: (state) => state.testCount--
}
export const actions = {
    // in production, this only occurs once (for each page) at build time
    async nuxtServerInit({ dispatch }, { req, route }) {
        await dispatch('common/init')
        // if (route.path === '/' || route.path.includes('products')) {
        //     await dispatch('categories/init')
        //     await dispatch('products/init')
        // }
    }
}
