import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
    sampleRequestForm: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        jobtitle: '',
        hs_persona: '',
        company: '',
        business_focus_up: '',
        spec_point_person_up: '',
        primary_electrical_controls_supplier_up: '',
        annual_electrical_controls_spend_up: '',
        numemployees: '',
        country: 'United States',
        address: '',
        street_address_2: '',
        city: '',
        state: '',
        zip: '',
    },
})

export const actions = {}

export const mutations = {
    updateSampleRequestField(state, field) {
        updateField(state.sampleRequestForm, field)
    },
    clearFields(state, payload) {
        state.sampleRequestForm.state = ''
        state.sampleRequestForm.city = ''
        state.sampleRequestForm.zip = ''
    },
}

export const getters = {
    getSampleRequestField(state) {
        return getField(state.sampleRequestForm)
    },
}
