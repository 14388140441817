export const state = () => ({
    priceList: [],
    activePriceListIds: [],
    appliedPriceList: [],
    selectedPriceListId: '',
})
export const actions = {
    async fetchPriceListEntries({ commit, rootGetters }, payload) {
        try {
            const response = await this.$axios.post(
                'erp-pricelistentries?method=read',
                {
                    priceListName: rootGetters['auth/priceList'] || 'OEM',
                }
            )
            commit('setPriceListEntries', response.data.priceListEntries)
        } catch (error) {
            console.error(error)
        }
    },
    async fetchPriceListNames({ commit }) {
        try {
            const response = await this.$axios.post('erp-pricelist?method=read')
            console.log(response.data.priceLists.map((i) => i.name))
            commit(
                'setActivePriceListIds',
                response.data.priceLists.map((i) => i.name)
            )
        } catch (error) {
            console.error(error)
        }
    },
    async fetchAppliedPriceList({ commit, state, rootGetters }, payload) {
        try {
            const response = await this.$axios.post(
                'erp-pricelistentries?method=read',
                {
                    priceListName: state.selectedPriceListId,
                }
            )
            commit('setAppliedPriceList', response.data.priceListEntries)
        } catch (error) {
            console.error(error)
        }
    },
}
export const mutations = {
    setPriceListEntries(state, payload) {
        state.priceList = payload
    },
    setActivePriceListIds(state, payload) {
        state.activePriceListIds = payload
    },
    setAppliedPriceList(state, payload) {
        state.appliedPriceList = payload
    },
    setSelectedPriceListId(state, payload) {
        state.selectedPriceListId = payload
    },
    initPriceList(state) {
        if (localStorage.getItem('c3PriceList')) {
            const localPriceList = JSON.parse(
                localStorage.getItem('c3PriceList')
            )
            for (const key in localPriceList) {
                if (state.hasOwnProperty(key)) {
                    state[key] = localPriceList[key]
                }
            }
        }
    },
}
export const getters = {
    cartPriceList(state, getters, rootState, rootGetters) {
        if (state.appliedPriceList.length > 0) {
            return state.appliedPriceList
        } else {
            return state.priceList
        }
    },
    cartPriceListId(state, getters, rootState, rootGetters) {
        if (rootGetters['auth/isAuthor']) {
            return (
                state.selectedPriceListId ||
                rootGetters['auth/priceList'] ||
                'OEM'
            )
        } else {
            return (
                state.selectedPriceListId ||
                rootGetters['auth/priceList'] ||
                'OEM'
            )
        }
    },
    isPriceListMismatched(state, getters, rootState, rootGetters) {
        const defaultPriceList = rootGetters['auth/priceList'] || 'OEM'
        if (state.selectedPriceListId) {
            return state.selectedPriceListId !== defaultPriceList
        } else {
            return false
        }
    },
}
