<template>
    <a :href="tel" :class="customClass">{{ text }}</a>
</template>
<script>
import embedLinks from '~/mixins/embedLinks'
export default {
    name: 'EmbedPhoneLink',
    props: {
        phoneLink: {
            type: Object,
            default: null
        }
    },
    mixins: [embedLinks],
    computed: {
        tel() {
            return this.phoneLink && this.phoneLink.linkTo
                ? `tel:+${this.phoneLink.linkTo}`
                : '#'
        }
    },
}
</script>
<style scoped>
.strong {
    font-weight: bold;
}
.italic {
    font-style: italic;
}
.underline {
    text-decoration: underline;
}
.strike-through {
    text-decoration: line-through;
}
</style>
