<template>
    <div class="eBook-wrapper">

        <iframe :src="url" 
            width="100%" 
            :height="height" 
            webkitallowfullscreen mozallowfullscreen allowfullscreen 
            :style="`border: 0px; max-width: ${maxWidth} ; display: block; background-color: #FFFFFF`">
        </iframe>
    </div>
</template>
<script>
export default {
    name: 'EmbedEbook',
    props: {
        url: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: '408'
        },
        maxWidth: {
            type: String,
            default: '100%'
        }
    }
}
</script>
