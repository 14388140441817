var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{class:{
        'nav-search--full-width': _vm.$vuetify.breakpoint.smAndDown,
        'nav-search--half-width': _vm.$vuetify.breakpoint.mdAndUp,
    },attrs:{"id":"nav-search"}},[_c('v-autocomplete',{ref:"autocomplete",class:{
            'nav-input--md-and-down': _vm.$vuetify.breakpoint.mdAndDown,
            'nav-input--lg-and-up': _vm.$vuetify.breakpoint.lgAndUp,
        },attrs:{"items":_vm.items,"search-input":_vm.search,"loading":_vm.isLoading,"item-text":"label","flat":"","outlined":"","dense":"","light":"","hide-details":"","label":_vm.localize('enterPnOrKw'),"return-object":"","no-filter":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearchSubmission($event)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('fa',{staticClass:"fa-lg",attrs:{"icon":['fal', 'search']}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_c('TranslatableStringComponent',{attrs:{"tstring":{ string: 'searchSuggestions' }}}),_vm._v(":\n                        ")],1),_vm._v(" "),_c('ul',{staticClass:"mb-0"},[_c('li',[_c('TranslatableStringComponent',{attrs:{"tstring":{
                                        string: 'checkYourSlashesAndDashes',
                                    }}})],1),_vm._v(" "),_c('li',[_c('TranslatableBlockComponent',{attrs:{"tstring":{
                                        string: 'tryShopPageToSearch',
                                    }}})],1)])],1)],1)],1)]},proxy:true},{key:"item",fn:function(data){return [[(data.item.contentType === 'flag')?_c('v-list-item-content',[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(data.item.text))]),_vm._v(" "),_c('v-divider')],1):(data.item.contentType === 'contents')?[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.title)}}),_vm._v(" "),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                                ("" + (data.item.subtitle
                                        ? data.item.subtitle
                                        : ''))
                            )}})],1)]:(data.item.contentType === 'products')?[_c('v-list-item-avatar',[_c('img',{attrs:{"src":"https://img.c3controls.com/c3controls-icon.jpg?h=80&fit=fill"}})]),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.title)}}),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v(_vm._s(data.item.subtitle))])],1)]:_vm._e()]]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('fa',{staticClass:"fa-lg",attrs:{"slot":"append","icon":['fal', 'search']},slot:"append"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }