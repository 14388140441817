export const state = () => ({
    isResetForm: false
})

export const actions = {
    resetForm({ commit }, payload) {
        commit('resetForm', payload)
    }
}

export const mutations = {
    resetForm(state, payload) {
        state.isResetForm = payload
    }
}

export const getters = {}
