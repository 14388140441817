export const state = () => ({
    primaryContact: null,
    primaryContactId: null,
})
export const actions = {}
export const mutations = {
    setNewPrimaryContact(state, payload) {
        let contactName, printAsName
        let { nameCompany, addressCity, nameFirst, nameLast } = payload
        if (nameCompany) {
            // console.log('company name is present')
            contactName = `${nameCompany} - ${addressCity} (W${Math.floor(
                Date.now() / 1000
            )})`
            printAsName = nameCompany
        } else {
            // console.log('company not present')
            contactName = `${nameFirst} ${nameLast} - ${addressCity} (W${Math.floor(
                Date.now() / 1000
            )})`
            printAsName = `${nameFirst} ${nameLast}`
        }
        state.primaryContact = {
            ...payload,
            name: contactName,
            namePrintAs: printAsName,
            createdBy: 'Web User',
            updatedBy: 'Web User',
        }
    },
    setPrimaryContactIdForCustomer(state, payload) {
        state.primaryContactId = payload
    },
}
export const getters = {
    getCustomerPrimaryContactQuery(state, getters) {
        if (state.primaryContactId) {
            return {
                connect: {
                    id: state.primaryContactId,
                },
            }
        } else {
            return {
                create: state.primaryContact,
            }
        }
    },
    getCustomerPrimaryContact(state, getters, rootState) {
        if (state.primaryContactId) {
            return rootState.checkout.billingFormArray.find(
                (item) => item.id === state.primaryContactId
            )
        } else {
            return state.primaryContact
        }
    },
    getCustomerName(state, getters, rootState) {
        if (getters.getCustomerPrimaryContact.nameCompany) {
            return getters.getCustomerPrimaryContact.nameCompany
        } else {
            return `${getters.getCustomerPrimaryContact.nameFirst} ${getters.getCustomerPrimaryContact.nameLast}`
        }
    },
}
