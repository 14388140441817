export const state = () => ({
    queryOptions: [
        {
            label: 'General question/feedback',
            value: 'general'
        },
        { label: 'Subscribe to newsletter', value: 'newsletter' },
        { label: 'Get a free sample', value: 'sample' },
        { label: 'Website issues/feedback', value: 'website' },
        { label: 'Product suggestion', value: 'product' },
        { label: 'Request a quote', value: 'rfq' },
        { label: 'Technical question', value: 'technical' },
        {
            label: 'Have a salesperson contact me',
            value: 'sales'
        },
        { label: 'Return a product', value: 'return' },
        { label: 'Order status', value: 'status' },
        {
            label: 'Question about an invoice',
            value: 'invoice'
        },
        { label: 'Apply for credit', value: 'credit' },
        {
            label: 'How are we doing? Let us know!',
            value: 'nps'
        },
        { label: 'Other', value: 'other' }
    ],
    inquiryOptions: [
        {
            label: 'Definite Purpose Contactors',
            value: 'Definite Purpose Contactors'
        },
        {
            label: 'Miniature Circuit Breakers',
            value: 'Miniature Circuit Breakers'
        },
        {
            label: 'High Density Terminal Blocks',
            value: 'High Density Terminal Blocks'
        },
        { label: 'Other Products', value: 'Other Products' }
    ],
    alpsInquiryOptions: [
        {
            label: 'Enclosed Power Supplies',
            value: 'Enclosed Power Supplies'
        },
        {
            label: 'Motor Control',
            value: 'Motor Control'
        },
        {
            label: 'Miniature Circuit Breakers',
            value: 'Miniature Circuit Breakers'
        },
        {
            label: 'Pilot Devices',
            value: 'Pilot Devices'
        },
        {
            label: 'Terminal Blocks',
            value: 'Terminal Blocks'
        },
        {
            label: 'Terminal Block Relays',
            value: 'Terminal Block Relays'
        },
        {
            label: 'General Purpose Relays',
            value: 'General Purpose Relays'
        },
        {
            label: 'Wire / Cable Duct',
            value: 'Wire / Cable Duct'
        }
    ],
    jobRoleOptions: [
        { label: '- select -', value: '' },
        { label: 'Owner', value: 'Owner' },
        { label: 'Engineering', value: 'Engineering' },
        { label: 'Purchasing', value: 'Purchasing' },
        { label: 'Operations', value: 'Operations' },
        { label: 'Technician', value: 'Technician' },
        { label: 'Administrator', value: 'Administrator' },
        { label: 'Finance / Accounting', value: 'Finance / Accounting' },
        { label: 'Sales / Marketing', value: 'Sales / Marketing' },
        { label: 'Distributor', value: 'Distributor' },
        { label: 'Other', value: 'Other' }
    ],
    businessFocusOptions: [
        { label: '- select -', value: '' },
        { label: 'OEM', value: 'OEM' },
        {
            label: 'Panel Builder-System Integrator',
            value: 'Panel Builder-System Integrator'
        },
        { label: 'EPC', value: 'EPC' },
        { label: 'Distributor', value: 'Distributor' },
        { label: 'Contractor', value: 'Contractor' },
        { label: 'User', value: 'User' },
        { label: 'Other', value: 'Other' }
    ],
    controlSpecificationOptions: [
        { label: '- select -', value: '' },
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' }
    ],
    primarySupplierOptions: [
        { label: '- select -', value: '' },
        { label: 'c3controls', value: 'c3controls' },
        { label: 'ABB', value: 'ABB' },
        { label: 'Allen Bradley', value: 'Allen Bradley' },
        { label: 'AutomationDirect', value: 'AutomationDirect' },
        { label: 'Eaton Cutler-Hammer', value: 'Eaton Cutler-Hammer' },
        { label: 'GE', value: 'GE' },
        { label: 'IDEC', value: 'IDEC' },
        {
            label: 'Schneider Electric (Square D / Telemecanique)',
            value: 'Schneider Electric (Square D / Telemecanique)'
        },
        { label: 'Siemens', value: 'Siemens' },
        { label: 'Other', value: 'Other' }
    ],
    companyElectricalExpenditureOptions: [
        { label: '- select -', value: '' },
        { label: '> $500,000', value: '> $500,000' },
        { label: '> $250,000-$499,999', value: '> $250,000-$499,999' },
        { label: '> $100,000-$249,999', value: '> $100,000-$249,999' },
        { label: '> $50,000-$99,999', value: '> $50,000-$99,999' },
        { label: '< $49,999', value: '< $49,999' }
    ],
    employeesOptions: [
        { label: '- select -', value: '' },
        { label: '200+', value: '200+' },
        { label: '100-199', value: '100-199' },
        { label: '50-99', value: '50-99' },
        { label: '10-49', value: '10-49' },
        { label: '< 10', value: '< 10' }
    ],
    jobRoles: [
        { label: '- select -', value: '' },
        { label: 'Owner', value: 'Owner' },
        { label: 'Engineering', value: 'Engineering' },
        { label: 'Purchasing', value: 'Purchasing' },
        { label: 'Operations', value: 'Operations' },
        { label: 'Technician', value: 'Technician' },
        { label: 'Administrator', value: 'Administrator' },
        { label: 'Finance / Accounting', value: 'Finance / Accounting' },
        { label: 'Sales / Marketing', value: 'Sales / Marketing' },
        { label: 'Distributor', value: 'Distributor' },
        { label: 'Other', value: 'Other' }
    ],
    devFormQueryOptions: [
        { label: 'Bug Report', value: 'bug' },
        { label: 'Suggestion', value: 'suggestion' },
        { label: 'Question', value: 'question' },
        { label: 'Other', value: 'other' }
    ]
})
