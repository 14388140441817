var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"column":"","justify-center":"","align-center":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.isAccountPage)?_c('TranslatableBlockComponent',{attrs:{"tstring":{string: 'formCustomerConnectDialogAccountPage'}}}):_c('TranslatableBlockComponent',{attrs:{"tstring":{string: 'formCustomerConnectDialog'}}})],1),_vm._v(" "),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"customerConnectForm",attrs:{"id":"customerConnectForm","data-netlify":"false","name":"customerConnectForm"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleSubmission)}}},[_c('input',{attrs:{"id":"form-name","type":"hidden","name":"form-name","value":"customerConnectForm"}}),_vm._v(" "),_c('v-container',{staticClass:"contact-form--wrapper pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"column":"","justify-center":"","align-center":""}},[_c('v-col',{class:{
                                    'pr-0 pt-0':
                                        _vm.$vuetify.breakpoint.mdAndUp,
                                    'pb-0': true,
                                },attrs:{"cols":"12","md":"9"}},[_c('ValidationProvider',{staticClass:"validationWrapper",attrs:{"name":"customerId","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return _c('div',{},[_c('span',{staticClass:"validationMessage"},[_vm._v("\n                                            "+_vm._s(errors[0])+"\n                                        ")]),_vm._v(" "),_c('v-text-field',{attrs:{"id":"customerId","name":"customerId","label":"Customer ID","outlined":"","dense":"","hide-details":true},model:{value:(_vm.customerId),callback:function ($$v) {_vm.customerId=_vm._n($$v)},expression:"customerId"}})],1)}}],null,true)})],1),_vm._v(" "),_c('v-col',{class:{
                                    'pr-0 pt-0':
                                        _vm.$vuetify.breakpoint.mdAndUp,
                                    'pb-0': true,
                                    'pl-2': true,
                                },attrs:{"cols":"12","md":"3"}},[_c('v-btn',{attrs:{"type":"submit","color":"primary","right":"","loading":_vm.loading}},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'connect'}}})],1)],1),_vm._v(" "),(_vm.loading)?_c('v-row',{staticClass:"ml-4"},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'pleaseWaitThisMayTakeSomeTime'}}}),_vm._v("...")],1):_vm._e(),_vm._v(" "),(_vm.error && !_vm.loading)?_c('span',{staticClass:"ml-4"},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'formCustomerConnectPart4'}}})],1):_vm._e()],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }