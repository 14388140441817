const currentVersion = localStorage.getItem('c3_HASH')
const newVersion = process.env.HASH

export default async ({ store }) => {
    if (currentVersion !== newVersion) {
        store.getters['auth/currentUser'] &&
            (await store.dispatch('auth/attemptLogout'))
        localStorage.clear()
        localStorage.setItem('c3_HASH', newVersion)
    }
}
