import fetchSchedulePrice from '~/util/fetchSchedulePriceNew'
import fetchSalePrice from '~/util/fetchSalePrice'
import fetchTaxableNexusObj from '~/util/fetchTaxableNexusObj'
import shippingZones from '~/util/arrays/shippingZones'
import roundToTwo from '~/util/roundToTwo'
export const state = () => ({
    cartProducts: [],
    selectedCarrierMethod: 'default',
    selectedShippingMethod: 'ground',
    internationalShippingMethod: '',
    tariffPercentage: 0.25,
    below_this_needs_review: true,
    discountRates: [],
    shipperNumber: '',
    carrierVendorName: '',
    carrierMethods: ['Default', 'Bill to My Carrier'],
    unavailableProductList: [],
    availibilityChecked: false,
    customerCreateResponseId: null,
    singleProductAvailabilityInElastic: [],
})

export const actions = {
    removeItem({ dispatch, commit, getters, rootGetters }, payload) {
        commit('removeItem', payload)
        commit('customProducts/remove', payload, { root: true })
        dispatch(
            'realTimeInventoryData/removeItemFromCartProductsAvailability',
            [payload],
            { root: true }
        )
        if (rootGetters['abandonedCartNew/isAbandonedCartPresent']) {
            if (getters.fetchCartProducts.length > 0)
                dispatch('abandonedCartNew/updateErpCart', null, {
                    root: true,
                })
            else
                dispatch('abandonedCartNew/deleteErpCart', null, {
                    root: true,
                })
        }
    },
    incrementQty({ dispatch, commit, state, rootGetters }, payload) {
        commit('incrementQty', payload)
        dispatch('debounceErpQtyUpdate')
    },
    decrementQty({ dispatch, commit, state, rootGetters }, payload) {
        commit('decrementQty', payload)
        dispatch('debounceErpQtyUpdate')
    },
    setQty({ dispatch, commit, state, rootGetters }, payload) {
        commit('setQty', payload)
        dispatch('debounceErpQtyUpdate')
    },
    debounceErpQtyUpdate({ dispatch, commit, state, rootGetters }, payload) {
        if (rootGetters['auth/isLoggedIn']) {
            if (rootGetters['abandonedCartNew/isAbandonedCartPresent'])
                dispatch('abandonedCartNew/debounceQtyUpdate', null, {
                    root: true,
                })
        }
    },
    updateCart({ commit, state, rootGetters, dispatch }, payload) {
        commit('addToCart', payload)
        dispatch('updateErpCart')
    },
    appendCart({ commit, state, rootGetters, dispatch }, payload) {
        commit('appendCartProducts', payload)
    },
    replaceCart({ commit, state, rootGetters, dispatch }, payload) {
        commit('replaceCartProducts', payload)
    },
    async updateErpCart({ commit, state, rootGetters, dispatch }, payload) {
        if (rootGetters['abandonedCartNew/isAbandonedCartPresent'])
            dispatch('abandonedCartNew/updateErpCart', null, {
                root: true,
            })
        else {
            await dispatch('auth/fetchWebUser', null, { root: true })
            dispatch('abandonedCartNew/setUserCart', null, {
                root: true,
            })
        }
    },
    updateCarrier({ commit }, payload) {
        commit('updateSelectedCarrierOptions', payload)

        if (payload === 'default') {
            commit('updateCarrierVendorName', '')
            commit('updateShipperNumber', '')
        }
    },
    updateShipping({ commit, getters }, payload) {
        commit('updateSelectedShippingMethod', payload)
    },
    updateInternationalShippingMethod({ commit, getters }, payload) {
        commit('updateInternationalShippingMethod', payload)
    },
    emptyCart({ commit }) {
        commit('emptyCart')
        commit('updateSelectedCarrierOptions', 'default')
        commit('updateSelectedShippingMethod', 'ground')
        commit('updateCarrierVendorName', '')
        commit('updateShipperNumber', '')
        dispatch('abandonedCartNew/updateErpCart', null, {
            root: true,
        })
    },
    async checkProductAvailibilityInElastic(
        { commit, state, dispatch },
        payload
    ) {
        const cartProductNumbers = payload.products.map((item) => item.pn)
        commit('checkingAvailibility', true)
        const res = await this.$axios.post('elastic-search', {
            elasticQuery: {
                size: 999,
                query: {
                    terms: {
                        pn: cartProductNumbers,
                    },
                },
            },
            elasticIndex: 'products-sanity',
        })
        // const elasticProductNumbers = res.data.hits.hits.map(
        //     (item) => item._source.pn
        // )
        // const nonExistingProducts = cartProductNumbers.filter(
        //     (item) => !elasticProductNumbers.includes(item)
        // )
        // commit('updateUnavailableProducts', nonExistingProducts)
        let tempProduct = payload.singleProduct
            ? payload.products
            : state.cartProducts
        const currentlyAvailableProducts = tempProduct.map((item) => {
            // 1st Round : Checking if product is available or not by PART NUMBER
            const cartProductAvailableInElastic = res.data.hits.hits.filter(
                (elasticItem) => item.pn === elasticItem._source.pn
            )
            if (cartProductAvailableInElastic.length > 0) {
                // 2nd Round : If product is available find product with similar HASH ID
                let productObj =
                    // 2nd Round : If product is available find product with similar HASH ID
                    cartProductAvailableInElastic.find(
                        (elasticItem) =>
                            elasticItem._source.product_id === item.product_id
                    ) ||
                    // 3nd Round : If no HASH ID found, find product with latest CREATED DATE
                    cartProductAvailableInElastic.reduce((prev, curr) =>
                        new Date(prev._source.createdDate) >
                        new Date(curr._source.createdDate)
                            ? prev
                            : curr
                    )
                productObj = productObj._source
                return {
                    ...productObj,
                    hash: item.hash,
                    prod_quantity: item.prod_quantity,
                    isAvailable: true,
                }
            } else {
                return { ...item, isAvailable: false }
            }
        })
        commit('checkingAvailibility', false)
        if (payload.singleProduct)
            commit(
                'setSingleProductAvailabilityInElastic',
                currentlyAvailableProducts
            )
        else {
            dispatch('replaceCart', currentlyAvailableProducts)
        }
    },
    clearCart({ state, commit, dispatch, rootGetters }) {
        dispatch(
            'realTimeInventoryData/removeItemFromCartProductsAvailability',
            state.cartProducts,
            { root: true }
        )
        if (rootGetters['checkout/shippingMethodException'])
            commit('clearCart', { selectedShippingMethod: 'air2day' })
        else commit('clearCart', { selectedShippingMethod: 'ground' })
    },
}

export const mutations = {
    addToCart(state, payload) {
        state.cartProducts = [
            ...state.cartProducts,
            { ...payload, prod_quantity: parseInt(payload.pkgqty) },
        ]
    },
    loadCart(state, payload) {
        state.cartProducts = payload
    },
    clearCart(state, payload) {
        state.cartProducts = []
        state.selectedCarrierMethod = 'default'
        state.selectedShippingMethod = payload.selectedShippingMethod
        state.shipperNumber = ''
        state.carrierVendorName = ''
        localStorage.removeItem('c3cart')
        // localStorage.clear()
    },
    removeItem(state, payload) {
        state.cartProducts = [...state.cartProducts].filter(
            (item) => item.pn !== payload.pn
        )
    },
    replaceCartProducts(state, payload) {
        state.cartProducts = payload
    },
    appendCartProducts(state, payload) {
        state.cartProducts = [...state.cartProducts, ...payload]
    },
    addQuantitiesOfExistingCartItems(state, payload) {
        state.cartProducts = [...state.cartProducts].map((item) => {
            const itemFound = payload.find((i) => {
                return i.pn === item.pn
            })
            if (itemFound) {
                item.prod_quantity += itemFound.orderPadQuantity
            }
            return item
        })
    },
    removeCartProductsByPns(state, payload) {
        state.cartProducts = [...state.cartProducts].filter(
            (item) => !payload.includes(item.pn)
        )
    },
    setQty(state, payload) {
        state.cartProducts = [...state.cartProducts].map((item) => {
            if (item.pn === payload.productObj.pn) {
                set()
                return item
            } else {
                return item
            }
            function set() {
                if (
                    payload.qty < payload.stepperQty ||
                    payload.qty % payload.stepperQty !== 0
                ) {
                    // TODO: improve this to use closest divisible integer instead of pkgqty
                    item.prod_quantity = parseInt(payload.stepperQty)
                } else {
                    item.prod_quantity = parseInt(payload.qty)
                }
            }
        })
    },
    incrementQty(state, payload) {
        state.cartProducts = [...state.cartProducts].map((item) => {
            if (item.pn === payload.pn) {
                item.prod_quantity += payload.stepperQty
                return item
            } else {
                return item
            }
        })
    },
    decrementQty(state, payload) {
        state.cartProducts = [...state.cartProducts].map((item) => {
            if (item.pn === payload.pn) {
                dec()
                return item
            } else {
                return item
            }
            function dec() {
                if (
                    item.prod_quantity - payload.stepperQty <=
                    payload.stepperQty
                ) {
                    item.prod_quantity = payload.stepperQty
                } else {
                    item.prod_quantity -= payload.stepperQty
                }
            }
        })
    },
    initLocalCart(state) {
        if (localStorage.getItem('c3cart')) {
            const localCart = JSON.parse(localStorage.getItem('c3cart'))
            for (const key in localCart) {
                if (state.hasOwnProperty(key)) {
                    state[key] = localCart[key]
                }
            }
            // state.cartProducts = localCart.cartProducts
            // state.selectedShippingMethod = localCart.selectedShippingMethod
            // state.selectedShippingMethodPrice =
            //     localCart.selectedShippingMethodPrice
            // state.correctShippingPrices = localCart.correctShippingPrices
            // state.selectedCarrierMethod = localCart.selectedCarrierMethod
        }
    },
    updateCarrierMethod(state, payload) {
        state.selectedCarrierMethod = payload
    },
    updateCarrierVendorName(state, payload) {
        state.carrierVendorName = payload
    },
    updateShipperNumber(state, payload) {
        state.shipperNumber = payload
    },
    updateSelectedCarrierOptions(state, payload) {
        state.selectedCarrierMethod = payload
    },
    updateSelectedShippingMethod(state, payload) {
        state.selectedShippingMethod = payload
    },
    updateInternationalShippingMethod(state, payload) {
        state.internationalShippingMethod = payload
    },
    emptyCart(state) {
        state.cartProducts = []
    },
    updateUnavailableProducts(state, payload) {
        state.unavailableProductList = payload
    },
    checkingAvailibility(state, payload) {
        state.availibilityChecked = payload
    },
    setCustomerCreateResponseId(state, payload) {
        state.customerCreateResponseId = payload
    },
    setSingleProductAvailabilityInElastic(state, payload) {
        state.singleProductAvailabilityInElastic = payload
    },
}

export const getters = {
    fetchCartProducts(state, getters) {
        return getters.appliedScheduledPriceProducts.map((cartItem) => {
            const discount = state.discountRates.find((discountItem) => {
                return cartItem.series === discountItem.series
            })

            const salePrice = roundToTwo(
                cartItem.sale_price * (discount ? discount.rate : 1)
            )

            // console.log('salePrice', salePrice)
            const subTotalSalePrice = roundToTwo(
                salePrice * cartItem.prod_quantity
            )
            // console.log('subTotalSalePrice', subTotalSalePrice)
            const subTotalListPrice = roundToTwo(
                cartItem.list_price * cartItem.prod_quantity
            )
            // let isAvailable = true
            // if (state.unavailableProductList.includes(cartItem.pn)) {
            //     isAvailable = false
            // }
            return {
                ...cartItem,
                salePrice,
                subTotalSalePrice,
                subTotalListPrice,
            }
        })
    },
    fetchAvailableCartProducts(state, getters) {
        return getters.fetchCartProducts.filter(
            (item) => item.isAvailable === true
        )
    },
    currentlyAvailableProducts(state, getters) {
        return [...state.cartProducts].map((item) => {})
    },
    appliedScheduledPriceProducts(state, getters, rootState, rootGetters) {
        return [...state.cartProducts].map((item) => {
            let salePrice
            if (rootGetters['priceList/cartPriceList'].length > 0) {
                const selectedSchedulePrice = fetchSchedulePrice(
                    rootGetters['priceList/cartPriceList'],
                    item
                )
                salePrice = roundToTwo(
                    selectedSchedulePrice &&
                        fetchSalePrice(selectedSchedulePrice, item.list_price)
                )
            }

            return {
                ...item,
                sale_price: salePrice,
            }
        })
    },
    fetchCartQty(state) {
        return state.cartProducts.reduce(function (prev, cur) {
            return prev + cur.prod_quantity
        }, 0)
    },
    fetchCartSubtotal(state, getters) {
        // CALCULATE SUBTOTAL HERE
        return roundToTwo(
            getters.fetchCartProducts.reduce((acc, item) => {
                if (item.isAvailable) {
                    return acc + roundToTwo(item.subTotalSalePrice)
                } else {
                    return acc
                }
            }, 0)
        )
    },
    fetchCartSubtotalForNormalProducts(state, getters) {
        return roundToTwo(
            getters.fetchCartProducts
                .filter((p) => {
                    return !getters.shippingChargedProducts.some(
                        (s) => s.pn === p.pn
                    )
                })
                .reduce((acc, item) => {
                    if (item.isAvailable) {
                        return acc + roundToTwo(item.subTotalSalePrice)
                    } else {
                        return acc
                    }
                }, 0)
        )
    },
    fetchCartTariffTotal(state, getters) {
        // CALCULATE 25% TARIFF CHARGE ONLY ON QUALIFIED ITEMS
        return getters.fetchCartProducts.reduce((acc, item) => {
            if (item.tariff && item.isAvailable) {
                return (
                    acc +
                    parseFloat(item.subTotalSalePrice) *
                        parseFloat(state.tariffPercentage)
                )
            } else {
                return acc
            }
        }, 0)
    },
    fetchNexusTaxObj(state, getters, rootState, rootGetters) {
        if (
            rootGetters['checkout/getShippingDetails']?.addressCountry ===
            'United States'
        ) {
            return fetchTaxableNexusObj({
                taxRates: rootState.common.siteSettings.taxRates,
                contactDetails: rootGetters['checkout/getShippingDetails'],
            })
        } else {
            return undefined
        }
    },
    fetchCartTaxRate(state, getters, rootState, rootGetters) {
        if (
            rootGetters['checkout/getShippingDetails']?.taxable &&
            getters.fetchNexusTaxObj?.nexus
        ) {
            const { schedule, percentage, rate } = getters.fetchNexusTaxObj
            return {
                schedule,
                percentage,
                rate,
                amount: getters.fetchCartTaxTotal,
            }
        } else {
            return undefined
        }
    },
    fetchCartTaxTotal(state, getters, rootState, rootGetters) {
        if (
            (rootGetters['auth/isCustomerTaxable'] ||
                state.customerCreateResponseId) &&
            rootGetters['checkout/getShippingDetails']?.taxable &&
            getters.fetchNexusTaxObj?.nexus
        ) {
            return roundToTwo(
                (parseFloat(getters.fetchCartSubtotal) +
                    parseFloat(getters.fetchNonInventorySubtotal) -
                    parseFloat(getters.fetchCartTariffTotal)) *
                    parseFloat(getters.fetchNexusTaxObj.rate)
            )
        } else {
            return 0
        }
    },
    fetchCartTotal(state, getters, rootState, rootGetters) {
        // CALCULATE ORDER TOTAL
        // console.log('subtotal', getters.fetchCartSubtotal)
        // console.log('method price', getters.fetchSelectedMethodPrice)
        // console.log('tariff total', getters.fetchCartTariffTotal)
        // console.log('tax total', getters.fetchCartTaxTotal)
        // console.log('discount is', getters.fetchComputedDiscountValue)
        return roundToTwo(
            parseFloat(getters.fetchCartSubtotal) -
                parseFloat(rootGetters['coupon/discountValue']) +
                parseFloat(getters.fetchSelectedMethodPrice) +
                parseFloat(getters.fetchShippingCharges) +
                parseFloat(getters.fetchHandlingFee) +
                parseFloat(getters.fetchHandlingFeePerItemTotal) +
                parseFloat(getters.fetchCartTariffTotal) +
                parseFloat(getters.fetchCartTaxTotal)
        )
    },
    fetchNonInventorySubtotal(state, getters, rootState, rootGetters) {
        return (
            parseFloat(getters.fetchHandlingFee) +
            parseFloat(getters.fetchHandlingFeePerItemTotal) +
            parseFloat(getters.fetchSelectedMethodPrice) +
            parseFloat(getters.fetchCartTariffTotal) +
            parseFloat(getters.fetchShippingCharges) -
            parseFloat(rootGetters['coupon/discountValue'])
        )
    },
    fetchCarrierVendors(state, getters, rootState, rootGetters) {
        if (rootState.common) {
            const carrierVendors = rootState.common.carriers.map((item) => {
                return item.title
            })
            return rootGetters['checkout/isInternationalShipping']
                ? [...carrierVendors, 'DHL']
                : carrierVendors
        } else {
            return []
        }
    },
    fetchShippingMethod(state, getters, rootState, rootGetters) {
        if (state.selectedCarrierMethod === 'default') {
            switch (state.selectedShippingMethod) {
                case 'ground':
                    return getters.fetchSelectedMethodPrice === 0
                        ? 'c3 Free Ground'
                        : 'FedEx Ground'
                case 'air2day':
                    return 'FedEx 2nd Day'
                case 'air1day':
                    return 'FedEx Standard Overnight'
                default:
                    return 'FedEx Ground'
            }
        } else if (
            state.selectedCarrierMethod === 'billToMyCarrier' &&
            state.carrierVendorName === 'FedEx'
        ) {
            switch (state.selectedShippingMethod) {
                case 'ground':
                    return 'FedEx Ground'
                case 'air2day':
                    return 'FedEx 2nd Day'
                case 'air1day':
                    return 'FedEx Standard Overnight'
                default:
                    return 'FedEx Ground'
            }
        } else if (
            state.selectedCarrierMethod === 'billToMyCarrier' &&
            state.carrierVendorName === 'UPS'
        ) {
            switch (state.selectedShippingMethod) {
                case 'ground':
                    return 'UPS Ground Collect'
                case 'air2day':
                    return 'UPS 2nd Day Air Collect'
                case 'air1day':
                    return 'UPS Next Day Air Collect'
                default:
                    return 'UPS Ground Collect'
            }
        } else if (
            state.selectedCarrierMethod === 'billToMyCarrier' &&
            state.carrierVendorName === 'DHL'
        ) {
            return 'DHL Collect'
        } else {
            return ''
        }
    },
    fetchInternationalShippingOptions(state, getters, rootState, rootGetters) {
        if (state.carrierVendorName === 'FedEx') {
            return [
                'FedEx Int Economy',
                'FedEx Int First',
                'FedEx Int Ground',
                'FedEx Int Priority',
            ]
        } else if (state.carrierVendorName === 'UPS') {
            return [
                'UPS Worldwide Expedited Collect',
                'UPS Worldwide Saver Collect',
                'UPS Worldwide Standard Collect',
                'UPS Worldwide Express Collect',
            ]
        } else if (state.carrierVendorName === 'DHL') {
            return ['DHL Collect']
        } else {
            return []
        }
    },
    fetchSelectedCarrier(state) {
        return state.selectedCarrierMethod
    },
    fetchSelectedMethodPrice(state, getters, rootState, rootGetters) {
        if (
            state.selectedCarrierMethod === 'billToMyCarrier' ||
            getters.isFreeGroundShipping
        ) {
            return 0
        } else if (getters.cartContainsOnlyShippingChargedProducts) {
            return 0
        } else if (
            state.selectedShippingMethod === 'ground' &&
            rootGetters['auth/isDistributor']
        ) {
            return getters.fetchDistributorGroundShippingPrice
        } else {
            return getters.fetchShippingOptions[state.selectedShippingMethod]
        }
    },
    fetchShippingOptions(state, getters, rootState) {
        // console.log('rootState for shipping', rootState.shipping)
        const subtotal = getters.fetchCartSubtotalForNormalProducts
        return !rootState.common
            ? []
            : rootState.common.rates.filter(
                  (pricing) =>
                      pricing.min <= subtotal && pricing.max >= subtotal
              )[0]
    },
    salesTaxPercentage(state, getters, rootState, rootGetters) {
        return getters.fetchCartTaxRate?.rate
    },
    isFreeGroundShipping(state, getters, rootState, rootGetters) {
        if (
            state.selectedShippingMethod === 'ground' &&
            rootState.coupon.couponObj &&
            rootState.coupon.couponObj.freeShipping
        ) {
            return true
        } else {
            return false
        }
    },
    isCartEmpty(state, getters, rootState, rootGetters) {
        return getters.fetchCartProducts.length === 0
    },
    isCarrierVendorDetailsEmpty(state, getters, rootState, rootGetters) {
        if (
            state.selectedCarrierMethod === 'billToMyCarrier' &&
            (!state.carrierVendorName ||
                !state.shipperNumber ||
                (rootGetters['checkout/isInternationalShipping'] &&
                    !state.internationalShippingMethod))
        ) {
            return true
        } else {
            return false
        }
    },
    isShipperNumberValidationTrue(state, getters, rootState, rootGetters) {
        if (state.selectedCarrierMethod === 'default') {
            return true
        } else if (
            state.selectedCarrierMethod === 'billToMyCarrier' &&
            state.carrierVendorName === 'UPS' &&
            state.shipperNumber.length === 6
        ) {
            return true
        } else if (
            state.selectedCarrierMethod === 'billToMyCarrier' &&
            state.carrierVendorName === 'FedEx' &&
            state.shipperNumber.length === 9
        ) {
            return true
        } else if (
            state.selectedCarrierMethod === 'billToMyCarrier' &&
            state.carrierVendorName === 'DHL'
        ) {
            return true
        } else {
            return false
        }
    },
    fetchHandlingFee(state, getters, rootState, rootGetters) {
        if (getters.fetchCartSubtotal > 0 && getters.fetchCartSubtotal < 49) {
            return rootGetters['checkout/isInternationalShipping'] ? 25 : 10
        } else {
            return 0
        }
        // if (getters.fetchCartSubtotalForNormalProducts > 0 && getters.fetchCartSubtotalForNormalProducts < 49) {
        //     return 10
        // } else {
        //     return 0
        // }
    },
    fetchHandlingFeePerItemTotal(state, getters, rootState, rootGetters) {
        return getters.nonStandardPkgQtyItems.length > 0
            ? getters.nonStandardPkgQtyItems.length * 10
            : 0
    },
    isInternationalOrderAboveThreshold(state, getters, rootState, rootGetters) {
        return (
            rootGetters['checkout/isInternationalShipping'] &&
            getters.fetchCartSubtotal > 300
        )
    },
    fetchDistributorGroundShippingPrice(
        state,
        getters,
        rootState,
        rootGetters
    ) {
        if (
            rootGetters['auth/freeGroundShippingThreshold'] &&
            getters.fetchCartSubtotalForNormalProducts >
                parseInt(rootGetters['auth/freeGroundShippingThreshold'])
        ) {
            return 0
        } else {
            return getters.fetchShippingOptions.groundDist
        }
    },
    isShippingTBD(state, getters, rootState, rootGetters) {
        if (
            state.selectedCarrierMethod === 'default' &&
            ['air1day', 'air2day'].includes(state.selectedShippingMethod) &&
            getters.fetchCartSubtotalForNormalProducts >= 500
        ) {
            return true
        } else {
            return false
        }
    },
    shippingExemptionFound(state, getters, rootState, rootGetters) {
        return getters.fetchCartProducts.some((item) => {
            return rootState.common.siteSettings.cartItemRules.find((i) => {
                if (i.pn && i.pn.includes('*')) {
                    const regEx = new RegExp(`^${i.pn.replace('*', '')}`)
                    return regEx.test(item.pn) && i.exemptFromFreeShipping
                } else if (i.pn) {
                    return i.pn === item.pn && i.exemptFromFreeShipping
                } else {
                    return false
                }
            })
        })
    },
    sameDayShippingExemptionFound(state, getters, rootState, rootGetters) {
        return getters.fetchCartProducts.some((item) => {
            return rootState.common.siteSettings.cartItemRules.find((i) => {
                if (i.pn && i.pn.includes('*')) {
                    const regEx = new RegExp(`^${i.pn.replace('*', '')}`)
                    return regEx.test(item.pn) && i.leadTime10Days
                } else if (i.pn) {
                    return i.pn === item.pn && i.leadTime10Days
                } else {
                    return false
                }
            })
        })
    },
    webOrderPayloadNew(state, getters, rootState, rootGetters) {
        return {
            docType: 'Web Order',
            state: 'Pending',
            shippingAccountNumber: state.shipperNumber,
            shippingAccountCarrier: state.carrierVendorName,
            isInx: rootGetters['checkout/isInternationalShipping'],
            messageFromCustomer: rootState.checkout.orderMessage,
            shippingPoNumber: rootState.checkout.purchaseOrderNumber,
            shippingPoNumber1: rootState.checkout.purchaseOrderNumber,
            shippingDate: new Date().toISOString(),
            tax: getters.fetchCartTaxTotal,
            subtotal:
                getters.fetchCartSubtotal + getters.fetchNonInventorySubtotal,
            total: getters.fetchCartTotal,
            billingAddressForOrder:
                rootGetters['checkout/billingAddressForOrder'],
            shippingAddressForOrder:
                rootGetters['checkout/shippingAddressForOrder'],
            // // build relation using 'connect' in graphql
            CustomerId:
                rootGetters['auth/primaryCustomer']?.id ||
                state.customerCreateResponseId,
            OrderShippingContactId:
                rootState.checkout.selectedShippingAddressID,
            OrderBillingContactId: rootState.checkout.selectedBillingAddressID,
            OrderShippingMethodName: rootGetters[
                'checkout/isInternationalShipping'
            ]
                ? state.internationalShippingMethod
                : getters.fetchShippingMethod,
            // to be removed from graphql payload
            handlingFee: getters.fetchHandlingFee,
            couponDiscountValue: rootGetters['coupon/discountValue'],
            shippingMethodPrice: getters.fetchSelectedMethodPrice,
            couponCode:
                rootGetters['coupon/isCouponValid'] &&
                rootState.coupon.couponCode,
            nonStandardPkgQtyItems: getters.nonStandardPkgQtyItems,
        }
    },
    nonInventoryLineItems(state, getters, rootState, rootGetters) {
        const lineItem = []
        if (rootGetters['checkout/isInternationalShipping']) {
            lineItem.push('International Paperwork')
            lineItem.push('EXW')
        }
        if (getters.fetchHandlingFee > 0) {
            rootGetters['checkout/isInternationalShipping']
                ? lineItem.push('International Handling Fee')
                : lineItem.push('53000')
        }
        if (
            rootGetters['coupon/isCouponValid'] &&
            rootState.coupon.couponCode
        ) {
            lineItem.push(rootState.coupon.couponCode)
        }
        if (state.selectedCarrierMethod === 'billToMyCarrier') {
            lineItem.push('3789')
        }

        if (state.selectedCarrierMethod === 'default') {
            let lItem = ''
            if (getters.fetchSelectedMethodPrice > 0) {
                lItem = getters.fetchHandlingFee > 0 ? '3814' : '54000'
            } else if (getters.isShippingTBD) {
                lItem = '3816'
            } else if (!getters.cartContainsOnlyShippingChargedProducts) {
                lItem = '3790'
            }
            lItem && lineItem.push(lItem)
        }

        if (
            getters.shippingChargedProducts.length > 0 &&
            state.selectedCarrierMethod === 'default'
        ) {
            lineItem.push('3811')
        }
        if (state.cartProducts.filter((i) => i.tariff).length > 0) {
            lineItem.push('Tariff- 25')
        }

        if (getters.nonStandardPkgQtyItems.length > 0) {
            lineItem.push('WD Handling Fee')
        }
        return lineItem
    },
    inventoryLineItems(state, getters) {
        return getters.fetchCartProducts.map((item) => {
            const {
                pn,
                list_price,
                salePrice,
                tariff,
                pkgqty,
                prod_quantity,
                discount_schedule,
                name_en,
            } = item

            const isShippingChargeCalculated = !!getters.shippingChargedProducts.find(
                (i) => i.pn === pn && state.selectedCarrierMethod === 'default'
            )
            return {
                pn,
                list_price,
                salePrice,
                tariff,
                pkgqty,
                prod_quantity,
                discount_schedule,
                name_en,
                isShippingChargeCalculated,
            }
        })
    },
    nonStandardPkgQtyItems(state, getters) {
        return getters.fetchCartProducts
            .filter((item) => {
                if (
                    Number.isInteger(item.pkgqty) &&
                    item.prod_quantity % item.pkgqty !== 0 &&
                    item.parent?.en?.current === 'wire-duct'
                ) {
                    return item
                }
            })
            .map((item) => item.pn)
    },
    shippingChargedProducts(state, getters, rootState) {
        return getters.fetchCartProducts.filter((item) => {
            return rootState.common.siteSettings.cartShippingRates.find((i) => {
                return item.pn.startsWith(i.family) && item.pn.includes(i.code)
            })
        })
    },
    cartContainsOnlyShippingChargedProducts(state, getters) {
        return (
            getters.fetchCartProducts.length > 0 &&
            getters.shippingChargedProducts.length ===
                getters.fetchCartProducts.length
        )
    },
    appliedShippingChargesList(state, getters, rootState) {
        return !getters.selectedZoneForShippingCharges ||
            getters.shippingChargedProducts.length === 0 ||
            state.selectedCarrierMethod === 'billToMyCarrier'
            ? []
            : getters.shippingChargedProducts.map((i) => {
                  const shippingRateObj = rootState.common.siteSettings.cartShippingRates.find(
                      (s) => i.pn.includes(s.code)
                  )
                  const selectedZoneRate = shippingRateObj.zones?.find(
                      (p) =>
                          p.id === getters.selectedZoneForShippingCharges?.zone
                  )
                  const overFlowCount =
                      i.prod_quantity % shippingRateObj.spqBoxCount
                  const stdBoxQty =
                      overFlowCount === 0
                          ? i.prod_quantity / shippingRateObj.spqBoxCount
                          : Math.floor(
                                i.prod_quantity / shippingRateObj.spqBoxCount
                            )
                  console.log(stdBoxQty)
                  const multipleOfNonStdBoxQty = Math.floor(
                      overFlowCount / shippingRateObj.nonBoxCount
                  )
                  console.log(
                      'multipleOfNonStdBoxQty: ',
                      multipleOfNonStdBoxQty
                  )
                  const overFlowOfNonStdBoxQty =
                      overFlowCount % shippingRateObj.nonBoxCount
                  console.log(
                      'overFlowOfNonStdBoxQty: ',
                      overFlowOfNonStdBoxQty
                  )
                  const nonStdBoxQty =
                      multipleOfNonStdBoxQty +
                      (overFlowOfNonStdBoxQty > 0 ? 1 : 0)
                  console.log(nonStdBoxQty)
                  console.log('total boxes : ', stdBoxQty + nonStdBoxQty)
                  const shippingChargesTotal =
                      stdBoxQty * selectedZoneRate.spqBoxPrice +
                      nonStdBoxQty * selectedZoneRate.nonBoxPrice
                  return {
                      pn: i.pn,
                      shippingPrice: shippingChargesTotal,
                  }
              })
    },
    fetchShippingCharges(state, getters) {
        return getters.appliedShippingChargesList?.length > 0
            ? getters.appliedShippingChargesList.reduce(
                  (acc, i) => acc + i.shippingPrice,
                  0
              )
            : 0
    },
    selectedZoneForShippingCharges(state, getters, rootState, rootGetters) {
        return (
            getters.shippingChargedProducts.length > 0 &&
            shippingZones.find((i) =>
                rootGetters[
                    'checkout/getShippingDetails'
                ]?.addressPostalCode.startsWith(i.zip)
            )
        )
    },
    getUnavailableProductListInElastic(state, getters) {
        return getters.fetchCartProducts.filter((p) => !p.isAvailable)
    },
}
