export default {
    methods: {
        setAllLocaleSlugs() {
            if (this.page && this.page.slug) {
                const localePaths = {}
                this.$i18n.locales.forEach((locale) => {
                    // eslint-disable-next-line standard/computed-property-even-spacing
                    localePaths[locale.code] = {
                        slug: this.page.slug[locale.code].current
                    }
                })
                this.$store.dispatch('i18n/setRouteParams', localePaths)
            }
        }
    },
    watch: {
        page() {
            this.setAllLocaleSlugs()
        }
    },
    mounted() {
        this.setAllLocaleSlugs()
    }
}
