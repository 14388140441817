export const state = () => ({
    currentHash: null,
    customProductList: []
})

export const actions = {}

export const mutations = {
    add(state, payload) {
        state.customProductList = [...state.customProductList, payload]
    },
    remove(state, payload) {
        state.customProductList = [...state.customProductList].filter(
            (item) => {
                return item.pn !== payload
            }
        )
    },
    empty(state, payload) {
        state.customProductList = []
    },
    initCustomProductList(state) {
        if (localStorage.getItem('customProducts')) {
            const customProducts = JSON.parse(
                localStorage.getItem('customProducts')
            )
            for (const key in customProducts) {
                if (state.hasOwnProperty(key)) {
                    state[key] = customProducts[key]
                }
            }
        }
    },
    setCurrentHash(state, payload) {
        state.currentHash = payload
    }
}

export const getters = {}
