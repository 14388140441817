import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
    devForm: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
        message: '',
        subject: ''
    }
})

export const actions = {}

export const mutations = {
    updateDevField(state, field) {
        updateField(state.devForm, field)
    }
}

export const getters = {
    getDevField(state) {
        return getField(state.devForm)
    }
}
