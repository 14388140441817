<template>
    <v-dialog v-model="showModal" width="500">
        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
                <div
                    class="d-flex justify-space-between align-center"
                    style="width: -webkit-fill-available"
                >
                    <div>
                        <TranslatableStringComponent
                            :tstring="{ string: 'unavailableItems' }"
                        />
                    </div>
                    <v-btn icon @click="$emit('handleDialog')">
                        <fa :icon="['fal', 'times']" class="fa-lg" />
                    </v-btn>
                </div>
            </v-card-title>
            <v-card-text class="pt-5">
                <div>
                    <p>
                        <TranslatableStringComponent
                            :tstring="{ string: 'unavailableItemWarning' }"
                        />
                    </p>
                    <ul>
                        <li
                            v-for="(
                                item, i
                            ) in getUnavailableProductListInElastic"
                            :key="i"
                        >
                            {{ item.pn }}
                        </li>
                    </ul>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="handleRemoveAndProceed">
                    <TranslatableStringComponent
                        :tstring="{ string: 'removeAndProceed' }"
                    />
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import TranslatableStringComponent from './TranslatableStringComponent.vue'
export default {
    name: 'UnavailableProductWarningModal',
    components: {
        TranslatableStringComponent,
    },
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            getUnavailableProductListInElastic:
                'cart/getUnavailableProductListInElastic',
        }),
    },
    methods: {
        handleRemoveAndProceed() {
            this.getUnavailableProductListInElastic.forEach((item) => {
                this.$store.dispatch('cart/removeItem', item)
            })
            this.$emit('handleProceedCheckout')
        },
    },
}
</script>
