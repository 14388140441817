import renderUrl from '~/util/renderUrl'
import prependRoute from '~/util/prependRoute'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
export default {
    components: {
        TranslatableStringComponent,
    },
    props: {
        menu: {
            type: Object,
            default: () => ({})
        },
        steez: {
            type: String,
            default: null
        },
        menuId: {
            type: String,
            default: null
        }
    },
    computed: {},
    methods: {
        isExternal(menuItem, locale) {
            if (menuItem.submenuItemLabel) {
                return (
                    menuItem.submenuItemURLo &&
                    menuItem.submenuItemURLo[locale] &&
                    menuItem.submenuItemLabel &&
                    menuItem.submenuItemLabel[locale]
                )
            }
            return (
                menuItem.menuItemURLo &&
                menuItem.menuItemURLo[locale] &&
                menuItem.menuItemLabel &&
                menuItem.menuItemLabel[locale]
            )
        },
        isInternal(menuItem, locale) {
            if (menuItem.submenuItemLabel) {
                return (
                    menuItem.submenuItemURL &&
                    menuItem.submenuItemURL.slug &&
                    menuItem.submenuItemURL.slug[locale] &&
                    menuItem.submenuItemLabel &&
                    menuItem.submenuItemLabel[locale]
                )
            }
            return (
                menuItem.menuItemURL &&
                menuItem.menuItemURL.slug &&
                menuItem.menuItemURL.slug[locale] &&
                menuItem.menuItemLabel &&
                menuItem.menuItemLabel[locale]
            )
        },
        calcTo(menuItem, locale) {
            if (menuItem.submenuItemLabel) {
                const type = menuItem.submenuItemURL._type
                const slug = menuItem.submenuItemURL.slug
                const route = prependRoute({ type, slug })
                return route[locale]
            }
            const type = menuItem.menuItemURL._type
            const slug = menuItem.menuItemURL.slug
            const route = prependRoute({ type, slug })
            return route[locale]
        },
        renderUrl({ item, lang, nuxt }) {
            return renderUrl({ item, lang, nuxt })
        },
        renderIcon({ item }) {
            // console.log(item)
            return item && item.menuItemIcon && item.menuItemIcon.snake
                ? ['fal', item.menuItemIcon.snake]
                : ['fal', 'user']
        }
    }
}
