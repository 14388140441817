import { extend } from 'vee-validate'
import {
    required,
    email,
    digits,
    numeric,
    between,
    confirmed,
    max,
} from 'vee-validate/dist/rules'

export default ({ app: { store, i18n } }) => {
    extend('required', {
        ...required,
        message: () =>
            `${store.state.common.strings['thisFieldIsRequired'][i18n.locale]}`,
    })
    extend('email', {
        ...email,
        message: () =>
            store.state.common.strings['pleaseEnterAValidEmail'][i18n.locale],
    })

    extend('numeric', {
        ...numeric,
        message: () =>
            store.state.common.strings['pleaseEnterAValidNumber'][i18n.locale],
    })

    extend('maxCharacter', {
        ...max,
        message: () =>
            store.state.common.strings['maxCharacterLimitReached'][i18n.locale],
    })

    extend('digits', {
        ...digits,
        message: () =>
            store.state.common.strings['zipCodeMustContain5Digits'][
                i18n.locale
            ],
    })

    extend('between', {
        ...between,
        message: () =>
            store.state.common.strings['ratingMustBeBetween1And5'][i18n.locale],
    })

    extend('confirmed', {
        ...confirmed,
        message: () =>
            store.state.common.strings['passwordsDoesNotMatch'][i18n.locale],
    })

    extend('emoji', {
        validate(value) {
            const regex = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu
            return !regex.test(value)
        },
        params: [],
        message: () =>
            store.state.common.strings['emojiNotAllowed'][i18n.locale],
    })

    extend('companyName', {
        validate(value) {
            const regex = /\b(na|none|n\/a|n\.a)\b/gi
            return !regex.test(value)
        },
        params: [],
        message: () =>
            store.state.common.strings['pleaseEnterValidCompanyName'][
                i18n.locale
            ],
    })

    extend('copyPasteFormat', {
        validate(value, { numberOfDigits }) {
            if (value.includes('\n')) {
                // multiline
                const products = value.split('\n')
                let invalidLines = 0
                for (let i = 0; i < products.length; i++) {
                    if (
                        products[i].includes(',') &&
                        products[i].split(',').length - 1 === 1 &&
                        !isNaN(products[i].split(',')[1]) &&
                        products[i].split(',')[1].trim().length > 0
                    ) {
                        continue
                    } else {
                        invalidLines++
                    }
                }
                return !(invalidLines > 0)
            } else if (
                value.includes(',') &&
                value.split(',').length - 1 === 1 &&
                value.split(',')[1].trim().length > 0
            ) {
                return !isNaN(value.split(',')[1])
            } else {
                return false
            }
        },
        params: ['numberOfDigits', 'vendorName'],
        message: () =>
            store.state.common.strings['pleaseReferToCorrectInputFormat'][
                i18n.locale
            ],
    })

    extend('shipperNumber', {
        validate(value, { numberOfDigits }) {
            let regex
            if (parseInt(numberOfDigits) === 6) {
                regex = /^[a-zA-Z0-9]{6}$/
            } else if (parseInt(numberOfDigits) === 9) {
                regex = /^[a-zA-Z0-9]{9}$/
            } else {
                regex = /^[a-zA-Z0-9]*$/
            }
            return regex.test(value)
        },
        params: ['numberOfDigits', 'vendorName'],
        message: (rule, params) => {
            let output =
                store.state.common.strings['1MustBe2AlphaNumericCharacters'][
                    i18n.locale
                ]
            output = output.replace(`~~1~~`, params.vendorName)
            output = output.replace(`~~2~~`, params.numberOfDigits)
            return output
        },
    })

    extend('number', {
        ...numeric,
        message: () =>
            store.state.common.strings['pleaseEnterAValidCustomerId'][
                i18n.locale
            ],
    })

    extend('nonPlussedEmail', {
        validate(value) {
            return !value.includes('+')
        },
        message: () =>
            store.state.common.strings[
                'theCharacterIsNotSupportedPleaseEnterAValidEmail'
            ][i18n.locale],
    })
}
