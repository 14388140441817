<template>
    <div :id="menuId">
        <client-only>
            <v-list>
                <v-list-item class="pa-0 bg-dark">
                    <v-list-item-content class="pb-0">
                        <v-list-item-title
                            v-if="isLoggedIn"
                            class="py-3 pl-4 white--text"
                        >
                            <div class="text-uppercase font-weight-bold">
                                <TranslatableStringComponent :tstring="{string: 'hello'}" />, {{ userName }}
                            </div>
                            <p class="mb-0">
                                {{ webUser && webUser.email }}
                            </p>
                        </v-list-item-title>
                        <v-list-item-title v-else class="py-3 pl-4">
                            <v-btn
                                outlined
                                :to="
                                    getStaticLocalePath({
                                        type: 'page',
                                        slugName: 'login'
                                    })
                                "
                                color="white"
                                nuxt
                                ><TranslatableStringComponent :tstring="{string: 'loginRegister'}" /></v-btn
                            >
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="!miniVariant">
                        <fa
                            :icon="['fal', 'times-circle']"
                            class="fa-lg"
                            @click.stop="$nuxt.$emit('toggleRightDrawer')"
                        />
                    </v-list-item-action>
                </v-list-item>
                <template v-for="(item, i) in menu.menuItems">
                    <v-list-item
                        v-if="
                            !item.submenuItems || item.submenuItems.length <= 0
                        "
                        :key="`item-${i}`"
                        :to="
                            item.menuItemPhone ? '' : getCustomLocalePath(item)
                        "
                        :href="
                            item.menuItemPhone && item.menuItemPhone.linkTo
                                ? `tel:+${item.menuItemPhone.linkTo}`
                                : '#'
                        "
                        :router="!item.menuItemPhone"
                        :exact="!item.menuItemPhone"
                        :class="{ bottom: item.menuItemSep, 'pa-0': true }"
                    >
                        <v-list-item-action>
                            <fa :icon="renderIcon({ item })" class="fa-lg" />
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="
                                    item.menuItemLabel &&
                                    item.menuItemLabel[$i18n.locale]
                                        ? item.menuItemLabel[$i18n.locale]
                                        : '- data missing -'
                                "
                            />
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group
                        v-if="item.submenuItems && item.submenuItems.length > 0"
                        :key="`item-${i}`"
                        v-model="allOpenItems[item._key]"
                        :class="{
                            'pa-0': true
                        }"
                    >
                        <template v-slot:activator>
                            <v-list-item
                                :key="`litem-${i}`"
                                :class="{
                                    bottom: item.menuItemSep,
                                    parent: true,
                                    'pa-0': true
                                }"
                                @click="
                                    $nuxt.$emit('toggleItemsOpen', item._key)
                                "
                            >
                                <v-list-item-action>
                                    <fa
                                        :icon="renderIcon({ item })"
                                        class="fa-lg"
                                    />
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title
                                        v-text="
                                            item.menuItemLabel &&
                                            item.menuItemLabel[$i18n.locale]
                                                ? item.menuItemLabel[
                                                      $i18n.locale
                                                  ]
                                                : '- data missing -'
                                        "
                                    />
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <v-list-item
                            v-for="(child, j) in item.submenuItems"
                            :key="`child-${j}`"
                            :to="getCustomLocalePath(child)"
                            dense
                            :class="{
                                bottom: child.menuItemSep,
                                child: true,
                                'pa-0': true
                            }"
                        >
                            <v-list-item-action v-if="miniVariant">
                                <fa
                                    :icon="renderIcon({ item })"
                                    class="fa-lg"
                                />
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="
                                        child.submenuItemLabel &&
                                        child.submenuItemLabel[$i18n.locale]
                                            ? child.submenuItemLabel[
                                                  $i18n.locale
                                              ]
                                            : '- data missing -'
                                    "
                                />
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </template>
                <v-list-item
                    v-if="isLoggedIn"
                    class="pa-0"
                    @click="handleDialog()"
                >
                    <v-list-item-action>
                        <fa :icon="['fal', 'sign-out']" class="fa-lg" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title><TranslatableStringComponent :tstring="{string: 'logout'}" /></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-list>
                <v-list-item>
                    <SocialIcons />
                </v-list-item>
                <v-list-item>
                    <LanguageSwitch />
                </v-list-item>
                <v-list-item>
                    <span class="mr-1"
                        >&copy; 1976-{{ new Date().getFullYear() }}
                    </span>
                    <span>c3controls</span>
                </v-list-item>
            </v-list>

            <dialogLogout :dialog="dialog" @handleDialog="handleDialog()" />
        </client-only>
    </div>
</template>
<script>
import handleAuth from '../../mixins/handleAuth'
import menuUtilities from '~/mixins/menuUtilities'
import dialogLogout from '~/components/dialogLogout.vue'
import SocialIcons from '~/components/SocialIcons.vue'
import LanguageSwitch from '~/components/LanguageSwitch.vue'
export default {
    name: 'MenuOffCanvas',
    components: {
        dialogLogout,
        SocialIcons,
        LanguageSwitch,
    },
    mixins: [handleAuth, menuUtilities],
    props: {
        miniVariant: {
            type: Boolean,
            default: false
        },
        openItems: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            collapse: '',
            allOpenItems: this.initOpenItems(this.menu),
            hasProductsOpen: false,
            dialog: false,
            languageSwitch: false
        }
    },
    computed: {
        productsKey() {
            const key =
                this && this.menu && this.menu.menuItems
                    ? this.menu.menuItems.filter((item) => {
                          return (
                              item &&
                              item.menuItemLabel &&
                              item.menuItemLabel.en === 'Find Products'
                          )
                      })[0]._key
                    : ''
            return key || ''
        }
    },
    mounted() {
        this.detectLangauge()
    },
    created() {
        this.$nuxt.$on('toggleProductsStart', () => {
            if (this.allOpenItems[this.productsKey]) {
                this.hasProductsOpen = false
                this.allOpenItems[this.productsKey] = false
            } else {
                this.hasProductsOpen = true
                this.allOpenItems[this.productsKey] = true
            }
        })
        this.$nuxt.$on('toggleRightDrawerNoSubs', () => {
            this.allOpenItems[this.productsKey] = false
            // this.hasProductsOpen = false
        })
    },
    methods: {
        initOpenItems(menu) {
            return menu && menu.menuItems
                ? menu.menuItems.reduce(function(acc, cur, i) {
                      acc[cur._key] = false
                      return acc
                  }, {})
                : {}
        },
        handleDialog() {
            this.dialog = !this.dialog
        },
        handleLangaugeSwitcher() {
            const selectedLanguage = this.languageSwitch ? 'es' : 'en'
            const path = this.switchLocalePath(selectedLanguage)
            this.$router.push({ path })
        },
        detectLangauge() {
            switch (this.$i18n.locale) {
                case 'en':
                    this.languageSwitch = false
                    break
                case 'es':
                    this.languageSwitch = true
                    break
                default:
                    break
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.v-application {
    .v-list-item.bottom {
        border-bottom: 1px solid $silver;
        box-shadow: 1px 1px 2px $silver;
    }
    .v-list-item.parent {
        padding-left: 0;
        & + .v-list-item__icon {
            width: 24px;
            min-width: 24px !important;
            margin-left: 0 !important;
        }
    }
    .v-list-item.child {
        background: $secondary;
        font-size: 0.75rem;
        .v-list-item__content,
        .v-list-item__action .svg-inline--fa {
            color: $white;
        }
        .v-list-item__action {
            padding-left: 0;
        }
        .v-list-item__content {
            padding-left: 1rem;
        }
        .v-list-item__title {
            white-space: break-spaces;
        }
    }
    .v-list-item__action {
        margin: 1rem;
    }
    .v-list-group.pa-0 .v-list-group__header {
        padding: 0;
        .v-list-item--link {
            margin-right: -24px;
        }
    }
    .v-list-item__action:first-child,
    .v-list-item__icon:first-child {
        margin-right: 1rem;
    }
    .v-list-item__icon .v-icon {
        margin-right: 1rem;
    }
    .v-navigation-drawer--mini-variant .v-list-item {
        .v-list-item__action {
            margin: 1rem !important;
            padding: 0 !important;
        }
    }
}
</style>
