/* eslint-disable prettier/prettier */
export default {
    methods: {
        localize(string, args = null) {
            if (args) {
                let output = this.$store.state.common.strings[string]
                    ? this.$store.state.common.strings[string][
                          this.$i18n.locale
                      ]
                    : '- data missing -'
                if (this.$store.state.common.strings[string].changeEditor) {
                    args.forEach((arg, i) => {
                        output.forEach((j) =>
                            j.children.forEach(
                                (k) =>
                                    (k.text = k.text.replace(
                                        `~~${i + 1}~~`,
                                        arg.var
                                    ))
                            )
                        )
                    })
                } else {
                    args.forEach((arg, i) => {
                        output = output.replace(`~~${i + 1}~~`, arg.var)
                    })
                }
                return output
            }
            return this.$store.state.common.strings[string]
                ? this.$store.state.common.strings[string][this.$i18n.locale]
                : '- data missing -'
        },
    },
}
