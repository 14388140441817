// import axios from 'axios'
import { client } from '~/plugins/global.js'
export const state = () => ({
    samples: [],
    selectedSamples: [],
    selectedButtons: [],
    selectedCategory: []
})

export const actions = {
    async fetchSamples(
        { dispatch, commit, state, rootState, getters },
        payload
    ) {
        // const api =
        // 'https://tkk9p83q.api.sanity.io/v1/data/query/production?query='
        const groq = `*[_type in ["sampleCategory","sampleProduct"]]{...,categories[]->{...}}`
        // const query = api + encodeURIComponent(groq)

        try {
            const result = await client.fetch(groq)
            // const { data } = await axios.get(query)
            // console.log(data)
            commit('setSamples', result)
        } catch (err) {
            console.log('ERROR NAME:', err.name)
            console.log('ERROR MESSAGE:', err.message)
            console.log('ERROR STACK:', err.stack)
        }
    },
    addSample({ dispatch, commit, state }, payload) {
        const isFound = state.selectedSamples.find(
            (item) => item._id === payload._id
        )
        if (isFound) {
            commit('removeSample', payload)
        } else if (state.selectedSamples.length < 3) {
            commit('addSample', payload)
        }
    }
}

export const mutations = {
    setSamples(state, payload) {
        state.samples = payload
    },
    addSample(state, payload) {
        state.selectedSamples = [...state.selectedSamples, payload]
    },
    removeSample(state, payload) {
        const updatedList = state.selectedSamples.filter(
            (item) => item._id !== payload._id
        )
        const updatedselectedButtonsList = state.selectedButtons.filter(
            (itemId) => itemId !== payload._id
        )
        state.selectedButtons = [...updatedselectedButtonsList]
        state.selectedSamples = [...updatedList]
    },
    updateSelectedButtons(state, payload) {
        state.selectedButtons = [...payload]
    },
    clearSamples(state) {
        state.selectedButtons = []
        state.selectedSamples = []
        state.selectedCategory = []
    },
    addSelectedCategory(state,payload){
        var temp = state.selectedCategory
        if(state.selectedCategory.length<3)
            temp.push(payload)
        state.selectedCategory = temp
    },
    removeSelectedCategory(state,payload){
        var index = state.selectedCategory.indexOf(payload);
        var temp = state.selectedCategory
        temp.splice(index,1)
        state.selectedCategory = temp
    }
}

export const getters = {
    sampleCategory(state) {
        if (state.samples.length > 0) {
            return state.samples.filter(
                (item) => item._type === 'sampleCategory'
            )
        } else {
            return undefined
        }
    },
    sampleProduct(state) {
        if (state.samples.length > 0) {
            return state.samples.filter(
                (item) => item._type === 'sampleProduct'
            )
        } else {
            return undefined
        }
    },
    selectedSampleProducts(state) {
        if (state.selectedSamples.length > 0) {
            return state.selectedSamples
        } else {
            return ['', '', '']
        }
    }
}
