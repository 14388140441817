import { render, staticRenderFns } from "./SubscriptionBox.vue?vue&type=template&id=220dbbe0"
import script from "./SubscriptionBox.vue?vue&type=script&lang=js"
export * from "./SubscriptionBox.vue?vue&type=script&lang=js"
import style0 from "./SubscriptionBox.vue?vue&type=style&index=0&id=220dbbe0&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VTabItem,VTabsItems})
