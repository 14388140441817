function vacuum(str) {
    return str
    // return str.replace(/\s/g, '')
}
const groqProductGuts = `
    ...,
    "attsDeep": atts[]-> {
        ...,
        "keyCats": key-> categories[]->{
            _id,
            "title": title.en
        },
        "key": key-> title.en,
        "value":value.en
    },
    "atts": atts[]-> {
        "key": key-> title.en,
        "value":value.en
    },
    categories[]-> {...},
    discountSchedule[]-> {...},
    series[]-> {...},
    resourceTag[]-> {
        ...,
        "resources": *[ _type == "resource" && isPublic == true && references(^._id)]{
            ...,
            "assetPdf":sanityPdf.selectedFile.asset->{...},
            "assetCad":sanityCad.selectedFile.asset->{...}
        }
    }
`

const groqStringProductTables = `
    ${groqProductGuts},
    cta{
        ...,
        en[]{
            ...,
            markDefs[]{
                ...,
                internalLink{
                    ...,
                    linkTo->{_type,slug,title}
                },
                settings{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
            },
            settings{
                ...,
                ctaLinkURL->{_type,slug,title}
            }
        },
        es[]{
            ...,
            markDefs[]{
                ...,
                internalLink{
                    ...,
                    linkTo->{_type,slug,title}
                },
                settings{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
            },
            settings{
                ...,
                ctaLinkURL->{_type,slug,title}
            }
        }
    },
    productTables[]-> {
        ${groqProductGuts},
        setsOfConditions[]{
            ...,
            setConditions[]{
                ...,
                arrayOfTablesAndOptions[]-> {${groqProductGuts}}
            }
        },
        compatibleWith[]-> {${groqProductGuts}},
        compatibleWithAll[]-> {${groqProductGuts}},
        incompatibleWith[]-> {${groqProductGuts}},
        incompatibleWithAll[]-> {${groqProductGuts}},
        productOptions[]-> {
            ${groqProductGuts},
            setsOfConditions[]{
                ...,
                setConditions[]{
                    ...,
                    arrayOfTablesAndOptions[]-> {${groqProductGuts}}
                }
            },
            compatibleWith[]-> {${groqProductGuts}},
            compatibleWithAll[]-> {${groqProductGuts}},
            incompatibleWith[]-> {${groqProductGuts}},
            incompatibleWithAll[]-> {${groqProductGuts}},
            optionData{
                ...,
                resourceTag[]-> {
                    ...,
                    "resources": *[ _type == "resource" && isPublic == true && references(^._id)]{
                        ...
                    }
                }
            }

        }
    },
    partialConfigurations[]{
        title,
        partialConfigurationSet[]-> {
            _type,
            optionsLabel,
            productOptions[]-> {
                _id,
                sequence,
                optionLabel,
                optionSlug
            }
        }
    }
`
// console.log(groqStringProductTables)
const groqStringContent = `
   contentSections{
        ...,
        content[]{
            ...,
            en{
                ...,
                image1en{
                    ...,
                    ctaLinks[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
                image2en{
                    ...,
                    ctaLinks[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
                image3en{
                    ...,
                    ctaLinks[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
            },
            es{
                ...,
                image1es{
                    ...,
                    ctaLinks[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
                image2es{
                    ...,
                    ctaLinks[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
                image3es{
                    ...,
                    ctaLinks[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
            },
            image1{
                ...,
                ctaLinks[]{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
            },
            image2{
                ...,
                ctaLinks[]{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
            },
            image3{
                ...,
                ctaLinks[]{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
            },
            en{
                heroImage1en{
                    ...,
                    ctaLinks[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    },
                    "selectedImage":selectedImage{...,"asset":asset->{...}}
                },
                heroImage2en{
                    ...,
                    ctaLinks[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    },
                    "selectedImage":selectedImage{...,"asset":asset->{...}}
                },
                heroImage3en{
                    ...,
                    ctaLinks[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    },
                    "selectedImage":selectedImage{...,"asset":asset->{...}}
                },
            },
            es{
                heroImage1es{
                ...,
                ctaLinks[]{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
                },
                heroImage2es{
                    ...,
                    ctaLinks[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
                heroImage3es{
                    ...,
                    ctaLinks[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
            },
          
            icon[]->{
                ...
            },
            blurbage{
                ...,
                en[]{
                    ...,
                    markDefs[]{
                        ...,
                        internalLink{
                            ...,
                            linkTo->{_type,slug,title}
                        },
                        settings{
                            ...,
                            ctaLinkURL->{_type,slug,title}
                        }
                    },
                    settings{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
                es[]{
                    ...,
                    markDefs[]{
                        ...,
                        internalLink{
                            ...,
                            linkTo->{_type,slug,title}
                        },
                        settings{
                            ...,
                            ctaLinkURL->{_type,slug,title}
                        }
                    },
                    settings{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                }
            },
            ctaLinks{
                ...,
                links[]{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                }
            },
            ctaLinks[]{
                ...,
                ctaLinkURL->{_type,slug,title}
            },
            content[]{
                ...,
                icon[]->{
                    ...
                },
                blurbage{
                    ...,
                    en[]{
                        ...,
                        markDefs[]{
                            ...,
                            internalLink{
                                ...,
                                linkTo->{_type,slug,title}
                            },
                            settings{
                                ...,
                                ctaLinkURL->{_type,slug,title}
                            }
                        },
                        settings{
                            ...,
                            ctaLinkURL->{_type,slug,title}
                        }
                    },
                    es[]{
                        ...,
                        markDefs[]{
                            ...,
                            internalLink{
                                ...,
                                linkTo->{_type,slug,title}
                            },
                            settings{
                                ...,
                                ctaLinkURL->{_type,slug,title}
                            }
                        },
                        settings{
                            ...,
                            ctaLinkURL->{_type,slug,title}
                        }
                    }
                },
                ctaLinks[]{
                    ...,
                    ctaLinkURL->{_type,slug,title}
                },
                ctaLinks{
                    ...,
                    links[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
                cardLink{
                    ...,
                    links[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
                imageLink{
                    ...,
                    links[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
                headingLink{
                    ...,
                    links[]{
                        ...,
                        ctaLinkURL->{_type,slug,title}
                    }
                },
            }
        },
    }`

// ORIGINAL
const groqDocs =
    process.env.V2_BASE_URL === 'http://localhost:3000'
        ? vacuum(`["page","product"] && isPublic == true`)
        : vacuum(
              `["account", "article", "category", "industry", "page", "product", "profile", "productSingle", "resource", "testimonial", "topic", "whitepaper"] && isPublic == true`
          )
// // ALL TRANSLATABLE // //
// const groqDocsTranslatable = `["coupon","location","tstring","attribute","attributeTitle","productSingle","menu","account","article","page","profile","resource","testimonial","whitepaper","category","industry","topic","sampleCategory","resourceCategory","product","productOption","productTable"]`
// // PREVIOUS TO 3/25/2021 // //
// const groqDocsTranslatable = `["profile","article","location","tstring","industry","whitepaper"]`
// // READY TO TRANSLATE // //
const groqDocsTranslatable = `["resource","testimonial","category","whitepaper"]`
// const groqDocsTranslatable = `["whitepaper"]`
// const groqDocsTranslatable = `["coupon"]`
// THIS SUCCEEDED
// const groqDocs = vacuum(`["article","whitepaper"] && isPublic == true`)
// THIS SUCCEEDED
// const groqDocs = vacuum(`["account"] && isPublic == true`)
// THIS SUCCEEDED
// const groqDocs = vacuum(
//     `["article", "category", "industry", "page", "profile", "productSingle", "testimonial", "topic", "whitepaper"] && isPublic == true`
// )

// THIS FAILED
// const groqDocs = vacuum(
//     `["account", "article", "category", "industry", "page", "profile", "productSingle", "resource", "testimonial", "topic", "whitepaper"] && isPublic == true`
// )
// THIS FAILED
// const groqDocs = vacuum(
//     `["account", "article", "category", "industry", "page", "product", "profile", "productSingle", "testimonial", "topic", "whitepaper"] && isPublic == true`
// )
// THIS FAILED
// const groqDocs = vacuum(
//     `["article", "category", "industry", "page", "product", "profile", "productSingle", "resource", "testimonial", "topic", "whitepaper"] && isPublic == true`
// )

const groqStringContentTemplate = `"contentTemplateTitle": contentTemplate[0]-> title`

// Account Pages
// const groqStringAccount = `
//     ...select(_type == "account" && isPublic == true => {
//         ...
//     })`
// Articles
// const groqStringArticle = `
//     ...select(_type == "article" && isPublic == true => {
//         ...
//     })`
// Categories
const groqStringCategory = `
    ...select(_type == "category" && isFeatured == true && isPublic == true => {
        ...,
        "subcategories": *[ _type == "category" && isPublic == true && references(^._id)]{
            ...,
            ${groqStringContent},
            "products": *[ _type == "product" && isPublic == true && references(^._id)]{
                _type,title,slug,thumbnail,sanityThumbnail,sequence,categories
            },
            setLandingPage{
                ...
                linkTo->{_type,slug,title,thumbnail},
                linkPartial,
                linkAnchor
            }
        },
        "products": *[ _type == "product" && isPublic == true && references(^._id)]{
            _type,title,slug,thumbnail,sanityThumbnail,sequence,categories
        }
    })`
// Industries
// const groqStringIndustry = `
//     ...select(_type == "industry" && isPublic == true => {
//         ...
//     })`
// Pages
// const groqStringPage = `
//     ...select(_type == "page" && isPublic == true => {
//         ...
//     })`
// Products (Configurator)
const groqStringProduct = `
    ...select(_type == "product" && isPublic == true => {
        ...,
        ${groqStringProductTables},
    })`
// Products (Single)
// const groqStringProductSingle = `
//     ...select(_type == "productSingle" && isPublic == true => {
//         ...
//     })`
// Resources
const groqStringResource = `
    ...select(_type == "resource" && isPublic == true => {
        ...,
        "assetPdf":sanityPdf.selectedFile.asset->{...},
        "assetCad":sanityCad.selectedFile.asset->{...}
    })`
// Topics
// const groqStringTopic = `
//     ...select(_type == "topic" && isPublic == true => {
//         ...
//     })`
// Whitepapers
// const groqStringWhitepaper = `
//     ...select(_type == "whitepaper" && isPublic == true => {
//         ...
//     })`
const groqRouteGuts = `_type,title,slug,sequence`
const groqPostGuts = `_type,title,slug,publishDate,thumbnail,sanityThumbnail,seometa,excerpt,sequence,isFeatured,topics[]->{...,sequence}`
const groqProfileGuts = `_id,_type,title,subtitle,slug,publishDate,thumbnail,sanityThumbnail,seometa,excerpt,sequence,${groqStringContent}`
const groqTestimonialGuts = `_id,_type,slug,logo,sanityLogo,thumbnail,sanityThumbnail,seometa,sequence`
const groqIndustryGuts = `_type,title,slug,sanityThumbnail,seometa,sequence`
const groqLocationGuts = `_type,title,subtitle,address`
const groqTopicGuts = `_type,title,slug,sequence`
// Section Checker
const groqSectionChecker = `
    "featured": select(
        contentSections.content[]._type match "sectionFeaturedCategories" => *[ _type in ["category"] && isFeatured == true && isPublic == true]{
            _type,title,slug,thumbnail,sanityThumbnail,seometa,sequence,changeLandingPage,
            setLandingPage{
                ...
                linkTo->{_type,slug,title,thumbnail}
            }
        }
    ),
    "featuredPosts": select(
        contentSections.content[]._type match "sectionFeaturedPosts" => *[ _type in ["article", "whitepaper"] && isFeatured == true && isPublic == true]{${groqPostGuts}}
    ),
    "featuredProfiles": select(
        contentSections.content[]._type match "sectionFeaturedProfiles" => *[ _type in ["profile"] && isFeatured == true && isPublic == true]{${groqProfileGuts}} | order(sequence asc)
    ),
    "featuredTestimonials": select(
        contentSections.content[]._type match "sectionFeaturedTestimonials" => *[ _type in ["testimonial"] && isFeatured == true && isPublic == true]{${groqTestimonialGuts}} | order(sequence asc)
    ),
    "industries": select(
        contentSections.content[]._type match "sectionFeaturedIndustries" => *[ _type in ["industry"] && isFeatured == true && isPublic == true]{${groqIndustryGuts}}
    ),
    "blog": select(
        contentSections.content[]._type match "sectionListBlog" => *[ _type in ["article", "whitepaper"] && isPublic == true]{${groqPostGuts}}
    ),
    "whitepapers": select(
        contentSections.content[]._type match "sectionListWhitepapers" => *[ _type in ["whitepaper"] && isPublic == true]{${groqPostGuts}}
    ),
    "topic": select(
        contentSections.content[]._type match "sectionListWhitepapers" => *[ _type in ["topic"] && isPublic == true]{${groqTopicGuts}}
    ),
    "topic": select(
        contentSections.content[]._type match "sectionListBlog" => *[ _type in ["topic"] && isPublic == true]{${groqTopicGuts}}
    ),
    "sidebar": select( showSidebar == true => {
        "recentPosts": [
            ...select(
                showSidebar == true => *[_type in ["whitepaper"] && isPublic == true]{${groqPostGuts}} | order(publishDate desc)[0..4]
            )
        ],
        "featuredPosts": [
            ...select(
                showSidebar == true => *[_type in ["article","whitepaper"] && isPublic == true && isFeatured == true]{${groqPostGuts}} | order(sequence asc)[0..4]
            )
        ],
        "blogTopics": [
            ...select(
                showSidebar == true => *[_type in ["topic"] && isPublic == true]{${groqRouteGuts}} | order(sequence asc)[0..20]
            )
        ]
    }),
    "locations": select(
        contentSections.content[]._type match "sectionLocationsMap" => *[ _type in ["location"] && isPublic == true]{${groqLocationGuts}}
    )`
// const groqSectionChecker = vacuum(groqSectionCheckerPretty)

const groqStringSeometa = `seometa{
    ...,
    canonical{
        ...,
        linkTo->{_type,slug,title}
    }
}`

// CURRENTLY UNUSED FROM ABOVE - MAY BE USED IN FUTURE
// ${ groqStringAccount },
// ${ groqStringArticle },
// ${ groqStringIndustry },
// ${ groqStringPage },
// ${ groqStringProductSingle },
// ${ groqStringResource },
// ${ groqStringTopic },
// ${ groqStringWhitepaper },
// ${ groqStringProductTables },
const groqStringGlobalPretty = `
    ...,
    ${vacuum(groqStringSeometa)},
    ${vacuum(groqStringCategory)},
    ${vacuum(groqStringProduct)},
    ${vacuum(groqStringContentTemplate)},
    ${vacuum(groqStringContent)},
    ${groqSectionChecker},
    ${groqStringResource}
`

// CONTENT ADMIN QUERIES
const groqDocsAdmin = `["attribute", "attributeTitle", "resourceTag", "resourceCategory"]`
const groqStringContentAdmin = vacuum(`"contentadmin": *[ _type in ${groqDocs} || _type in ${groqDocsAdmin}]{
    ...,
    ${groqStringGlobalPretty},
    parent[]->{
        title
    },
    contentTemplate[]->{
        ...
    }
}`)

const groqStringContentProducts = vacuum(`"contentproducts": *[ _type in ["product"] && _id == "0085dfec-b4c8-4980-899e-1347d8a0fb31"]{
    ...,
    ${groqStringGlobalPretty},
    parent[]->{
        title
    },
    contentTemplate[]->{
        ...
    }
}`)

// console.log('groqStringGlobal', groqStringGlobal)
const groqStringGlobal = groqStringGlobalPretty

export {
    groqDocs,
    groqStringGlobal,
    groqStringContentAdmin,
    groqStringContentProducts,
    groqDocsTranslatable,
    groqStringProduct,
    groqStringProductTables
}
