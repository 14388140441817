export const childDOLSlugs = [
    'dol-620',
    'dol-630',
    'dol-630-type-e-200v',
    'dol-630-type-e-240v',
    'dol-630-type-e-480y-277v',
    'dol-630-type-e-600y-347v',
    'dol-e620',
    'dol-e630',
    'dol-e630-type-e-200v',
    'dol-e630-type-e-240v',
    'dol-e630-type-e-480y-277v',
    'dol-e630-type-e-600y-347v',
]

export const interpolateDOLStartersSlug = (slug) => {
    if (childDOLSlugs.includes(slug)) {
        return 'direct-on-line-starters'
    } else {
        return slug
    }
}
