import fallbackDiscountSchedule from '~/util/fallbackDiscountSchedule'
const fetchPricelistEntry = (priceList = [], item) => {
    if (priceList.length === 0 || !item) {
        return null
    }
    const priceListTypeActual = priceList.filter(
        (i) => i.valueType === 'Actual'
    )
    const priceListTypeDiscount = priceList.filter(
        (i) => i.valueType === '% Discount'
    )
    const sortedPriceList = [...priceListTypeActual, ...priceListTypeDiscount]

    let selectedPriceListEntry = null
    for (let i = 0; i < sortedPriceList.length; i++) {
        // console.log('sortedPriceList[i]', sortedPriceList[i])
        const itemName = sortedPriceList[i]?.PriceListEntryItem?.name
        const priceListSchedule = sortedPriceList[i].schedule
        if (itemName === item.pn) {
            // console.log('itemName === item.pn...')
            // console.log('itemName:', itemName)
            // console.log('item.pn:', item.pn)
            // console.log('sortedPriceList[i]:', sortedPriceList[i])
            selectedPriceListEntry = sortedPriceList[i]
            break
        } else if (
            priceListSchedule &&
            priceListSchedule.includes(
                item.discount_schedule || fallbackDiscountSchedule(item.pn)
            )
        ) {
            // console.log('priceListSchedule.includes...')
            // console.log('priceListSchedule:', priceListSchedule)
            // console.log('item.discount_schedule:', item.discount_schedule)
            // console.log('fallbackDiscountSchedule(item.pn):', fallbackDiscountSchedule(item.pn))
            selectedPriceListEntry = sortedPriceList[i]
            break
        }
    }
    // console.log(selectedPriceListEntry)
    // console.log(sortedPriceList.filter((pl) => pl.schedule === 'Schedule Z'))
    return (
        selectedPriceListEntry ||
        sortedPriceList.filter((pl) => pl.schedule === 'Schedule Z')[0]
    )
}

export default fetchPricelistEntry
