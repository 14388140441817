<template>
    <div>
        <v-badge
            color="red"
            right
            offset-x="20px"
            offset-y="16px"
            dot
            :value="showBadge && !permCookie && showSubscriptionTag"
        >
            <v-btn
                v-show="!permCookie && showSubscriptionTag"
                icon
                class="btn--light"
                @click="handleSubscriptionBox"
            >
            <div class="menu-icon-wrapper">
                <fa
                    :icon="['fal', 'bell']"
                    class="fa-lg"
                />
            </div>
            <span class="menu-icon-subtext">
                        <TranslatableStringComponent :tstring="{string: 'subscribe'}" />
                    </span>
            </v-btn>
        </v-badge>
        <v-dialog
            v-if="$vuetify.breakpoint.mdAndUp"
            v-model="menu"
            max-width="500px"
            overlay-opacity="0.2"
            persistent
        >
            <SubscriptionBox
                @closeSubscriptionBox="menu = false"
                @hideSubscriptionTag="showSubscriptionTag = false"
            />
        </v-dialog>
        <v-bottom-sheet v-if="!$vuetify.breakpoint.mdAndUp" v-model="sheet" scrollable>
            <v-card height="510px">
                <v-card-text class="pa-0">
                    <SubscriptionBox
                        @closeSubscriptionBox="sheet = false"
                        @hideSubscriptionTag="showSubscriptionTag = false"
                    />
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>
<script>
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
import SubscriptionBox from '~/components/SubscriptionBox.vue'
import { mapState } from 'vuex'
import Cookies from 'js-cookie'
export default {
    name: 'SubscriptionTag',
    components: {
        SubscriptionBox,
        TranslatableStringComponent
    },
    data() {
        return {
            menu: false,
            showSubscriptionTag: false,
            sheet: false,
        }
    },
    computed: {
        ...mapState('subscription', ['showBadge']),
        permCookie() {
            return Cookies.get('c3_never_show_subscription_again')
        },
    },
    mounted(){
        this.showSubscriptionTag = true
    },
    methods: {
        handleSubscriptionBox() {
            this.menu = !this.menu
            this.sheet = !this.sheet
            this.$store.commit('subscription/handleShowBadge',false)
        },
    },
}
</script>
