export const state = () => ({
    webusers: []
})

export const actions = {
    //  async getWebUsers({ dispatch, commit, state, rootState, getters }, payload) {
    async getWebUsers({ commit }) {
        try {
            console.log('Starting lambda post...')
            const resp = await this.$axios.post('intacct-webuser-sync')
            console.log('Lambda post complete. Results:')
            console.log(resp.data.data._data)
            commit('setWebUsers', resp.data.data._data)
        } catch (err) {
            console.log(err)
        }
    }
}

export const mutations = {
    setWebUsers(state, payload) {
        state.webusers = payload
    }
}

export const getters = {
    hasWebUsers(state) {
        if (state.webusers.length > 0) {
            return true
        } else {
            return false
        }
    }
}
