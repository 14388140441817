import _ from 'lodash'
export const state = () => ({
    saveCartLoader: false,
    isCartSaved: false,
    shareableLink: false,
    isShareableLinkDisplayed: false,
    currentSavedCart: [],
    currentSavedCartId: '',
    currentSavedCartOwner: '',
    savedCarts: [],
    promptToMutateSavedCart: false,
    promptToMutateSavedCartMessage: '',
    toPage: '',
    savedCartLabel: '',
    maxSavedCartLimit: 50,
    showMaxLimitMessage: false
})

export const actions = {
    async createSavedCart(
        { dispatch, commit, state, rootState, getters, rootGetters },
        payload
    ) {
        const priceList = rootGetters['priceList/cartPriceListId']
        const email = rootGetters['auth/webUser']?.email
        let cartItems, subTotal, id, label
        if (payload) {
            ;({ cartItems, subTotal, id, label } = payload)
        } else {
            cartItems = rootGetters['cart/fetchCartProducts'].map((item) => {
                return {
                    pn: item.pn,
                    qty: item.prod_quantity,
                    listPrice: item.list_price,
                    yourPrice: item.salePrice,
                    img: item.img,
                }
            })
            subTotal = rootGetters['cart/fetchCartSubtotal']
            id = state.currentSavedCartId
            label = state.savedCartLabel
        }
        try {
            commit('setSaveCartLoader', true)
            const resp = await this.$axios.post('erp-cart?method=create', {
                priceList,
                subTotal,
                cartItems,
                email,
                isLive: false,
                label,
            })
            // console.log(resp)
            if (resp) {
                commit('setCurrentSavedCartId', resp.data.createCart.id)
                commit(
                    'setCurrentSavedCart',
                    rootGetters['cart/fetchCartProducts']
                )
                commit(
                    'setCurrentSavedOwner',
                    resp.data.createCart.CreatedByWebUserEmail
                )
                commit('setSavedCartLabel', resp.data.createCart.label)
            }
            commit('setSaveCartLoader', false)
        } catch (err) {
            console.log(err)
        }
    },
    async updateSavedCart(
        { dispatch, commit, state, rootState, getters, rootGetters },
        payload
    ) {
        const priceList = rootGetters['priceList/cartPriceListId']
        const email = rootGetters['auth/webUser']?.email
        let cartItems, subTotal, id, label
        if (payload) {
            ({ cartItems, subTotal, id, label } = payload)
        } else {
            cartItems = rootGetters['cart/fetchCartProducts'].map((item) => {
                return {
                    pn: item.pn,
                    qty: item.prod_quantity,
                    listPrice: item.list_price,
                    yourPrice: item.salePrice,
                    img: item.img,
                }
            })
            subTotal = rootGetters['cart/fetchCartSubtotal']
            id = state.currentSavedCartId
            label = state.savedCartLabel
        }
        try {
            const resp = await this.$axios.post('erp-cart?method=update', {
                id,
                priceList,
                subTotal,
                cartItems,
                email,
                isLive: false,
                label,
            })
            if (resp) {
                commit('setSavedCartLabel', resp.data.updateCart.label)
            }
            commit('setCurrentSavedCart', rootGetters['cart/fetchCartProducts'])
        } catch (err) {
            console.log(err)
        }
    },
    async fetchSavedCartById(
        { dispatch, commit, state, rootState, getters, rootGetters },
        payload
    ) {
        try {
            const resp = await this.$axios.post('erp-cart?method=read', {
                id: payload,
            })
            // console.log(resp)
            if (resp.data?.carts[0]?.id === '') {
                throw new Error('No such cart found')
            }

            const listOfPn = resp.data.carts[0]?.itemData.map((item) => item.pn)
            const elasticCartProducts = await dispatch(
                'fetchProductsFromElastic',
                listOfPn
            )
            const cartProducts = elasticCartProducts.map((elasticItem) => {
                const prodQuantity = resp.data.carts[0]?.itemData.find(
                    (item) => item.pn === elasticItem.pn
                ).qty
                return {
                    ...elasticItem,
                    prod_quantity: prodQuantity,
                }
            })
            commit(
                'priceList/setSelectedPriceListId',
                resp.data.carts[0]?.PriceList.name,
                {
                    root: true,
                }
            )
            await dispatch(
                'priceList/fetchAppliedPriceList',
                resp.data.carts[0]?.PriceList.name,
                {
                    root: true,
                }
            )
            const sortedCartProducts = _.sortBy(cartProducts, function (item) {
                return listOfPn.indexOf(item.pn)
            })
            commit('cart/loadCart', sortedCartProducts, { root: true })

            // Below code to check the shareable cart link belongs to the owner or not
            if (
                rootGetters['auth/isLoggedIn'] &&
                resp.data.carts[0]?.CreatedByWebUserEmail ===
                    rootGetters['auth/webUser'].email
            ) {
                commit('setCurrentSavedCartId', resp.data.carts[0]?.id)
                commit(
                    'setCurrentSavedOwner',
                    resp.data.carts[0]?.CreatedByWebUserEmail
                )
                commit(
                    'setCurrentSavedCart',
                    rootGetters['cart/fetchCartProducts']
                )
                commit('setSavedCartLabel', resp.data.carts[0]?.label)
            }
        } catch (err) {
            console.log(err)
        }
    },
    async fetchProductsFromElastic(
        { dispatch, commit, state, rootState, getters },
        payload
    ) {
        const res = await this.$axios.post('elastic-search', {
            elasticQuery: {
                size: 999,
                query: {
                    terms: {
                        pn: payload,
                    },
                },
            },
            elasticIndex: 'products-sanity',
        })
        return res.data.hits.hits
            .map((p) => ({
                ...p._source,
                pkgqty: p._source.pkgqty || 1,
            }))
            .filter((obj, pos, arr) => {
                // removing duplicate products with same 'pn' number
                return (
                    arr.map((mapObj) => obj.pn && mapObj.pn).indexOf(obj.pn) ===
                    pos
                )
            })
    },
    async fetchSavedCarts(
        { dispatch, commit, state, rootState, rootGetters, getters },
        payload
    ) {
        try {
            const resp = await this.$axios.post('erp-cart?method=read', {
                email: rootGetters['auth/webUser']?.email,
                isLive: false,
            })
            commit('setSavedCarts', resp.data.carts)
            commit(
                'setCurrentSavedOwner',
                resp.data.carts[0]?.CreatedByWebUserEmail
            )
        } catch (err) {
            console.log(err)
        }
    },
    async deleteSavedCart(
        { dispatch, commit, state, rootState, getters },
        payload
    ) {
        try {
            const resp = await this.$axios.post('erp-cart?method=delete', {
                id: payload,
            })
            if (resp.data.deleteCart.id === payload) {
                const filteredSavedCarts = state.savedCarts.filter(
                    (item) => item.id !== payload
                )
                commit('setSavedCarts', filteredSavedCarts)
            }
        } catch (err) {
            console.log(err)
        }
    },
    clearSavedCartData(
        { dispatch, commit, state, rootState, getters },
        payload
    ) {
        commit('setCurrentSavedCart', [])
        commit('setCurrentSavedCartId', null)
        commit('displayShareableLink', false)
        commit('setSavedCartLabel', '')
    },
    setPromptToMutateSavedCart(
        { dispatch, commit, state, rootState, getters },
        payload
    ) {
        commit('setPromptToMutateSavedCart', payload)
    },
}

export const mutations = {
    setSaveCartLoader(state, payload) {
        state.saveCartLoader = payload
    },
    setShareableLink(state, payload) {
        state.shareableLink = payload
    },
    displayShareableLink(state, payload) {
        state.isShareableLinkDisplayed = payload
    },
    setCurrentSavedCart(state, payload) {
        state.currentSavedCart = payload
    },
    setCurrentSavedCartId(state, payload) {
        state.currentSavedCartId = payload
    },
    setCurrentSavedOwner(state, payload) {
        state.currentSavedCartOwner = payload
    },
    setSavedCarts(state, payload) {
        state.savedCarts = payload
    },

    setPromptToMutateSavedCart(state, payload) {
        state.promptToMutateSavedCart = payload
    },
    setPromptToMutateSavedCartMessage(state, payload) {
        state.promptToMutateSavedCartMessage = payload
    },
    setToPage(state, payload) {
        state.toPage = payload
    },
    initSavedCarts(state) {
        if (localStorage.getItem('c3SavedCarts')) {
            const localSavedCarts = JSON.parse(
                localStorage.getItem('c3SavedCarts')
            )
            for (const key in localSavedCarts) {
                if (state.hasOwnProperty(key)) {
                    state[key] = localSavedCarts[key]
                }
            }
        }
    },
    setSavedCartLabel(state, payload) {
        state.savedCartLabel = payload
    },
    showMaxLimitMessage(state, payload) {
        state.showMaxLimitMessage = payload
    }
}

export const getters = {
    isSavedcartUpdated(state, getters, rootState, rootGetters) {
        if (
            state.currentSavedCart.length > 0 &&
            rootGetters['cart/fetchCartProducts'].length > 0
        ) {
            return (
                JSON.stringify(
                    state.currentSavedCart.map((item) => {
                        return {
                            pn: item.pn,
                            prod_quantity: item.prod_quantity,
                            salePrice: item.salePrice,
                        }
                    })
                ) !==
                JSON.stringify(
                    rootGetters['cart/fetchCartProducts'].map((item) => {
                        return {
                            pn: item.pn,
                            prod_quantity: item.prod_quantity,
                            salePrice: item.salePrice,
                        }
                    })
                )
            )
        } else {
            return undefined
        }
    },
    isCartSaved(state, getters, rootState, rootGetters) {
        if (state.currentSavedCart.length > 0) {
            return true
        } else {
            return false
        }
    },
    isSavedCartOwned(state, getters, rootState, rootGetters) {
        return (
            rootGetters['auth/webUser'] &&
            rootGetters['auth/webUser'].email === state.currentSavedCartOwner
        )
    },
    maxSavedCartLimit(state) {
        return state.maxSavedCartLimit
    }
}
