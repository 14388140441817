<template>
    <v-dialog v-model="showMaintenanceNotice" width="605" persistent>
        <v-card id="holiday-notice">
            <v-container fluid class="pa-0 my-10">
                <v-row column justify-center align-center class="pa-0">
                    <v-col cols="12" class="text-center pa-0">
                        <SettingIcon />
                        <h2> <TranslatableStringComponent :tstring="{string: 'websiteUnderMaintenance'}" /></h2>
                        <p>
                            <TranslatableStringComponent :tstring="{string: 'pleaseCheckBackSoonForUpdates'}" />
                        </p></v-col
                    >
                </v-row>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState } from 'vuex'
import SettingIcon from '../animatedIcons/SettingIcon.vue'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
export default {
    name: 'MaintenanceNotice',
    components: {
        SettingIcon,
        TranslatableStringComponent,
    },
    data() {
        return {}
    },
    computed: {
        ...mapState('common', ['noticeSettings']),
        showMaintenanceNotice() {
            return process.env.V2_BASE_URL !== 'https://www.c3controls.com'
                ? this.noticeSettings?.maintenanceNoticeDev
                : this.noticeSettings?.maintenanceNotice
        },
    },
}
</script>
