<template>
    <div :style="`font-size:${fontSize}px`">{{ this.$slots.default[0].text }}</div>
</template>
<script>
export default {
    name: 'EmbedFontSize',
    props: {
        fontSize: {
            type: String,
            default: '',
        },
    },
}
</script>
