var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.menuId}},[_vm._l((_vm.menu.menuItems),function(menuItem,i){return _c('v-menu',{key:i,attrs:{"close-on-content-click":true,"open-on-hover":true,"offset-y":true,"nudge-left":60},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var hover = ref.on;
return [_c('nuxt-link',{class:("nav-a--text" + (parseInt(i) + 1 === _vm.menu.menuItems.length
                        ? ' mr-0off'
                        : '')),attrs:{"to":_vm.getCustomLocalePath(menuItem),"nuxt":"","exact":"","color":"primary"}},[_c('span',_vm._g({},hover),[_vm._v(_vm._s(menuItem.menuItemLabel[_vm.$i18n.locale])+"\n                ")])]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(i > 0 && i < _vm.menu.menuItems.length),expression:"i > 0 && i < menu.menuItems.length"}],staticClass:"nav-sep--text"},[_vm._v("|")])]}}],null,true)},[_vm._v(" "),(menuItem.menuItemLabel.en === 'Shop')?_c('div',[_c('ShopPageNavigationMenu')],1):(
                menuItem.submenuItems && menuItem.submenuItems.length > 0
            )?_c('v-list',{attrs:{"dense":""}},[(menuItem.menuItemLabel.en.includes('My Account'))?_c('v-list-item',[(_vm.isLoggedIn)?_c('v-list-item-title',{staticClass:"py-3"},[_c('div',{staticClass:"text-uppercase font-weight-bold"},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'hello'}}}),_vm._v(", "+_vm._s(_vm.userName)+"\n                    ")],1),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.webUser && _vm.webUser.email))])]):_c('v-list-item-title',{staticClass:"py-3"},[_c('nuxt-link',{staticClass:"nav-a--text",attrs:{"to":_vm.getStaticLocalePath({
                                type: 'page',
                                slugName: 'login',
                            })}},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'loginRegister'}}})],1)],1)],1):_vm._e(),_vm._v(" "),_c('v-divider'),_vm._v(" "),_vm._l((menuItem.submenuItems),function(submenuItem,j){return _c('v-list-item',{key:j},[(_vm.isExternal(submenuItem, _vm.$i18n.locale))?_c('v-list-item-title',[_c('a',{staticClass:"nav-a--text",attrs:{"href":submenuItem.submenuItemURLo[_vm.$i18n.locale]}},[_vm._v("\n                        "+_vm._s(submenuItem.submenuItemLabel[_vm.$i18n.locale])+"\n                    ")])]):(_vm.isInternal(submenuItem, _vm.$i18n.locale))?_c('v-list-item-title',[_c('nuxt-link',{staticClass:"nav-a--text",attrs:{"to":_vm.getCustomLocalePath(submenuItem),"nuxt":"","exact":""}},[_vm._v("\n                        "+_vm._s(submenuItem.submenuItemLabel[_vm.$i18n.locale])+"\n                    ")])],1):_vm._e()],1)})],2):_vm._e()],1)}),_vm._v(" "),_c('span',{staticClass:"nav-sep--text"},[_vm._v("|")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn),expression:"isLoggedIn"}]},[_c('a',{staticClass:"d-none d-md-flex nav-a--text nav-a--logout nohover",attrs:{"small":"","outlined":""},on:{"click":function($event){_vm.dialog = true}}},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'logout'}}})],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoggedIn),expression:"!isLoggedIn"}]},[_c('nuxt-link',{staticClass:"d-none d-md-flex nav-a--text nav-a--register",attrs:{"to":_vm.getStaticLocalePath({
                    type: 'page',
                    slugName: 'register',
                }),"color":"primary","small":""}},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'register'}}})],1)],1),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoggedIn),expression:"!isLoggedIn"}],staticClass:"nav-sep--text"},[_vm._v("|")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoggedIn),expression:"!isLoggedIn"}]},[_c('nuxt-link',{staticClass:"d-none d-md-flex nav-a--text nav-a--login nohover",attrs:{"to":_vm.getStaticLocalePath({
                    type: 'page',
                    slugName: 'login',
                }),"color":"secondary","small":""}},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'login'}}})],1)],1),_vm._v(" "),_c('LanguageSwitch',{attrs:{"seperator":true}}),_vm._v(" "),_c('dialogLogout',{attrs:{"dialog":_vm.dialog},on:{"handleDialog":function($event){return _vm.handleDialog()}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }