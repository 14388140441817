import _ from 'lodash'
import { Object } from 'core-js'

export default class {
    constructor(allTables, selectedConfig) {
        this.allTables = _.cloneDeep(allTables)
        this.selectedConfigObj = this.getSeletectedOptionObj(
            selectedConfig,
            this.allTables
        )
        this.operatorSyntax = {
            AND: '&&',
            OR: '||',
            END: ''
        }
    }

    getSeletectedOptionObj(selectedConfig, allTables) {
        const selectedConfigObj = {}
        for (const key in selectedConfig) {
            if (selectedConfig.hasOwnProperty(key)) {
                selectedConfigObj[key] = _.chain(allTables)
                    .find({ _id: key })
                    .get('productOptions')
                    .find({ _id: selectedConfig[key] })
                    .value()
            }
        }
        return selectedConfigObj
    }

    get SeletectedOption() {
        const selectedConfig = {}
        for (const key in this.selectedConfigObj) {
            selectedConfig[key] =
                this.selectedConfigObj &&
                this.selectedConfigObj[key] &&
                this.selectedConfigObj[key]._id
                    ? this.selectedConfigObj[key]._id
                    : {}
        }
        return selectedConfig
    }

    get SelectedOptionIDs() {
        return _.chain(this.selectedConfigObj)
            .values()
            .map('_id')
            .value()
    }

    getSelectedTableID(currentTableID) {
        return Array.from(Object.entries(this.selectedConfigObj))
            .filter(([tableId]) => {
                const loopingTableIndex = _.findIndex(this.allTables, {
                    _id: tableId
                })
                const currentTableIndex = _.findIndex(this.allTables, {
                    _id: currentTableID
                })
                return loopingTableIndex < currentTableIndex
            })
            .map(([_, option]) => (option && option._id ? option._id : null))
    }

    get SelectedtableIDs() {
        return _.keys(this.selectedConfigObj)
    }

    removeTableIDs(tableIds) {
        const matchedTableIDs = _.intersection(
            tableIds,
            _.map(this.allTables, '_id')
        )

        this.removeTableIDfromSelectedConfig(tableIds)

        this.allTables = this.allTables.filter(
            (t) => !matchedTableIDs.includes(t._id)
        )
    }

    removeTableIDfromSelectedConfig(tableIds) {
        tableIds.forEach((tableId) => {
            delete this.selectedConfigObj[tableId]
        })
    }

    removeOptionsfromSelectedConfig(optionIDs) {
        optionIDs = optionIDs.filter(
            (optionId) =>
                !this.allTables[0].productOptions.some(
                    (opt) => opt._id === optionId
                )
        )
        Object.keys(this.selectedConfigObj).forEach((tableId) => {
            if (
                this.selectedConfigObj[tableId] &&
                this.selectedConfigObj[tableId]._id &&
                optionIDs.includes(this.selectedConfigObj[tableId]._id)
            ) {
                delete this.selectedConfigObj[tableId]
            }
        })
    }

    removeEmptytables() {
        const emptyTables = _.chain(this.allTables)
            .filter((t) => t.productOptions.length === 0)
            .map('_id')
            .value()
        this.removeTableIDs(emptyTables)
    }

    isSubSet(subsetArr, supersetArr) {
        return (
            subsetArr.length === _.intersection(subsetArr, supersetArr).length
        )
    }

    filterIncompatibleOptionswithSelectedOptionANY() {
        this.allTables.forEach((table, index) => {
            if (index === 0) return false
            const allIncompatOptionIDs = _.chain(this.selectedConfigObj)
                .values()
                .map('incompatibleWith')
                .flatten()
                .filter({ _type: 'productOption' })
                .map('_id')
                .value()

            table.productOptions = table.productOptions.filter(
                (p) => !allIncompatOptionIDs.includes(p._id)
            )
            this.removeEmptytables()
            this.removeOptionsfromSelectedConfig(allIncompatOptionIDs)
        })
    }

    filterIncompatibleTableswithSelectedOptionANY() {
        const AllIncompatTableIDs = _.chain(this.selectedConfigObj)
            .values()
            .map('incompatibleWith')
            .flatten()
            .filter({ _type: 'productTable' })
            .map('_id')
            .value()
        this.removeTableIDs(AllIncompatTableIDs)
    }

    filterIncompatibleTablesBySelectedConfigANY() {
        const AllIncompatTables = this.allTables
            .filter((table, index) => {
                if (index === 0) return false
                if (
                    table.incompatibleWith &&
                    table.incompatibleWith.length > 0
                ) {
                    const inCompatOptions = _.chain(table.incompatibleWith)
                        .filter({
                            _type: 'productOption'
                        })
                        .map('_id')
                        .value()
                    if (
                        _.intersection(
                            inCompatOptions,
                            this.getSelectedTableID(table._id)
                        ).length > 0
                    ) {
                        this.removeTableIDfromSelectedConfig([table._id])
                        return true
                    }
                }
                return false
            })
            .map((table) => table._id)
        this.removeTableIDs(AllIncompatTables)
    }

    filterIncompatibleOptionsBySelectedConfigANY() {
        this.allTables.forEach((table, index) => {
            if (index === 0) return
            table.productOptions = table.productOptions.filter((option) => {
                let isvalid = true
                if (
                    option.incompatibleWith &&
                    option.incompatibleWith.length > 0
                ) {
                    const incompatOptions = _.chain(option.incompatibleWith)
                        .filter({
                            _type: 'productOption'
                        })
                        .map('_id')
                        .value()
                    const incompatTables = _.chain(option.incompatibleWith)
                        .filter({
                            _type: 'productTable'
                        })
                        .map('_id')
                        .value()
                    if (
                        _.intersection(incompatOptions, this.SelectedOptionIDs)
                            .length > 0 ||
                        _.intersection(incompatTables, this.SelectedtableIDs)
                            .length > 0
                    ) {
                        isvalid = false
                        this.removeOptionsfromSelectedConfig([option._id])
                    }
                }
                return isvalid
            })
            this.removeEmptytables()
        })
    }
    filterIncompatibleOptionsBySelectedConfigAND() {
        this.allTables.forEach((table, index) => {
            if (index === 0) return
            table.productOptions = table.productOptions.filter((option) => {
                let isvalid = true
                if (
                    option.incompatibleWithAll &&
                    option.incompatibleWithAll.length > 0
                ) {
                    const incompatOptions = _.chain(option.incompatibleWithAll)
                        .filter({
                            _type: 'productOption'
                        })
                        .map('_id')
                        .value()
                    const incompatTables = _.chain(option.incompatibleWithAll)
                        .filter({
                            _type: 'productTable'
                        })
                        .map('_id')
                        .value()
                    if (
                        this.isSubSet(
                            incompatOptions,
                            this.SelectedOptionIDs
                        ) &&
                        this.isSubSet(incompatTables, this.SelectedtableIDs)
                    ) {
                        isvalid = false
                        this.removeOptionsfromSelectedConfig([option._id])
                    }
                }
                return isvalid
            })
            this.removeEmptytables()
        })
    }
    filterIncompatibleTablesBySelectedOptionAND() {
        const AllIncompatTables = this.allTables
            .filter((table, index) => {
                if (index === 0) return false
                if (
                    table.incompatibleWithAll &&
                    table.incompatibleWithAll.length > 0
                ) {
                    const inCompatOptions = _.chain(table.incompatibleWithAll)
                        .filter({
                            _type: 'productOption'
                        })
                        .map('_id')
                        .value()
                    const incompatTables = _.chain(table.incompatibleWithAll)
                        .filter({
                            _type: 'productTable'
                        })
                        .map('_id')
                        .value()
                    if (
                        this.isSubSet(
                            inCompatOptions,
                            this.SelectedOptionIDs
                        ) &&
                        this.isSubSet(incompatTables, this.SelectedtableIDs)
                    ) {
                        this.removeTableIDfromSelectedConfig([table._id])
                        return true
                    }
                }
                return false
            })
            .map((table) => table._id)
        this.removeTableIDs(AllIncompatTables)
    }

    filterOnlycompatibleOptionsBySelectedConfigANY() {
        this.allTables.forEach((table, index) => {
            if (index === 0) return
            table.productOptions = table.productOptions.filter((option) => {
                const isvalid = true
                if (option.compatibleWith && option.compatibleWith.length > 0) {
                    const compatOptions = _.chain(option.compatibleWith)
                        .filter({
                            _type: 'productOption'
                        })
                        .map('_id')
                        .value()
                    const compatTables = _.chain(option.compatibleWith)
                        .filter({
                            _type: 'productTable'
                        })
                        .map('_id')
                        .value()
                    if (
                        _.intersection(compatOptions, this.SelectedOptionIDs)
                            .length > 0 ||
                        _.intersection(compatTables, this.SelectedtableIDs)
                            .length > 0
                    ) {
                        return true
                    } else {
                        this.removeOptionsfromSelectedConfig([option._id])
                        return false
                    }
                }
                return isvalid
            })
            this.removeEmptytables()
        })
    }

    filterOnlycompatibleTablesBySelectedConfigANY() {
        const AllIncompatTables = this.allTables
            .filter((table, index) => {
                if (index === 0) return false
                if (table.compatibleWith && table.compatibleWith.length > 0) {
                    const compatOptions = _.chain(table.compatibleWith)
                        .filter({
                            _type: 'productOption'
                        })
                        .map('_id')
                        .value()
                    const compatTables = _.chain(table.compatibleWith)
                        .filter({
                            _type: 'productTable'
                        })
                        .map('_id')
                        .value()
                    if (
                        _.intersection(compatOptions, this.SelectedOptionIDs)
                            .length > 0 ||
                        _.intersection(compatTables, this.SelectedtableIDs)
                            .length > 0
                    ) {
                        return false
                    } else {
                        this.removeTableIDfromSelectedConfig([table._id])
                        return true
                    }
                }
                return false
            })
            .map((table) => table._id)
        this.removeTableIDs(AllIncompatTables)
    }

    filterOnlycompatibleOptionsBySelectedConfigAND() {
        this.allTables.forEach((table, index) => {
            if (index === 0) return
            table.productOptions = table.productOptions.filter((option) => {
                const isvalid = true
                if (
                    option.compatibleWithAll &&
                    option.compatibleWithAll.length > 0
                ) {
                    const compatOptions = _.chain(option.compatibleWithAll)
                        .filter({
                            _type: 'productOption'
                        })
                        .map('_id')
                        .value()
                    const compatTables = _.chain(option.compatibleWithAll)
                        .filter({
                            _type: 'productTable'
                        })
                        .map('_id')
                        .value()
                    if (
                        this.isSubSet(compatOptions, this.SelectedOptionIDs) &&
                        this.isSubSet(compatTables, this.SelectedtableIDs)
                    ) {
                        return true
                    } else {
                        this.removeOptionsfromSelectedConfig([option._id])
                        return false
                    }
                }
                return isvalid
            })
            this.removeEmptytables()
        })
    }

    filterOnlycompatibleTablesBySelectedConfigAND() {
        const AllIncompatTables = this.allTables
            .filter((table, index) => {
                if (index === 0) return false
                if (
                    table.compatibleWithAll &&
                    table.compatibleWithAll.length > 0
                ) {
                    const compatOptions = _.chain(table.compatibleWithAll)
                        .filter({
                            _type: 'productOption'
                        })
                        .map('_id')
                        .value()
                    const compatTables = _.chain(table.compatibleWithAll)
                        .filter({
                            _type: 'productTable'
                        })
                        .map('_id')
                        .value()
                    if (
                        this.isSubSet(compatOptions, this.SelectedOptionIDs) &&
                        this.isSubSet(compatTables, this.SelectedtableIDs)
                    ) {
                        return false
                    } else {
                        this.removeTableIDfromSelectedConfig([table._id])
                        return true
                    }
                }
                return false
            })
            .map((table) => table._id)
        this.removeTableIDs(AllIncompatTables)
    }

    filterTableByCondition() {
        const AllIncompatTables = this.allTables
            .filter((table, index) => {
                if (index === 0) return false

                const { setsOfConditions } = table
                const isInCompatible =
                    this.getIsCompatible(setsOfConditions) === false
                isInCompatible &&
                    this.removeTableIDfromSelectedConfig([table._id])
                return isInCompatible
            })
            .map((table) => table._id)
        this.removeTableIDs(AllIncompatTables)
    }

    filterOptionsByCondition() {
        this.allTables.forEach((table, index) => {
            if (index === 0) return
            table.productOptions = table.productOptions.filter((option) => {
                const { setsOfConditions } = option
                const isCompatible = this.getIsCompatible(setsOfConditions)
                !isCompatible &&
                    this.removeOptionsfromSelectedConfig([option._id])
                return isCompatible
            })
            this.removeEmptytables()
        })
    }
    getIsCompatible(setsOfConditions) {
        if ((setsOfConditions?.length || 0) === 0) return true
        const conditionString = setsOfConditions
            .map((condition) => {
                const { setConditions, setOperator } = condition
                const innerConditionString = setConditions
                    .map((setCondition) => {
                        const {
                            arrayCondition,
                            arrayOfTablesAndOptions,
                            arrayOperator
                        } = setCondition
                        const idsToCheck = _.map(arrayOfTablesAndOptions, '_id')
                        return [
                            this.resolveCondition(idsToCheck, arrayCondition),
                            this.operatorSyntax[arrayOperator]
                        ]
                    })
                    .flat()
                    .join(' ')
                return [
                    // eslint-disable-next-line no-eval
                    eval(innerConditionString),
                    this.operatorSyntax[setOperator]
                ]
            })
            .flat()
            .join(' ')
        // eslint-disable-next-line no-eval
        return eval(conditionString)
    }

    resolveCondition(idsToCheck, condition) {
        if (condition === 'ANY') {
            return this.compatibleAny(idsToCheck)
        } else if (condition === 'ALL') {
            return this.compatibleAll(idsToCheck)
        } else if (condition === 'NOT ANY') {
            return this.inCompatibleAny(idsToCheck)
        } else if (condition === 'NOT ALL') {
            return this.inCompatibleAll(idsToCheck)
        } else {
            // eslint-disable-next-line no-throw-literal
            throw 'Condition not Implemented'
        }
    }

    compatibleAny(idsToCheck) {
        return (
            _.intersection(idsToCheck, [
                ...this.SelectedOptionIDs,
                ...this.SelectedtableIDs
            ]).length > 0
        )
    }

    compatibleAll(idsToCheck) {
        return this.isSubSet(idsToCheck, [
            ...this.SelectedOptionIDs,
            ...this.SelectedtableIDs
        ])
    }

    inCompatibleAny(idsToCheck) {
        return !this.compatibleAny(idsToCheck)
    }

    inCompatibleAll(idsToCheck) {
        return !this.compatibleAll(idsToCheck)
    }

    getFilteredValues() {
        // // filter Producttables which are not compatible with Any of the selected option
        // topdown validation
        this.filterIncompatibleTableswithSelectedOptionANY()
        this.filterIncompatibleOptionswithSelectedOptionANY()

        // bottoms to top validation
        this.filterIncompatibleTablesBySelectedConfigANY()
        this.filterIncompatibleOptionsBySelectedConfigANY()

        // bottoms to top validation
        this.filterIncompatibleOptionsBySelectedConfigAND()
        this.filterIncompatibleTablesBySelectedOptionAND()

        // bottoms to top validation
        this.filterOnlycompatibleOptionsBySelectedConfigANY()
        this.filterOnlycompatibleTablesBySelectedConfigANY()

        // bottoms to top validation
        this.filterOnlycompatibleOptionsBySelectedConfigAND()
        this.filterOnlycompatibleTablesBySelectedConfigAND()

        this.filterTableByCondition()
        this.filterOptionsByCondition()
        return {
            newselectedConfig: this.SeletectedOption,
            filteredTables: this.allTables
        }
    }
}
