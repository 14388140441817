<template>
    <div>
        <div v-for="i in bullets" :key="i" :style="`font-size:${fontSize}px`">
        <li :style="bulletCss">{{i}}</li>
        </div>
    </div>
    
</template>
<script>
export default {
    name: 'EmbedBullet',
    props: {
        sanityImage: {
            type: Object,
            default: null,
        },
        bulletPoints: {
            type: String,
            default: ''
        },
        fontSize: {
            type: String,
            default: '',
        },
    },
    computed: {
        imageUrl(){
            return this.$img(`${process.env.ASSET_HOSTNAME}${
                this.sanityImage?.selectedImage?.asset
                    ?.originalFilename || 'c3controls-icon.jpg'
            }?fit=scale`, {format: 'webp'})
        },
        bulletCss() {
            return `background-image:url(${this.imageUrl});
                    padding-left: 1.6rem;
                    background-position: left;
                    background-size: 1rem 1rem;
                    background-repeat: no-repeat;
                    list-style-type: none;`
        },
        bullets() {
            return this.bulletPoints?.split(',') || []
        }
    }
}
</script>
