// import axios from 'axios'
import { client } from '~/plugins/global.js'
import roundToTwo from '~/util/roundToTwo'

export const state = () => ({
    couponCode: '',
    couponObj: null,
    isCouponChecked: false,
    loader: false,
})

export const actions = {
    async checkCouponExist(
        { dispatch, commit, state, rootState, getters },
        payload
    ) {
        commit('setLoader', true)

        commit('setCouponCode', payload)
        // const api =
        //     'https://tkk9p83q.api.sanity.io/v1/data/query/production?query='
        const groq = `*[_type in ["coupon"] && code=="${payload}" && isPublic==true]{...}`

        // const query = api + encodeURIComponent(groq)

        try {
            const resp = await client.fetch(groq)
            commit('setLoader', false)
            commit('setCouponChecked', true)
            commit('setCouponObj', resp[0])
        } catch (err) {
            commit('setLoader', false)
            console.log('ERROR NAME:', err.name)
            console.log('ERROR MESSAGE:', err.message)
            console.log('ERROR STACK:', err.stack)
        } finally {
            commit('setCouponChecked', true)
        }
    },
    removeCoupon({ dispatch, commit, state, rootState, getters }) {
        commit('setCouponObj', null)
        commit('setCouponChecked', false)
        commit('setCouponCode', '')
    },
}

export const mutations = {
    setCouponCode(state, payload) {
        state.couponCode = payload
    },
    setCouponObj(state, payload) {
        state.couponObj = payload
    },
    setCouponChecked(state, payload) {
        state.isCouponChecked = payload
    },
    setLoader(state, payload) {
        state.loader = payload
    },
}

export const getters = {
    cartItemsApplicableForDiscount(state, getters, rootState, rootGetters) {
        if (getters.categoriesIncluded.length > 0) {
            return getters.cartItemsIncluded
        } else if (getters.categoriesExcluded.length > 0) {
            return rootGetters['cart/fetchCartProducts'].filter(
                (item) => !getters.cartItemIdsExcluded.includes(item._id)
            )
        } else {
            return rootGetters['cart/fetchCartProducts']
        }
    },
    netTotalApplicableForDiscount(state, getters, rootState, rootGetters) {
        if (state.couponObj) {
            return getters.cartItemsApplicableForDiscount.reduce(
                (acc, curr) => {
                    return acc + curr.subTotalSalePrice
                },
                0
            )
        } else {
            return 0
        }
    },
    discountValue(state, getters) {
        if (
            state.couponObj &&
            getters.doesCouponSatisfiesMinCartValue &&
            !getters.isCouponExpired &&
            !getters.isCouponYetToBegin
        ) {
            if (state.couponObj.isDollars === false) {
                return roundToTwo(
                    getters.netTotalApplicableForDiscount *
                        (state.couponObj.discount / 100),
                    0
                )
            } else {
                return state.couponObj.discount
            }
        } else {
            return 0
        }
    },
    doesCouponSatisfiesMinCartValue(state, getters, rootState, rootGetters) {
        if (state.couponObj) {
            return (
                getters.netTotalApplicableForDiscount > state.couponObj.minimum
            )
        } else {
            return false
        }
    },
    isCouponExpired(state) {
        if (state.couponObj) {
            if (state.couponObj.endDate) {
                const endDate = new Date(state.couponObj.endDate)
                const currrentDate = new Date()
                return currrentDate > endDate
            } else {
                return false
            }
        } else {
            return false
        }
    },
    isCouponYetToBegin(state) {
        if (state.couponObj) {
            if (state.couponObj.startDate) {
                const startDate = new Date(state.couponObj.startDate)
                const currrentDate = new Date()
                return currrentDate < startDate
            } else {
                return false
            }
        } else {
            return false
        }
    },
    categoriesIncluded(state) {
        if (
            state.couponObj &&
            state.couponObj.including &&
            state.couponObj.including.length > 0
        ) {
            return state.couponObj.including.map((item) => item._ref)
        } else {
            return []
        }
    },
    categoriesExcluded(state) {
        if (
            state.couponObj &&
            state.couponObj.excluding &&
            state.couponObj.excluding.length > 0
        ) {
            return state.couponObj.excluding.map((item) => item._ref)
        } else {
            return []
        }
    },
    cartItemsIncluded(state, getters, rootState, rootGetters) {
        if (getters.categoriesIncluded.length > 0) {
            return rootGetters['cart/fetchCartProducts'].filter((item) => {
                return (
                    item.categoriesObj &&
                    item.categoriesObj.some((c) =>
                        getters.categoriesIncluded.includes(c._id)
                    )
                )
            })
        } else {
            return []
        }
    },
    cartItemIdsExcluded(state, getters, rootState, rootGetters) {
        if (getters.categoriesExcluded.length > 0) {
            return rootGetters['cart/fetchCartProducts']
                .filter((item) => {
                    return (
                        item.categoriesObj &&
                        item.categoriesObj.some((c) =>
                            getters.categoriesExcluded.includes(c._id)
                        )
                    )
                })
                .map((item) => item._id)
        } else {
            return []
        }
    },
    cartItemsExcluded(state, getters, rootState, rootGetters) {
        if (getters.categoriesExcluded.length > 0) {
            return rootGetters['cart/fetchCartProducts'].filter((item) => {
                return (
                    item.categoriesObj &&
                    item.categoriesObj.some((c) =>
                        getters.categoriesExcluded.includes(c._id)
                    )
                )
            })
        } else {
            return []
        }
    },
    isCouponValid(state, getters) {
        return (
            state.couponObj &&
            !getters.isCouponExpired &&
            !getters.isCouponYetToBegin &&
            getters.doesCouponSatisfiesMinCartValue &&
            getters.categoryInclusionCriteria &&
            getters.categoryExclusionCriteria
        )
    },
    fetchCouponError(state, getters, rootState, rootGetters) {
        if (state.isCouponChecked && !state.couponObj) {
            return 'Coupon code is invalid.'
        } else if (getters.isCouponExpired) {
            return 'Coupon has expired!'
        } else if (getters.isCouponYetToBegin) {
            return 'Coupon offer will start soon!'
        } else if (
            getters.categoriesIncluded.length > 0 &&
            getters.cartItemsIncluded.length === 0
        ) {
            // This means none the items present in cart are included
            return 'Coupon is not applicable for current cart items.'
        } else if (
            getters.categoriesExcluded.length > 0 &&
            getters.cartItemsExcluded.length ===
                rootGetters['cart/fetchCartProducts'].length
        ) {
            // This means all the items present in cart are excluded
            return 'Coupon is not applicable for current cart items.'
        } else if (
            !getters.doesCouponSatisfiesMinCartValue &&
            state.couponObj
        ) {
            return `Coupon does not statisfy minimum cart value of $${state.couponObj.minimum}.`
        } else {
            return ''
        }
    },
    fetchSuccessMsg() {
        return 'Coupon code has been applied successfully.'
    },
    categoryInclusionCriteria(state, getters) {
        if (getters.categoriesIncluded.length > 0) {
            return getters.cartItemsIncluded.length !== 0
        } else {
            return true
        }
    },
    categoryExclusionCriteria(state, getters, rootState, rootGetters) {
        if (getters.categoriesExcluded.length > 0) {
            return (
                getters.cartItemsExcluded.length <
                rootGetters['cart/fetchCartProducts'].length
            )
        } else {
            return true
        }
    },
}
