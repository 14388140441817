<template>
    <v-card style="background: #fff" :light="true">
        <div v-if="showHeading" class="text-center pt-2">
            <span class="primary--text text-h4"><TranslatableStringComponent :tstring="{string: 'selectAProductCategory'}" /></span>
        </div>
        <v-list-item-group color="primary">
            <v-container>
                <v-row no-gutters>
                    <v-col
                        v-for="(colSection, si) in globalSection"
                        :key="si"
                        sm="4"
                    >
                        <template v-for="(item, index) in colSection">
                            <div :key="index">
                                <v-list-item :disabled="true" dense>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            class="primary--text font-weight-bold"
                                            >{{
                                                item.label[$i18n.locale]
                                            }}</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    dense
                                    v-for="(s, i) in item.shopMenuItems"
                                    :key="i"
                                    :to="handleCategoryRoute(s.category)"
                                    @click="$emit('handleCategoryModal')"
                                    exact
                                >
                                    <v-list-item-content>
                                        <v-list-item-subtitle
                                            class="subtitle"
                                            >{{
                                                s.label[$i18n.locale]
                                            }}</v-list-item-subtitle
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                            </div>
                        </template>
                    </v-col>
                </v-row>
            </v-container>
        </v-list-item-group>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
export default {
    name: 'ShopPageNavigationMenu',
    components: {
        TranslatableStringComponent,
    },
    data() {
        return {}
    },
    props: {
        showHeading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState('common', ['siteSettings']),
        sectionOne() {
            return this.siteSettings?.shopPageSettings.shopMenuGroups.filter(
                (item) => item.column === 1
            )
        },
        sectionTwo() {
            return this.siteSettings?.shopPageSettings.shopMenuGroups.filter(
                (item) => item.column === 2
            )
        },
        sectionThree() {
            return this.siteSettings?.shopPageSettings.shopMenuGroups.filter(
                (item) => item.column === 3
            )
        },
        globalSection() {
            return [this.sectionOne, this.sectionTwo, this.sectionThree].filter(
                (i) => i.length > 0
            )
        },
    },
    methods: {
        handleCategoryRoute(category) {
            return this.getStaticLocalePath({
                type: 'page',
                slugName: 'shop',
                query: {
                    Categories: category.title.en,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.v-list-item {
    min-height: 28px !important;
}
.v-list-item--dense .v-list-item__content {
    padding: 4px 0;
}
.v-application .v-menu__content[role='menu'] .v-list-item:first-child {
    border-top: 0 !important;
}
.subtitle {
    white-space: normal !important;
}
</style>
