<template>
    <div class="video-wrapper">
        <!-- https://debbie.codes/blog/nuxt-lite-youtube-embeds/ -->
        <lite-youtube :videoid="video || videoID || ''" />
        <!-- <client-only>
            <youtube
                :video-id="video || videoID || ''"
                player-width="1120"
                player-height="630"
                host="https://www.youtube-nocookie.com"
            ></youtube>
        </client-only> -->
    </div>
</template>
<script>
export default {
    name: 'EmbedYouTube',
    props: {
        url: {
            type: String,
            default: ''
        },
        video: {
            type: String,
            default: ''
        },
        videoID: {
            type: String,
            default: ''
        }
    }
}
</script>
