export default {
    methods: {
        dollars(number, decimals = 2) {
            const num = parseFloat(number).toFixed(decimals)
            const numArr = num.toString().split('.')
            numArr[0] = numArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            return `$${numArr.join('.')}`
        }
    }
}
