import { getField, updateField } from 'vuex-map-fields'
import countries from '~/util/arrays/countries.js'
export const state = () => ({
    checkoutForm: {
        shippingIsBilling: true,
        billingFirstName: '',
        billingLastName: '',
        billingCompany: '',
        billingCountry: 'US',
        billingAddress1: '',
        billingAddress2: '',
        billingCity: '',
        billingState: '',
        billingZip: '',
        billingPhone: '',
        billingEmail: '',
        shippingFirstName: '',
        shippingLastName: '',
        shippingCompany: '',
        shippingCountry: 'US',
        shippingAddress1: '',
        shippingAddress2: '',
        shippingCity: '',
        shippingState: '',
        shippingZip: '',
        shippingPhone: '',
        shippingEmail: '',
        purchaseOrder: '',
        shippingAccount: '',
        orderMessage: '',
        netlifyProducts: '',
        orderProducts: null,
    },
    billingForm: {
        billingFirstName: '',
        billingLastName: '',
        billingCompany: '',
        billingCountry: 'US',
        billingAddress1: '',
        billingAddress2: '',
        billingCity: '',
        billingState: '',
        billingZip: '',
        billingPhone: '',
        billingEmail: '',
    },
    billingFormArray: [],
    selectedBillingAddressID: null,
    selectedShippingAddressID: null,
    purchaseOrderNumber: '',
    isShipItComplete: false,
    orderMessage: '',
    checkoutPages: [
        {
            name: 'cart',
            slug: {
                en: 'cart',
                es: 'carro-de-compras',
            },
            step: 1,
        },
        {
            name: 'checkout',
            slug: {
                en: 'checkout',
                es: 'realizar-pedido',
            },
            step: 2,
        },
        {
            name: 'customer-details',
            slug: {
                en: 'customer-details',
                es: 'detalles-del-cliente',
            },
            step: 3,
        },
        {
            name: 'payment',
            slug: {
                en: 'payment',
                es: 'pago',
            },
            step: 4,
        },
    ],
})

export const actions = {
    addNewContact({ commit }, payload) {
        commit('addNewBillingContact', payload)
    },
    editExistingContact({ commit }, payload) {
        commit('editBillingContact', payload)
    },
    async removeContact({ commit, state }, payload) {
        const res = await this.$axios.post('erp-contact?method=delete', payload)
        if (res?.response?.errors.length > 0) {
            alert(
                'Operation unsuccessful. Please refresh and try again or contact customerfirst@c3controls.com'
            )
            return
        }
        // console.log(res)
        commit('removeContact', payload.id)
        if (parseInt(state.selectedBillingAddressID) === parseInt(payload.id)) {
            commit('updateSelectedBillingAddressID', null)
        }
        if (
            parseInt(state.selectedShippingAddressID) === parseInt(payload.id)
        ) {
            commit('updateSelectedShippingAddressID', null)
        }
    },
    async getContacts({ commit, rootGetters }) {
        const res = await this.$axios.post('erp-contact?method=read', {
            webUserId: rootGetters['auth/webUser']?.id,
            customerIds: rootGetters['auth/webUser']?.Customers.map(
                (c) => c.id
            ),
        })
        // console.log(res)
        const contactList =
            res.data && res.data.contacts ? res.data.contacts : []

        commit('populateContactList', contactList)
    },
}

export const mutations = {
    updateCheckoutField(state, field) {
        updateField(state.checkoutForm, field)
    },
    updateBillingField(state, field) {
        updateField(state.billingForm, field)
    },
    updateShippingField(state, field) {
        updateField(state.shippingForm, field)
    },
    addNewBillingContact(state, payload) {
        state.billingFormArray = [...state.billingFormArray, { ...payload }]
    },
    editBillingContact(state, payload) {
        const recordNoEdited = payload.recordNo
        state.billingFormArray = [...state.billingFormArray].map((item) => {
            if (parseInt(item.recordNo) === recordNoEdited) {
                return payload
            }
            return item
        })
    },
    removeContact(state, payload) {
        state.billingFormArray = [...state.billingFormArray].filter(
            (item) => payload !== item.id
        )
    },
    populateContactList(state, payload) {
        state.billingFormArray = payload
    },
    updateSelectedBillingAddressID(state, payload) {
        state.selectedBillingAddressID = payload
    },
    updateSelectedShippingAddressID(state, payload) {
        state.selectedShippingAddressID = payload
    },
    updatePurchaseOrderNumber(state, payload) {
        state.purchaseOrderNumber = payload
    },
    updateOrderMessage(state, payload) {
        state.orderMessage = payload
    },
    updateIsShipItComplete(state, payload) {
        state.isShipItComplete = payload
    },
    clearCheckout(state) {
        state.cartProducts = []
        state.purchaseOrderNumber = ''
        state.orderMessage = ''
        state.isShipItComplete = false
    },
}

export const getters = {
    getCheckoutField(state) {
        return getField(state.checkoutForm)
    },
    getBillingField(state) {
        return getField(state.billingForm)
    },
    getBillingDetails(state) {
        const billingDetails = state.billingFormArray.find(
            (item) =>
                parseInt(item.id) === parseInt(state.selectedBillingAddressID)
        )
        return billingDetails
    },
    getShippingDetails(state) {
        const shippingDetails = state.billingFormArray.find(
            (item) =>
                parseInt(item.id) === parseInt(state.selectedShippingAddressID)
        )
        return shippingDetails
    },
    noAddressFound(state) {
        if (state.billingFormArray.length === 0) {
            return true
        } else {
            return false
        }
    },
    salesRepAsPerAreaCode(state, getters, rootState, rootGetters) {
        if (
            getters.getBillingDetails.phone1 &&
            rootState.common.siteSettings.salesReps
        ) {
            const areaCodePhone = getters.getBillingDetails.phone1.substring(
                1,
                4
            )
            const salesRep = rootState.common.siteSettings.salesReps.find(
                (item) => {
                    return (
                        item.areaCodes && item.areaCodes.includes(areaCodePhone)
                    )
                }
            )
            return salesRep
        } else {
            return null
        }
    },
    salesRepAsPerStateProvince(state, getters, rootState, rootGetters) {
        if (
            getters.getBillingDetails.addressState &&
            rootState.common.siteSettings.salesReps
        ) {
            const salesRep = rootState.common.siteSettings.salesReps.find(
                (item) => {
                    return (
                        item.states &&
                        item.states.includes(
                            getters.getBillingDetails.addressState
                        )
                    )
                }
            )
            return salesRep
        } else {
            return null
        }
    },
    salesRepAsPerCountry(state, getters, rootState, rootGetters) {
        if (
            getters.getBillingDetails.addressCountry &&
            rootState.common.siteSettings.salesReps
        ) {
            const alpha2code = countries.find(
                (item) => item.name === getters.getBillingDetails.addressCountry
            ).alpha2code

            const salesRep = rootState.common.siteSettings.salesReps.find(
                (item) => {
                    return item.countries && item.countries.includes(alpha2code)
                }
            )
            return salesRep
        } else {
            return null
        }
    },
    salesRepFallback(state, getters, rootState, rootGetters) {
        if (rootState.common.siteSettings.salesReps) {
            const salesRep = rootState.common.siteSettings.salesReps.find(
                (item) => {
                    return item.label && item.label.includes('FALLBACK')
                }
            )
            return salesRep
        } else {
            return null
        }
    },
    salesRep(state, getters, rootState, rootGetters) {
        if (getters.salesRepAsPerAreaCode) {
            return getters.salesRepAsPerAreaCode
        } else if (getters.salesRepAsPerStateProvince) {
            return getters.salesRepAsPerStateProvince
        } else if (getters.salesRepAsPerCountry) {
            return getters.salesRepAsPerCountry
        } else {
            return getters.salesRepFallback
        }
    },
    isInternationalShipping(state, getters, rootState, rootGetters) {
        return (
            getters.getShippingDetails &&
            getters.getShippingDetails.addressCountry !== 'United States'
        )
    },
    shippingAddressForOrder(state, getters, rootState, rootGetters) {
        const shippingAddress = state.billingFormArray.find(
            (item) => item.id === state.selectedShippingAddressID
        )
        return {
            shippingContactName: shippingAddress.name,
            shippingContactPrintAs: shippingAddress.namePrintAs,
            shippingContactAddress1: shippingAddress.address1,
            shippingContactAddress2: shippingAddress.address2,
            shippingContactCity: shippingAddress.addressCity,
            shippingContactState: shippingAddress.addressState,
            shippingContactPostalCode: shippingAddress.addressPostalCode,
            shippingContactCountry: shippingAddress.addressCountry,
            shippingContactCountryCode: shippingAddress.addressCountryCode,
            shippingContactEmail1: shippingAddress.email1,
            shippingContactPhone1: shippingAddress.phone1,
        }
    },
    billingAddressForOrder(state, getters, rootState, rootGetters) {
        const billingAddress = state.billingFormArray.find(
            (item) => item.id === state.selectedBillingAddressID
        )
        return {
            billingContactName: billingAddress.name,
            billingContactPrintAs: billingAddress.namePrintAs,
            billingContactAddress1: billingAddress.address1,
            billingContactAddress2: billingAddress.address2,
            billingContactCity: billingAddress.addressCity,
            billingContactState: billingAddress.addressState,
            billingContactPostalCode: billingAddress.addressPostalCode,
            billingContactCountry: billingAddress.addressCountry,
            billingContactCountryCode: billingAddress.addressCountryCode,
            billingContactEmail1: billingAddress.email1,
            billingContactPhone1: billingAddress.phone1,
        }
    },
    shippingMethodException(state, getters, rootState, rootGetters) {
        return (
            getters.getShippingDetails?.addressCountry === 'United States' &&
            ['AK', 'HI'].includes(getters.getShippingDetails?.addressState)
        )
    },
}
