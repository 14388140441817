<template>
    <div class="d-flex justify-end">
            <span v-if="seperator" class="nav-sep--text ml-2">|</span>
            <span class="mr-2 nav-a-lang-switcher">EN</span
            ><v-switch
                v-model="languageSwitchLocal"
                color="primary"
                dense
                hide-details
                class="mt-0 pt-0"
                @change="handleLangaugeSwitcher()"
            ></v-switch>
            <span class="nav-a-lang-switcher">ES</span>
        </div>
</template>
<script>
export default {
    name: 'LanguageSwitch',
    props: {
        seperator: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        languageSwitchLocal: {
            get() {
                return this.$store.state.common.languageSwitchGlobal
            },
            set(newVal) {
                this.$store.commit('common/handleLanguageSwitch', newVal)
            }
        }
    },
    mounted() {
        this.detectLangauge()
    },
    methods: {
        handleLangaugeSwitcher() {
            const selectedLanguage = this.languageSwitchLocal ? 'es' : 'en'
            const path = this.switchLocalePath(selectedLanguage)
            this.$router.push({ path })
        },
        detectLangauge() {
            switch (this.$i18n.locale) {
                case 'en':
                    this.languageSwitchLocal = false
                    break
                case 'es':
                    this.languageSwitchLocal = true
                    break
                default:
                    break
            }
        },
    }
}
</script>
