<template>
    <v-dialog v-model="localShowModal" width="500">
        <v-stepper v-model="stepperValue">
            <v-stepper-items>
                <v-stepper-content step="1" class="pa-0">
                    <v-card>
                        <v-card-title
                            class="headline grey lighten-2"
                            primary-title
                        >
                            <TranslatableStringComponent :tstring="{string: 'customerAccount'}" />
                            ?
                        </v-card-title>
                        <v-card-text class="pt-5">
                            <TranslatableBlockComponent :tstring="{string: 'customerAccountPresentAlert'}" />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="changeStepperValue()"
                            >
                                <TranslatableStringComponent :tstring="{string: 'yes'}" />
                            </v-btn>
                            <v-btn
                                color="error"
                                text
                                @click="handleProceedCheckout()"
                            >
                                <TranslatableStringComponent :tstring="{string: 'no'}" />
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
                <v-stepper-content step="2" class="pa-0">
                    <v-card>
                        <v-card-title
                            class="headline grey lighten-2"
                            primary-title
                        >
                            <div
                                class="d-flex justify-space-between align-center"
                                style="width: -webkit-fill-available"
                            >
                                <div>
                                    <TranslatableStringComponent :tstring="{string: 'connectToYourCustomerAccount'}" />
                                </div>
                                <v-btn icon @click="handleDialog()">
                                    <fa
                                        :icon="['fal', 'times']"
                                        class="fa-lg"
                                    />
                                </v-btn>
                            </div>
                        </v-card-title>
                        <FormConnectCustomer
                            :parent="parent"
                            :isAccountPage="isAccountPage"
                            @closeModal="handlePostCustomerConnect()"
                        />
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-dialog>
</template>

<script>
import FormConnectCustomer from '~/components/forms/FormConnectCustomer.vue'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue' 
import TranslatableBlockComponent from '~/components/TranslatableBlockComponent.vue' 
export default {
    name: 'ConnectCustomerDialog',
    components: {
        FormConnectCustomer,
        TranslatableBlockComponent,
        TranslatableStringComponent
    },
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        stepperValue: {
            type: String,
            default: '1',
        },
        parent: {
            type: String,
            default: ''
        },
        isAccountPage: {
            type: Boolean,
            default: false,
        },
    },  
    computed: {
        localShowModal: {
            get() {
                return this.showModal
            },
            set(val) {
                this.handleDialog()
            },
        }
    },
    methods: {
        handlePostCustomerConnect() {
            this.$emit('handlePostCustomerConnect')
        },
        handleDialog() {
            this.$emit('handleDialog')
        },
        handleProceedCheckout() {
            this.$emit('handleProceedCheckout')
        },
        changeStepperValue() {
            this.$emit('changeStepperValue')
        },
    },
}
</script>
