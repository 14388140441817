var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cta-links-wrapper"},[(_vm.layout === 'Default')?_c('div',_vm._l((_vm.links),function(link){return _c('div',{key:link._key},[_c(_vm.isButton(link) ? 'v-btn' : 'div',{tag:"component",class:_vm.calcButtonStyle(link)},[(_vm.isInternalLink(link))?_c('nuxt-link',{class:_vm.calcLinkClass(link),attrs:{"to":_vm.calcTo(link)}},[_vm._v(_vm._s(_vm.calcText(link)))]):(_vm.isExternalLink(link))?_c('a',{class:_vm.calcLinkClass(link),attrs:{"href":_vm.calcHref(link),"target":_vm.calcTarget(link)}},[_vm._v(_vm._s(_vm.calcText(link)))]):(_vm.isPhoneLink(link) || _vm.isEmailLink(link))?_c('a',{class:_vm.calcLinkClass(link),attrs:{"href":_vm.calcHref(link)}},[_vm._v(_vm._s(_vm.calcText(link)))]):_vm._e()],1)],1)}),0):(_vm.layout === 'AnchorTabs')?_c('div',[_c('v-btn-toggle',{attrs:{"tile":"","color":"white","group":"","dark":"","dense":""},model:{value:(_vm.AnchorTab),callback:function ($$v) {_vm.AnchorTab=$$v},expression:"AnchorTab"}},_vm._l((_vm.links),function(link){return _c('v-btn',{key:link._key,attrs:{"value":_vm.calcText(link),"target":_vm.isExternalLink(link) ? '_blank' : '_self'},on:{"click":function($event){_vm.calcAnchor(link)
                        ? _vm.$vuetify.goTo(_vm.calcAnchor(link), _vm.scrollOptions)
                        : _vm.goTo(link)}}},[_vm._v("\n                "+_vm._s(_vm.calcText(link))+"\n            ")])}),1)],1):(_vm.layout === 'NoLink')?_c('div',[_c('div',{staticClass:"cta-links-wrapper"},[_c('h2',{class:("shadow " + _vm.font)},[_vm._v(_vm._s(_vm.calcText(_vm.links[0])))])])]):(_vm.layout === 'Image')?_c('div',[_c('div',{staticClass:"cta-links-wrapper"},[(_vm.isInternalLink(_vm.links[0]))?_c('nuxt-link',{class:_vm.calcLinkClass(_vm.links[0]),attrs:{"to":_vm.calcTo(_vm.links[0])}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('EmbedSanityImage',{attrs:{"selectedImage":_vm.content.sanityImage.selectedImage,"classes":"mt-0","alt":_vm.content.sanityImage.alt,"width":_vm.imgWidth,"height":"150"}})],1)]}}],null,false,2189338875)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.calcText(_vm.links[0])))])])],1):(_vm.isExternalLink(_vm.links[0]))?_c('a',{class:_vm.calcLinkClass(_vm.links[0]),attrs:{"href":_vm.calcHref(_vm.links[0]),"target":_vm.calcTarget(_vm.links[0])}},[_c('EmbedCloudinary2',{attrs:{"image-id":_vm.content.image.imageId,"image-dir":_vm.content.image.imageDir,"image-align":_vm.img.align,"classes":"mt-0","alt":_vm.content.image.alt && _vm.content.image.alt[_vm.$i18n.locale]
                            ? _vm.content.image.alt
                            : {
                                  en: 'c3controls image',
                                  es: 'c3controls image',
                              },"width":_vm.imgWidth,"height":"300"}})],1):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }