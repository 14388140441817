import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
    productForm: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        company: '',
        message: '',
        subject: 'other'
    }
})

export const actions = {}

export const mutations = {
    updateInquiryField(state, field) {
        updateField(state.productForm, field)
    }
}

export const getters = {
    getInquiryField(state) {
        return getField(state.productForm)
    }
}
