import prependRoute from './prependRoute'
function renderUrl({ item, lang, nuxt, type }) {
    // const nothing = '#'
    let route = ''
    if (type === 'editProduct') {
        const id = item.product_id || null
        const slug = item.parent || null
        if (!id || !slug) return '#'
        route = prependRoute({
            type: 'product',
            slug
        })
    }

    if (item && item.menuItemPhone && item.menuItemPhone.linkTo) {
        return `tel:+${item.menuItemPhone.linkTo}`
    }
    if (
        item &&
        lang &&
        item._type &&
        ((item.changeLandingPage && item.setLandingPage) ||
            (item.slug && item.slug[lang]))
    ) {
        if (
            item.changeLandingPage &&
            item.setLandingPage &&
            item.setLandingPage._type &&
            item.setLandingPage.slug &&
            item.setLandingPage.slug[lang]
        ) {
            route = prependRoute({
                type: item.setLandingPage._type,
                slug: item.setLandingPage.slug
            })
        } else {
            route = prependRoute({ type: item._type, slug: item.slug })
        }
    }
    if (item.menuItemURL) {
        route = prependRoute({
            type: item.menuItemURL._type,
            slug: item.menuItemURL.slug
        })
    }
    if (item.submenuItemURL) {
        route = prependRoute({
            type: item.submenuItemURL._type,
            slug: item.submenuItemURL.slug
        })
    }
    if (item.eBookCtaLink) {
        route = prependRoute({
            type: item.eBookCtaLink._type,
            slug: item.eBookCtaLink.slug
        })
    }
    const base = process.env.V2_BASE_URL || 'https://www.c3controls.com'
    if (route[lang]) return nuxt ? route[lang] : `${base}${route[lang]}`
    return false
}
export { renderUrl as default }
