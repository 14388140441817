<template>
    <div>
        <div
            :class="`cloudinary-wrapper ${wrapperClasses} ${classes} ${imageAlign}`"
            :style="css"
        >
            <client-only>
                <div
                    :class="`cld-image ${innerClasses} ${classes} ${imageAlign} `"
                >
                    <img
                        :src="src.src"
                        :srcset="
                            $vuetify.breakpoint.xs
                                ? src.srcsetMobile
                                : src.srcset
                        "
                        :alt="
                            alt && alt[$i18n.locale]
                                ? alt[$i18n.locale]
                                : 'c3controls image'
                        "
                        :width="width"
                        :height="height"
                    />
                </div>
            </client-only>
        </div>
        <div
            v-if="caption && caption[$i18n.locale]"
            class="img-caption--wrapper"
        >
            <div class="img-caption--text">{{ caption[$i18n.locale] }}</div>
        </div>
    </div>
</template>
<script>
// NOTES
// https://cloudinary.com/blog/how_to_automatically_adapt_website_images_to_retina_and_hidpi_devices
// https://cloudinary.com/blog/responsive_images_with_srcset_sizes_and_cloudinary
// https://cloudinary.com/documentation/javascript_image_manipulation
// https://cloudinary.com/documentation/image_transformations
import { cl } from '~/plugins/global.js'
export default {
    name: 'EmbedCloudinary2',
    props: {
        imageId: {
            type: String,
            default: '',
        },
        imageDir: {
            type: String,
            default: '',
        },
        alt: {
            type: Object,
            default() {
                return { en: 'c3controls', es: 'c3controls' }
            },
        },
        borderRadius: {
            type: Boolean,
            default: true,
        },
        // border: {
        //     type: String,
        //     default: 'none'
        // },
        width: {
            type: String,
            default: 'auto',
        },
        height: {
            type: String,
            default: 'auto',
        },
        classes: {
            type: String,
            default: '',
        },
        wrapperClasses: {
            type: String,
            default: '',
        },
        innerClasses: {
            type: String,
            default: '',
        },
        // imgClasses: {
        //     type: String,
        //     default: ''
        // },
        imageAlign: {
            type: String,
            default: 'center',
        },
        crop: {
            type: String,
            default: 'fill',
        },
        mobileWidth: {
            type: String,
            default: '100',
        },
        tabletWidth: {
            type: String,
            default: '100',
        },
        desktopWidth: {
            type: String,
            default: '100',
        },
        // context: {
        //     type: String,
        //     default: ''
        // },
        caption: {
            type: Object,
            default: null,
        },
        // isSavedCartItem: {
        //     type: Boolean,
        //     default: false
        // }
        // mobileAlign: {
        //     type: String,
        //     default: 'center'
        // },
        // tabletAlign: {
        //     type: String,
        //     default: 'left'
        // },
        // desktopAlign: {
        //     type: String,
        //     default: 'right'
        // }
    },
    computed: {
        css() {
            if (process.client && this.context !== 'specific') {
                const bp = this.$vuetify.breakpoint
                if (bp.smAndDown) {
                    return {
                        maxWidth: `${this.mobileWidth}%`,
                        // float: 'none',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        textAlign: 'center',
                    }
                } else if (bp.mdAndDown) {
                    return { maxWidth: `${this.tabletWidth}%` }
                } else if (bp.lgAndUp) {
                    return { maxWidth: `${this.desktopWidth}%` }
                }
            }
            return {}
        },
        src() {
            const idString =
                this.imageId && this.imageDir
                    ? `${this.imageDir}/${this.imageId}`
                    : this.imageId
            const validIdString = idString || 'logos/c3controls-icon'
            const hx1 =
                this.height === 'auto'
                    ? ''
                    : Math.floor(parseFloat(this.height))
            const hx03 =
                this.height === 'auto'
                    ? ''
                    : Math.floor(parseFloat(this.height) * 0.3)
            const hx04 =
                this.height === 'auto'
                    ? ''
                    : Math.floor(parseFloat(this.height) * 0.4)
            const hx05 =
                this.height === 'auto'
                    ? ''
                    : Math.floor(parseFloat(this.height) * 0.5)
            const hx15 =
                this.height === 'auto'
                    ? ''
                    : Math.floor(parseFloat(this.height) * 1.5)
            const hx20 =
                this.height === 'auto'
                    ? ''
                    : Math.floor(parseFloat(this.height) * 2)
            const hx25 =
                this.height === 'auto'
                    ? ''
                    : Math.floor(parseFloat(this.height) * 2.5)
            const hx30 =
                this.height === 'auto'
                    ? ''
                    : Math.floor(parseFloat(this.height) * 3)
            const wx1 = this.width === 'auto' ? 'auto' : parseFloat(this.width)
            const wx03 =
                this.width === 'auto'
                    ? 'auto'
                    : Math.floor(parseFloat(this.width) * 0.3)
            const wx04 =
                this.width === 'auto'
                    ? 'auto'
                    : Math.floor(parseFloat(this.width) * 0.4)
            const wx05 =
                this.width === 'auto'
                    ? 'auto'
                    : Math.floor(parseFloat(this.width) * 0.5)
            const wx15 =
                this.width === 'auto'
                    ? 'auto'
                    : Math.floor(parseFloat(this.width) * 1.5)
            const wx20 =
                this.width === 'auto'
                    ? 'auto'
                    : Math.floor(parseFloat(this.width) * 2)
            const wx25 =
                this.width === 'auto'
                    ? 'auto'
                    : Math.floor(parseFloat(this.width) * 2.5)
            const wx30 =
                this.width === 'auto'
                    ? 'auto'
                    : Math.floor(parseFloat(this.width) * 3)
            const standardProps = {
                crop: this.crop,
                gravity: 'west',
                fetch_format: 'auto',
                flags: 'lossy',
                quality: 'auto',
                dpr: 'auto',
            }
            const src = cl.url(validIdString, {
                width: hx1 ? 'auto' : wx1,
                height: hx1 || '',
                ...standardProps,
            })
            const src03 = cl.url(validIdString, {
                width: hx03 ? 'auto' : wx03,
                height: hx03 || '',
                ...standardProps,
            })
            const src04 = cl.url(validIdString, {
                width: hx04 ? 'auto' : wx04,
                height: hx04 || '',
                ...standardProps,
            })
            const src05 = cl.url(validIdString, {
                width: hx05 ? 'auto' : wx05,
                height: hx05 || '',
                ...standardProps,
            })
            const src15 = cl.url(validIdString, {
                width: hx15 ? 'auto' : wx15,
                height: hx15 || '',
                ...standardProps,
            })
            const src20 = cl.url(validIdString, {
                width: hx20 ? 'auto' : wx20,
                height: hx20 || '',
                ...standardProps,
            })
            const src25 = cl.url(validIdString, {
                width: hx25 ? 'auto' : wx25,
                height: hx25 || '',
                ...standardProps,
            })
            const src30 = cl.url(validIdString, {
                width: hx30 ? 'auto' : wx30,
                height: hx30 || '',
                ...standardProps,
            })
            if (this.imageId !== 'c3controls-logo-t') {
                return {
                    src,
                    srcset: `${src},${src04} 0.4x, ${src05} 0.5x, ${src15} 1.5x, ${src20} 2x, ${src25} 2.5x, ${src30} 3x`,
                    srcsetMobile: `${src03} 0.3x, ${src04} 0.4x, ${src05} 0.5x,`,
                }
            } else {
                return {
                    src: cl.url(validIdString, {
                        width: 'auto',
                        height: '50',
                        ...standardProps,
                    }),
                    srcset: '',
                }
            }
        },
        // imgCss() {
        //     return this.height === 'auto'
        //         ? this.isSavedCartItem
        //             ? { maxHeight: '6rem' }
        //             : {}
        //         : { maxHeight: `${parseFloat(this.height)}px` }
        // }
    },
}
</script>
<style lang="scss" scoped>
.highlight {
    border: 2px solid red;
}
</style>
