<template>
    <div>
        <a
            v-for="i in rearrangeSocialIcons()"
            :key="i._id"
            :href="i.socialUrl"
            target="_blank"
            class="footer-icon--social"
        >
            <fa :icon="['fab', i.icon]" :class="iconStyle"
        /></a>
    </div>
</template>
<script>
export default {
    name: 'SocialIcons',
    props: {
        section: {
            type: String,
            default: 'footer',
        },
    },
    computed: {
        socialIcons() {
            return this.$store.state.common.socialIcons
        },
        iconStyle() {
            if (this.section === 'widget') {
                return 'fa-2x'
            } else {
                return 'fa-lg'
            }
        },
    },
    methods: {
        getIconName(icon) {
            const a = ['fab', icon]
            return a
        },
        rearrangeSocialIcons() {
            const order = ['facebook', 'linkedin', 'twitter', 'youtube', 'instagram']
            return [...this.socialIcons].sort(
                (a, b) => order.indexOf(a.menuSlug) - order.indexOf(b.menuSlug)
            )
        },
    },
}
</script>
