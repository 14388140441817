var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.menuId}},[_c('client-only',[_c('v-list',[_c('v-list-item',{staticClass:"pa-0 bg-dark"},[_c('v-list-item-content',{staticClass:"pb-0"},[(_vm.isLoggedIn)?_c('v-list-item-title',{staticClass:"py-3 pl-4 white--text"},[_c('div',{staticClass:"text-uppercase font-weight-bold"},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'hello'}}}),_vm._v(", "+_vm._s(_vm.userName)+"\n                        ")],1),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v("\n                            "+_vm._s(_vm.webUser && _vm.webUser.email)+"\n                        ")])]):_c('v-list-item-title',{staticClass:"py-3 pl-4"},[_c('v-btn',{attrs:{"outlined":"","to":_vm.getStaticLocalePath({
                                    type: 'page',
                                    slugName: 'login'
                                }),"color":"white","nuxt":""}},[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'loginRegister'}}})],1)],1)],1),_vm._v(" "),(!_vm.miniVariant)?_c('v-list-item-action',[_c('fa',{staticClass:"fa-lg",attrs:{"icon":['fal', 'times-circle']},on:{"click":function($event){$event.stopPropagation();return _vm.$nuxt.$emit('toggleRightDrawer')}}})],1):_vm._e()],1),_vm._v(" "),_vm._l((_vm.menu.menuItems),function(item,i){return [(
                        !item.submenuItems || item.submenuItems.length <= 0
                    )?_c('v-list-item',{key:("item-" + i),class:{ bottom: item.menuItemSep, 'pa-0': true },attrs:{"to":item.menuItemPhone ? '' : _vm.getCustomLocalePath(item),"href":item.menuItemPhone && item.menuItemPhone.linkTo
                            ? ("tel:+" + (item.menuItemPhone.linkTo))
                            : '#',"router":!item.menuItemPhone,"exact":!item.menuItemPhone}},[_c('v-list-item-action',[_c('fa',{staticClass:"fa-lg",attrs:{"icon":_vm.renderIcon({ item: item })}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                                item.menuItemLabel &&
                                item.menuItemLabel[_vm.$i18n.locale]
                                    ? item.menuItemLabel[_vm.$i18n.locale]
                                    : '- data missing -'
                            )}})],1)],1):_vm._e(),_vm._v(" "),(item.submenuItems && item.submenuItems.length > 0)?_c('v-list-group',{key:("item-" + i),class:{
                        'pa-0': true
                    },scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{key:("litem-" + i),class:{
                                bottom: item.menuItemSep,
                                parent: true,
                                'pa-0': true
                            },on:{"click":function($event){return _vm.$nuxt.$emit('toggleItemsOpen', item._key)}}},[_c('v-list-item-action',[_c('fa',{staticClass:"fa-lg",attrs:{"icon":_vm.renderIcon({ item: item })}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                                        item.menuItemLabel &&
                                        item.menuItemLabel[_vm.$i18n.locale]
                                            ? item.menuItemLabel[
                                                  _vm.$i18n.locale
                                              ]
                                            : '- data missing -'
                                    )}})],1)],1)]},proxy:true}],null,true),model:{value:(_vm.allOpenItems[item._key]),callback:function ($$v) {_vm.$set(_vm.allOpenItems, item._key, $$v)},expression:"allOpenItems[item._key]"}},[_vm._v(" "),_vm._l((item.submenuItems),function(child,j){return _c('v-list-item',{key:("child-" + j),class:{
                            bottom: child.menuItemSep,
                            child: true,
                            'pa-0': true
                        },attrs:{"to":_vm.getCustomLocalePath(child),"dense":""}},[(_vm.miniVariant)?_c('v-list-item-action',[_c('fa',{staticClass:"fa-lg",attrs:{"icon":_vm.renderIcon({ item: item })}})],1):_vm._e(),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                                    child.submenuItemLabel &&
                                    child.submenuItemLabel[_vm.$i18n.locale]
                                        ? child.submenuItemLabel[
                                              _vm.$i18n.locale
                                          ]
                                        : '- data missing -'
                                )}})],1)],1)})],2):_vm._e()]}),_vm._v(" "),(_vm.isLoggedIn)?_c('v-list-item',{staticClass:"pa-0",on:{"click":function($event){return _vm.handleDialog()}}},[_c('v-list-item-action',[_c('fa',{staticClass:"fa-lg",attrs:{"icon":['fal', 'sign-out']}})],1),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_c('TranslatableStringComponent',{attrs:{"tstring":{string: 'logout'}}})],1)],1)],1):_vm._e()],2),_vm._v(" "),_c('v-list',[_c('v-list-item',[_c('SocialIcons')],1),_vm._v(" "),_c('v-list-item',[_c('LanguageSwitch')],1),_vm._v(" "),_c('v-list-item',[_c('span',{staticClass:"mr-1"},[_vm._v("© 1976-"+_vm._s(new Date().getFullYear())+"\n                ")]),_vm._v(" "),_c('span',[_vm._v("c3controls")])])],1),_vm._v(" "),_c('dialogLogout',{attrs:{"dialog":_vm.dialog},on:{"handleDialog":function($event){return _vm.handleDialog()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }