export const state = () => ({
    orders: []
})

export const actions = {
    async fetchOrderHistory({ commit }, payload) {
        const response = await this.$axios.post('intacct-order-history', {})
        commit('addOrders', response.data.orders)
    },

    emptyOrderList({ commit }, payload) {
        commit('removeOrders')
    }
}

export const mutations = {
    addOrders(state, payload) {
        state.orders = payload
    },
    removeOrders(state, payload) {
        state.orders = []
    }
}

export const getters = {
    getBillingDetails(state) {
        const billingDetails = state.billingFormArray.find(
            (item) =>
                parseInt(item.recordNo) ===
                parseInt(state.selectedBillingAddressID)
        )
        return billingDetails
    },
    getShippingDetails(state) {
        const shippingDetails = state.billingFormArray.find(
            (item) =>
                parseInt(item.recordNo) ===
                parseInt(state.selectedShippingAddressID)
        )
        return shippingDetails
    }
}
