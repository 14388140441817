const prefixEnum = {
    dash: '-',
    slash: '/'
}

/**
 * prefixing function.
 * @param {object} table - ProductTable with property Code Prefix
 * @param {object} option - ProductOption
 * @returns {object} - returns option with valid prefix applied.
 */
export default function prefixing(table, option) {
    const firstCharOfCode = option.code.substring(0, 1)
    const hasPrefix = Object.values(prefixEnum).includes(firstCharOfCode)
    const { codePrefix } = table
    const isCodePrefixValid = Object.keys(prefixEnum).includes(codePrefix)

    if (hasPrefix || !isCodePrefixValid) {
        return option
    }

    option.code = prefixEnum[codePrefix] + option.code
    return option
}

export function getPrefix(codePrefix, code) {
    if (codePrefix === 'dash' && code.substring(0, 1) !== '-') {
        return '-' + code
    } else if (codePrefix === 'slash' && code.substring(0, 1) !== '/') {
        return '/' + code
    } else if (code === '(Blank)') {
        return ''
    } else {
        return code
    }
}
