<template>
    <v-footer v-if="!isCookieSet" id="cookie-notice" :fixed="true">
        <v-container fluid>
            <v-row column justify-center align-center>
                <v-col cols="12" class="text-center"
                    >
                    <TranslatableStringComponent :tstring="{string: 'weUseCookies'}" />
                    <!-- </v-col>
                <v-col cols="6" class="text-left"> -->
                    <v-btn
                        small
                        color="white"
                        class="ml-4 mr-4 mb-2 mt-2"
                        @click="setCookie()"
                        >OK</v-btn
                    ><v-btn
                        small
                        color="white"
                        class="mb-2 mt-2"
                        :to="
                            getStaticLocalePath({
                                type: 'page',
                                slugName: 'terms'
                            })
                        "
                        >
                        <TranslatableStringComponent :tstring="{string: 'privacyPolicy'}" />
                    </v-btn
                    >
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>
<script>
import Cookies from 'js-cookie'
import TranslatableStringComponent from '~/components/TranslatableStringComponent.vue'
export default {
    name: 'PopupCookieNotice',
    components: {
        TranslatableStringComponent,
    },
    data() {
        return {
            isCookieSet: true // assume the cookie is true, to prevent flash
        }
    },
    mounted() {
        this.getCookie()
    },
    methods: {
        setCookie() {
            Cookies.set('c3Cookies', 'true')
            this.isCookieSet = true
        },
        getCookie() {
            const cookieValue = Cookies.get('c3Cookies')
            if (cookieValue === '' || cookieValue === undefined) {
                this.isCookieSet = false
            } else {
                this.isCookieSet = true
            }
        }
    }
}
</script>
