import { mapState, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapState('auth', ['inviteToken']),
        ...mapGetters({
            isLoggedIn: 'auth/isLoggedIn',
            currentUser: 'auth/currentUser',
            webUser: 'auth/webUser',
            signupToken: 'auth/signupToken',
            passwordRecoveryToken: 'auth/passwordRecoveryToken',
            isApprovedForTerms: 'auth/isApprovedForTerms',
            ARTerm: 'auth/ARTerm',
            userName: 'auth/userName',
        }),
    },
}
