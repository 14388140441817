<template>
    <div :id="menuId">
        <v-menu
            v-for="(menuItem, i) in menu.menuItems"
            :key="i"
            :close-on-content-click="true"
            :open-on-hover="true"
            :offset-y="true"
            :nudge-left="60"
        >
            <template v-slot:activator="{ on: hover }">
                <!-- <a
                    v-if="isExternal(menuItem, $i18n.locale)"
                    :href="menuItem.menuItemURLo[$i18n.locale]"
                    :class="`nav-a--text${
                        i + 1 === menu.menuItems.length ? ' mr-0off' : ''
                    }`"
                    color="primary"
                    v-on="hover"
                >
                    {{ menuItem.menuItemLabel[$i18n.locale] }}
                </a> -->
                <nuxt-link
                    :to="getCustomLocalePath(menuItem)"
                    nuxt
                    exact
                    :class="`nav-a--text${
                        parseInt(i) + 1 === menu.menuItems.length
                            ? ' mr-0off'
                            : ''
                    }`"
                    color="primary"
                >
                    <span v-on="hover"
                        >{{ menuItem.menuItemLabel[$i18n.locale] }}
                    </span>
                </nuxt-link>
                <span
                    v-show="i > 0 && i < menu.menuItems.length"
                    class="nav-sep--text"
                    >|</span
                >
            </template>
            <div v-if="menuItem.menuItemLabel.en === 'Shop'">
                <ShopPageNavigationMenu />
            </div>
            <v-list
                v-else-if="
                    menuItem.submenuItems && menuItem.submenuItems.length > 0
                "
                dense
            >
                <v-list-item
                    v-if="menuItem.menuItemLabel.en.includes('My Account')"
                >
                    <v-list-item-title v-if="isLoggedIn" class="py-3">
                        <div class="text-uppercase font-weight-bold">
                            <TranslatableStringComponent :tstring="{string: 'hello'}" />, {{ userName }}
                        </div>
                        <p>{{ webUser && webUser.email }}</p>
                    </v-list-item-title>
                    <v-list-item-title v-else class="py-3">
                        <nuxt-link
                            :to="
                                getStaticLocalePath({
                                    type: 'page',
                                    slugName: 'login',
                                })
                            "
                            class="nav-a--text"
                        >
                            <TranslatableStringComponent :tstring="{string: 'loginRegister'}" />
                        </nuxt-link>
                    </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item
                    v-for="(submenuItem, j) in menuItem.submenuItems"
                    :key="j"
                >
                    <v-list-item-title
                        v-if="isExternal(submenuItem, $i18n.locale)"
                    >
                        <a
                            :href="submenuItem.submenuItemURLo[$i18n.locale]"
                            class="nav-a--text"
                        >
                            {{ submenuItem.submenuItemLabel[$i18n.locale] }}
                        </a>
                    </v-list-item-title>
                    <v-list-item-title
                        v-else-if="isInternal(submenuItem, $i18n.locale)"
                    >
                        <nuxt-link
                            :to="getCustomLocalePath(submenuItem)"
                            nuxt
                            exact
                            class="nav-a--text"
                        >
                            {{ submenuItem.submenuItemLabel[$i18n.locale] }}
                        </nuxt-link>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <span class="nav-sep--text">|</span>
        <div v-show="isLoggedIn">
            <a
                class="d-none d-md-flex nav-a--text nav-a--logout nohover"
                small
                outlined
                @click="dialog = true"
                ><TranslatableStringComponent :tstring="{string: 'logout'}" /></a
            >
        </div>
        <div v-show="!isLoggedIn">
            <nuxt-link
                :to="
                    getStaticLocalePath({
                        type: 'page',
                        slugName: 'register',
                    })
                "
                color="primary"
                class="d-none d-md-flex nav-a--text nav-a--register"
                small
                ><TranslatableStringComponent :tstring="{string: 'register'}" /></nuxt-link
            >
        </div>
        <span v-show="!isLoggedIn" class="nav-sep--text">|</span>
        <div v-show="!isLoggedIn">
            <nuxt-link
                :to="
                    getStaticLocalePath({
                        type: 'page',
                        slugName: 'login',
                    })
                "
                color="secondary"
                class="d-none d-md-flex nav-a--text nav-a--login nohover"
                small
                ><TranslatableStringComponent :tstring="{string: 'login'}" /></nuxt-link
            >
        </div>
        <LanguageSwitch  :seperator="true" />
        <dialogLogout :dialog="dialog" @handleDialog="handleDialog()" />
    </div>
</template>
<script>
import handleAuth from '../../mixins/handleAuth'
import menuUtilities from '~/mixins/menuUtilities'
import dialogLogout from '~/components/dialogLogout.vue'
import ShopPageNavigationMenu from '~/components/shop/shopUtilities/ShopPageNavigationMenu.vue'
import LanguageSwitch from '~/components/LanguageSwitch.vue'
export default {
    name: 'MenuNavigation',
    components: {
        dialogLogout,
        ShopPageNavigationMenu,
        LanguageSwitch,
    },
    mixins: [menuUtilities, handleAuth],
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
        handleDialog() {
            this.dialog = !this.dialog
        },
    },
}
</script>
