import _ from 'lodash'

export const localeRoutePrefix = {
    en: '',
    es: 'es',
}
export const simpleRoutes = {
    article: { slugPrefix: { en: 'article', es: 'articulo' } },
    topic: { slugPrefix: { en: 'blog', es: 'blog' } },
    industry: { slugPrefix: { en: 'industry', es: 'industria' } },
    category: { slugPrefix: { en: 'category', es: 'categoria' } },
    account: { slugPrefix: { en: 'my-account', es: 'mi-cuenta' } },
    resource: { slugPrefix: { en: 'resource', es: 'documento' } },
    whitepaper: { slugPrefix: { en: 'white-paper', es: 'documento-tecnico' } },
    testimonial: { slugPrefix: { en: 'testimonial', es: 'testimonial' } },
    profile: { slugPrefix: { en: 'team', es: 'personal' } },
    product: { slugPrefix: { en: 'products', es: 'productos' } },
    productSingle: { slugPrefix: { en: 'products', es: 'productos' } },
}

const complexRoutes = {
    page: (entity) => {
        let routes = []
        if (
            entity.slug.en.current === 'home' ||
            entity.slug.es.current === 'inicio'
        ) {
            routes = routes.concat(['/', `/${localeRoutePrefix.es}/`])
        } else {
            routes = routes.concat([
                `/${entity.slug.en.current}/`,
                `/${localeRoutePrefix.es}/${entity.slug.es.current}/`,
            ])
        }
        return routes
    },
}
function getRoutesForSimple(Route, entity) {
    return [
        `/${Route.slugPrefix.en}/${entity.slug.en.current}/`,
        `/${localeRoutePrefix.es}/${Route.slugPrefix.es}/${entity.slug.es.current}/`,
    ]
}
export default function routesGenerator(allEntities) {
    const allRoutes = allEntities.map((entity) => {
        if (Object.keys(complexRoutes).includes(entity._type)) {
            return complexRoutes[entity._type](entity).map((route) => ({
                route,
                payload: entity,
            }))
        } else if (Object.keys(simpleRoutes).includes(entity._type)) {
            return getRoutesForSimple(simpleRoutes[entity._type], entity).map(
                (route) => ({
                    route,
                    payload: entity,
                })
            )
        } else {
            return [
                `/oops/${entity.slug.en.current}/`,
                `/${localeRoutePrefix.es}/oops/${entity.slug.en.current}/`,
            ].map((route) => ({
                route,
                payload: entity,
            }))
        }
    })

    const flat = _.flatten(allRoutes)
    const filtered = flat.filter((item) => {
        // Spanish (es) routes
        // const isSpanish = item.route.substring(0, 4) === '/es/'
        // const baseUrlSpanish =
        //    process.env.V2_BASE_URL === 'https://es.c3controls.tech'
        // IMPORTANT! Excluding subcategories here
        const isBaseStaging = process.env.V2_BASE_URL === 'https://staging.c3controls.tech'
        // const isBaseLocal = process.env.V2_BASE_URL === 'http://localhost:3000'
        const isSubcategory =
            item.payload &&
            item.payload._type === 'category' &&
            item.payload.isFeatured === false
        if (isSubcategory) {
            return false
        } else if (item.route.includes('dol-620')) {
            return isBaseStaging
        } else {
            return true
        }
    })
    return filtered
}
