export default (context) => {
    // console.log(context)

    const { app, $axios } = context
    app.router.beforeEach((to, from, next) => {
        if (from.path !== '/') {
            const { params, path, query } = from
            const prevRoute = { params, path, query }
            localStorage.setItem('c3prev', JSON.stringify(prevRoute))
        }
        const exceptionPaths = [
            '/checkout/',
            '/payment/',
            '/customer-details/',
            '/cart/',
            '/login/',
            '/register/',
        ]

        const isFound = exceptionPaths.find((item) => {
            const lastChar = to.path.substr(-1) // Selects the last character
            let toPath
            if (lastChar !== '/') {
                // If the last character is not a slash
                toPath = to.path + '/' // Append a slash to it.
            } else {
                toPath = to.path
            }
            return item === toPath
        })

        if (
            app.store.state.priceList.appliedPriceList.length > 0 &&
            app.store.getters['priceList/isPriceListMismatched']
        ) {
            let message = `Shared cart pricing will revert to your approved pricing level if you leave this page. Are you sure you want to leave?`
            if (to.query.isIgnore) {
                // If user Agrees to remove the pricelist and navigate else where
                app.store.commit('priceList/setSelectedPriceListId', '')
                app.store.commit('priceList/setAppliedPriceList', [])
                app.store.commit('savedCarts/setCurrentSavedCartId', null)
                app.store.commit('savedCarts/setCurrentSavedCart', [])
                next()
                return
            } else if (!isFound) {
                message = `Shared cart pricing will revert to your approved pricing level if you leave this page. Are you sure you want to leave?`
            } else if (
                app.store.getters['auth/isLoggedIn'] &&
                isFound &&
                isFound !== '/cart/'
            ) {
                message = `You are not eligible for the shared cart pricing applied to these items. Cart items will revert to your approved pricing if you proceed to checkout. Do you want to proceed?`
            } else {
                next()
                return
            }
            app.store.commit(
                'savedCarts/setPromptToMutateSavedCartMessage',
                message
            )
            app.store.commit('savedCarts/setPromptToMutateSavedCart', true)
            app.store.commit('savedCarts/setToPage', to.path)
            return
        }
        next()
    })
    app.router.afterEach(async (to, from) => {

        if (from.path.includes('products/')) {
            // when navigating away from a configurator, make sure
            // the configurations.js state doesnt haunt their next configurator
            app.store.dispatch('configurations/clearConfiguration')
        }
        if (app.store.getters['auth/isLoggedIn']) {
            const resp = await $axios.post('handle-page-view', {
                route: to.fullPath,
                email: app.store.getters['auth/currentUser']?.email,
                timeStamp: new Date(),
            })
        }

    })
}
