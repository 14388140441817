/* ******** STATE *******   */

export const state = () => ({
    isActive: false,
    currentMessage: {},
    currentColor: 'primary',
    currentTimeout: 6000,
    notifications: []
})
/* ******** ACTIONS ******* */
export const actions = {
    CLEAR_SNACKBAR({ dispatch, commit }) {
        commit('CLOSE_SNACKBAR')
        commit('CLEAR_CURRENT_MESSAGE')
        commit('RESET_CURRENT_TIMEOUT')
        setTimeout(() => commit('RESET_CURRENT_COLOR'),2000)
        
    },
    GET_READY_TO_CLEAR_SNACKBAR({ dispatch, commit, state }) {
        setTimeout(function() {
            dispatch('CLEAR_SNACKBAR')
        }, state.currentTimeout)
    }
}
/* ******** MUTATIONS ******* */
export const mutations = {
    SET_CURRENT_MESSAGE(state, payload) {
        state.currentMessage = payload
    },
    SET_CURRENT_COLOR(state, payload) {
        state.currentColor = payload ? payload : 'primary'
    },
    SET_CURRENT_TIMEOUT(state, payload) {
        state.currentTimeout = payload ? payload : 6000
    },
    CLEAR_CURRENT_MESSAGE(state) {
        state.currentMessage = { string: ''}
    },
    RESET_CURRENT_COLOR(state){
        state.currentColor = 'primary'
    },
    RESET_CURRENT_TIMEOUT(state){
        state.currentTimeout = 6000
    },
    ADD_NOTIFICATION(state, notification) {
        state.notifications.push(notification)
    },
    REMOVE_NOTIFICATION(state, notification) {
        state.notifications.splice(state.notifications.indexOf(notification), 1)
    },
    OPEN_SNACKBAR(state) {
        state.isActive = true
    },
    CLOSE_SNACKBAR(state) {
        state.isActive = false
    }
}
/* ******** GETTERS ******* */
export const getters = {
    snackbarIsActive(state) {
        return state.isActive
    },
    fetchCurrentMessage(state) {
        return state.currentMessage
    },
    fetchCurrentTimeout(state) {
        return state.currentTimeout
    },
    fetchCurrentColor(state) {
        return state.currentColor
    }
}
